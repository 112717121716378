import React from "react";
import { useEffect, useState } from "react";
import SearchInput from "./searchInput";
import Header from "../../layout/header";
import "./jumbotron.css";

export default function () {
  useEffect(() => {
    const words = [
      "collaboration",
      "events",
      "connection",
      "experience",
      "chance",
      "milestone",
      "exposure",
      "partnership",
    ];
    const changeWord = () => {
      const word = words[Math.floor(Math.random() * words.length)];
      setWord(word);
    };
    const interval = setInterval(() => {
      changeWord();
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [word, setWord] = useState("Craft");
  return (
    <div className="landing_page_jumbotron jumbotron ">
      <div className="landing_page__navbar">
        <div className="mainmenu-wrapper">
          <Header class="menu--light" />
        </div>
      </div>

      <div className="landing_page_jumbotron_title_description_container">
        <div className="landing_page_jumbotron_title_description">
          <h1 className="display-4 landing_page_jumbotron_title">
            Socicraft: find, hire and work with the best talent for your
            projects from specialist to artist & creatives of all types.
          </h1>

          <h3 className="landing_page_jumbotron_description  landing_page_jumbotron_bottom_description">
            We connect you with talent & crafters who are ready to help you with
            you project.
          </h3>
        </div>
        <SearchInput />
      </div>
      <h3 className="landing_page_jumbotron_description">
        Do you need a ... <span>{word} ? The possibilities are endless.</span>
      </h3>
    </div>
  );
}
