import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect } from "react";
import UploadImage from "../content/modal/gallery/uploadImage";
import CreateAlbum from "../content/modal/gallery/createAlbum";

import { useSelector, useDispatch } from "react-redux";
import {
  getAudioAlbum,
  clearAlbumAudios,
} from "../../redux/action/craftProfile";
import FolderBar from "../folder/folderBar";
import { AudioBar } from "../audio/audioBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
}));

export const Audio = ({ isAuthenticated, viewType, userId }) => {
  const styles = {
    view: {
      visibility: viewType === "view-profile" ? "hidden" : "visible",
    },
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAudioAlbum());
  }, [dispatch]);

  const audioAlbum = useSelector((state) => state.craftProfile.audioAlbums);

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [view, setView] = useState("audio");
  const [showCreateAlbum, setShowCreateAlbum] = useState(false);
  const [showUploadAudio, setShowUploadAudio] = useState(false);
  const [showAudioAlbums, setShowAudioAlbums] = useState(true);
  const [selectedAlbumId, setSelectedAlbumId] = useState("");

  const toggleCreateAlbum = () => {
    setShowCreateAlbum(!showCreateAlbum);
  };
  const toggleUploadAudio = () => {
    setShowUploadAudio(!showUploadAudio);
  };

  const toggleAudioView = (type, id) => {
    setView(type);
    let audioAlbumShowState = showAudioAlbums;
    setShowAudioAlbums(!audioAlbumShowState);
    setSelectedAlbumId(id);
    dispatch(clearAlbumAudios());
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>Audio</h4>
                </div>
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div>
              {audioAlbum != null && showAudioAlbums === true ? (
                <div>
                  {" "}
                  <FolderBar
                    viewType={viewType}
                    isAuthenticated={isAuthenticated}
                    type="audioalbum"
                    albumInfo={audioAlbum}
                    togglePhotoView={toggleAudioView}
                  />
                </div>
              ) : (
                <p> </p>
              )}
            </div>
            {!showAudioAlbums ? (
              <AudioBar
                viewType={viewType}
                isAuthenticated={isAuthenticated}
                userId={userId}
                selectedAlbumId={selectedAlbumId}
              />
            ) : (
              <p></p>
            )}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {view !== "audio" && (
                  <div className="custom-control custom-switch">
                    <button
                      className="btn  btn-sm btn-info"
                      onClick={() => toggleAudioView("audio")}
                    >
                      <i className="fa fa-backward" aria-hidden="true"></i>
                      {""}
                      {""} Back
                    </button>
                  </div>
                )}
              </Grid>

              <Grid item style={styles.view}>
                {view === "audio" ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleCreateAlbum}
                  >
                    <i className="fas fa-plus "> </i>{" "}
                    {view === "audio" ? "Create Play List " : "Upload Audio"}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleUploadAudio}
                  >
                    <i className="fas fa-plus "> </i>{" "}
                    {view === "audio" ? "Create Audio Album" : "Upload Audio"}
                  </button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Put the audio player here */}
      </div>
      <CreateAlbum
        type={"audio"}
        showCreateAlbum={showCreateAlbum}
        toggleCreateAlbum={toggleCreateAlbum}
      />{" "}
      {
        <UploadImage // This must be turn in to UploadVideo
          showUploadPhoto={showUploadAudio}
          toggleUploadPhoto={toggleUploadAudio}
          selectedAlbumId={selectedAlbumId}
          type={"audio"}
        />
      }
    </Fragment>
  );
};
