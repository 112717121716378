import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Invoice from "./invoice/invoice";
import TaxInformation from "./taxInfo/taxInformation";
import "./earnings.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showItemsInCart } from "../../redux/action/cart";

import Earnings from "./Earnings/earnings";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { URL_MAPPING_EARNINGS_SUB1 } from "../../services/consts";
import { paymentHistory } from "../../redux/action/payment";

export default function EarningsParent() {
  const [value, setValue] = useState("1");
  const tabStyle = {
    outline: "none",
  };

  const dispatch = useDispatch();
  const location = useLocation().search;
  const history = useHistory();
  useEffect(() => {
    dispatch(showItemsInCart());

    const st = new URLSearchParams(location).get("st");

    if (st) {
      history.push({
        search: location + st,
      });
      switch (st) {
        case "earnings":
          handleChange(null, "1");
          break;
        case "invoice":
          handleChange(null, "2");
          break;
        case "taxInfo":
          handleChange(null, "3");
          break;

        default:
          break;
      }
    }
  }, [dispatch]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    history.push({
      search: URL_MAPPING_EARNINGS_SUB1[newValue],
    });
  };

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            scrollBehavior: "auto",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            className="mb-3 mt-3 "
          >
            {" "}
            <Tab
              style={tabStyle}
              className="ml-5 mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              label="Earnings"
              value="1"
            />
            {/* <Tab
              style={tabStyle}
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              label="Invoice"
              value="2"
            /> */}
            <Tab
              style={tabStyle}
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              label="Tax Info"
              value="3"
            />
          </TabList>
        </Box>{" "}
        <TabPanel value="1">
          <Earnings />
        </TabPanel>
        <TabPanel value="2">
          <Invoice />
        </TabPanel>
        <TabPanel value="3">
          <TaxInformation />
        </TabPanel>{" "}
      </TabContext>
    </div>
  );
}
