import React from "react";
import "./audioplayer.css";
function Details(props) {
  return (
    <div className="c-player--details">
      <div className="details-img">
        {props.albumImage && (
          <img
            style={{
              width: "130px",
              height: "130px",
              objectFit: "cover",
              animation: props.isPlaying ? "rotation 10s infinite linear" : "",
            }}
            src={props.albumImage}
            alt="socicraft user audio"
          />
        )}
      </div>
    </div>
  );
}

export default Details;
