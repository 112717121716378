import {
  LOADUSER,
  LOGINFAIL,
  REGISTERFAIL,
  REGISTERSUCCESS,
  LOGINSUCCESS,
  LOADINGSTARTED,
  LOADINGCOMPLETED,
  LOGOUT,
  REGISTRATION_STARTED,
  REGISTRATION_COMPLETED,
} from "./types";

import API from "../../api/api";
import setAuthToken from "../../api/setAuthToken";
import { setAlert } from "./alert";
import { toast } from "react-toastify";
import { getConversation } from "./messaging";

export const registeruser = (body) => async (dispatch) => {
  try {
    dispatch({ type: REGISTRATION_STARTED });
    toast.dismiss();
    const result = await API.post("/auth/register", body, {
      withCredentials: true,
    });

    setAuthToken(result.headers["x-auth-user"], result.data);
    dispatch({ type: REGISTERSUCCESS, payload: result.data });
    dispatch(
      setAlert(
        "green",
        "Hello " +
          result.data.userName +
          " Thank you for being a part of socicraft "
      )
    );
    dispatch({ type: REGISTRATION_COMPLETED });
  } catch (error) {
    error.response.data.msg.user &&
      dispatch(setAlert("error", error.response.data.msg.user));
    error.response.data.msg.email &&
      dispatch(setAlert("error", error.response.data.msg.email));
    dispatch({ type: REGISTERFAIL });
  }
  dispatch({ type: REGISTRATION_COMPLETED });

  //
};
export const providerLoaduser = () => async (dispatch) => {
  try {
    const userloader = await API.get("/auth/google/userloader", {
      withCredentials: true,
    });

    setAuthToken(userloader.headers["x-auth-user"], userloader.data);

    dispatch({ type: LOGINSUCCESS, payload: userloader.data });
    dispatch(getConversation(userloader.headers["x-auth-user"]));
  } catch (error) {
    //Login fail similar to loaduser fail :)
    dispatch({ type: LOGINFAIL });
    dispatch({ type: LOADINGCOMPLETED });
    return false;
  }
};

export const loaduser = () => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");

    if (!token) return dispatch({ type: LOGINFAIL });

    const data = await API.get("/auth", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    setAuthToken(token, data.data);
    dispatch({ type: LOADUSER, payload: data.data });
  } catch (error) {
    //Login fail similar to loaduser fail :)
    dispatch({ type: LOGINFAIL });
    dispatch({ type: LOADINGCOMPLETED });
    let token = localStorage.getItem("token");
    if (token) {
      dispatch(
        setAlert("warning", "You have been logged out! Session Expired")
      );
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
};
export const logoutuser = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: LOADINGSTARTED });

    dispatch({ type: LOGOUT });
    dispatch({ type: LOADINGCOMPLETED });
    dispatch(setAlert("warning", "You have been logged out successfully"));
  } catch (error) {
    //Login fail similar to loaduser fail :)
    dispatch({ type: LOGINFAIL });
    dispatch({ type: LOADINGCOMPLETED });
  }
};

export const loginuser = (body) => async (dispatch) => {
  try {
    dispatch({ type: LOADINGSTARTED });
    const response = await API.post(
      "/auth/login",

      body,
      { withCredentials: true }
    );
    setAuthToken(response.headers["x-auth-user"], response.data);
    dispatch({ type: LOGINSUCCESS, payload: response.data });
    dispatch({ type: LOADINGCOMPLETED });
    dispatch(setAlert("success", "Welcome " + response.data.userName));
    return true;
  } catch (error) {
    //Login fail similar to loaduser fail :)
    if (!error.response) {
      dispatch(setAlert("error", "Connection Problem!"));
      return dispatch({ type: LOADINGCOMPLETED });
    }

    dispatch(setAlert("error", error.response.data.msg));

    dispatch({ type: LOGINFAIL });
    dispatch({ type: LOADINGCOMPLETED });
  }
};
export const sendResetLink = (email) => async (dispatch) => {
  try {
    dispatch({ type: LOADINGSTARTED });

    let response = await API.post(
      `/auth/password-reset`,
      {},
      {
        headers: {
          "x-auth-email": email,
        },
      }
    );

    dispatch({ type: LOADINGCOMPLETED });
    return response;
  } catch (error) {
    //Login fail similar to loaduser fail :)
    dispatch(setAlert("error", "If you don't have an account please signup"));
    dispatch({ type: LOADINGCOMPLETED });
  }
};

export const completeprofile = (body) => async (dispatch) => {
  try {
    const pfcomplete = await API.post("/auth/google/completeProfile", body, {
      withCredentials: true,
    });
    setAuthToken(pfcomplete.headers["x-auth-user"], pfcomplete.data);

    dispatch({ type: LOGINSUCCESS, payload: pfcomplete.data });
    dispatch(getConversation());
  } catch (error) {
    dispatch(setAlert("error", "Ahhhhh  " + error.response.data.msg));
    dispatch({ type: LOGINFAIL });
    dispatch({ type: LOADINGCOMPLETED });
  }
};
export const validatePasswordResetLink = (token) => async (dispatch) => {
  try {
    let response = await API.post(`/auth/validate-psw-link/${token}`);

    return response ? response : false;
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(setAlert("error", error.response.data.msg));
    }
    //Login fail similar to loaduser fail :)
    // dispatch({ type: LOGINFAIL });
    // dispatch({ type: LOADINGCOMPLETED });
  }
};

export const updatePassword = (user) => async (dispatch) => {
  try {
    if (!user.password && !user.password2)
      return dispatch(setAlert("error", "Please Enter Password"));
    if (user.password !== user.password2)
      return dispatch(
        setAlert("error", "Password and confirm password does not match.")
      );

    let response = await API.post("/auth/change-password", user);
    if (response && response.status === 200) {
      setAuthToken(response.headers["x-auth-user"]);
      dispatch({ type: LOGINSUCCESS, payload: response.data });
      dispatch(setAlert("success", "Password changed!"));
    }

    //   return response ? response : false;
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(setAlert("error", error.response.data.msg));
    }
    //Login fail similar to loaduser fail :)
    // dispatch({ type: LOGINFAIL });
    // dispatch({ type: LOADINGCOMPLETED });
  }
};
export const uploadProfilePicture = (data) => async (dispatch) => {
  try {
    await API.post(
      "/auth/upload_profile_picture",
      data,
      {
        headers: {
          "x-auth-user": localStorage.getItem("token"),
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      },
      { withCredentials: true }
    );
    dispatch(loaduser());
    dispatch(setAlert("success", "Profile Image Saved!!"));
    //   return response ? response : false;
  } catch (error) {
    dispatch(setAlert("error", "Unable to upload Image Please"));
    //Login fail similar to loaduser fail :)
    // dispatch({ type: LOGINFAIL });
    // dispatch({ type: LOADINGCOMPLETED });
  }
};
export const postRating =
  ({ applicant, listing, body }) =>
  async (dispatch) => {
    try {
      await API.post(
        `/auth/rating/${applicant.userId.id}/${listing.listingId.id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );
      dispatch(setAlert("success", "Review Submitted, Thank You !!"));
    } catch (error) {
      console.log(error);
    }
  };
export const verifyEmail = (token) => async (dispatch) => {
  try {
    const response = await API.post(
      `/auth/verify-email`,
      { token: token },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    if (response.data.msg === "Verified") {
      return;
    }
    dispatch(loaduser());
    dispatch(setAlert("success", " Email Verified Success"));
    // dispatch(setAlert("success", "Review Submitted, Thank You !!"));
  } catch (error) {
    console.log(error);
    dispatch(setAlert("error", "Unable to Verify Email"));
  }
};
