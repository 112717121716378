import * as React from "react";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import moment from "moment";

export default function ApplicantCard({
  userFirstName,
  userLastName,
  userAvatar,
  date,
  dateLabel,
}) {
  return (
    <div>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem
          style={{
            borderTop: "1px solid #e9e7e3",
            borderBottom: "1px solid #e9e7e3",
          }}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={userAvatar} />
          </ListItemAvatar>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <b>Applicant Name </b> :{" "}
                  <b>
                    {userFirstName} {userLastName}{" "}
                  </b>
                </Typography>
                <br />
                <b>{dateLabel ? dateLabel : "Accepted Date"} </b> :{" "}
                <b> {date && moment(date).format("DD MMM YYYY")} </b>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
