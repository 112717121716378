import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function NoticeModal({
  open,
  handleOpen,
  handleClose,
  updateCart,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Warning !
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Only one applicant can be processed at a time. Please finish your
            first transaction before proceeding with another.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 6,
              display: "flex",
              padding: "10px ",
              justifyContent: "center",
              gap: "50px",
            }}
          >
            <Button variant="contained" color="success" onClick={updateCart}>
              Proceed
            </Button>
            <Button variant="outlined" onClick={handleClose} color="secondary">
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
