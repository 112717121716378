import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { updateProjectReopened } from "../../../../redux/action/userpost";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  display: "flex",
  flexDirection: "column",

  boxShadow: 24,
  p: 4,
};

export default function ReopenModal({
  openReopenModal,
  handleReopenModal,
  currentApplicant,
  handleOpenReviewContainer,
}) {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [issues, setIssues] = React.useState("");
  const onChangeHandler = ({ currentTarget }) => {
    const { value } = currentTarget;
    setIssues(value);
  };
  const reopenHandler = () => {
    currentApplicant.body = { issues: issues };
    dispatch(updateProjectReopened(currentApplicant));
    handleReopenModal();
    handleOpenReviewContainer();
  };
  return (
    <div>
      <Modal
        open={openReopenModal}
        onClose={handleReopenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reopen Project
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, alignSelf: "center" }}
          >
            <p> Please Provide Detailed Issues.</p>
            <textarea
              onChange={onChangeHandler}
              value={issues}
              rows="8"
              cols="70"
            />
          </Typography>{" "}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 5, alignSelf: "center" }}
          >
            <button className="btn btn-primary" onClick={reopenHandler}>
              Submit
            </button>
          </Typography>{" "}
        </Box>
      </Modal>
    </div>
  );
}
