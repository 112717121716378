import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
const ContactInfoPrivate = () => {
  const { user } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );
  const { isAuthenticated } = useSelector((state) => state.auth);
  const webAddress = process.env.CLIENT_URL;
  return (
    <Fragment>
      <div className="atbd_content_module atbd_contact_information_module">
        <div className="atbd_content_module__tittle_area">
          <div className="atbd_area_title">
            <h4>Contact Information </h4>
          </div>
        </div>
        {isAuthenticated && user && (
          <div>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <span
                    style={{ fontSize: "30px" }}
                    className="la la-map-marker"
                  ></span>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      {user &&
                      user.address &&
                      !user.address.state &&
                      !user.address.country &&
                      !user.address.street &&
                      !user.address.email &&
                      !user.address.city &&
                      !user.address.zipCode
                        ? "Not Available"
                        : user &&
                          user.address && (
                            <>
                              {user.address.street} {user.address.city}{" "}
                              {user.address.state} {user.address.country}
                              {user.address.zipCode}
                            </>
                          )}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <span
                    style={{ fontSize: "30px" }}
                    className="la la-envelope"
                  ></span>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginTop: "10px" }}
                  primary={user && user.email}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <span
                    style={{ fontSize: "30px" }}
                    className="la la-phone"
                  ></span>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginTop: "10px" }}
                  primary={user && user.address && user.address.phoneNumber}
                />
              </ListItem>
              <Divider variant="inset" component="li" />

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <i className="la la-globe  la-2x text-primary"></i>
                </ListItemAvatar>
                <ListItemText style={{ marginTop: "10px" }} />

                <Link
                  style={{ marginTop: "10px" }}
                  to={`${webAddress}/${user.userName}`}
                >{`${webAddress}/${user.userName}`}</Link>
              </ListItem>
            </List>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ContactInfoPrivate;
