import React, { Fragment } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import { connect } from "react-redux";
import { BreadcrumbSingle } from "../content/element/breadcrumb-private";
import { ContentStory } from "../content/element/listing-details-story";
import Gallery from "../content/carousel/gallery";
import ListingCraftInfo from "../craftProfile/listing-craft-info";
import { AboutEntry } from "../content/element/aboutentry";
import { Video } from "../craftProfile/video";
import { Audio } from "../craftProfile/audio";
import { listing } from "../../services/softdata";
import ContactInfoPrivate from "../content/element/contact-info";
import {
  TagStyle,
  Supporters,
  RecentlyViewed,
  ViewProfile,
} from "../content/element/widget";

import Report from "../content/modal/report";
import Clime from "../content/modal/clime-list";
import { LinkModal, SupportModal } from "../content/element/modal";

const PrivateListingDetails = (props) => {
  const light = props.logo[0].light;
  const id = props.match.params.id;
  const filter = Object.values(listing).filter((value) => {
    return value.id === id;
  });

  return (
    <Fragment>
      <section className="listing-details-wrapper bgimage">
        <div className="bg_image_holder">
          <img src="./assets/img/details-img.jpg" alt="" />
        </div>
        <div className="mainmenu-wrapper">
          <Header logo={light} class="menu--light" />
        </div>

        <div className="listing-info content_above">
          <div className="container">
            <div className="row">
              <BreadcrumbSingle filter={filter} />
            </div>
          </div>
        </div>
      </section>
      <section className="directory_listiing_detail_area single_area section-bg p-top-30 p-bottom-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="atbd_auhor_profile_area">
                <div className="atbd_author_avatar">
                  <div className="atbd_auth_nd">
                    <h4>Testing Box</h4>
                    <br />
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                      ></input>
                      <label
                        class="custom-control-label"
                        htmlFor="customSwitch1"
                      >
                        Toggle this switch element
                      </label>
                      <h5 editable="true">Text goes here</h5>
                      <LinkModal />
                      <br />
                      <SupportModal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <Video />

              <ContentStory postingData={props.location.state.data} />
              {/* this line is an odd problem during front-end development, comment this code out during development and comment it back in before you push! */}

              <AboutEntry />
              <Audio />
              <ListingCraftInfo />
              <Gallery />
            </div>

            <div className="col-lg-4">
              <ContactInfoPrivate />
              <TagStyle />
              <Supporters />
              <ViewProfile />
              <RecentlyViewed />
            </div>
          </div>
        </div>
      </section>
      <Report />
      <Clime />
      <Footer />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
  };
};
export default connect(mapStateToProps)(PrivateListingDetails);
