import * as React from "react";
import Box from "@mui/material/Box";
import SearchInputs from "./searchInputs";
import "./dropdownfilter.css";

export default function Search() {
  return (
    <Box
      component="span"
      sx={{
        "& .MuiTextField-root": { width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="dropdown">
        <SearchInputs />
      </div>
    </Box>
  );
}
