import Modal from "@mui/material/Modal";
import PayoutTable from "./payOutTable";
import { useSelector } from "react-redux";

const PaymentHistoryModal = ({
  handlePayout,
  handlePendingPayout,
  payout,
  pendingPayout,
  open,
  handleOpen,
}) => {
  const title = {
    pendingPayout: "Pending Payout",
    payout: " Payout ",
  };
  const availablePayoutData = useSelector(
    (state) => state.stripe.availablePayout
  );
  const pendingPayoutData = useSelector((state) => state.stripe.pendingPayout);

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {" "}
        <div role="dialog" aria-labelledby="login_modal_label">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="login_modal_label">
                  {pendingPayout && title.pendingPayout}
                  {payout && title.payout}
                </h5>

                <button
                  type="button"
                  className="close overflow-hidden  "
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleOpen}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <PayoutTable
                  data={payout ? availablePayoutData : pendingPayoutData}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default PaymentHistoryModal;
