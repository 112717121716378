import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { getAllListings } from "../../../redux/action/userpost";
import { useDispatch, useSelector } from "react-redux";
import "./jumbotron.css";

export default function () {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState({
    keyword: "",
    address: "",
    category: "",
  });
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const onChangeHandler = ({ currentTarget }) => {
    setSearchTerm({ ...searchTerm, [currentTarget.name]: currentTarget.value });
  };

  const getListings = () => {
    dispatch(getAllListings(searchTerm));
  };

  return (
    <div className="landing_page_search_container container">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          aria-label="listing search text input"
          aria-describedby="basic-addon2"
          onChange={onChangeHandler}
          value={searchTerm.keyword}
          name="keyword"
        />
        <div className="input-group-append" onClick={() => getListings()}>
          <NavLink
            to={{
              pathname: "/all-listings-list",
            }}
          >
            <span
              className="landing_page_jumbotron_input_group_text input-group-text"
              id="basic-addon2"
            >
              <SearchIcon className="landing_page_jumbotron_search_icon" />
            </span>
          </NavLink>
        </div>
      </div>
      {!isAuthenticated ?
      <div>
        <a
          href="/register"
          className="access-link btn btn-xs btn-gradient btn-gradient-one"
        >
          Sign Up for free
        </a>
      </div> : '' }
    </div>
  );
}
