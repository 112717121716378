import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./industry-subcategories.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 375,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  maxHeight: "500px",
  overflowY: "scroll",
};

export default function IndustrySubcategories({
  open,
  handleOpen,
  handleClose,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Art</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div>
                <Typography class="accordion-details">Animation</Typography>
                <Typography class="accordion-details">Ceramics</Typography>

                <Typography class="accordion-details">Craft</Typography>
                <Typography class="accordion-details">Drawing</Typography>

                <Typography class="accordion-details">Glassblowing</Typography>

                <Typography class="accordion-details">illustration</Typography>
                <Typography class="accordion-details">Painting</Typography>
                <Typography class="accordion-details">Pottery</Typography>
                <Typography class="accordion-details">Photography</Typography>
                <Typography class="accordion-details">Tattoo</Typography>
                <Typography class="accordion-details">Sculpting</Typography>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Audio & Music</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Artist</Typography>
              <Typography class="accordion-details">Comedy</Typography>
              <Typography class="accordion-details">Composer</Typography>
              <Typography class="accordion-details">Editor</Typography>
              <Typography class="accordion-details">
                Instrumental & Beats
              </Typography>
              <Typography class="accordion-details">Lyricist</Typography>
              <Typography class="accordion-details">Producer</Typography>
              <Typography class="accordion-details">Podcast</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Culinary</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Catering</Typography>
              <Typography class="accordion-details">Culinary</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Cosmetics</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Barber</Typography>
              <Typography class="accordion-details">Hair</Typography>
              <Typography class="accordion-details">Lashes</Typography>
              <Typography class="accordion-details">Make-up</Typography>
              <Typography class="accordion-details">Nails</Typography>
              <Typography class="accordion-details">Skin</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Crew</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">
                Tech Crew Lighting
              </Typography>
              <Typography class="accordion-details">
                Tech Crew Listening
              </Typography>
              <Typography class="accordion-details">
                Tech Crew Podcasting
              </Typography>
              <Typography class="accordion-details">
                Tech Crew Production
              </Typography>
              <Typography class="accordion-details">
                Tech Crew Queuing
              </Typography>
              <Typography class="accordion-details">Tech Crew Sound</Typography>
              <Typography class="accordion-details">
                Backstage Crew Costumes
              </Typography>
              <Typography class="accordion-details">
                Backstage Crew Curtains
              </Typography>
              <Typography class="accordion-details">Backstage Props</Typography>
              <Typography class="accordion-details">
                Backstage Movement
              </Typography>
              <Typography class="accordion-details">
                Backstage Queuing
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Dance</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Aerobics</Typography>
              <Typography class="accordion-details">Choreographic</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Fashion</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Design</Typography>
              <Typography class="accordion-details">Modeling</Typography>
              <Typography class="accordion-details">Styling</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Film & Video</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Acting</Typography>
              <Typography class="accordion-details">Commentary</Typography>
              <Typography class="accordion-details">Cinema</Typography>
              <Typography class="accordion-details">Directors</Typography>
              <Typography class="accordion-details">Production</Typography>
              <Typography class="accordion-details">Videography</Typography>
              <Typography class="accordion-details">Voice Acting</Typography>
              <Typography class="accordion-details">SFX</Typography>
              <Typography class="accordion-details">Streaming</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Literature</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Blog</Typography>
              <Typography class="accordion-details">Editor</Typography>
              <Typography class="accordion-details">Story-board</Typography>
              <Typography class="accordion-details">Writing</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">Management</Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Event Planning</Typography>
              <Typography class="accordion-details">
                Project Management
              </Typography>
              <Typography class="accordion-details">Promotion</Typography>
              <Typography class="accordion-details">
                Talent Acquisition
              </Typography>
              <Typography class="accordion-details">
                Talent Management
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography class="typography">
                Media Communications & Technology
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography class="accordion-details">Graphic Design</Typography>

              <Typography class="accordion-details">Print</Typography>

              <Typography class="accordion-details">Packaging</Typography>
              <Typography class="accordion-details">Logo</Typography>
              <Typography class="accordion-details">Branding</Typography>
              <Typography class="accordion-details">Web development</Typography>
              <Typography class="accordion-details">
                User Interface (UI)
              </Typography>
              <Typography class="accordion-details">
                User Experience (UX)
              </Typography>
              <Typography class="accordion-details">Researcher</Typography>
              <Typography class="accordion-details">
                Software Development
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Modal>
    </div>
  );
}
