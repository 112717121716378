import React from "react";
import Rating from "@mui/material/Rating";
import { Typography } from "@mui/material";
import "./style.css";
export default function ListingRating({
  contractorRating,
  totalContractorReviews,
}) {
  console.log("contractor Rating ", contractorRating);
  return (
    <div className="landing_page_review-container">
      <div className="landing_page_review">
        <Rating name="read-only" value={Math.ceil(contractorRating)} readOnly />
      </div>

      <Typography
        variant="caption"
        display="block"
        gutterBottom
        className="landing_page_review-counter"
      >
        ({totalContractorReviews}{" "}
        {totalContractorReviews > 1 ? "reviews" : "review"} )
      </Typography>
    </div>
  );
}
