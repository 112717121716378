import {
  LOAD_DISCOVERY_CONTENTS,
  LOAD_TRENDING_LISTINGS,
  LOAD_ABOUT_AND_LISTINGS,
  CLEAN_ABOUT_AND_LISTINGS,
  LOAD_COMMENTS,
  LOAD_REPLIES,
  CLEAR_REPLIES,
  CLEAN_CONTENTS,
  FAILED_REQUEST,
  LOAD_DISCOVERY_ABOUT,
  CLEAN_COMMENTS,
  LOAD_LATEST_COMMENT,
  LOAD_LATEST_REPLY,
  LOAD_NEW_LISTINGS,
  SET_NAVIGATION,
  UPLOAD_SUCCESS,
  LOAD_UPVOTED_CONTENTS_IDS,
  LOADINGCOMPLETED,
  LOADINGSTARTED,
} from "./types";
import API from "../../api/api";
import { trendingListsTestData } from "../../components/postFeed/trendingListsTestData";
import { formatError } from "../../services/formatError";

export const getPost = (postId) => async (dispatch) => {
  try {
    console.log("shared post ", postId);
    const response = await API.get(`/discovery/content/shared/${postId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(getUpVotedContentIds());
    dispatch({ type: LOAD_DISCOVERY_CONTENTS, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getPosts =
  ({ limit = 5, skip = 0, fileType, searchKeyWord }) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/discovery/content?limit=${limit}&skip=${skip}&fileType=${fileType}&searchKeyWord=${searchKeyWord}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );
      dispatch(getUpVotedContentIds());
      dispatch({ type: LOAD_DISCOVERY_CONTENTS, payload: response.data });
    } catch (error) {
      const errorMessage = formatError(error.response.data.msg);

      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const postComment = (postId, comment) => async (dispatch) => {
  const limit = 1;
  const skip = 0;
  const isCommentPosted = true;
  const body = {
    comment: comment,
  };

  try {
    const response = await API.post(
      `/discovery/content/${postId}/comment`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );

    dispatch(getComments(postId, limit, skip, isCommentPosted));
      dispatch(getPosts( {limit : 5, skip : 0} ));
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const postReply = (commentId, reply) => async (dispatch) => {
  const body = {
    reply: reply,
  };
  const limit = 1;
  const skip = 0;
  const isReplyPosted = true;
  try {
    const response = await API.post(
      `/discovery/content/reply/${commentId}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    dispatch(getReply(commentId, limit, skip, isReplyPosted));
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    console.log("error ", error);
  }
};

export const getReply =
  (commentId, limit = 1, skip = 0, isReplyPosted, replyLimit = 1) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/discovery/content/reply/${commentId}?limit=${limit}&skip=${skip}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );
      if (isReplyPosted) {
        dispatch({ type: LOAD_LATEST_REPLY, payload: response.data });
      } else {
        dispatch({ type: LOAD_REPLIES, payload: response.data });
      }
    } catch (error) {
      const errorMessage = formatError(error.response.data.msg);

      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const getComments =
  (postId, limit, skip, isCommentPosted, replyLimit = 1) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/discovery/content/comment/${postId}?limit=${limit}&skip=${skip}&replyLimit=${replyLimit}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      if (isCommentPosted) {
        dispatch({ type: LOAD_LATEST_COMMENT, payload: response.data });
      } else {
        dispatch({ type: LOAD_COMMENTS, payload: response.data });
      }
    } catch (error) {
      const errorMessage = formatError(error.response.data.msg);

      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const postDiscoveryContent =
  (fileType, contents) => async (dispatch) => {
    try {
      dispatch({ type: LOADINGSTARTED });
      const response = await API.post(
        `/discovery/content?fileType=${fileType}`,
        contents,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${contents._boundary}`,
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: LOADINGCOMPLETED });
      dispatch(setNavigation("home"));
    } catch (error) {
      const errorMessage = formatError(error.response.data.msg);
      dispatch({ type: LOADINGCOMPLETED });
      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const getDiscoveryAbout = () => async (dispatch) => {
  try {
    const response = await API.get(`/discovery/user/profile`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: LOAD_DISCOVERY_ABOUT, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getTrendingListings = () => async (dispatch) => {
  try {
    const trendingListings = await API.get(`/discovery/trendinglistings`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_TRENDING_LISTINGS, payload: trendingListings.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);
    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getNewListings = () => async (dispatch) => {
  try {
    const newListings = await API.get(`/discovery/newlistings`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_NEW_LISTINGS, payload: newListings.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const postSaveForLater = (contentId) => async (dispatch) => {
  try {
    const response = await API.post(
      `/discovery/content/saveforlater/${contentId}`,
      {
        headers: {
          "Content-Type": `multipart/form-data;`,
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    // dispatch({
    //   type: UPLOAD_SUCCESS,
    //   payload: { isUploadSuccessful: true, message: "post saved" },
    // });
    // setTimeout(() => {
    //   dispatch({
    //     type: UPLOAD_SUCCESS,
    //     payload: { isUploadSuccessful: false, message: "" },
    //   });
    // }, 3000);
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const removeSaveForLater = (contentId) => async (dispatch) => {
  try {
    const response = await API.delete(
      `/discovery/content/removesaveforlater/${contentId}`,
      {
        headers: {
          "Content-Type": `multipart/form-data;`,
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );

    // dispatch({
    //   type: UPLOAD_SUCCESS,
    //   payload: { isUploadSuccessful: true, message: "post removed" },
    // });
    // setTimeout(() => {
    //   dispatch({
    //     type: UPLOAD_SUCCESS,
    //     payload: { isUploadSuccessful: false, message: "" },
    //   });
    // }, 3000);
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getSaveForLater =
  ({ limit = 5, skip = 0 }) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/discovery/content/saveforlater?limit=${limit}&skip=${skip}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: LOAD_DISCOVERY_CONTENTS, payload: response.data });
    } catch (error) {
      const errorMessage = formatError(error);

      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const getUpVotedContents =
  ({ limit = 5, skip = 0 }) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/discovery/content/upvotedcontents?limit=${limit}&skip=${skip}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: LOAD_DISCOVERY_CONTENTS, payload: response.data });
    } catch (error) {
      const errorMessage = formatError(error.response.data.msg);

      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const getCommentsPost =
  ({ limit = 5, skip = 0 }) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/discovery/content/commentspost?limit=${limit}&skip=${skip}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: LOAD_DISCOVERY_CONTENTS, payload: response.data });
    } catch (error) {
      const errorMessage = formatError(error.response.data.msg);

      // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    }
  };

export const upvote = (contentId, voteStatus) => async (dispatch) => {
  try {
    const response = await API.post(`/discovery/content/upvote/${contentId}`, {
      headers: {
        "Content-Type": `multipart/form-data;`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(getUpVotedContentIds());
    // dispatch({
    //   type: UPLOAD_SUCCESS,
    //   payload: { isUploadSuccessful: true, message: voteStatus },
    // });
    // setTimeout(() => {
    //   dispatch({
    //     type: UPLOAD_SUCCESS,
    //     payload: { isUploadSuccessful: false, message: "" },
    //   });
    // }, 3000);
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getUpVotedContentIds = () => async (dispatch) => {
  try {
    const response = await API.get(`/discovery/content/upvotedcontentsIds/`, {
      headers: {
        "Content-Type": `multipart/form-data;`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_UPVOTED_CONTENTS_IDS, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getAboutAndListings = (userId) => async (dispatch) => {
  try {
    const response = await API.get(`/discovery/user/${userId}/info`, {
      headers: {
        "Content-Type": `multipart/form-data;`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(clearAboutAndListings());
    dispatch({ type: LOAD_ABOUT_AND_LISTINGS, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const Ads = () => async (dispatch) => {};

export const clearAboutAndListings = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAN_ABOUT_AND_LISTINGS, payload: [] });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};
export const clearReplies = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_REPLIES, payload: [] });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};
export const cleanContents = () => async (dispatch) => {
  dispatch({ type: CLEAN_CONTENTS, payload: [] });
};

export const clearComments = () => async (dispatch) => {
  dispatch({ type: CLEAN_COMMENTS, payload: [] });
};

export const setNavigation = (navigationState) => async (dispatch) => {
  dispatch(cleanContents());
  dispatch(clearComments());
  dispatch(clearReplies());
  dispatch({ type: SET_NAVIGATION, payload: navigationState });
};
