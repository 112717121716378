import React from "react";
import MediaElement from "./mediaElement";
import "./style/mediaScroller.css";
import { useState } from "react";
import AudioPlayerCard from "../../postFeed/audioPlayer/audioPlayerCard";
import InfoCard from "../folder/infoCard";
import { useSelector } from "react-redux";

export const AudioBar = ({
  isAuthenticated,
  viewType,
  selectedAlbumId,
  type,
  userId,
}) => {
  const audios = useSelector(
    (state) => state.publicReducer.publicCraftProfile.albumAudios
  );
  const user = useSelector(
    (state) => state.publicReducer.publicCraftProfile.user
  );
  const [showUploadPhoto, setShowUploadPhoto] = useState(false);

  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
  };

  return (
    <div>
      {audios.length ? (
        <div className="audio_bar">
          {audios.map((audio, index) => (
            <div key={audio._id}>
              <AudioPlayerCard
                audios={[audio]}
                albumImage={user.userAvatar}
                albumImageKey={user.userAvatarKey}
              />
              <InfoCard
                title={audio.title}
                description={audio.description}
                tags={audio.tags}
              />
            </div>
          ))}

          {audios.map((audio, index) => (
            <MediaElement
              key={index}
              showUploadPhoto={showUploadPhoto}
              toggleUploadPhoto={toggleUploadPhoto}
              url={audio.url}
              id={audio._id}
              description={" "}
              tag={audio.tags}
              title={audio.title}
              selectedAlbumId={selectedAlbumId}
              viewType={viewType}
            />
          ))}
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          {" "}
          Empty Album
        </div>
      )}
    </div>
  );
};
