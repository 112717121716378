import React from "react";
import { useState } from "react";

export default function LinkCopier({ id }) {
  const [copyLink, setCopyLink] = useState(false);

  const copyLinkHandler = (link) => {
    const webAddress =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
        ? "https://workshop.socicraft.us"
        : "https://workshop.socicraft.us";
    setCopyLink(true);

    setTimeout(() => {
      setCopyLink(false);
    }, 1500);
    navigator.clipboard.writeText(`${webAddress}${link}`);
  };
  return (
    <button
      disabled={copyLink}
      onClick={() => copyLinkHandler(`/view-listing-details/${id}`)}
      className="btn btn-outline-primary m-2"
    >
      {copyLink ? (
        <span className="d-flex align-items-center justify-content-center ">
          <i className="fa fa-check"></i>{" "}
        </span>
      ) : (
        <>
          {" "}
          <span className="d-flex align-items-center justify-content-center ">
            <i className="fa fa-link"></i>{" "}
          </span>
        </>
      )}
    </button>
  );
}
