import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";

import { Typography } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import LoadingPercentage from "../loadingPercentage/loadingPercentage";
import ProgressStepper from "../stepper/stepper";
import ApplicantCardDocument from "../applicantCard/applicantCardDocument";
const cardStyle = {
  marginLeft: "20px",
  marginTop: "20px",
  maxWidth: "300px",
  minWidth: "300px",
  border: "2px solid red",
  borderRadius: "5px",
  padding: "10px",
};
export default function WaitingDocument({ document }) {
  const [mounted, setMounted] = useState(true);
  const [progress, setProgress] = useState(1);
  const [borderStyle, setBorderStyle] = useState("2px solid red");
  const [documentStatus, setDocumentStatus] = useState("");
  const dispatch = useDispatch();
  const documentStatusCheck = (document) => {
    if (
      document.recipients.client.userId.id.toString() === user.id &&
      document.recipients.client.status === "COMPLETED"
    ) {
      setBorderStyle("2px solid green");
      return setDocumentStatus("View Document");
    }
    if (
      document.recipients.contractor.userId.id.toString() === user.id &&
      document.recipients.contractor.status === "COMPLETED"
    ) {
      setBorderStyle("2px solid green");
      return setDocumentStatus("View Document");
    }
    if (
      document.recipients.contractor.userId.id.toString() === user.id &&
      document.recipients.client.status !== "COMPLETED"
    ) {
      setBorderStyle("2px solid green");
      return setDocumentStatus(
        <i className="text-secondary text-xl-center">
          Pending Client Signature
        </i>
      );
    }

    return setDocumentStatus("View and Sign Document");
  };
  useEffect(() => {
    documentStatusCheck(document);
    return () => {
      setMounted(false);
    };
  }, [dispatch]);

  const user = useSelector((state) => state.auth.user);

  const calculateProgress = (diff) => {
    console.log(mounted);
    const percentage = ((diff * 100) / 120).toFixed();
    setProgress(percentage);
  };

  const processing = (document) => {
    const diff = isProcessingCompleted(document);
    if (
      isProcessing(document) &&
      isProcessingCompleted(document) &&
      mounted &&
      diff < 121
    ) {
      const timeOut = setTimeout(() => {
        calculateProgress(diff);
      }, 2000);
      if (diff > 120) clearTimeout(timeOut);
      return true;
    } else {
      return false;
    }
  };
  const isProcessing = (document) => {
    if (
      document.recipients.contractor.userId.id.toString() === user.id &&
      document.recipients.contractor.processing
    ) {
      return true;
    }
    if (
      document.recipients.client.userId.id.toString() === user.id &&
      document.recipients.client.processing
    ) {
      return true;
    }
  };
  const isProcessingCompleted = (document) => {
    console.log(document);
    let diff = 0;
    if (
      document.recipients.contractor.userId.id.toString() === user.id &&
      document.recipients.contractor.processing
    ) {
      diff = moment().diff(
        moment(document.recipients.contractor.processingStarted),
        "seconds"
      );
      console.log(diff);
      return diff > 120 ? 0 : diff;
    }
    if (
      document.recipients.client.userId.id.toString() === user.id &&
      document.recipients.client.processing
    ) {
      diff = moment().diff(
        moment(document.recipients.client.processingStarted),
        "seconds"
      );
      console.log(diff);
      return diff > 120 ? 0 : diff;
    }
  };

  return (
    <div>
      <>
        <Card style={{ ...cardStyle, border: borderStyle }}>
          <div className="d-flex ">
            {/* <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={
                document.listing.listingImageUrl.length
                  ? document.listing.listingImageUrl[0].url
                  : "/assets/img/folder.png"
              }
            /> */}
            <div className="">
              <ProgressStepper step={document.step} />
            </div>
          </div>

          <CardContent style={{ maxHeight: "70px", marginBottom: "5px" }}>
            <Typography gutterBottom variant="h5" component="div">
              {document.listing.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {document.listing.projectLabel}
            </Typography>
          </CardContent>
          <ApplicantCardDocument document={document} />
          <CardActions style={{ display: "flex", justifyContent: "center" }}>
            {processing(document) ? (
              <>
                <div className="d-flex flex-column text-center ">
                  <LoadingPercentage done={progress} />
                </div>
              </>
            ) : (
              <div className="d-flex flex-column text-center ">
                {" "}
                <a href={`/docusign-processing/${document.id}`}>
                  {" "}
                  <button
                    disabled={!document.ready}
                    // onClick={() => createView(document.id)}
                    className="btn btn-primary text-dark"
                  >
                    {documentStatus}
                  </button>
                </a>
              </div>
            )}
          </CardActions>
        </Card>
      </>
    </div>
  );
}

// <iframe
//   width="900px"
//   height="900px"
//   title="sign"
//   src="https://demo.docusign.net/Signing/MTRedeem/v1/ec134107-67a4-454b-b729-ae73aa615b82?slt=eyJ0eXAiOiJNVCIsImFsZyI6IlJTMjU2Iiwia2lkIjoiNjgxODVmZjEtNGU1MS00Y2U5LWFmMWMtNjg5ODEyMjAzMzE3In0.AQYAAAABAAMABwAANCTUEn7aSAgAANQ1WzR-2kgYAAEAAAAAAAAAIQC9AgAAeyJUb2tlbklkIjoiYzMyZjkwZDAtOGYyMy00MzZhLWFlNTctN2Q1MDgxZGRkZjAxIiwiRXhwaXJhdGlvbiI6IjIwMjItMDgtMTRUMTY6Mzk6MTYrMDA6MDAiLCJJc3N1ZWRBdCI6IjIwMjItMDgtMTRUMTY6MzQ6MTYuNzEzNTkxMyswMDowMCIsIlJlc291cmNlSWQiOiIzNmViZTZhYy1kNmZiLTQ2MzktYjYzMS02YWI3MGM2Y2U0MDUiLCJSZXNvdXJjZXMiOiJ7XCJFbnZlbG9wZUlkXCI6XCIzNmViZTZhYy1kNmZiLTQ2MzktYjYzMS02YWI3MGM2Y2U0MDVcIixcIkFjdG9yVXNlcklkXCI6XCJlZDI5Mjg3Mi1hMWZjLTQyZDEtYmIzZS1kNjgwMjQxNTc4NThcIixcIlJlY2lwaWVudElkXCI6XCI5ZTkzZDBiZi1hODFmLTRjMTAtODk2OC0xODI2NzA5ZmIxOThcIixcIkZha2VRdWVyeVN0cmluZ1wiOlwidD1kMDkxYTM0NC03YTg3LTQyMzAtOThhMy1mNjMzZTk2OGVhNmRcIixcIkludGVncmF0b3JLZXlcIjpcIjBmZDIzODJjLTNlZjAtNDg4Ni1iODM3LTE1YjliMjU4MTkwYVwifSIsIlRva2VuVHlwZSI6MSwiQXVkaWVuY2UiOiIyNWUwOTM5OC0wMzQ0LTQ5MGMtOGU1My0zYWIyY2E1NjI3YmYiLCJSZWRpcmVjdFVyaSI6Imh0dHBzOi8vZGVtby5kb2N1c2lnbi5uZXQvU2lnbmluZy9TdGFydEluU2Vzc2lvbi5hc3B4IiwiSGFzaEFsZ29yaXRobSI6MCwiSGFzaFJvdW5kcyI6MCwiVG9rZW5TdGF0dXMiOjAsIklzU2luZ2xlVXNlIjpmYWxzZX0_AID7W4YTftpI.XbhCQk4-YJxSV8PZOZ-QAkGDsny_w-zsaCL7opENrNyp9tpMo9WJRFQvp4LzJdmrUuZw_6QkMqMjL3BE36cQepylIpKG3t2fiIP2_Oxr8dEd2Lzh3cG9WhzX2g_iBo6ro8xo88wnXoRcvV6xYjB5P4UU7nUObfHMxCwtkfhcRfsWV_0iZ8NcRuJA8QtrcZ1DLPE__DSIKu8w5m_e1Sc40rxuxZ87zm6lnpdaRYfOzBADJQemlNrGOpE09P0SVRqowYJQYzNmSBjhf2DJoi-o3VwQjVb71twoqizM7VOIkd5Y6XMv5fREXDmWVqAUyjWo6m0CAHLFGEMjJPzFF3HQ3w"
// >
//   {" "}
// </iframe>;
