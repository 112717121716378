import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { FilteredListings } from "./filteredListings";
import SkeletonLoader from "../skeletonLoader";
import {
  getNewListings,
  getTrendingListings,
} from "../../../redux/action/discovery";
import "../postFeedstyles.css";

export default function AllListings() {
  const [mount, setMount] = useState(true);
  const [dotClicked, setDotClicked] = useState(false);
  const [index, setIndex] = useState(0);
  const trendingDisplayTime = 5000;
  const listingImagePlaceholder =
    "https://images.unsplash.com/photo-1542621334-a254cf47733d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fHByb2plY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";

  const dispatch = useDispatch();
  const [currentListing, setCurrentListing] = useState("all");
  useEffect(() => {
    dispatch(getTrendingListings());
    dispatch(getNewListings());
    return () => {
      setMount(false);
    };
  }, []);

  const trendingLists = useSelector(
    (state) => state.discoveryReducer.trendingLists
  );

  const newListings = useSelector(
    (state) => state.discoveryReducer.newListings
  );

  const trendingTimeOut = setTimeout(() => {
    index === combined.length - 1 ? setIndex(0) : setIndex(index + 1);
  }, trendingDisplayTime);

  if (!mount) {
    clearTimeout(trendingTimeOut);
  }

  function listingChanger(type) {
    setCurrentListing(type);
  }

  if (dotClicked) {
    clearTimeout(trendingTimeOut);
  }

  let combined = [];

  for (let i = 0; i <= 4; i++) {
    combined.push({
      type: "trending",
      listing: trendingLists[i],
    });
    combined.push({ type: "new", listing: newListings[i] });
  }

  const dotHandler = (index) => {
    setDotClicked(true);
    setIndex(index);
  };

  return (
    <div>
      {trendingLists && trendingLists[0] ? (
        <div>
          {currentListing === "all" ? (
            <Card
              variant="outlined"
              className="trending"
              sx={{ minWidth: 350, maxWidth: 350 }}
            >
              {combined && combined[index].listing && (
                <div>
                  <CardContent>
                    {combined && combined[0].type && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="div"
                          style={{
                            color:
                              combined && combined[index].type === "trending"
                                ? "black"
                                : "#B8B8B8",
                          }}
                          onClick={() => listingChanger("trending")}
                        >
                          Trending
                        </Typography>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="div"
                          style={{
                            color:
                              combined && combined[index].type === "new"
                                ? "black"
                                : "#B8B8B8",
                          }}
                          onClick={() => listingChanger("new")}
                        >
                          New
                        </Typography>
                      </div>
                    )}

                    {combined[index].listing && (
                      <span className="listing_title">
                        {combined[index] &&
                          combined[index].listing.listingTitle}
                      </span>
                    )}
                    <CardMedia
                      component="img"
                      height="60"
                      image={
                        combined[index].listing.listingImage.url
                          ? combined[index].listing.listingImage.url
                          : listingImagePlaceholder
                      }
                      alt="socicraft user listing"
                    />
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{ mb: 1, mt: 2 }}
                    >
                      learn more about the author of this listing{" "}
                      <NavLink to={`/${combined[index].listing.username}`}>
                        <span className="username">
                          @{combined[index].listing.username}
                        </span>
                      </NavLink>
                    </Typography>
                    <div>
                      <Typography variant="body1" component="div">
                        {combined[index].listing.views} views posted on{" "}
                        {moment(combined[index].listing.postedDate).format(
                          "ll"
                        )}
                      </Typography>
                      <NavLink
                        style={{ marginLeft: "-7px" }}
                        to={`/view-listing-details/${combined[index].listing.listingId}`}
                      >
                        <span sx={{ ml: -1 }} className="viewMore">
                          view more
                        </span>
                      </NavLink>{" "}
                    </div>
                  </CardContent>

                  <div className="dotsContainerStyles">
                    {combined.map((slide, slideIndex) => (
                      <div
                        className="dotStyle"
                        key={slideIndex}
                        onClick={() => dotHandler(slideIndex)}
                      >
                        {slideIndex === index ? (
                          <div style={{ color: "cyan" }}> ● </div>
                        ) : (
                          <div> ●</div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <FilteredListings
              selectedListing={currentListing}
              trendingListings={trendingLists}
              newListings={newListings}
            />
          )}
        </div>
      ) : (
        <SkeletonLoader height={230} width={350} />
      )}
    </div>
  );
}
