import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import moment from "moment";
import ReplyDisplay from "../reply/replyDisplay";
import "../postFeedstyles.css";

export default function Comment({ postId, postComment, commentFrom }) {
  const { _id, userId, comment, repliesCount, createdAt } = postComment;

  const todayDate = new Date().getDate();
  const commentCreatedAt = new Date(createdAt).getDate();
  return (
    <Card
      className="comment_card"
      variant="outlined"
      sx={{
        mt: 2,
        borderRadius: 4,
        backgroundColor: "#ebedf0",
        border: 0,
      }}
    >
      <CardContent sx={{ display: "flex" }}>
        <Avatar
          sx={{ bgcolor: deepOrange[500], mr: 3 }}
          alt="socicraft user avatar"
        >
          {userId.userAvatarKey ? (
            <img className="discovery_user_avatar" src={userId.userAvatar} />
          ) : (
            userId.userName[0].toUpperCase()
          )}
        </Avatar>
        <div className="comment-body">
          <Typography variant="body1" className="author_name">
            {userId.firstName} {userId.lastName}
          </Typography>
          <Typography variant="body2">{comment}</Typography>

          <div className="time_display">
            {" "}
            {todayDate > commentCreatedAt
              ? moment(createdAt).format("ll")
              : moment(createdAt).fromNow("dd") + " ago"}
          </div>
        </div>
      </CardContent>
      <ReplyDisplay
        commentId={_id}
        repliesCount={repliesCount}
        postId={postId}
        commentFrom={commentFrom}
        style={{ width: "80%", margin: "0 auto" }}
      />
    </Card>
  );
}
