import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Details from "./details";

import { CardActionArea } from "@mui/material";

export default function InfoCard({ title, description, tags }) {
  return (
    <Card
      style={{
        background: "#c9c9c966",
        height: "20%",
        outline: "none !important",
      }}
    >
      <CardActionArea>
        <CardContent style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
          </div>

          {/* <Typography
            variant="body1"
            color="text.secondary"
            style={{ marginBottom: "10px" }}
          >
            {description}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {tags.map((tag) => (
              <span>{"#" + tag} </span>
            ))}
          </Typography> */}
          <Typography
            variant="body1"
            color="text.secondary"
            style={{ marginBottom: "10px" }}
          >
            {description.length > 25
              ? description.slice(0, 30) + " ..."
              : description}
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" color="text.primary">
              {tags.length > 5
                ? tags.slice(0, 4).map((tag) => <span>{"#" + tag} </span>)
                : tags.map((tag) => <span>{"#" + tag} </span>)}
            </Typography>
            {(title.length > 25 ||
              description.length > 25 ||
              tags.length > 5) && (
              <Details title={title} description={description} tags={tags} />
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
