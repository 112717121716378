import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";

import { Typography } from "@material-ui/core";
import {
  completeOnboarding,
  createPayout,
  getAccountBalance,
} from "../../../redux/action/stripe";

import { useLocation } from "react-router-dom";

import { loaduser } from "./../../../redux/action/auth";
import PaymentHistoryModal from "./paymentHistoryModal";
import { paymentHistory } from "../../../redux/action/payment";

export default function Earnings() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    const loadAccountDetails = () => {
      dispatch(getAccountBalance());
      dispatch(paymentHistory());
    };
    if (user && user.connectedAccounts.stripe.onboardingCompleted) {
      loadAccountDetails();
    }
  }, []);

  useEffect(() => {
    const status = new URLSearchParams(location.search).get("status");
    if (status === "completed") {
      dispatch(loaduser());
    }
    // const updatedUrl =
    //   window.location.protocol +
    //   "//" +
    //   window.location.host +
    //   window.location.pathname;
    // window.history.pushState({ path: updatedUrl }, "", updatedUrl);
  }, []);

  const { isLoading, balance, processingOnBoarding } = useSelector(
    (state) => state.stripe
  );

  const onboardingHandler = () => {
    dispatch(completeOnboarding())
      .then((data) => {
        const { url } = data;
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const cardStyle = {
    marginLeft: "20px",
    marginTop: "20px",
    maxWidth: "300px",
    minWidth: "300px",
    textAlign: "center",
    backgroundColor: "#ffffffbf",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    borderRadius: "13px",
  };
  const cardTextStyle = {
    padding: "4px 10px",
    backgroundColor: "#d4d4d4",
    borderRadius: "7px",
  };
  const cardButtonStyle = {
    borderRadius: "40px",
  };

  const cardDescriptionStyle = {
    color: "black",
  };
  const onboardingStatus = () => {
    if (user && !user.emailVerified) {
      return (
        <>
          <h3 className="alert alert-warning " role="alert">
            We have sent verification link to Your Email, click the link and
            verify your email to complete onboarding process.
          </h3>{" "}
        </>
      );
    } else if (
      (user &&
        user.emailVerified &&
        user.connectedAccounts.stripe.card_payments === "pending") ||
      user.connectedAccounts.stripe.transfers === "pending"
    ) {
      return (
        <h3>We are verifying your information, check back later. Thank you </h3>
      );
    } else {
      return (
        <>
          <h3 className="alert alert-warning " role="alert">
            Onboarding Not Completed. Complete onboarding and start Earning .
          </h3>{" "}
          <button onClick={onboardingHandler} className="btn btn-primary">
            Complete Onboarding
          </button>
        </>
      );
    }
  };
  const createPayoutHandler = () => {
    handlePayoutModal();
  };

  const [pendingPayout, showPendingPayout] = React.useState(false);
  const [payout, showPayout] = React.useState(false);

  const handlePayoutModal = () => {
    if (pendingPayout || payout) {
      showPendingPayout(false);
    }
    showPayout(!payout);
  };
  const handlePendingPayoutModal = () => {
    if (payout || pendingPayout) {
      showPayout(false);
    }
    showPendingPayout(!pendingPayout);
  };

  return (
    <>
      {/* {isLoading && (
        <div className="container d-flex justify-content-center">
          <Spinner />
        </div>
      )} */}

      {user && !user.connectedAccounts.stripe.onboardingCompleted && (
        <div className="container ">{onboardingStatus()}</div>
      )}

      {user &&
        user.emailVerified &&
        user.connectedAccounts.stripe.onboardingCompleted && (
          <div className="container d-flex flex-wrap justify-content-center ">
            <i className="font-weight-bolder text-center">
              <strong style={cardDescriptionStyle}>
                Funds will not be available for payout until the completion of
                the project is approved by the project author. If the project
                author is unresponsive, funds will be available for cash out in
                48 hours.
              </strong>{" "}
            </i>
            <Card style={cardStyle}>
              <CardContent
                className="d-flex flex-column align-items-center pt-5 m-auto"
                style={{ maxHeight: "100px" }}
              >
                <Typography variant="h6" color="text.secondary">
                  <span style={cardTextStyle}>
                    {balance.pendingBalance} <i className="fa fa-dollar"></i>
                  </span>
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="pt-3"
                >
                  Pending Payout
                </Typography>{" "}
                <Typography gutterBottom variant="i" component="i">
                  <strong> </strong>
                </Typography>
              </CardContent>

              <CardActions>
                {!!balance.pendingBalance && (
                  <button
                    onClick={handlePendingPayoutModal}
                    // disabled={balance.availableBalance === 0 ? true : false}
                    className={`btn  btn-primary m-auto `}
                  >
                    Pending
                  </button>
                )}
              </CardActions>
            </Card>{" "}
            <Card style={cardStyle}>
              <CardContent
                className="d-flex flex-column align-items-center pt-5 m-auto"
                style={{ maxHeight: "100px" }}
              >
                <Typography style={cardTextStyle}>
                  <span className={cardTextStyle}>
                    {balance.availableBalance} <i className="fa fa-dollar"></i>
                  </span>
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="pt-3"
                >
                  Available Payout
                </Typography>{" "}
                <Typography gutterBottom variant="i" component="i">
                  <strong> </strong>
                </Typography>
              </CardContent>

              <CardActions>
                {!!balance.availableBalance && (
                  <button
                    onClick={createPayoutHandler}
                    // disabled={balance.availableBalance === 0 ? true : false}
                    className={`btn ${
                      balance.availableBalance === 1
                        ? "btn-disabled"
                        : "btn-primary"
                    }   m-auto `}
                  >
                    Cash Out
                  </button>
                )}
              </CardActions>
            </Card>
          </div>
        )}
      <PaymentHistoryModal
        handlePayout={handlePayoutModal}
        handlePendingPayout={handlePendingPayoutModal}
        pendingPayout={pendingPayout}
        payout={payout}
        open={payout || pendingPayout}
        handleOpen={payout ? handlePayoutModal : handlePendingPayoutModal}
      />
    </>
  );
}
