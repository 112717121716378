import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { adverts } from "./testAdvertismentData";
import { loadAdverts } from "../../redux/action/advert";
import "./postFeedstyles.css";

export default function Advertisement() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAdverts());
    return () => {
      setMount(false);
    };
  }, []);

  const adverts = useSelector((state) => state.advertReducer);

  const advertList = adverts && adverts.adverts.ads ? adverts.adverts.ads : [];

  const [mount, setMount] = useState(true);
  const [currentAd, setCurrentAd] = useState(0);
  const currentAdTime = advertList[currentAd]
    ? parseInt(advertList[currentAd].timeDuration)
    : 4000;
  // const currentAdTime = 3000;

  const adTimeOut = setTimeout(() => {
    currentAd === advertList.length - 1 || advertList.length === 1
      ? setCurrentAd(0)
      : setCurrentAd(currentAd + 1);
  }, currentAdTime);

  if (!mount) {
    clearTimeout(adTimeOut);
  }

  const numberOfAds = advertList.length;
  const lastAd = numberOfAds - 1;

  return (
    <div>
      {advertList.length > 0 ? (
        <Card
          variant="outlined"
          sx={{ minWidth: 250, maxWidth: 350 }}
          className="advert"
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="100"
              image={
                advertList[currentAd] &&
                advertList[currentAd].imageInfo.location
              }
              alt="socicraft advert"
            />
            <CardContent style={{ padding: "5px" }}>
              <Typography gutterBottom variant="h6" component="div">
                {advertList[currentAd] && advertList[currentAd].label}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {advertList[currentAd] && advertList[currentAd].description}
              </Typography>
              <div className="dotsContainerStyles">
                {advertList.map((slide, slideIndex) => (
                  <div
                    className="dotStyle"
                    key={slideIndex}
                    //   onClick={() => goToAd(slideIndex)}
                  >
                    {slideIndex === currentAd ? (
                      <div style={{ color: "cyan" }}> ● </div>
                    ) : (
                      <div> ●</div>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <div></div>
      )}
    </div>
  );
}
