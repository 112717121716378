import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Spinner from "../content/modal/spinnermodal";
import { useEffect } from "react";

export default function BackDropSpinner({ isUploading }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isUploading);
  }, [isUploading]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 370,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Box sx={style}>
          <div style={{ display: "flex" }}>
            {" "}
            <Spinner />{" "}
          </div>
          <div style={{ margin: "auto", width: "60%", color: "black" }}>
            uploading please wait...
          </div>
        </Box>
      </Backdrop>
    </div>
  );
}
