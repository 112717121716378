import {
  GET_ALL_LISTINGS,
  UPVOTE,
  RESET_LISTING_SEARCH,
  UPDATE_VOTE,
} from "../action/types";

const initialState = {
  isLoading: true,
  address: "",
  categories: "",
  internalListings: [],
  externalListings: [],
  keyword: null,
};
const listings = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_LISTINGS:
      return {
        ...state,
        internalListings: payload.listings.internalListings.listing,
        externalListings: payload.listings.externalListings.usJobsList,
        keyword: payload.keyTerm,
        address: payload.address,
        category: payload.category,
        isLoading: false,
      };
    case UPVOTE:
      return {
        ...state,
        internalListings: payload.listings.internalListings.listing,
        externalListings: payload.listings.externalListings.usJobsList,
        keyword: payload.keyword,
        address: payload.address,
        category: payload.category,
        isLoading: false,
      };
    case UPDATE_VOTE:
      return {
        ...state,
        internalListings: state.internalListings.map((listing) => {
          if (listing.id === payload.listingId) {
            listing.votes = listing.votes + payload.value;
          }
          return listing;
        }),
      };
    case RESET_LISTING_SEARCH:
      return {
        isLoading: true,
        address: "",
        categories: "",
        internalListings: [],
        externalListings: [],
        keyword: null,
      };
    default:
      return state;
  }
};

export default listings;
