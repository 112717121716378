import React, { Fragment, Component } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import AdvSearch from "../content/element/advance-search";
import { ContentBlockHome } from "../content/element/content-block";
import { connect } from "react-redux";

import CompleteOnboarding from "../content/modal/onboarding/onboarding";

class Index extends Component {
  render() {
    const light = this.props.logo[0].light;

    return (
      <Fragment>
        <CompleteOnboarding
          open={
            this.props.location.state &&
            this.props.location.state.showOnboarding
          }
        />
        {/* Header section start */}
        <section className="intro-wrapper bgimage overlay overlay--dark">
          <div className="bg_image_holder">
            <img src="./assets/img/intro1.jpg" alt="" />
          </div>
          <div className="mainmenu-wrapper">
            <Header logo={light} class="menu--light" />
          </div>

          {/* <!-- ends: .mainmenu-wrapper --> */}
          <AdvSearch />
        </section>
        {/* Header section end */}
        {/* Category section start */}

        {/* <section className='categories-cards section-padding-two'>
					<div className='container'>
						<SectionTitle
							title='What Kind of Activity do you Want to try?'
							content='Discover best things to do restaurants, shopping, hotels, cafes and places around the world by categories.'
						/>
						<div className='row'>
							<CardCategoryGrid4 />
						</div>
					</div>
				</section> */}
        {/* Category section end */}
        {/* Listing section start */}
        {/* <section className='listing-cards section-bg section-padding'>
					<div className='container'>
						<SectionTitle
							title='Current National Projects'
							content='Explore the popular listings'
						/>
						<div className='row'>
							<div className='listing-cards-wrapper col-lg-12'>
								<div className='row'>
									<CardListingGrid4 />
									<div className='col-lg-12 text-center m-top-20'>
										<NavLink
											to='/at_demo'
											className='btn btn-gradient btn-gradient-two'>
											Explore All 200+
										</NavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
        {/* Listing section end */}

        {/* <ContentBlockHome /> */}
        {/* Place section start */}

        {/* <section className='testimonial-wrapper section-padding--bottom'>
					<div className='container'>
						<SectionTitle
							title='Trusted By Over 4000+ Users'
							content='Here is what people say about Direo'
						/>
						<div className='row'>
						
						</div>
					</div>
				</section> */}

        {/* <section className='clients-logo-wrapper border-top p-top-100'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<ClientLogo />
							</div>
						</div>
					</div>
				</section> */}
        {/* client section end

				{/* <Subscribe /> */}
        {/* <Footer /> */}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
  };
};

export default connect(mapStateToProps)(Index);
