import React from "react";

const Spinner = ({ width }) => {
  return (
    <div className="m-auto">
      {" "}
      <img
        src={"/assets/img/loading/purple-loading-spinner_1.gif"}
        alt=""
        width={width ? width : "60"}
      />
    </div>
  );
};

export default Spinner;
