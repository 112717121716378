import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Details from "../folder/details";
import Button from "@mui/material/Button";
import "./style/infocard.css";

import { CardActionArea } from "@mui/material";

export default function InfoCard({
  title,
  description,
  tags,
  update,
  deletePost,
}) {
  return (
    <Card className="info-card">
      <CardActionArea>
        <CardContent className="card-content">
          <div className="card-title">
            <Typography variant="h6" component="div">
              {title.length > 20 ? title.slice(0, 15) + " ..." : title}
            </Typography>
            <div className="buttons">
              <Button
                className="delete-btn"
                variant="outlined"
                onClick={() => update()}
              >
                Update
              </Button>

              <Button
                className="update-btn"
                variant="outlined"
                onClick={() => deletePost()}
              >
                {" "}
                <span className="delete-btn">Delete</span>
              </Button>
            </div>
          </div>

          <Typography variant="body1" color="text.secondary">
            {description.length > 25
              ? description.slice(0, 30) + " ..."
              : description}
          </Typography>
          <div className="title-and-tag">
            <Typography variant="body1" color="text.primary">
              {tags.length > 5
                ? tags.slice(0, 5).map((tag) => <span>{"#" + tag} </span>)
                : tags.map((tag) => <span>{"#" + tag} </span>)}
            </Typography>
            {(title.length > 25 ||
              description.length > 25 ||
              tags.length > 5) && (
              <Details title={title} description={description} tags={tags} />
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
