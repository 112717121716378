import React, { Component } from "react";

class Placeholder extends Component {
  render() {
    return (
      <div className="col-lg-12">
        {/* <div className="alert alert-warning" role="alert">Data Not found!</div> */}
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Oops, no projects found...</h4>
            <section className="d-flex ">
              {" "}
              <h5 className="card-text">
                We didn't find any results for{" "}
                {/* in |Search
              Location| */}
              </h5>
              <h1 className="color-dark badge badge-primary font-weight-bold ml-5">
                {this.props.searchTerm}{" "}
              </h1>
            </section>

            <br />
            <ul className="card-text">
              <li>Check the spelling of the keywords you entered</li>
              <li>Try fewer or different keywords in your search</li>
              <li>Broaden your search radius</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default Placeholder;
