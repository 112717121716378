import { io } from "socket.io-client";
import { SET_SOCKET } from "./types";
// let url = "https://websocket.socicraft.us";
// let url = "http://44.197.38.234";
let url = "https://websocket.socicraft.us";
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "PRODUCTION") {
  url = process.env.REACT_APP_SERVER_URL;
}

export const initializeSocket = () => async (dispatch) => {
  let token = localStorage.getItem("token");

  if (token) {
    const socket = io(url, {
      extraHeaders: {
        token: token,
      },
      reconnection: true,
    });
    dispatch({
      type: SET_SOCKET,
      payload: socket,
    });
  }
};
