import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../redux/action/auth";
import HeaderV2 from "../layout/headerv2/headerv2";

const EmailVerification = (props) => {
  const user = useSelector((state) => state.auth.user);
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = props.match.params.token;
    dispatch(verifyEmail(token));
  }, [dispatch, loading]);

  if (user && user.emailVerified) {
    return <Redirect to="/social/discovery" />;
  }
  if (loading && !isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <HeaderV2 />
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ marginTop: "300px" }}
      >
        {" "}
        <div className="blinker">
          <h1>We are verifying your email ....</h1>{" "}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
