import moment from "moment";

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import Container from "./swipeButton/container";
import {
  updateStartProject,
  updateCompletedProject,
} from "../../../redux/action/userpost";
import { useDispatch } from "react-redux";
import Contractors from "./swipeButton/contractorsInfo";
import CompleteProjectInfo from "./swipeButton/completeProjectInfo";
import { ProjectStatus, ProjectStatusStepper } from "../../../services/consts";
import { Chip, Paper } from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StepperModal from "./stepperModal";
const AppliedProjectList = ({ appliedListings }) => {
  const dispatch = useDispatch();
  const [startProject, setStartProject] = useState(false);
  const [titleHeader, setTitleHeader] = useState("");
  const [completeProject, setCompleteProject] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("");
  const [currentProject, setCurrentProject] = useState(null);
  const startProjectHandler = (list) => {
    setStartProject(!startProject);
    setTitleHeader("Ready to Start Project !!!");
    setButtonTitle("SLIDE TO START PROJECT ");

    setCurrentProject(list);
  };
  const completeProjectHandler = (list) => {
    setTitleHeader("!!! Complete Project !!!");
    setCompleteProject(!completeProject);
    setButtonTitle("SLIDE TO COMPLETE PROJECT ");

    setCurrentProject(list);
  };
  const updateStartProjectHandle = () => {
    dispatch(
      updateStartProject(currentProject._id, currentProject.document._id)
    );
  };
  const updateCompleteProjectHandle = () => {
    dispatch(
      updateCompletedProject(currentProject._id, currentProject.document._id)
    );
  };

  const getCurrentStatus = (status) => {};
  const StepperComponent = () => {};
  const filterStepper = (status) => {
    let steps = [];

    Object.keys(ProjectStatusStepper).map((label) => {
      steps.push(ProjectStatus[label]["label"]);
    });
    console.log(status);
    if (status === "SCREENING") steps[1] = "Screening";
    if (status === "DECLINED") steps[1] = "Declined";
    // if(status === "REOPENED") steps[]

    console.log(steps);
    return steps;
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpen = (value) => {
    setOpen(!open);
  };
  return (
    <>
      {appliedListings &&
        appliedListings.map((list) => {
          const { title, id, createdAt, projectLocation } = list;
          const { status } = list;
          const image = list.listingImageUrl.length
            ? list.listingImageUrl[0].url
            : "/assets/img/no-image-available.jpeg";
          return (
            <div className="col-lg-12" key={uuidv4()}>
              <StepperModal
                handleOpen={handleOpen}
                open={open}
                projectStatus={ProjectStatus}
                status={status}
                filterStepper={filterStepper}
              />

              <div className="atbd_single_listing atbd_listing_list">
                <article className="atbd_single_listing_wrapper">
                  <figure className="atbd_listing_thumbnail_area">
                    <div className="atbd_listing_image">
                      <NavLink
                        to={"/view-listing-details/" + list._id}
                        target="_blank"
                      >
                        <img src={image} alt="listingimage" />
                      </NavLink>
                    </div>

                    {/* <div className="atbd_thumbnail_overlay_content">
                      <ul className="atbd_upper_badge">
                        <li>
                          <span
                            className={
                              "text-capitalize atbd_badge atbd_badge_" +
                              "featured"
                            }
                          >
                            {"Featured"}
                          </span>
                        </li>
                      </ul>
                    </div> */}
                  </figure>

                  <div className="atbd_listing_info">
                    <div className="atbd_content_upper">
                      <h4 className="atbd_listing_title">
                        <NavLink
                          to={"/view-listing-details/" + list._id}
                          target="_blank"
                        >
                          {title}
                        </NavLink>
                      </h4>
                      <div className="atbd_listing_meta">
                        {/* <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> */}
                        {/* <span className="atbd_meta atbd_listing_price">{"$ "+price}</span> */}
                        {/* <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span> */}
                      </div>
                      <div className="applicant_status_container">
                        <div className="atbd_listing_data_list ">
                          <ul>
                            <li>
                              <p style={{ maxWidth: "300px" }}>
                                <span className="la la-map-marker"></span>
                                {projectLocation &&
                                  projectLocation.toUpperCase()}
                              </p>
                            </li>
                            <li>
                              {/* <p><span className="la la-phone"></span>{phone}</p> */}
                            </li>
                            <li>
                              <p>
                                <span className="la la-envelope"></span>
                                {moment(createdAt).fromNow()}{" "}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* <div className="atbd_listing_bottom_content">
                      <div className="atbd_content_left">
                        <div className="atbd_listing_category">
                          <a href=" ">
                            <span className={"la " + "icon"}></span>
                            {"category"}
                          </a>
                        </div>
                      </div>
                      <ul className="atbd_content_right">
                        <li className="atbd_count">
                          <span className="la la-eye"></span>900+
                        </li>
                        <li className="atbd_save">
                          <span className="la la-heart-o"></span>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="atbd_listing_info">
                    <div className="atbd_content_upper">
                      {" "}
                      <div className="applied__listing-status">
                        <div className="d-flex justify-content-center flex-column mb-3">
                          <div className="d-flex align-items-center ">
                            <div className="mr-1">
                              <h4>Status</h4>
                            </div>
                            <div className="mt-1 ">
                              <span
                                className="applied__listing__status-icon"
                                onClick={handleOpen}
                              >
                                <HourglassBottomIcon />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Chip
                            label={
                              status === "Opted-Out"
                                ? "Opted-Out"
                                : ProjectStatus[status]["label"]
                            }
                            variant="outlined"
                            clickable={false}
                            style={{
                              maxWidth: "200px",
                              height: "30px",
                              fontSize: "15px",
                              color: "#97e5e5",
                              fontWeight: "bold",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </div>

                        {(status === "HIRED" || status === "REOPENED") && (
                          <div className="applied__listing__action-button">
                            {" "}
                            <button
                              onClick={() => startProjectHandler(list)}
                              className="btn btn-primary"
                            >
                              Review and Start Project
                            </button>
                          </div>
                        )}
                        {status === "IN_PROGRESS" && (
                          <div className="applied__listing__action-button">
                            <button
                              onClick={() => completeProjectHandler(list)}
                              className="btn btn-primary"
                            >
                              Complete Project
                            </button>
                          </div>
                        )}
                        {(startProject || completeProject) && (
                          <Container
                            appliedListing={currentProject}
                            startJobHandler={
                              startProject
                                ? startProjectHandler
                                : completeProjectHandler
                            }
                            updateStartProjectHandle={
                              startProject
                                ? updateStartProjectHandle
                                : updateCompleteProjectHandle
                            }
                            title={titleHeader}
                            buttonTitle={buttonTitle}
                            containerBody={
                              startProject ? Contractors : CompleteProjectInfo
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          );
        })}
    </>
  );
};
export default AppliedProjectList;
