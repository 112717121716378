import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Divider from "@mui/material/Divider";
import Name from "./name/name";
import ProjectTitle from "./projectTitle/projectTitle";
import Badge from "./badge/badge";
import Save from "./save/save";
import Location from "./location/location";
import IndustriesPopper from "./industriesPopper/industriesPopper";
import { NavLink } from "react-router-dom";
import "./style.css";
import ReviewsPopper from "./reviewsPopper/reviewPopper";

export default function ListingCard(props) {
  const placeHolderImage =
    "https://images.unsplash.com/photo-1668977309586-50c338c48cf5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60";

  let industries = [];
  let cost = "";
  let image = "";
  let state = "";
  let contractorRating = 0;
  let totalContractorReviews = 0;
  let clientFirstName = "";
  let clientLastName = "";
  let clientUserName = "";
  let contractorFirstName = "";
  let contractorLastName = "";
  let contractorUserName = "";
  let link = "";
  let buttonTitle = "";
  // let rateUserId = "";
  // let rateDate = "";
  let contractorRatings = [];

  if (props.cardType === "popularListing") {
    cost = props.listingInfo.cost;
    industries = props.listingInfo.relevantIndustries.split(",");
    image = props.listingInfo.listingImage;
    buttonTitle = "View";
    link = `/view-listing-details/${props.listingInfo.listingId}`;
  }
  if (props.cardType === "popularClients") {
    cost = props.listingInfo.count;
    clientFirstName = props.listingInfo.firstName;
    clientLastName = props.listingInfo.lastName;
    clientUserName = props.listingInfo.userName;
    image = props.listingInfo.userAvatar;
    state = props.listingInfo.state ? props.listingInfo.state : "N/A";
    industries = props.listingInfo.industries;
    link = `/${props.listingInfo.userName}`;
    buttonTitle = "Profile";
  }

  if (props.cardType === "popularContractors") {
    cost = props.listingInfo.count;
    contractorFirstName = props.listingInfo.firstName;
    contractorLastName = props.listingInfo.lastName;
    contractorUserName = props.listingInfo.userName;
    image = props.listingInfo.userAvatar;
    state = props.listingInfo.state ? props.listingInfo.state : "N/A";
    industries = props.listingInfo.industries;
    link = `/${props.listingInfo.userName}`;
    buttonTitle = "Profile";
    if (props.listingInfo.rating && props.listingInfo.rating.length) {
      const totalRatings = props.listingInfo.rating.reduce(
        (a, b) => a + b.rate,
        0
      );

      contractorRating = totalRatings / props.listingInfo.rating.length;
      totalContractorReviews = props.listingInfo.rating.length;
      contractorRatings = props.listingInfo.rating;
      // rateUserId = props.listingInfo.rating[0].userId;
      // rateDate = props.listingInfo.rating[0].date;
    }
  }

  return (
    <div>
      {props.listingInfo && (
        <Card sx={{ maxWidth: 300, minWidth: 300 }}>
          <CardActionArea className="landing_page_listing_card_main_card">
            <CardMedia
              component="img"
              height="140"
              image={image ? image : placeHolderImage}
              alt="soci-craft project listing image"
              className="landing_page_listing_card_card-image"
            />
            {props.showFavorite && (
              <Save className="landing_page_listing_card_save" />
            )}
            <span className="landing_page_listing_card_badges">
              <Badge title="Featured" />
              <Badge title={"$ " + cost} />
            </span>

            {props.showAvatar && (
              <Avatar
                className="landing_page_listing_card_avatar"
                alt="soci-craft user avatar"
                src={
                  props.listingInfo.userAvatar
                    ? props.listingInfo.userAvatar
                    : ""
                }
                sx={{ width: 65, height: 65 }}
              />
            )}

            {props.showReview && (
              <ReviewsPopper
                contractorRatings={contractorRatings}
                contractorRating={contractorRating}
                totalContractorReviews={totalContractorReviews}
              />
            )}
            <CardContent>
              {props.cardType === "popularClients" && (
                <div>
                  <Name firstName={clientFirstName} lastName={clientLastName} />
                  <p className="landing_page_listing_card_username">
                    {clientUserName}
                  </p>
                </div>
              )}
              {props.cardType === "popularContractors" && (
                <div>
                  <Name
                    firstName={contractorFirstName}
                    lastName={contractorLastName}
                  />
                  <p className="landing_page_listing_card_username">
                    {contractorUserName}
                  </p>
                </div>
              )}
              {props.cardType === "popularListing" && (
                <ProjectTitle title={props.listingInfo.listingTitle} />
              )}
              <Typography
                variant="body2"
                color="text.secondary"
                className="landing_page_listing_card_description"
              >
                {props.listingInfo.longDescription
                  ? props.listingInfo.longDescription.slice(0, 50) + " ..."
                  : ""}
              </Typography>
              {props.showLocation && <Location state={state} />}
            </CardContent>
          </CardActionArea>
          <Divider />
          <CardActions className="landing_page_listing_card_bottom_btns">
            <div className="landing_page_listing_card_industries_count_container">
              <Typography
                size="small"
                className="landing_page_listing_card_industries_btn"
              >
                {industries && industries.length > 0 && industries[0]}
              </Typography>{" "}
              {industries && industries.length > 1 && (
                <IndustriesPopper industries={industries} />
              )}
            </div>
            <div className="landing_page_listing_card_viewmore_btn">
              <NavLink to={link}>{buttonTitle}</NavLink>
            </div>
          </CardActions>
        </Card>
      )}
    </div>
  );
}
