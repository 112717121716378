import React, { Fragment, useEffect } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import BreadcrumbWraper from "../content/element/breadcrumb";
import ListingList from "../container/all-listing-list";
import { resetListingSearch } from "../../redux/action/userpost";
import { connect, useDispatch } from "react-redux";

const AllListingGrid = (props) => {
  const light = props.logo[0].light;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetListingSearch());
    };
  }, []);
  return (
    <Fragment>
      {/* Header section start */}
      <section className="header-breadcrumb bgimage overlay overlay--dark">
        <div className="bg_image_holder">
          <img src="./assets/img/breadcrumb1-blank-alt.jpg" alt="" />
        </div>
        <div className="mainmenu-wrapper">
          <Header logo={light} class="menu--light" />
        </div>
        {/* <!-- ends: .mainmenu-wrapper --> */}
        <BreadcrumbWraper title="Project Listing" />
      </section>
      {/* Header section end */}

      <ListingList listings={props.listings} />
      <Footer />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listings: state.listings,
  };
};

export default connect(mapStateToProps)(AllListingGrid);
