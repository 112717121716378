import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "./page/index";
import ListingDetails from "./page/listing-details";
import PrivateListingDetails from "./page/private-listing-details";
import Category from "./page/all-categoris";
import Location from "./page/all-location";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import About from "./TeamInfoPage/about";
import Contact from "./page/contact";
import AuthDashboard from "./page/auth-dashboard";
import AddListing from "./page/add-listing";
import Register from "./page/register";
import Profile from "./craftProfile/profile";
import { loaduser } from "../redux/action/auth";
import ChangePassword from "./content/modal/changePassword";
import { ToastContainer } from "react-toastify";
import ViewSupportList from "./page/view-support-list";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./content/element/loader";
import PrivateRoutes from "./routing/PrivateRoutes";
import AllListingList from "./page/all-listing-list";
import ViewListingDetail from "./page/view-listing-details";
import NotFound from "./content/element/Notfound";
import Chat from "./chat/chat";
import IncomingCallNotification from "./videoCall/notifications/incomingCallNotification";
import { initializeSocket } from "../redux/action/socket";
import { incomingCall } from "../redux/action/videoCalling";
import socketProcessor from "../services/socket/socketFactory";
import TermsAndConditions from "./page/termsOfUse";
import PrivatePolicy from "./page/privatePolicy";
import Discovery from "./page/discovery";
import EmailVerification from "./page/email-verification";
import ViewProfile from "./viewPublicProfile/viewprofile";
import DocusignReturnPage from "./projectActivity/docusign/docuSIgnReturnPage";
import DocuSignBridge from "./projectActivity/docusign/docuBridge";
import LandingPage from "./landingPage/landingPage";
import LandingPagePublic from "./landingPage/landingPagePublic";

function App({ loaduser, initializeSocket, socket, incomingCall }) {
  useEffect(() => {
    loaduser();
  }, [loaduser]);
  useEffect(() => {
    if (socket.connected) {
      socketProcessor(socket.socket);
    } else if (!socket.connected) {
      initializeSocket();
    }
    return () => {
      if (socket.connected) {
        socket.socket.disconnect();
      }
    };
  }, [socket, initializeSocket]);

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        {/* {user && <ModalView user={user} />}  */}
        {incomingCall && <IncomingCallNotification />}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick={true}
        />
        {/* Same as */}
        <ToastContainer />

        <Switch>
          {" "}
          {/* Cleaned Routes  */}{" "}
          <Route
            exact
            path="/passwordreset/:token?"
            component={ChangePassword}
          />{" "}
          <Route
            exact
            path="/register/:withprovider?/:provider?"
            component={() => <Register />}
          />{" "}
          <Route
            path="/listing-details&title=:title&api_id=:api_id&id=:id"
            component={ListingDetails}
          />{" "}
          <PrivateRoutes path="/contact" component={Contact} />{" "}
          <PrivateRoutes
            exact
            path="/dashboard-listings"
            component={AuthDashboard}
          />{" "}
          <PrivateRoutes path="/add-listing" component={AddListing} />{" "}
          <PrivateRoutes path="/chat" component={Chat} />
          <PrivateRoutes exact path="/about" component={About} />{" "}
          <PrivateRoutes path="/loader" component={Loader} />{" "}
          <Route exact path="/terms-of-use" component={TermsAndConditions} />{" "}
          <PrivateRoutes
            path="/view-network-list/:userId"
            component={ViewSupportList}
          />
          <Route path="/private-policy" component={PrivatePolicy} />{" "}
          <PrivateRoutes path="/all-listings-list" component={AllListingList} />{" "}
          <Route
            path="/view-listing-details/:id?"
            component={ViewListingDetail}
          />
          <PrivateRoutes
            path="/private-listing-details"
            component={PrivateListingDetails}
          />{" "}
          <PrivateRoutes
            exact
            path="/docusign-processing/:id"
            component={DocusignReturnPage}
          />
          <PrivateRoutes
            exact
            path="/docusign-bridge"
            component={DocuSignBridge}
          />
          <PrivateRoutes exact path="/all-categories" component={Category} />
          <PrivateRoutes
            exact
            path="/all-locations"
            component={Location}
          />{" "}
          <PrivateRoutes exact path="/craft-profile" component={Profile} />{" "}
          <Route exact path="/social/discovery" component={Discovery} />
          <Route exact path="/social/discovery/:id" component={Discovery} />
          <Route
            exact
            path="/email-verification/:token"
            component={EmailVerification}
          />{" "}
          <Route exact path="/landingpage" component={LandingPage} />{" "}
          <Route
            exact
            path="/landingpagepublic"
            component={LandingPagePublic}
          />{" "}
          <Route exact path="/:username" component={ViewProfile} />{" "}
          <Route exact path="/" component={LandingPage} />{" "}
          <Route path="*" component={NotFound} />
          {/* END  */}
        </Switch>
      </Router>{" "}
    </>
  );
}
App.propTypes = {
  loaduser: PropTypes.func.isRequired,
  initializeSocket: PropTypes.func.isRequired,
  incomingCall: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  socket: state.socket,
});

export default connect(mapStateToProps, {
  loaduser,
  initializeSocket,
  incomingCall,
})(App);
