import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListingPublic } from "../../redux/action/public";
import Header from "../layout/header";
import BreadcrumbWraper from "../content/element/breadcrumb";
import { Footer } from "../layout/footer";
import { ContentStory } from "../content/element/listing-details-story";
import { Typography } from "@material-ui/core";
import { ImageList } from "@material-ui/core";
import { ImageListItem } from "@material-ui/core";
import Gallery from "../viewPublicProfile/gallery";
import Report from "../content/modal/report";
import Clime from "../content/modal/clime-list";
import Apply from "../content/modal/internListingDetails/apply";
import SignIn from "../content/modal/login/signin";
import Spinner from "../content/modal/spinnermodal";
import { completeOnboarding } from "./../../redux/action/stripe";
import { getListingDetails } from "./../../redux/action/userpost";

import { Box } from "@material-ui/core";
import Button from "@mui/material/Button";

export default function ViewListingDetails({ match }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const toggleApply = () => {
    if (isAuthenticated) {
      setShowApply(!showApply);
    } else if (!isAuthenticated) {
      setOpenSignIn(!openSignIn);
    }
  };

  const applyUpdate = (value) => {
    listingData.applied = true;
    // value passed from child
}

  const webAddress =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
      ? "https://workshop.socicraft.us/"
      : "https://workshop.socicraft.us/";
  const listingId = match.params.id;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListingPublic(listingId));
  }, [dispatch, listingId]);
  const listingData = useSelector(
    (state) => state.publicReducer.publicListingDetails
  );
  const user = useSelector((state) => state.auth.user);

  const loading = useSelector(
    (state) => state.publicReducer.publicListingLoading
  );

  const userData = useSelector(
    (state) =>
      state.publicReducer.publicListingDetails &&
      state.publicReducer.publicListingDetails.userId
  );
  const handleOnboarding = () => {
    if (user && !user.connectedAccounts.stripe.onboardingCompleted) {
      dispatch(completeOnboarding())
        .then((data) => {
          const { url } = data;
          window.open(url, "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      {
        <div>
          <section className="header-breadcrumb bgimage overlay overlay--dark">
            <div className="bg_image_holder">
              <img src="" alt="" />
            </div>
            <div className="mainmenu-wrapper">
              <Header toggleApply={toggleApply} class="menu--light" />
            </div>
            {/* <!-- ends: .mainmenu-wrapper --> */}
            <BreadcrumbWraper title="Listing Details" />
          </section>

          {listingData && userData ? (
            <div>
              <>
                {/* Header section start */}

                {/* Header section end */}
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        {/* <Video /> */}
                        {/* {
                          <Typography className={"my-5"} variant="h5">
                            <span style={{ color: "#008080" }}>
                              Project Owner :
                            </span>{" "}
                            {userData.firstName} {userData.lastName}
                          </Typography>
                        }

                        {
                          <Typography variant="h6">
                            {" "}
                            <span style={{ color: "#008080" }}>
                              Project Title :
                            </span>{" "}
                            {listingData.title}
                          </Typography>
                        } */}
                        <div className="atbd_view_listing_details_listing_image_container">
                          {listingData.listingImageUrl.length > 0 && (
                            // <img
                            //   width="100%"
                            //   src={listingData.listingImageUrl[0].url}
                            //   alt="listingImage"
                            // />
                            // listingData.listingImageUrl.map((image) => (
                            //   <img width="100%" src={image.url} />
                            // ))}
                            <ImageList
                              sx={{ width: 500, height: 450 }}
                              cols={2}
                              rowHeight={164}
                            >
                              {listingData.listingImageUrl.map((image) => (
                                <ImageListItem
                                  style={{
                                    width:
                                      listingData.listingImageUrl.length === 1
                                        ? "100%"
                                        : "50%",
                                    border: "0.2px solid grey",
                                  }}
                                >
                                  <img
                                    src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${image.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={""}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          )}
                        </div>
                        <ContentStory
                          listing={listingData}
                          location={listingData.projectLocation}
                          firstName={userData.firstName}
                          lastName={userData.lastName}
                          projectTitle={listingData.title}
                        />
                        <div className="col-lg-12">
                          {listingData.images && listingData.images.length && (
                            <Gallery listing={listingData} />
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 my-5">
                        <div className="widget atbd_widget widget-card">
                          <div className="atbd_widget_title">
                            <h4>
                              <span className="la la-user"></span>
                              Company Info
                            </h4>
                          </div>

                          <div
                            className="company-info"
                            style={{ width: "70%", margin: "auto" }}
                          >
                            {listingData && listingData.userId.address && (
                              <div>
                                <br />
                                {listingData.userId.address.state && (
                                  <span>
                                    {" "}
                                    <b style={{ color: "#008080" }}>
                                      State :
                                    </b>{" "}
                                    {listingData.userId.address.state}
                                  </span>
                                )}
                                <br />
                                {listingData.userId.address.country && (
                                  <span>
                                    {" "}
                                    <b style={{ color: "#008080" }}>
                                      Country :
                                    </b>{" "}
                                    {listingData.userId.address.country}
                                  </span>
                                )}

                                {listingData.userId.address.phoneNumber && (
                                  <span>
                                    {" "}
                                    <b style={{ color: "#008080" }}>
                                      PhoneNumber :{" "}
                                    </b>{" "}
                                    {listingData.userId.address.phoneNumber}
                                  </span>
                                )}
                              </div>
                            )}
                            {listingData &&
                              listingData.userId.socialNetwork && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "10px",
                                  }}
                                  className="atbd_director_social_wrap"
                                >
                                  {listingData.userId.socialNetwork
                                    .facebook && (
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        "https://facebook.com/" +
                                        listingData.userId.socialNetwork
                                          .facebook
                                      }
                                    >
                                      <span className="fab fa-facebook-f"></span>
                                    </a>
                                  )}
                                  {listingData.userId.socialNetwork.twitter && (
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        "https://twitter.com/" +
                                        listingData.userId.socialNetwork.twitter
                                      }
                                    >
                                      <span className="fab fa-twitter"></span>
                                    </a>
                                  )}
                                  {listingData.userId.socialNetwork.youtube && (
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        "https://youtube.com/" +
                                        listingData.userId.socialNetwork.youtube
                                      }
                                    >
                                      <span className="fab fa-youtube"></span>
                                    </a>
                                  )}
                                  {listingData.userId.socialNetwork
                                    .instagram && (
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        "https://instagram.com/" +
                                        listingData.userId.socialNetwork
                                          .instagram
                                      }
                                    >
                                      <span className="fab fa-instagram"></span>
                                    </a>
                                  )}
                                  {listingData.userId.socialNetwork
                                    .portfolio && (
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={
                                        listingData.userId.socialNetwork
                                          .portfolio
                                      }
                                    >
                                      <span className="fa fa-globe"></span>
                                    </a>
                                  )}
                                </div>
                              )}
                          </div>

                          <>
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "2rem",
                                paddingTop: "0rem",
                                height:
                                  user &&
                                  !user.connectedAccounts.stripe
                                    .onboardingCompleted &&
                                  "350px",
                              }}
                              className="widget-body atbd_author_info_widget"
                            >
                              <div className="atbd_avatar_wrapper">
                                {/* <div className="atbd_review_avatar">
                            <img src="./assets/img/avatar-60x60.jpg" alt="AvatarImage" />
                        </div> */}
                                <div className="atbd_name_time">
                                  {/* <h4>
                {this.props.listing.firstname} {this.props.listing.lastname}
                <span
                  className="verified"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Verified"
                ></span>
              </h4> */}
                                  <span className="review_time">
                                    {/* // Posted {dateDifference} days ago */}
                                  </span>
                                </div>
                              </div>
                              {/* <!-- ends: .atbd_avatar_wrapper --> */}
                              <div className="atbd_widget_contact_info">
                                <ul>
                                  {/* <li>
                                <span className="la la-phone"></span>
                                <span className="atbd_info">(213) 995-7799</span>
                            </li>
                            <li>
                                <span className="la la-envelope"></span>
                                <span className="atbd_info">widget@aazztech.com</span>
                            </li>
                            <li>
                                <span className="la la-globe"></span>
                                <NavLink to="/at_demo" className="atbd_info" onClick={noAction}>www.aazztech.com</NavLink>
                            </li> */}
                                </ul>
                              </div>
                              {/* <!-- ends: .atbd_widget_contact_info --> */}
                              {/* <div className="atbd_social_wrap">
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-facebook-f"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-twitter"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-google-plus-g"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-linkedin-in"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-instagram"></span></NavLink></p>
                    </div> */}
                              {/* <!-- ends: .atbd_social_wrap --> */}
                              {user &&
                              user.id !== listingData.userId._id.toString() &&
                              !user.connectedAccounts.stripe
                                .onboardingCompleted ? (
                                <Box textAlign="center">
                                  {" "}
                                  <Button
                                    variant="contained"
                                    disabled
                                    alignSelf="center"
                                  >
                                    Apply
                                  </Button>
                                </Box>
                              ) : (
                                user &&
                                user.id !==
                                  listingData.userId._id.toString() && (
                                  <button
                                    className={
                                      listingData.applied
                                        ? "btn btn-gradient btn-gradient-three btn-block btn-disabled "
                                        : "btn btn-gradient btn-gradient-two btn-block"
                                    }
                                    disabled={listingData.applied.applied}
                                  >
                                    <a
                                      href="#/"
                                      data-toggle={
                                        isAuthenticated ? null : "modal"
                                      }
                                      data-target={
                                        isAuthenticated ? null : "#/login_modal"
                                      }
                                      onClick={() => {
                                        !listingData.applied && toggleApply();
                                      }}
                                    >
                                      <div>
                                        {/* If applied change the color to green */}
                                        {listingData.applied
                                          ? "Applied"
                                          : "Apply"}
                                      </div>
                                    </a>{" "}
                                  </button>
                                )
                              )}
                              {user &&
                                !user.connectedAccounts.stripe
                                  .onboardingCompleted && (
                                  <div
                                    className="alert alert-warning mt-1 d-flex flex-column"
                                    role="alert"
                                  >
                                    <p className="text-center">
                                      Compete Onboarding and Start Earning Money
                                    </p>
                                    <button
                                      className="btn btn-primary m-0"
                                      onClick={handleOnboarding}
                                    >
                                      {" "}
                                      Complete Onboarding{" "}
                                    </button>
                                  </div>
                                )}
                              <div className="btn btn-block font-weight-bold ">
                                {" "}
                                <a
                                  rel="noopener noreferrer"
                                  href={webAddress + userData.userName}
                                  target="_blank"
                                >
                                  {" "}
                                  View Profile
                                </a>
                              </div>
                            </div>{" "}
                          </>
                        </div>
                        <Apply
                          id={listingData._id}
                          showApply={showApply}
                          toggleApply={toggleApply}
                          passToApply={applyUpdate}
                          // toggleInternalDetails={props.toggleInternalDetails}
                        />{" "}
                        <SignIn
                          toggleSignIn={toggleApply}
                          modalOpen={openSignIn}
                        />
                        {/* end seller info */}
                        {/* {(listingData.state 
listingData.country 
listingData.facebook 
listingData.instagram 
listingData.tumblr 
listingData.linkedin 
listingData.youtube ||
listingData.facebook) && (
<ContactInfo listing={listingData} />
)} */}
                      </div>
                    </div>
                  </div>
                )}

                <Report />
                <Clime />
              </>
              <Footer />
            </div>
          ) : (
            loading && (
              <div className="d-flex">
                <Spinner />
              </div>
            )
          )}
        </div>
      }
    </div>
  );
}
