import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  createGalleryAlbum,
  createVideoAlbum,
  createAudioAlbum,
  createHeaderImage,
} from "../../../../redux/action/craftProfile";
import { getGallery } from "../../../../redux/action/craftProfile";
import ImageUploader from "../../controls/ImageUploader";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const CreateAlbum = (props) => {
  const [form, setForm] = useState({
    title: "",
    description: "",
    tags: "",
    isPublic: false,
    coverPhoto: null,
    fileName: null,
  });

  let [image, setImage] = useState([]);

  const [showPublic, setShowPublic] = useState(true);

  const handleSwitchChange = (event) => {
    setShowPublic(event.target.checked);
  };

  const imageRemover = async (blob) => {
    let check = image.filter(
      (img) => img.filename !== blob.name.split(/(?=jpg|png|jpeg|gif)/g)[0]
    );

    setImage([...check]);
  };
  const imageUploader = async (blob) => {
    // let imageBase = await blobToBase64(blob);

    let imageTemp = {
      filename: blob.name.split(/(?=jpg|png|jpeg|gif)/g)[0],
      file: blob,
    };

    setForm({ ...form, coverPhoto: blob, fileName: imageTemp.filename });
  };

  const onChangeHandler = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setForm({ ...form, [name]: value });
  };

  const submitHandler = () => {
    let album = new FormData();

    if (form.coverPhoto != null && form.fileName != null) {
      album.append("gallery", form.coverPhoto, form.fileName);
    }

    let albumInformation = {
      isPublic: showPublic,
      description: form.description,
      title: form.title,
      tags: form.tags,
    };

    album.append("data", JSON.stringify(albumInformation));

    if (props.type === "video") {
      props.createVideoAlbum(album);
    } else if (props.type === "audio") {
      props.createAudioAlbum(album);
    } else if (props.type === "headerImage") {
      props.createHeaderImage(album);
    } else {
      props.createGalleryAlbum(album);
    }

    //Call Redux Action
    props.toggleCreateAlbum();

    setForm({
      title: "",
      description: "",
      tags: "",
      isPublic: false,
      coverPhoto: null,
    });
  };

  const closeCreateAlbum = () => {
    props.toggleCreateAlbum();
    setForm({
      title: "",
      description: "",
    });
  };

  return (
    <>
      <Modal
        className="col-lg-12"
        show={props.showCreateAlbum}
        animation={true}
        onHide={() => closeCreateAlbum()}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 id="claim_listing_label">
              {props.type === "headerImage" ? (
                "Upload Header Image"
              ) : (
                <>
                  <i className="la la-plus"></i> Create Album
                </>
              )}
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <form action="/">
              <div className="form-group">
                <div className="col">
                  {props.type === "headerImage" ? (
                    <></>
                  ) : (
                    <div className="formInputs">
                      <div className="col-md-12">
                        <label id="title">
                          {" "}
                          <h5 id="claim_listing_label">Title:</h5>{" "}
                        </label>
                        <input
                          id="title"
                          name="title"
                          type="text"
                          onChange={onChangeHandler}
                          className="form-control "
                          value={form.title}
                        />
                      </div>
                      <div className="col-md-12">
                        <label id="description">
                          {" "}
                          <h5 id="claim_listing_label">Description:</h5>{" "}
                        </label>
                        <input
                          id="description"
                          type="text"
                          onChange={onChangeHandler}
                          name="description"
                          className="form-control "
                          value={form.description}
                        />
                      </div>

                      {/* <div className="col-md-12">
                        <label id="tags">
                          {" "}
                          <h5 id="claim_listing_label">Tags:</h5>{" "}
                        </label>
                        <div style={{ display: "flex" }}>
                          <input
                            style={{ borderRadius: "0px 0px" }}
                            id="tags"
                            type="text"
                            onChange={onChangeHandler}
                            name="tags"
                            className="form-control "
                            value={form.tags}
                          />
                        </div>
                      </div> */}
                    </div>
                  )}

                  <ImageUploader
                    imageRemover={imageRemover}
                    imageUploader={imageUploader}
                    options={{
                      acceptedFileTypes: "image/*",
                      label: "Album Image",
                      allowFileTypeValidation: true,
                    }}
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {props.type === "headerImage" ? (
                  <></>
                ) : (
                  <FormGroup color="success">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={showPublic}
                          onChange={handleSwitchChange}
                        />
                      }
                      label="Publish to public profile"
                    />
                  </FormGroup>
                )}

                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={submitHandler}
                >
                  {" "}
                  {props.type === "headerImage"
                    ? "Upload Header Image"
                    : "Create Album"}
                </button>
              </div>
            </form>
          </Fragment>
        </Modal.Body>

        <Modal.Footer>
          {/* //<Button onClick={() => submitAgreement()} variant="secondary">
            Agree
          </Button>
         // <Button onClick={() => toggleModal(false)} variant="danger">
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listings: state.listings,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  createGalleryAlbum,
  createHeaderImage,
  createVideoAlbum,
  createAudioAlbum,
  getGallery,
})(CreateAlbum);
