import {
  LOAD_POPULAR_CLIENT,
  LOAD_POPULAR_LISTINGS,
  LOAD_POPULAR_CONTRACTORS,
} from "../action/types";

const initialState = {
  popularClient: [],
  popularListings: [],
  popularContractors: [],
};

const home = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_POPULAR_CLIENT:
      return {
        ...state,
        popularClient: action.payload,
      };

    case LOAD_POPULAR_LISTINGS:
      return {
        ...state,
        popularListings: action.payload,
      };

    case LOAD_POPULAR_CONTRACTORS:
      return {
        ...state,
        popularContractors: action.payload,
      };

    default:
      return state;
  }
};

export default home;
