import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Link } from "react-router-dom";

export default function ProfileInfoList({
  state,
  country,
  street,
  email,
  zipCode,
  phoneNumber,
  webSite,
  city,
}) {
  return (
    <div>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <span
              style={{ fontSize: "30px" }}
              className="la la-map-marker"
            ></span>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                {!state && !country && !street && !city && !zipCode ? (
                  "Not Available"
                ) : (
                  <>
                    {street} {city} {state} {country}
                    {zipCode}
                  </>
                )}
              </>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <span
              style={{ fontSize: "30px" }}
              className="la la-envelope"
            ></span>
          </ListItemAvatar>
          <ListItemText style={{ marginTop: "10px" }} primary={email} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <span style={{ fontSize: "30px" }} className="la la-phone"></span>
          </ListItemAvatar>
          <ListItemText style={{ marginTop: "10px" }} primary={phoneNumber} />
        </ListItem>
        <Divider variant="inset" component="li" />

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <i className="la la-globe  la-2x text-primary"></i>
          </ListItemAvatar>
          <ListItemText style={{ marginTop: "10px" }} />

          <Link
            style={{ marginTop: "10px" }}
            to={`socicraft.com/${webSite}`}
          >{`socicraft.com/${webSite}`}</Link>
        </ListItem>
      </List>
      {!state &&
        !country &&
        !street &&
        !email &&
        !zipCode &&
        !phoneNumber &&
        !webSite &&
        "Not Available"}
    </div>
  );
}
