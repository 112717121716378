import {
  SAVE_CRAFT_PROFILE,
  GET_CERTFICATION,
  GET_ABOUT,
  GET_PHOTO_GALLERY,
  GET_ALBUM_PHOTOS,
  CLEAR_ALBUM_PHOTOS,
  GET_VIDEO_ALBUM,
  GET_ALBUM_VIDEOS,
  CLEAR_ALBUM_VIDEOS,
  GET_ALBUM_AUDIOS,
  CLEAR_ALBUM_AUDIOS,
  GET_AUDIO_ALBUM,
  DELETE_CERTIFICATION,
  GET_UPDATED_ALBUM_PHOTOS,
  GET_UPDATED_ALBUM_VIDEOS,
  GET_HEADER_IMAGE,
  ADD_CERTFICATION,
  GET_UPDATED_PHOTO_ALBUM,
  GET_UPDATED_AUDIO_ALBUM,
  GET_UPDATED_VIDEO_ALBUM,
  DELETE_FILE,
} from "../action/types";

const initialState = {
  headerImage: null,
  about: null,
  album: null,
  albumPhotos: [],
  videoAlbums: null,
  albumVideos: [],
  audioAlbums: null,
  albumAudios: [],
  userCertifications: [],
  videoAlbumLoading: true,
  photoAlbumLoading: true,
  audioAlbumLoading: true,
};

const craftProfile = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CRAFT_PROFILE:
    case GET_ABOUT:
      return {
        ...state,
        about: action.payload,
        //   loading: false,
      };
    case GET_HEADER_IMAGE:
      return {
        ...state,
        headerImage: action.payload,
      };
    case GET_PHOTO_GALLERY:
      return {
        ...state,
        album: action.payload,
        //   loading: false,
      };
    case GET_UPDATED_PHOTO_ALBUM:
      return {
        ...state,
        album: action.payload,
      };
    case GET_ALBUM_PHOTOS:
      return {
        ...state,
        albumPhotos: [...action.payload],
        photoAlbumLoading: false,
      };
    case GET_UPDATED_ALBUM_PHOTOS:
      return {
        ...state,
        albumPhotos: [...action.payload],
      };
    case DELETE_FILE:
      return {
        ...state,
        albumPhotos: [
          ...state.albumPhotos.filter((photo) => photo._id !== action.payload),
        ],
      };

    case GET_UPDATED_VIDEO_ALBUM:
      return {
        ...state,
        videoAlbums: state.videoAlbums.map((video) => {
          return video._id === action.payload._id ? action.payload : video;
        }),
      };
    case GET_UPDATED_AUDIO_ALBUM:
      return {
        ...state,
        audioAlbums: state.audioAlbums.map((audio) => {
          return audio._id === action.payload._id ? action.payload : audio;
        }),
      };
    case GET_UPDATED_ALBUM_VIDEOS:
      return {
        ...state,
        albumVideos: [...action.payload],
      };
    case GET_VIDEO_ALBUM:
      return {
        ...state,
        videoAlbums: action.payload,
        loading: false,
      };
    case CLEAR_ALBUM_PHOTOS:
      return {
        ...state,
        albumPhotos: action.payload,
        photoAlbumLoading: true,
      };
    case CLEAR_ALBUM_VIDEOS:
      return {
        ...state,
        albumVideos: action.payload,
        videoAlbumLoading: true,
      };
    case GET_ALBUM_VIDEOS:
      return {
        ...state,
        albumVideos: [...action.payload],
        videoAlbumLoading: false,
      };

    case GET_ALBUM_AUDIOS:
      return {
        ...state,
        albumAudios: [...action.payload],
        audioAlbumLoading: false,
      };
    case GET_AUDIO_ALBUM:
      return {
        ...state,
        audioAlbums: action.payload,
        //     loading: false,
      };
    case CLEAR_ALBUM_AUDIOS:
      return {
        ...state,
        albumAudios: action.payload,
        audioAlbumLoading: true,
      };
    case GET_CERTFICATION:
      return {
        ...state,
        userCertifications: action.payload,
        //   loading: false,
      };
    case ADD_CERTFICATION:
      return {
        ...state,
        userCertifications: [...action.payload],
      };
    case DELETE_CERTIFICATION:
      return {
        ...state,
        userCertifications: [
          ...state.userCertifications.filter(
            (cert) => cert._id !== action.payload
          ),
        ],
      };

    default:
      return state;
  }
};

export default craftProfile;
