import React from "react";
// import CardListing from "./";
import CardListing from "./listingCard";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import Spinner from "../content/modal/spinnermodal";

const ProjectListings = ({ isAuthenticated, userId }) => {
  const loading = useSelector(
    (state) => state.userListing.userListings.loading
  );

  return (
    <>
      <div>
        <div className="atbd_content_module__tittle_area">
          <div className="atbd_area_title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {/* <div className="atbd_content_module atbd_listing_details"> */}
                {/* <div className="atbd_content_module__tittle_area"> */}

                <h2>Most Recent Listings</h2>

                <br />

                {/* </div> */}
                {/* <label  className="btn btn-xs btn-gradient btn-gradient-two">
                      <span className="">View All</span>
                  </label> */}
                {/* </div> */}
              </Grid>
            </Grid>
            {loading ? (
              <Spinner />
            ) : (
              <Grid container>
                {" "}
                <CardListing />{" "}
              </Grid>
            )}
          </div>
        </div>

        {/* <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="toggleSwitchGallery"></input>
            <label class="custom-control-label" for="toggleSwitchGallery">I don't need this.</label>
        </div> */}
      </div>
    </>
  );
};

export default ProjectListings;
