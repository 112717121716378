import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import "./postFeedstyles.css";

export default function StatusDisplay({ message, openSnackBar }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(openSnackBar);
  };
  return (
    <div>
      {openSnackBar && (
        <div>
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
          />
        </div>
      )}
    </div>
  );
}
