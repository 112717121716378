import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import moment from "moment";
const noAction = (e) => e.preventDefault();

const DashBoardFavoriteListings = ({ user, favoriteListings }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="atbd_saved_items_wrapper">
            <div className="atbd_content_module">
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>
                    <span className="la la-list"></span>My Saved Listings
                  </h4>
                </div>
              </div>
              <div className="atbdb_content_module_contents">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Project Title</th>
                        <th scope="col">Industry Category</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {favoriteListings &&
                        favoriteListings.map((favorite) => (
                          <tr>
                            <td>
                              <NavLink to="/at_demo" onClick={noAction}>
                                {favorite.title}
                              </NavLink>
                            </td>
                            <td>
                              <NavLink to="/at_demo" onClick={noAction}>
                                {favorite.relevantIndustries.split(",")[0]}
                              </NavLink>
                            </td>
                            <td>
                              {" "}
                              {moment(favorite.projectStartDate).format(
                                "MM-DD-YYYY"
                              )}
                            </td>
                            <td>
                              {favorite.projectEndDate
                                ? moment(favorite.projectEndDate).format(
                                    "MM-DD-YYYY"
                                  )
                                : "Until Closed"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*<!-- ends: .atbdb_content_module_contents -->*/}
            </div>
          </div>
          {/*<!--  ends: .atbd_saved_items_wrapper -->*/}
        </div>
        {/*<!-- ends: .col-lg-12 -->*/}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  favoriteListings: state.userListing.favoriteListings,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(DashBoardFavoriteListings);
