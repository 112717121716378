import React, { Fragment, useState } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import { connect } from "react-redux";
import { BreadcrumbSingle } from "../content/element/breadcrumb";
import { ContentStory } from "../content/element/listing-details-story";

import { ContactInfo } from "../content/element/contact-info";

import SellerInfo from "../content/element/widget";

import Report from "../content/modal/report";
import Clime from "../content/modal/clime-list";
import { searchProviders, fetchData } from "../../services/searchProviders";

const ListingDetails = (props) => {
  const [listingData, setListingData] = useState({});

  const retrieveListingData = async () => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.data
    ) {
      setListingData(props.location.state.data);
    }

    const { title, api_id, id } = props.match.params;
    const provider = searchProviders.find(
      (searchProvider) => searchProvider.alias === api_id
    );
    const listingResponses = await fetchData(provider, title);
    const listingData = listingResponses.find((response) => id === response.id);
    setListingData(listingData);
  };

  if (!listingData || !Object.keys(listingData).length) {
    retrieveListingData();
  }

  const light = props.logo[0].light;

  if (listingData && Object.keys(listingData).length) {
    return (
      <Fragment>
        {/* Header section start */}
        <section className="listing-details-wrapper bgimage">
          <div className="bg_image_holder">
            <img src="./assets/img/details-img-blank-alt.jpg" alt="" />
          </div>
          <div className="mainmenu-wrapper">
            <Header logo={light} class="menu--light" />
          </div>
          {/* <!-- ends: .mainmenu-wrapper --> */}
          <div className="listing-info content_above">
            <div className="container">
              <div className="row">
                <BreadcrumbSingle postingData={listingData} />
                {/* this is where the Tags and Categories are */}
              </div>
            </div>
          </div>
        </section>
        {/* Header section end */}
        <section className="directory_listiing_detail_area single_area section-bg section-padding-strict">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {/* <Video /> */}

                <ContentStory postingData={listingData} />
              </div>

              <div className="col-lg-4">
                <div className="widget atbd_widget widget-card">
                  <div className="atbd_widget_title">
                    <h4>
                      <span className="la la-user"></span>
                      Company Info
                    </h4>
                  </div>
                  {/* <!-- ends: .atbd_widget_title --> */}
                  <SellerInfo login={props.login} postingData={listingData} />
                </div>
                {/* end seller info */}

                <ContactInfo />
              </div>
            </div>
          </div>
        </section>
        <Report />
        <Clime />
        <Footer />
      </Fragment>
    );
  } else {
    return "";
  }
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
  };
};
export default connect(mapStateToProps)(ListingDetails);
