import {
  GET_CERTIFICATION_PUBLIC,
  GET_ABOUT_PUBLIC,
  GET_PHOTO_GALLERY_PUBLIC,
  GET_ALBUM_PHOTOS_PUBLIC,
  GET_VIDEO_ALBUM_PUBLIC,
  GET_ALBUM_VIDEOS_PUBLIC,
  GET_ALBUM_AUDIOS_PUBLIC,
  GET_AUDIO_ALBUM_PUBLIC,
  GET_HEADER_IMAGE_PUBLIC,
  GET_USER_PROFILE_PUBLIC,
  GET_ALL_LISTINGS_PUBLIC,
  GET_LISTING_DETAILS_PUBLIC,
  GET_USER_CONNECTIONS_PUBLIC,
  LOAD_POPULAR_CLIENT_PUBLIC,
  LOAD_POPULAR_LISTINGS_PUBLIC,
  LOAD_POPULAR_CONTRACTORS_PUBLIC,
} from "./types";
import API from "../../api/api";
export const getCraftProfilePublic = (userName) => async (dispatch) => {
  try {
    let response = await API.get("/public/craft-profile", {
      headers: {
        "x-auth-api": "PASS",
        "x-user-name": userName,
      },
    });
    dispatch({ type: GET_ABOUT_PUBLIC, payload: response.data.userAbout });

    dispatch({ type: GET_USER_PROFILE_PUBLIC, payload: response.data.user });
    dispatch({
      type: GET_VIDEO_ALBUM_PUBLIC,
      payload: response.data.userContent.videoAlbums,
    });
    dispatch({
      type: GET_PHOTO_GALLERY_PUBLIC,
      payload: response.data.userContent.imageAlbums,
    });
    dispatch({
      type: GET_AUDIO_ALBUM_PUBLIC,
      payload: response.data.userContent.audioAlbums,
    });
    dispatch({
      type: GET_HEADER_IMAGE_PUBLIC,
      payload:
        response.data.userContent.headerImage &&
        response.data.userContent.headerImage.coverPhoto
          ? response.data.userContent.headerImage.coverPhoto
          : null,
    });
    dispatch({
      type: GET_CERTIFICATION_PUBLIC,
      payload: response.data.userCertification
        ? response.data.userCertification.certifications
        : [],
    });
    dispatch({
      type: GET_ALL_LISTINGS_PUBLIC,
      payload: response.data.listings,
    });
    dispatch({
      type: GET_USER_CONNECTIONS_PUBLIC,
      payload: response.data.connections,
    });
  } catch (e) {
    console.log("Error While Calling ", e);
  }
};

export const getListingPublic = (id) => async (dispatch) => {
  try {
    let response = await API.get("/public/listing/" + id, {
      headers: {
        "x-auth-api": "PASS",
      },
    });
    dispatch({ type: GET_LISTING_DETAILS_PUBLIC, payload: response.data });
  } catch (e) {
    console.log("Error While Calling Get Listing Public  ", e);
  }
};

export const getGalleries = (type, id) => async (dispatch) => {
  try {
    switch (type) {
      case "Video":
        return dispatch({ type: GET_ALBUM_VIDEOS_PUBLIC, payload: id });
      case "Audio":
        return dispatch({ type: GET_ALBUM_AUDIOS_PUBLIC, payload: id });
      case "Photo":
        return dispatch({ type: GET_ALBUM_PHOTOS_PUBLIC, payload: id });
      default:
        return;
    }
  } catch (e) {
    console.log("Error While Calling ", e);
  }
};

export const getPopularListingsPublic = (id) => async (dispatch) => {
  try {
    let response = await API.get("/public/popular-listings", {
      headers: {
        "x-auth-api": "PASS",
      },
    });
    dispatch({ type: LOAD_POPULAR_LISTINGS_PUBLIC, payload: response.data });
  } catch (e) {
    console.log("Error While Calling Get popular listings  ", e);
  }
};
