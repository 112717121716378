import React, { Component, Fragment } from "react";

export class AboutEntry extends Component {
  render() {
    return (
      <Fragment>
        <div className="atbd_content_module atbd_listing_details">
          <div className="atbd_content_module__tittle_area">
            <div className="">
              <h4>About</h4>
            </div>
          </div>
          <div className="atbdb_content_module_contents">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="6"
              placeholder="Tell me about yourself..."
            ></textarea>
          </div>
        </div>
      </Fragment>
    );
  }
}
