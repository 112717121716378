import { REDEEM_SILVER } from "../action/types";

const initialState = {
  silverCompleted: false,
  goldCompleted: false,
};
const awards = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case REDEEM_SILVER:
      return {
        ...state,
        silverCompleted: payload,
      };

    default:
      return state;
  }
};

export default awards;
