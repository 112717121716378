import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { sendResetLink } from "../../../redux/action/auth";

const Recoverpsw = ({ openRecoverypsw, sendResetLink }) => {
  const [form, setForm] = useState({
    email: "",
  });
  const [emailSent, setEmailSent] = useState(false);
  const { email } = form;
  const submitHandler = async () => {
    let response = await sendResetLink(email);
    if (response && response.data.success) {
      setEmailSent(true);
    }
  };
  const onChangeHandler = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      {/* {this.state.errors && (
        <AlertMessage type="danger" error={this.state.errors.msg} />
      )} */}
      {emailSent ? (
        <>
          <div className="" Style="display:flex;  justify-content: center;">
            <div>
              <img
                src="/assets/success.gif"
                className="img-fluid rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle"
                alt=""
              />
            </div>
          </div>
          <div className="bg-green-400 p-3">
            <h6
              className="modal-title text-center  "
              Style=" color:black "
              id="login_modal_label"
            >
              An email with the password reset link has been sent to your email
              address.
            </h6>{" "}
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="bg-blue-100">
            <h6 className="modal-title" id="login_modal_label">
              <i className="la la-info" /> Please enter your Email or User name
            </h6>
          </div>
          <br />
          <form action="/" id="login-form">
            {/* Input email or User name */}
            <input
              onChange={onChangeHandler}
              type="text"
              value={email}
              className="form-control"
              placeholder="Email"
              name="email"
              required
            />
          </form>{" "}
          <br />
          <h6 className="modal-title" id="login_modal_label">
            You may need to check your spam folder or unblock
            info@socicraft.com.
          </h6>
          <br />
          <button
            onClick={submitHandler}
            type="submit"
            className="btn btn-block btn-lg btn-gradient btn-gradient-two"
          >
            Submit
          </button>
          <div className="form-excerpts">
            <ul className="list-unstyled">
              <li>
                Not a member? <a href="/register">Sign up</a>
              </li>
              <li>
                <a
                  href="#0"
                  onClick={() => openRecoverypsw()}
                  // className="access-link"
                  // data-dismiss="modal"
                  // data-toggle="modal"
                  // data-target="#recoverpsw_modal"
                >
                  Already member? Sign in
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

Recoverpsw.propTypes = {
  sendResetLink: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { sendResetLink })(Recoverpsw);
