import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AboutDetailCard from "./aboutDetailCard";

import "../postFeedstyles.css";

export default function Personal({ about, numberOfContents }) {
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <Card variant="outlined" className="personal" sx={{ minWidth: 250 }}>
        <CardMedia
          component="img"
          height="100"
          image={user && user.userAvatar ? user.userAvatar : ""}
          alt="socicraft user avatar"
        />
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography gutterBottom variant="h6" component="div">
              {user &&
                `${user.firstName ? user.firstName : ""} ${
                  user.lastName ? user.lastName : ""
                }`}
            </Typography>
            <NavLink
              to="/craft-profile"
              target="_blank"
              style={{ width: "0%", paddingRight: "15px" }}
            >
              <EditIcon />
            </NavLink>
          </div>

          {about && (
            <Typography variant="body2" color="text.secondary">
              {/* {about} */}
              {/* {about.slice(0, 100)} */}
              {about && about.length > 100
                ? about.slice(0, 100) + "..."
                : about}
            </Typography>
          )}

          {about && about.length > 100 && <AboutDetailCard about={about} />}
        </CardContent>
        <CardActions>
          {numberOfContents && (
            <div className="user_contents">
              {numberOfContents.photos ? (
                <div className="photo content">
                  <p>{numberOfContents.photos}</p>{" "}
                  <i className="fa fa-camera"></i>
                </div>
              ) : (
                <div></div>
              )}
              {numberOfContents.videos ? (
                <div className="video content">
                  <p> {numberOfContents.videos}</p>{" "}
                  <i className="fa fa-film"></i>
                </div>
              ) : (
                <div></div>
              )}

              {numberOfContents.audios ? (
                <div className="audio content">
                  <p>{numberOfContents.audios}</p>
                  <i className="fa fa-file-audio"></i>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </CardActions>
      </Card>
    </div>
  );
}
