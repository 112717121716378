import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { useDispatch } from "react-redux";
import { createPayout } from "../../../redux/action/stripe";

export default function PayoutTable({ data }) {
  const dispatch = useDispatch();
  const handlePayOut = (id) => {
    console.log(id);
    dispatch(createPayout(id));
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((payout) => (
              <TableRow
                key={payout._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {" "}
                  <Avatar alt="Remy Sharp" src={payout.client.userAvatar} />
                  {payout.listing.title}
                </TableCell>
                <TableCell align="right">{payout.netContractorPay}</TableCell>
                <TableCell align="right">
                  <button
                    onClick={() => handlePayOut(payout._id)}
                    className="btn btn-xs btn-gradient   btn-gradient-two"
                  >
                    Cash Out
                  </button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
