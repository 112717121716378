import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import "./style.css";
export default function IndustriesPopper(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <div className="landing_page_listing_card_industries_count">
        <span
          className="landing_page_listing_card_count"
          aria-describedby={id}
          type="button"
          onClick={handleClick}
        >
          1+
        </span>
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        {props.industries &&
          props.industries.map((industry) => (
            <div className="landing_page_listing_card_popper">
              <Box
                sx={{
                  border: 0.2,
                  pl: 5,
                  pr: 5,
                  pt: 1,
                  pb: 1,
                  bgcolor: "background.paper",
                }}
              >
                {industry}
              </Box>
            </div>
          ))}
      </Popper>
    </div>
  );
}
