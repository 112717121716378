import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { applyListing } from "../../../../redux/action/userpost";
const Apply = (props) => {
  const [interestInformation, setInterestInformation] = useState("");

  const onChangeHandler = ({ currentTarget }) => {
    let { value } = currentTarget;
    setInterestInformation(value);
  };

  const apply = (listingId) => {
    //close all modals and persit the search
    //props.toggleInternalDetails();
    props.toggleApply();
    props.applyListing(listingId, interestInformation);
    props.passToApply('applyButton');
  };

  return (
    <>
      <Modal
        show={props.showApply}
        animation={true}
        onHide={() => props.toggleApply()}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 id="claim_listing_label">
              <i className="la la-check-square"></i> Apply to this project
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <form action="/">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={
                        props.user && props.user.firstName
                          ? props.user.firstName
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={
                        props.user && props.user.email
                          ? props.user.lastName
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-9">
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={
                        props.user && props.user.address.phoneNumber
                          ? props.user.address.phoneNumber
                          : "No Phone Number In Account"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <textarea
                      className="form-control"
                      rows="6"
                      onChange={onChangeHandler}
                      value={interestInformation}
                      placeholder="Tell Us Why You are Interested in short words!"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>{" "}
              <button
                //   onClick={noAction}
                type="button"
                onClick={() => {
                  apply(props.id);
                }}
                className="btn btn-secondary"
              >
                Submit Query
              </button>
            </form>
          </Fragment>
        </Modal.Body>

        <Modal.Footer>
          {/* //<Button onClick={() => submitAgreement()} variant="secondary">
            Agree
          </Button>
         // <Button onClick={() => toggleModal(false)} variant="danger">
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listings: state.listings,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { applyListing })(Apply);
