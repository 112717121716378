import React, { useEffect } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
// import InfiniteScroll from "react-infinite-scroll-component";
import "./Message-List.css";
import ApplicantsBot from "../templates/applicantsBot";
import { v4 as uuidv4 } from "uuid";
import socibotImage from "../socibotImage/Socibot.jpg";
import ReactPlayer from "react-player";
import AudioPlayerCard from "../../postFeed/audioPlayer/audioPlayerCard";
import { useSelector } from "react-redux";


function MessageList({ messageStatus }) {
  // const [messages, setMessages] = useState([]);
  // const [hasMore, setHasMore] = useState(true);
  // const [loadMessages, setLoadMessages] = useState(-10);
  // let loadingRef = useRef(null);
  // console.log(loadingRef);
  useEffect(() => {
    // const options = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 1.0,
    // };
    // const observer = new IntersectionObserver(fetchMoreData, options);
    // observer.observe(loadingRef.current);
    // if (-1 * loadMessages >= messageStatus.messages.length) {
    //   setHasMore(false);
    // }
    // let m = messageStatus.messages.slice(loadMessages);
    // setMessages(m);
    // console.log(messageStatus.messages);
    // return () => {
    //   setMessages([]);
    //   setLoadMessages(-10);
    //   setHasMore(true);
    // };
  }, []);

  // const fetchMoreData = (entities, observer) => {
  //   const y = entities[0].boundingClientRect.y;
  //   console.log("Y, y", y);
  //   // setMessages(messageStatus.messages);
  // };

  const users = useSelector((state) => state.auth.user);

  const getAvatar = (userId) => {
    const member = messageStatus.participants.find(
      (item) => item.userId.id === userId
    );

    return member.userAvatar;
  };
  const messageRenderer = (data, index) => {
    if (data.self) {
      return (
        <div key={uuidv4()} className="message-row you-message">
          <div className="message-content">
            {data.fileType == 0 ? <div className="message-text">{data.text}</div> :
              data.fileType == 1 ?
                <> {data.attachement.map(audio => (
                // <ReactPlayer
                //   url={audio}
                //   width="250px"
                //   height="50px"
                //   playing={false}
                //   controls={true}
                //   config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                //   class="audioFileL"
                //   type='audio/mp3'
                // />
                <div class='audioFileL'>
                <AudioPlayerCard
            audios={[audio]}
            albumImage={users.userAvatar}
                  albumImageKey={users.userAvatarKey}
          />
          </div>
                ))} </>
                :
                data.fileType == 2 ?
                  <>{data.attachement.map(video =>
                  (<video class="chatVideo" controls controlsList="nodownload">
                    <source
                      src={video}
                      type="video/mp4"
                    />
                  </video>))} </>
                  :
                  data.fileType == 3 ? <> {data.attachement.map(image => (<img class="chatImg" src={image} alt="chatImg" />))}</> :
                    data.fileType == 4 ? <> {data.attachement.map(document => (<iframe width="100%" height="200" frameborder="0" class="dcmntFile" src={`https://docs.google.com/gview?url=${document}&embedded=true`}></iframe>))} </> : ''}
            {data.fileType != 0 && data.text != '' ? <div className="message-text message-text-attachment">{data.text}</div> : ''}
            <div className="message-time">
              {moment(new Date(data.createdAt).toISOString()).format(
                "DD MMM  LT"
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="message-row other-message" key={uuidv4()}>
            <div className="message-content">
              <img
                src={
                  messageStatus.title === "SOCIBOT"
                    ? socibotImage
                    : data.userAvatar
                }
                alt=""
              />
              {/* <div className="message-text">
                {data.bot ? (
                  <ApplicantsBot
                    message={data}
                    threadId={messageStatus.currentConversation}
                  />
                ) : (
                  ReactHtmlParser(data.text)
                )}
              </div> */}
              {data.fileType == 0 ? <div className="message-text">
                {data.bot ? (
                  <ApplicantsBot
                    message={data}
                    threadId={messageStatus.currentConversation}
                  />
                ) : (
                  ReactHtmlParser(data.text)
                )}
              </div> :
                data.fileType == 1 ?
                  <> <div> {data.attachement.map(audio => (<ReactPlayer
                    url={audio}
                    width="250px"
                    height="50px"
                    playing={false}
                    controls={true}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                    class="audioFileL"
                  />))} </div></>
                  :
                  data.fileType == 2 ?
                    <> <div>{data.attachement.map(video =>
                    (<video class="chatVideo" controls controlsList="nodownload">
                      <source
                        src={video}
                        type="video/mp4"
                      />
                    </video>))} </div> </> :
                    data.fileType == 3 ? <div> {data.attachement.map(image => (<img class="chatImg" src={image} alt="chatImg" />))} </div> :
                      data.fileType == 4 ? <> <div> {data.attachement.map(document => (<iframe width="100%" height="200" frameborder="0" class="dcmntFile" src={`https://docs.google.com/gview?url=${document}&embedded=true`}></iframe>))} </div> </> : ''}
              <div>
                {data.fileType != 0 && data.text != '' ? <div className="message-text message-text-attachment">{data.text}</div> : ''}
                <div className="message-time">
                  {/* {moment(data.createdAt).format("ll")} */}
                  {moment(new Date(data.createdAt).toISOString()).format(
                    "DD MMM  LT"
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="chat-message-list" id="scrollableDiv">
      {/* {
        <div
          ref={loadingRef}
          className="text-center  font-weight-bold"
          style={{ display: !hasMore && "none" }}
        >
          Load More .....
        </div>
      } */}
      {messageStatus &&
        messageStatus.loaded &&
        messageStatus.messages.length ? (
        <>
          {messageStatus.messages.map((data, index) =>
            messageRenderer(data, index)
          )}
        </>
      ) : (
        <div
          style={{ margin: "auto" }}
          // className="message-row you-message"
          key={uuidv4()}
        >
          <div className="message-content">
            <div className="message-text">Start Conversation</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MessageList;
