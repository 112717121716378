export const searchProviders = [
  {
    name: "USAJobs",
    alias: "usaj",
    beginningUrl: "https://data.usajobs.gov/api/search?",
    requestDetails: {
      method: "GET",
      headers: {
        Host: "data.usajobs.gov",
        "User-Agent": "alexander.camacho@socicraft.com",
        "Authorization-Key": "D2hFO5b93hyol61BDfhOksYZVArlwCc3MrO3GPnXCMw=",
      },
    },
    keywordText: "Keyword",
    dataStructureTree: "SearchResult.SearchResultItems",
    resultsPropertiesTrees: {
      id: "MatchedObjectId",
      title: "MatchedObjectDescriptor.PositionTitle",
      post_date: "MatchedObjectDescriptor.PublicationStartDate",
      company: {
        name: "MatchedObjectDescriptor.OrganizationName",
      },
      description: "MatchedObjectDescriptor.UserArea.Details.JobSummary",
      location: {
        city: `MatchedObjectDescriptor.PositionLocation[0].CityName.split(',')[0]`,
        state:
          "MatchedObjectDescriptor.PositionLocation[0].CountrySubDivisionCode",
        longitude: "MatchedObjectDescriptor.PositionLocation[0].Longitude",
        latitude: "MatchedObjectDescriptor.PositionLocation[0].Latitude",
      },
      posting_link: "MatchedObjectDescriptor.ApplyURI[0]",
    },
  },
];

export const fetchData = async (provider, keywordString) => {
  const responseData = await fetch(
    `${provider.beginningUrl}${
      keywordString.length ? provider.keywordText + "=" + keywordString : ""
    }`,
    provider.requestDetails
  );
  const data = await responseData.json();
  const results = processTree(data, provider.dataStructureTree);

  const newResults = results.map((result) => {
    const resultObj = { api_id: provider.alias };

    Object.keys(provider.resultsPropertiesTrees).forEach((propertyKey) => {
      const propertyTree = provider.resultsPropertiesTrees[propertyKey];

      if (typeof propertyTree === "object") {
        resultObj[propertyKey] = {};
        Object.keys(propertyTree).forEach((subPropertyKey) => {
          const subPropertyTree = propertyTree[subPropertyKey];
          resultObj[propertyKey][subPropertyKey] = processTree(
            result,
            subPropertyTree
          );
        });
      } else {
        resultObj[propertyKey] = processTree(result, propertyTree);
      }
    });

    return resultObj;
  });

  return newResults;
};

export const processTree = (data, tree) => {
  let result = data;
  const treeSegments = tree.split(".");

  treeSegments.forEach((segment) => {
    if (segment.indexOf("(") > -1) {
      switch (segment.substring(0, segment.indexOf("("))) {
        case "split":
          result = result.split(getTreeContainerContents(segment, "("));
          break;
        default:
          return;
      }

      if (segment.indexOf("[") > -1) {
        const index = parseInt(getTreeContainerContents(segment, "["), 10);
        result = result[index];
      }
    } else {
      if (segment.indexOf("[") > -1) {
        result = result[segment.substring(0, segment.indexOf("["))];
        const index = parseInt(getTreeContainerContents(segment, "["), 10);
        result = result[index];
      } else {
        result = result[segment];
      }
    }
  });

  return result;
};

const getTreeContainerContents = (string, surrounderOpener) => {
  const surrounderPairs = {
    "(": ")",
    "[": "]",
  };
  const firstIndex = string.indexOf(surrounderOpener) + 2;
  const lastIndex = string.indexOf(surrounderPairs[surrounderOpener]) - 1;
  return string.substring(firstIndex, lastIndex);
};
