import { GET_NOTIFICATION } from "../action/types";

const initialState = {
  notifications: {},
};

const notifications = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_NOTIFICATION:
      return {
        ...state,
        notifications: payload,
      };
    default:
      return state;
  }
};

export default notifications;
