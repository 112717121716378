import {
  GET_ONBOARDING_LINK,
  GET_ACCOUNT_DETAILS,
  GET_ACCOUNT_BALANCE,
  ONBOARDING_PROCESS_STARTED,
  PAYMENT_HISTORY,
  ONBOARDING_PROCESS_COMPLETED,
} from "../action/types";

const initialState = {
  // onboardingCompleted: null,
  onboardingLink: null,
  onboardingReference: null,
  dueRequirements: null,
  isLoading: true,
  balance: {
    availableBalance: 0,
    pendingBalance: 0,
  },
  processingOnBoarding: false,
  payoutHistory: [],
  availablePayout: [],
  paymentHistory: [],
  pendingPayoutHistory: [],
};

const stripe = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ACCOUNT_DETAILS:
      return {
        ...state,
        // onboardingCompleted: payload.onboardingCompleted,
        dueRequirements: null,
        isLoading: false,
      };
    case GET_ONBOARDING_LINK:
      return {
        ...state,
        onboardingReference: payload,
        isLoading: false,
      };
    case GET_ACCOUNT_BALANCE:
      return {
        ...state,
        balance: payload,
      };
    case ONBOARDING_PROCESS_STARTED:
      return {
        ...state,
        processingOnBoarding: true,
      };
    case ONBOARDING_PROCESS_COMPLETED:
      return {
        ...state,
        processingOnBoarding: false,
      };
    case PAYMENT_HISTORY:
      return {
        ...state,
        payoutHistory: payload.payoutHistory,
        availablePayout: payload.availablePayout,
        paymentHistory: payload.paymentHistory,
        pendingPayoutHistory: payload.pendingPayoutHistory,
      };
    default:
      return state;
  }
};

export default stripe;
