import React from "react";
import { Card } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ApplicantCard from "../applicantCard/applicantCard";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, clearCart } from "../../../redux/action/cart";
import NoticeModal from "./noticeModal";

export default function PendingProject() {
  const dispatch = useDispatch();

  const acceptedListings = useSelector(
    (state) => state.userListing.acceptedListings
  );

  const cartState = useSelector((state) => state.cart.showCart);

  const isCartAdded = (listingId, applicantId) => {
    let found = false;
    if (cartState) {
      found =
        cartState.items &&
        cartState.items.find((item) => {
          return (
            item.listingId.id.toString() === listingId.toString() &&
            item.applicant.id.toString() === applicantId.toString()
          );
        });
    }

    return !!found;
  };
  const [open, setOpen] = React.useState(false);
  const [holdRequest, setHoldRequest] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const updateCart = () => {
    if (holdRequest) {
      const { compensation, listingId, applicantId } = holdRequest;
      dispatch(clearCart()).then((data) => {
        dispatch(addToCart(compensation, listingId, applicantId));
      });
    }
    setHoldRequest(null);
    handleClose();
  };
  const cart = (compensation, listingId, applicantId) => {
    //Only Same applicant in a Cart ("Similar to Ordering a food from diff restaurant")
    const valid =
      cartState &&
      cartState.items.find(
        (item) => item.applicant.id.toString() === applicantId
      );
    if (valid || !cartState.items.length) {
      dispatch(addToCart(compensation, listingId, applicantId));
    } else {
      // Bring A POP up MODAL
      setHoldRequest({ compensation, listingId, applicantId });
      handleOpen();
    }
  };
  const cardStyle = {
    marginLeft: "20px",
    marginTop: "20px",
    maxWidth: "300px",
    minWidth: "300px",
  };
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {acceptedListings &&
        acceptedListings.map((applicant) => (
          <div className="pending-project">
            {applicant.status === "ACCEPTED" ? (
              <>
                <Card style={cardStyle}>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={
                      applicant.listing.listingImageUrl.length === 0
                        ? "/assets/img/folder.png"
                        : applicant.listing.listingImageUrl[0].url
                    }
                  />
                  <CardContent
                    style={{ maxHeight: "100px", marginBottom: "10px" }}
                  >
                    <Typography gutterBottom variant="h5" component="div">
                      {applicant.listing.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {applicant.listing.projectLabel}
                    </Typography>
                  </CardContent>
                  <ApplicantCard
                    userFirstName={applicant.applicantFirstName}
                    userLastName={applicant.applicantLastName}
                    userAvatar={applicant.applicantUserAvatar}
                    date={applicant.date}
                  />
                  <CardActions>
                    {isCartAdded(
                      applicant.listing.id,
                      applicant.applicantId
                    ) ? (
                      <>
                        {" "}
                        <small style={{ paddingTop: "7px", color: "green" }}>
                          ADDED TO CART{" "}
                        </small>
                        <span className="badge  badge-success badge-lg text-dark p-1 text-bold ">
                          {" "}
                          <i className="fa fa-dollar"></i>
                          {applicant.listing.cost}{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Button
                          onClick={() =>
                            cart(
                              applicant.listing.compensation,
                              applicant.listing.id,
                              applicant.applicantId
                            )
                          }
                          variant="contained"
                          size="small"
                        >
                          {" "}
                          ADD TO CART
                        </Button>
                        <span className="badge  badge-primary badge-lg text-dark p-1 text-bold ">
                          <i className="fa fa-dollar"></i>
                          {applicant.listing.cost}{" "}
                        </span>
                      </>
                    )}
                  </CardActions>
                </Card>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "0px",
                    height: "0px",
                    display: "none",
                    color: "green",
                  }}
                ></div>
              </>
            )}
          </div>
        ))}
      <NoticeModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        holdRequest={holdRequest}
        updateCart={updateCart}
      />
    </div>
  );
}
