import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "../postFeedstyles.css";

export default function Reply({ replyInfos, color }) {
  const { userId, reply, createdAt } = replyInfos;

  const todayDate = new Date().getDate();
  const commentCreatedAt = new Date(createdAt).getDate();

  return (
    <div
      style={{
        backgroundColor: "#dadde1",
        padding: "10px",
        borderRadius: "8px",
        marginTop: "10px",
      }}
    >
      <div style={{ display: "flex" }}>
        <Avatar
          sx={{ bgcolor: deepOrange[color], mr: 3 }}
          alt="socicraft user avatar"
        >
          {userId.userAvatarKey ? (
            <img className="discovery_user_avatar" src={userId.userAvatar} />
          ) : (
            userId.userName[0].toUpperCase()
          )}
        </Avatar>
        <Typography variant="body1" className="author_name">
          {userId.firstName} {userId.lastName}
          <Typography variant="body1">{reply}</Typography>
          <div className="time_display">
            {todayDate > commentCreatedAt
              ? moment(createdAt).format("ll")
              : moment(createdAt).fromNow("dd") + " ago"}
          </div>
        </Typography>
      </div>
    </div>
  );
}
