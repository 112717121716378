import React, { Fragment, useEffect, useState } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import { BreadcrumbDashboard } from "../content/element/breadcrumb";
import { connect } from "react-redux";
import CardListingGrid4 from "../content/card/card-listing-grid-4";
import DashBoardFavoriteListings from "./profile-favorite_listings";
import ProfileDashboard from "./profile-dashboard";
import ViewAppliedProjects from "../authDashboard/appliedProjects/appliedProjects";
import {
  getUserListings,
  getApplicants,
  getFavoriteListings,
} from "../../redux/action/userpost";
import ProjectActivity from "../projectActivity/projectActivity";
import { useHistory, withRouter } from "react-router-dom";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import EarningsParent from "../earnings/earningsParent";
import { UrlParamMapping } from "../../services/consts";
import ViewApplicantList from "../authDashboard/applicantList/viewApplicantList";
import DocumentView from "../documents/document";

const AuthDashboard = (props) => {
  const history = useHistory();

  const tabs = {
    profile: false,
    myListings: false,
    favoriteListing: false,
    appliedProjects: false,
    applicantList: false,
    projectActivity: false,
    documents: false,
    earnings: false,
  };
  const [initState, setInitialState] = useState({
    profile: false,
    myListings: false,
    favoriteListing: false,
    appliedProjects: false,
    applicantList: false,
    projectActivity: false,
    documents: false,
    earnings: false,
  });
  useEffect(() => {
    props.getUserListings();
    props.getApplicants();
    props.getFavoriteListings();
    const query = new URLSearchParams(props.location.search).get("t");
    const queryst = new URLSearchParams(props.location.search).get("st");

    if (query) {
      if (queryst) {
        history.push({ search: `${UrlParamMapping[query]}&st=${queryst}` });
        setInitialState({ ...initState, [query]: true });
      } else {
        history.push({ search: `${UrlParamMapping[query]}` });
        setInitialState({ ...initState, [query]: true });
      }
    } else {
      history.push({ search: `${UrlParamMapping["profile"]}` });
      setInitialState({ ...tabs, profile: true });
    }
  }, []);
  const switchTab = ({ currentTarget }) => {
    const { name } = currentTarget;
    setInitialState({ ...tabs, [name]: true });
    history.push({ search: `${UrlParamMapping[name]}` });
  };

  const light = props.logo[0].light;
  const {
    profile,
    myListings,
    favoriteListing,
    appliedProjects,
    applicantList,
    projectActivity,
    documents,
    earnings,
  } = initState;
  return (
    <Fragment>
      <section className="header-breadcrumb bgimage overlay overlay--dark">
        <div className="bg_image_holder">
          <img src="./assets/img/breadcrumb1.jpg" alt="" />
        </div>
        <div className="mainmenu-wrapper">
          <Header logo={light} class="menu--light" />
        </div>
        {/* <!-- ends: .mainmenu-wrapper --> */}
        <BreadcrumbDashboard title="Author Dashboard" />
      </section>
      <div className="section-bg pt-3">
        <Tab.Container defaultActiveKey="profile">
          <div className="account__menu-container">
            <div>
              <Nav variant="pills" className="account__menu">
                <Nav.Item>
                  <Nav.Link
                    onClick={switchTab}
                    name="profile"
                    active={profile}
                    eventKey="profile"
                    className={`account__menu-link ${
                      profile && "account__menu__active-link"
                    }`}
                  >
                    Account
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={switchTab}
                    name="myListings"
                    active={myListings}
                    eventKey="myListings"
                    className={`account__menu-link ${
                      myListings && "account__menu__active-link"
                    }`}
                  >
                    Listings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={favoriteListing}
                    onClick={switchTab}
                    name="favoriteListing"
                    eventKey="favoriteListing"
                    className={`account__menu-link ${
                      favoriteListing && "account__menu__active-link"
                    }`}
                  >
                    Favorite
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={appliedProjects}
                    onClick={switchTab}
                    name="appliedProjects"
                    eventKey="appliedProjects"
                    className={`account__menu-link ${
                      appliedProjects && "account__menu__active-link"
                    }`}
                  >
                    Applied Projects
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={applicantList}
                    onClick={switchTab}
                    name="applicantList"
                    eventKey="applicantList"
                    className={`account__menu-link ${
                      applicantList && "account__menu__active-link"
                    }`}
                  >
                    Applicants
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={projectActivity}
                    onClick={switchTab}
                    name="projectActivity"
                    eventKey="projectActivity"
                    className={`account__menu-link ${
                      projectActivity && "account__menu__active-link"
                    }`}
                  >
                    {/* Project Activity */}
                    Pending Projects
                  </Nav.Link>
                </Nav.Item>{" "}
                <Nav.Item>
                  <Nav.Link
                    active={documents}
                    onClick={switchTab}
                    name="documents"
                    eventKey="documents"
                    className={`account__menu-link ${
                      documents && "account__menu__active-link"
                    }`}
                  >
                    Documents
                  </Nav.Link>
                </Nav.Item>{" "}
                <Nav.Item>
                  <Nav.Link
                    active={earnings}
                    onClick={switchTab}
                    name="earnings"
                    eventKey="earnings"
                    className={`account__menu-link ${
                      earnings && "account__menu__active-link"
                    }`}
                  >
                    Earnings
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div>
              <Tab.Content>
                <Tab.Pane active={myListings} eventKey="myListings">
                  <CardListingGrid4 userListings={props.userListings} />
                </Tab.Pane>
                <Tab.Pane active={profile} eventKey="profile">
                  <ProfileDashboard />
                </Tab.Pane>
                <Tab.Pane active={favoriteListing} eventKey="favoriteListing">
                  <DashBoardFavoriteListings />
                </Tab.Pane>
                <Tab.Pane active={appliedProjects} eventKey="appliedProjects">
                  <ViewAppliedProjects />
                </Tab.Pane>
                <Tab.Pane active={applicantList} eventKey="applicantList">
                  <ViewApplicantList />
                </Tab.Pane>
                <Tab.Pane active={projectActivity} eventKey="projectActivity">
                  <ProjectActivity />
                </Tab.Pane>{" "}
                <Tab.Pane active={documents} eventKey="documents">
                  <DocumentView />
                </Tab.Pane>{" "}
                <Tab.Pane active={earnings} eventKey="earnings">
                  <EarningsParent />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>{" "}
        <Footer />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  getUserListings,
  getApplicants,
  getFavoriteListings,
})(withRouter(AuthDashboard));
