import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import InternalLIstings from "../content/card/internalListingsGrid-12";
import ExternalListings from "../content/card/externalListingsGrid";
import * as consts from "../../services/consts.js";
import Placeholder from "../content/card/placeholder";
import {
  favoriteListing,
  getAllListings,
  getFavoriteListings,
  getUpVotes,
} from "../../redux/action/userpost";
import { PropTypes } from "prop-types";
import IndustrySubcategories from "../industrySubcategories/industry-subcategories";
import Spinner from "../content/modal/spinnermodal";

class ListingList extends Component {
  state = {
    keyword: this.props.listings.keyword,
    category: this.props.listings.category,
    address: this.props.listings.address,
    typeOfWork: "",
    role: "",
  };
  componentDidUpdate(nextProps) {
    const { listings } = this.props;
    if (nextProps.listings !== listings) {
      this.setState({
        keyword: this.props.listings.keyword,
        category: this.props.listings.category,
        address: this.props.listings.address,
      });
    }
  }
  componentWillMount() {
    if (this.props.isAuthenticated) {
      this.props.getFavoriteListings();
      this.props.getUpVotes();
    }
  }

  showInternalListings = () => {
    if (this.props.isLoading) {
      return <Spinner />;
    }
    if (
      this.props.listings.internalListings &&
      this.props.listings.internalListings.length > 0
    ) {
      return this.props.listings.internalListings.map((cardData, idx) => {
        return (
          // <NavLink
          //   to={{
          //     pathname: `/listing-details&title=${cardData.title}&api_id=${cardData.api_id}&id=${cardData.id}`,
          //     state: { data: cardData },
          //   }}
          // >
          <InternalLIstings
            key={cardData.id}
            data={cardData}
            // favoriteListing={this.saveFavorite('3')}
            // upVote={this.incrementUpvote}
            // upVotes={this.state.upVotes}
            // loggedIn={this.state.login}
          />
          //  </NavLink>
        );
      });
    } else if (
      this.props.listings.internalListings &&
      !this.props.listings.internalListings.length
    ) {
      return (
        <Placeholder searchTerm={this.props.searchTerm}>
          Data not Found{" "}
        </Placeholder>
      );
    }
  };
  showExternalListings = () => {
    if (this.props.isLoading) {
      return <Spinner />;
    }
    if (
      this.props.listings.internalListings &&
      this.props.listings.externalListings.length > 0
    ) {
      return this.props.listings.externalListings.map((cardData, idx) => {
        return (
          <ExternalListings
            key={cardData.id}
            data={cardData}
            // favoriteListing={this.saveFavorite('3')}
            // upVote={this.incrementUpvote}
            // upVotes={this.state.upVotes}
            // loggedIn={this.state.login}
          />
        );
      });
    } else if (
      this.props.listings.internalListings &&
      !this.props.listings.externalListings.length
    ) {
      return (
        <Placeholder searchTerm={this.props.searchTerm}>
          Data not Found{" "}
        </Placeholder>
      );
    }
  };

  onchangeHandler = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    this.setState({ [name]: value });
  };
  handleSearch = () => {
    this.props.getAllListings(this.state);
    localStorage.setItem('searchKeywordsOnListing', JSON.stringify(this.state));
  };

  render() {
    return (
      <Fragment>
        <section className="all-listing-wrapper section-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 listing-items">
                <div className="row">
                  <div className="col-lg-4 order-lg-0 order-1 mt-5 mt-lg-0">
                    <div className="listings-sidebar">
                      <div className="search-area default-ad-search">
                        <form>
                          <div className="form-group">
                            <input
                              id="search-input"
                              type="text"
                              placeholder="What are you looking for?"
                              className="form-control"
                              onChange={(e) => {
                                this.onchangeHandler(e);
                              }}
                              name="keyword"
                              value={this.state.keyword}
                            />
                          </div>

                          <div className="form-group">
                            <div className="select-basic">
                              <div>
                                <label htmlFor="category">
                                  Choose Industry
                                </label>
                                <IndustrySubcategories />
                              </div>

                              <select
                                className="form-control"
                                // onChange={(e) => this.selectCategory(e)}
                                id="category"
                                value={this.state.category}
                                onChange={(e) => {
                                  this.onchangeHandler(e);
                                }}
                                name="category"
                              >
                                <option value="1" disabled defaultValue>
                                  Select
                                </option>{" "}
                                <option value="none">Not Selected</option>
                                {consts.searchCategories.map(
                                  (category, idx) => {
                                    return (
                                      <option
                                        key={idx}
                                        label={category}
                                        value={category}
                                      >
                                        {category}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="position-relative">
                              <input
                                onChange={(e) => {
                                  this.onchangeHandler(e);
                                }}
                                type="text"
                                placeholder="City, state or zip code"
                                className="form-control location-name"
                                value={this.state.address}
                                name="address"
                              />
                              <button type="button" className="locator">
                                <span className="la la-crosshairs"></span>
                              </button>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={this.handleSearch}
                            className="btn btn-gradient btn-gradient-two btn-block btn-icon icon-right m-top-40"
                          >
                            Search{" "}
                            <span className="la la-long-arrow-right"></span>
                          </button>
                        </form>
                        {/*<!-- ends: form -->*/}
                      </div>
                    </div>
                  </div>{" "}
                  {/* wiget */}
                  {/* Returns listing card list */}
                  <div className="col-lg-8 order-lg-1 order-0">
                    <h1 className="title">Socicraft Listings</h1> <i></i>
                    <br />
                    <div className="row">
                      {this.showInternalListings()}{" "}
                    </div>{" "}
                    <h1 className="title">Partners Listings</h1>
                    <br />
                    <div className="row">{this.showExternalListings()} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
ListingList.propTypes = {
  favoriteListing: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    searchTerm: state.listings.keyword,
    isLoading: state.listings.isLoading,
    listings: state.listings,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  favoriteListing,
  getAllListings,
  getFavoriteListings,
  getUpVotes,
})(ListingList);
