import React, { useState, useRef, useEffect } from "react";
import Controls from "./control";
import Details from "./details";
import "./audioplayer.css";

function Player(props) {
  const audioEl = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  {
    console.log(props);
  }
  useEffect(() => {
    if (isPlaying) {
      audioEl.current.play();
    } else {
      audioEl.current.pause();
    }
  });

  const SkipSong = (forwards = true) => {
    if (forwards) {
      props.setCurrentSongIndex(() => {
        let temp = props.currentSongIndex;
        temp++;

        if (temp > props.audios.length - 1) {
          temp = 0;
        }

        return temp;
      });
    } else {
      props.setCurrentSongIndex(() => {
        let temp = props.currentSongIndex;
        temp--;

        if (temp < 0) {
          temp = props.audios.length - 1;
        }

        return temp;
      });
    }
  };

  return (
    <div className="c-player">
      <Details
        isPlaying={isPlaying}
        song={props.audios[props.currentSongIndex]}
        albumImage={props.albumImage}
        albumImageKey={props.albumImageKey}
      />
      <Controls
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        SkipSong={SkipSong}
        audiosNumber={props.audios.length}
      />
      {/* <audio
        style={{ backgroundColor: "#efefef" }}
        className="c-player--audio"
        src={
          props.audios[props.currentSongIndex].url
            ? props.audios[props.currentSongIndex].url
            : props.audios[props.currentSongIndex]
        }
        ref={audioEl}
        controls
        type="audio/mp4"
        controlsList="nodownload"
        preload="none"
      ></audio> */}
      <audio ref={audioEl} style={{ backgroundColor: "#efefef" }}
        className="c-player--audio" controls="controls" controlsList="nodownload" preload="none">
        <source src={
          props.audios[props.currentSongIndex].url
            ? props.audios[props.currentSongIndex].url
            : props.audios[props.currentSongIndex]
        }
          type="audio/mpeg" />
      </audio>
    </div>
  );
}

export default Player;
