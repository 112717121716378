import React, { Component } from "react";
import { connect } from "react-redux";
import {
  remoteVideoToggler,
  remoteAudioToggler,
} from "../../../redux/action/videoCalling";
import "./App.css";
class Track extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    if (this.props.track !== null) {
      const child = this.props.track.attach();
      this.ref.current.classList.add(this.props.track.kind);
      this.ref.current.appendChild(child);

      this.props.track.on("disabled", (track) => {
        if (
          track.kind === "video" &&
          this.props.user.userName !== this.props.identity
        ) {
          this.props.remoteVideoToggler(false);
        }
        if (
          track.kind === "audio" &&
          this.props.user.userName !== this.props.identity
        )
          this.props.remoteAudioToggler(false);
      });
      this.props.track.on("enabled", (track) => {
        if (
          track.kind === "video" &&
          this.props.user.userName !== this.props.identity
        )
          this.props.remoteVideoToggler(true);
        if (
          track.kind === "audio" &&
          this.props.user.userName !== this.props.identity
        )
          this.props.remoteAudioToggler(true);
      });
    }
  }
  render() {
    return <div className="track" ref={this.ref}></div>;
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  remoteVideoToggler,
  remoteAudioToggler,
})(Track);
