import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Typography from "@mui/material/Typography";
import "./style.css";

export default function Location({ state }) {
  return (
    <div className="landing_page_location_typography">
      {" "}
      <LocationOnOutlinedIcon />{" "}
      <Typography variant="body2" component="div" sx={{ mt: 0.7 }}>
        {state}
      </Typography>
    </div>
  );
}
