import React, { Fragment, Component } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import BreadcrumbWraper from "../content/element/breadcrumb";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { addListing } from "../../redux/action/userpost";
import { searchCategories } from "../../services/consts.js";
import { validateAddListing } from "../../services/validatorpost";
import AlertMessage from "../content/controls/AlertMessage";
import { loaduser } from "../../redux/action/auth";
import ImageUploader from "../content/controls/ImageUploader";
import Spinner from "../content/modal/spinnermodal";
import { Multiselect } from "multiselect-react-dropdown";

import IndustrySubcategories from "../industrySubcategories/industry-subcategories";
import { getCurrentLocation } from "./../../redux/action/mapBox";

class AddListing extends Component {
  state = {
    title: "",
    longDescription: "",
    projectStartDate: "",
    projectEndDate: "",
    location: "",
    totalHours: null,
    cost: 0,
    enableTotalHours: false,
    //Compensation
    ratePerHour: "",
    flatRate: "",
    projectLabel: "",
    //type of work and Hours PerDay
    // estimatedHoursPerDay: "",
    typeOfWork: "",
    relevantIndustries: "",
    imageandvideo: { image: [], video: [] },
    videoUrl: "",
    //Errors validation Object
    errors: {},
    //CheckBOx List
    nonPaid: false,
    flatRateBox: false,
    ratePerHourBox: false,
    remote: true,
    continuous: false,
    street: "",
    state: "",
    city: "",
    country: "",
    zipcode: "",
    openIndustriesDetails: false,
    selectedDefaultListingImage:
      "https://defaultimages-soci.s3.us-west-1.amazonaws.com/lisiting__default_2.JPG",
  };

  handleSelectDefaultImage = (image) => {
    this.setState({ selectedDefaultListingImage: image });
  };

  handleIndustriesDetailsOpen = () =>
    this.setState({ openIndustriesDetails: true });
  handleIndustriesDetailsClose = () =>
    this.setState({ openIndustriesDetails: false });
  componentWillUnmount() {}
  onChangeHandler = ({ currentTarget }) => {
    const { value, name } = currentTarget;

    if (name === "ratePerHour") {
      if (!value) {
        this.setState({ enableTotalHours: false });
      }
      value > 1 && this.setState({ enableTotalHours: true });
    }
    if (name === "totalHours") {
      if (value) this.setState({ cost: value * this.state.ratePerHour });
      if (!value) this.setState({ cost: 0 });
    }

    this.setState({ [name]: value });
    this.setState({ errors: { ...this.state.errors, [name]: null } });
  };
  imageRemover = async (blob) => {
    let imageandvideo = this.state.imageandvideo;
    let check = imageandvideo.image.filter(
      (img) => img.filename !== blob.name.split(/(?=jpg|png|jpeg|gif)/g)[0]
    );
    imageandvideo = { ...imageandvideo, image: [...check] };
    if (!imageandvideo.image.length) {
      this.setState({
        selectedDefaultListingImage:
          "https://defaultimages-soci.s3.us-west-1.amazonaws.com/lisiting__default_2.JPG",
      });
    }
    this.setState({ imageandvideo });
  };
  imageUploader = async (blob) => {
    // let imageBase = await  blobToBase64(blob);
    let image = {
      filename: blob.name.split(/(?=jpg|png|jpeg|gif)/g)[0],
      file: blob,
    };
    this.setState({ selectedDefaultListingImage: null });
    let imageandvideo = this.state.imageandvideo;
    imageandvideo = {
      ...imageandvideo,
      image: [...imageandvideo.image, image],
    };
    this.setState({ imageandvideo: imageandvideo });
  };
  getLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        async (position, props) => {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let location = await this.props.getCurrentLocation({
            lat: lat,
            long: long,
          });

          this.setState({
            state: location.state,
            city: location.city,
            street: location.street,
            zipcode: location.zipcode,
            country: location.country,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  onSubmitHandler = () => {
    const {
      continuous,
      errors,
      flatRateBox,
      // flatRate,
      hoursPerDay,
      imageandvideo,
      nonPaid,
      ratePerHour,
      location,
      projectEndDate,
      ratePerHourBox,
      enableTotalHours,
      videoUrl,
      cost,
      selectedDefaultListingImage,
      openIndustriesDetails,
      typeOfWork,
      ...rest
    } = this.state;
    console.log(this.state.errors);
    console.log(this.state);
    if (nonPaid || flatRateBox) {
      delete rest.totalHours;
    }
    if (rest.remote) {
      delete rest.state;
      delete rest.city;
      delete rest.street;
      delete rest.country;
      delete rest.zipcode;
    }

    if (ratePerHourBox || nonPaid) {
      delete rest.flatRate;
    }
    const validate = validateAddListing(rest);
    rest.selectedDefaultListingImage = this.state.selectedDefaultListingImage;
    if (validate !== null) {
      this.setState({
        errors: {
          ...validate,
          projectEndDate:
            !continuous && projectEndDate === ""
              ? "Project End Date is Required"
              : "",
          // location:
          //   !remote && location === "" ? "Project Location is Required" : "",
        },
      });
      return (
        document.post[Object.keys(validate)[0]] &&
        document.post[Object.keys(validate)[0]].focus()
      );
    }

    if (!continuous) {
      if (!continuous && projectEndDate === "") {
        return this.setState({
          errors: {
            ...this.state.errors,
            projectEndDate: "Project End Date is Required",
          },
        });
      }
      // if (!remote && location === "") {
      //   return this.setState({
      //     errors: {
      //       ...this.state.errors,
      //       location: "Project Location  is Required",
      //     },
      //   });
      // }
    }

    let response = this.props.addListing(this.state);
    if (response) {
      this.props.history.push("/");
    }
  };
  render() {
    const light = this.props.logo[0].light;

    return (
      <Fragment>
        <section className="header-breadcrumb bgimage overlay overlay--dark">
          <div className="bg_image_holder">
            <img src="" alt="" />
          </div>
          <div className="mainmenu-wrapper">
            <Header logo={light} class="menu--light" />
          </div>
          <BreadcrumbWraper title="Add Listing" userId={this.state.userId} />
        </section>

        <section className="add-listing-wrapper border-bottom section-bg section-padding-strict">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>
                        Project Information
                      </h4>
                    </div>
                  </div>

                  <div className="atbdb_content_module_contents">
                    <form name="post">
                      <div className="form-group required">
                        <label
                          htmlFor="title"
                          className="form-label control-label"
                        >
                          Title of Project
                        </label>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          value={this.state.title}
                          name="title"
                          id="title"
                          placeholder="Title of Listing..."
                          required
                        />
                        {this.state.errors && (
                          <AlertMessage
                            error={this.state.errors.title}
                            type="danger"
                          />
                        )}
                      </div>
                      <div className="form-group required">
                        <label
                          htmlFor="desc"
                          className="form-label control-label"
                        >
                          Project Description
                        </label>
                        <textarea
                          value={this.state.longDescription}
                          name="longDescription"
                          onChange={this.onChangeHandler}
                          id="desc"
                          rows="8"
                          className="form-control"
                          placeholder="Tell me more about your listing..."
                        ></textarea>
                        {this.state.errors && (
                          <AlertMessage
                            error={this.state.errors.longDescription}
                            type="danger"
                          />
                        )}
                      </div>
                      <div className="form-group required">
                        <label
                          htmlFor="projectLabel"
                          className="form-label control-label"
                        >
                          Project Label{" "}
                        </label>
                        <br />
                        <i>
                          Add a catchy description to draw in potential
                          applicants!
                        </i>
                        <input
                          type="text"
                          onChange={this.onChangeHandler}
                          className="form-control"
                          value={this.state.projectLabel}
                          name="projectLabel"
                          id="projectLabel"
                          placeholder="Project Label..."
                          required
                        />
                        {this.state.errors && (
                          <AlertMessage
                            error={this.state.errors.projectLabel}
                            type="danger"
                          />
                        )}
                      </div>
                      <div className="form-group required">
                        <label
                          htmlFor="projectStartDate"
                          className="form-label control-label"
                        >
                          Project Start Date
                        </label>
                        <input
                          min={
                            new Date().getFullYear() +
                            "-" +
                            new Date().getMonth() +
                            "-" +
                            new Date().getDate()
                          }
                          type="date"
                          className="form-control"
                          id="projectStartDate"
                          name="projectStartDate"
                          required
                          onChange={(event) =>
                            this.setState({
                              projectStartDate: event.target.value,
                              errors: {
                                ...this.state.errors,
                                projectStartDate: null,
                              },
                            })
                          }
                          value={this.state.projectStartDate}
                        />
                        {this.state.errors && (
                          <AlertMessage
                            error={this.state.errors.projectStartDate}
                            type="danger"
                          />
                        )}
                      </div>

                      <div className="project-end-date form-group required">
                        <div className="project-start-date ">
                          <label className="form-label control-label">
                            Project End Date
                          </label>
                        </div>

                        <div className="form-group">
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="Continuous"
                              value="priceRange"
                              name="priceMethod"
                              checked={this.state.continuous}
                              onChange={() =>
                                this.setState({
                                  continuous: !this.state.continuous,
                                  errors: {
                                    ...this.state.errors,
                                    projectEndDate: null,
                                  },
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Continuous"
                            >
                              Continuous
                            </label>
                          </div>
                        </div>
                      </div>

                      <>
                        <input
                          min={
                            new Date().getFullYear() +
                            "-" +
                            new Date().getMonth() +
                            "-" +
                            new Date().getDate()
                          }
                          type="date"
                          className="form-control "
                          id="cdate"
                          required
                          name="projectEndDate"
                          onChange={(event) =>
                            this.setState({
                              projectEndDate: event.target.value,
                              errors: {
                                ...this.state.errors,
                                projectEndDate: null,
                              },
                            })
                          }
                          value={this.state.projectEndDate}
                          disabled={this.state.continuous}
                        />
                        <br />
                        {this.state.errors && (
                          <AlertMessage
                            error={this.state.errors.projectEndDate}
                            type="danger"
                          />
                        )}
                      </>

                      <div className="form-group required">
                        <div>
                          <div className="remote-opportunity">
                            <label className="form-label control-label pr-5">
                              Project Location
                            </label>

                            <div className="form-group">
                              <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary mb-5">
                                <input
                                  checked={this.state.remote}
                                  type="checkbox"
                                  className="custom-control-input "
                                  name="remote"
                                  id="remote"
                                  value={this.state.remote}
                                  onChange={() => {
                                    if (this.state.remote) {
                                      this.setState({
                                        street: "",
                                        state: "",
                                        city: "",
                                        country: "",
                                        zipcode: "",
                                      });
                                    }
                                    this.setState({
                                      remote: !this.state.remote,
                                    });
                                  }}
                                />
                                <label
                                  className="custom-control-label control text-dark font-weight-bold"
                                  htmlFor="remote"
                                >
                                  Remote
                                </label>
                              </div>
                              <div>
                                {!this.state.remote && (
                                  <div>
                                    <div className="user_info_wrap">
                                      <div className="row">
                                        {" "}
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              htmlFor="street"
                                              className="street"
                                            >
                                              Street
                                            </label>
                                            <input
                                              className="form-control"
                                              id="street"
                                              type="text"
                                              name="street"
                                              value={this.state.street}
                                              onChange={this.onChangeHandler}
                                            />
                                          </div>{" "}
                                          {this.state.errors && (
                                            <AlertMessage
                                              error={this.state.errors.street}
                                              type="danger"
                                            />
                                          )}
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              htmlFor="city"
                                              className="city"
                                            >
                                              City
                                            </label>
                                            <input
                                              className="form-control"
                                              id="city"
                                              type="text"
                                              name="city"
                                              value={this.state.city}
                                              onChange={this.onChangeHandler}
                                            />
                                          </div>{" "}
                                          {this.state.errors && (
                                            <AlertMessage
                                              error={this.state.errors.city}
                                              type="danger"
                                            />
                                          )}
                                        </div>{" "}
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              htmlFor="state"
                                              className="state"
                                            >
                                              State
                                            </label>
                                            <input
                                              className="form-control"
                                              id="state"
                                              type="text"
                                              name="state"
                                              value={this.state.state}
                                              onChange={this.onChangeHandler}
                                            />
                                          </div>
                                          {this.state.errors && (
                                            <AlertMessage
                                              error={this.state.errors.state}
                                              type="danger"
                                            />
                                          )}
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              htmlFor="country"
                                              className="country"
                                            >
                                              Country
                                            </label>
                                            <input
                                              className="form-control"
                                              id="country"
                                              type="text"
                                              name="country"
                                              value={this.state.country}
                                              onChange={this.onChangeHandler}
                                            />
                                          </div>
                                          {this.state.errors && (
                                            <AlertMessage
                                              error={this.state.errors.country}
                                              type="danger"
                                            />
                                          )}
                                        </div>{" "}
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              htmlFor="zipCode"
                                              className="zipCode"
                                            >
                                              Zip Code
                                            </label>
                                            <input
                                              className="form-control"
                                              id="zipCode"
                                              type="number"
                                              name="zipcode"
                                              value={this.state.zipcode}
                                              onChange={this.onChangeHandler}
                                            />
                                          </div>
                                          {this.state.errors && (
                                            <AlertMessage
                                              error={this.state.errors.zipcode}
                                              type="danger"
                                            />
                                          )}
                                        </div>{" "}
                                        <div className="col-md-5 mt-4">
                                          <div className="form-group">
                                            <button
                                              type="button"
                                              onClick={() => this.getLocation()}
                                              // onClick={this.updateUser}
                                              className="btn  btn-lg btn-dark "
                                            >
                                              <i className="fas fa-compass"></i>{" "}
                                              Current Location
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group required">
                        <label className="form-label control-label">
                          Compensation
                        </label>

                        <div className="pricing-options">
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              checked={this.state.ratePerHourBox}
                              type="checkbox"
                              className="custom-control-input"
                              name="ratePerHourBox"
                              id="ratePerHour"
                              value={this.state.ratePerHourBox}
                              onChange={() => {
                                this.setState({
                                  ratePerHourBox: !this.state.ratePerHourBox,
                                  nonPaid: false,
                                  flatRateBox: false,
                                  compensation: "ratePerHour",
                                  flatRate: "",
                                  errors: {
                                    ...this.state.errors,
                                    compensation: null,
                                  },
                                });
                              }}
                            />
                            <label
                              className="custom-control-label control"
                              htmlFor="ratePerHour"
                            >
                              Rate Per Hour
                            </label>
                          </div>

                          <div className="pricing-checkboxes">
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={this.state.flatRateBox}
                                type="checkbox"
                                className="custom-control-input"
                                id="flatRate"
                                value={this.state.flatRateBox}
                                name="flatRateBox"
                                onChange={() => {
                                  this.setState({
                                    ratePerHourBox: false,
                                    nonPaid: false,
                                    flatRateBox: !this.state.flatRateBox,
                                    compensation: "flatRate",
                                    ratePerHour: "",
                                    errors: {
                                      ...this.state.errors,
                                      compensation: null,
                                    },
                                  });
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="flatRate"
                              >
                                Flat Rate
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="pricing-option-inputs">
                          <div>
                            {this.state.ratePerHourBox && (
                              <>
                                <div className="form-group m-0 required">
                                  {" "}
                                  <label
                                    htmlFor="ratePerHour"
                                    className="control-label"
                                  >
                                    Rate Per hour
                                  </label>
                                  <input
                                    name="ratePerHour"
                                    onChange={this.onChangeHandler}
                                    value={this.state.ratePerHour}
                                    type="number"
                                    id="ratePerHour"
                                    className="form-control"
                                    placeholder="Enter Rate Per Hour"
                                  />
                                </div>
                                <div className="form-group m-0">
                                  <div className="atbdb_content_module_contents">
                                    <div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label
                                              htmlFor="totalHours"
                                              className="control-label"
                                            >
                                              Hours{" "}
                                              <i className="fa fa-clock"></i>
                                            </label>
                                            <input
                                              disabled={
                                                !this.state.enableTotalHours
                                              }
                                              className="form-control "
                                              id="totalHours"
                                              type="number"
                                              name="totalHours"
                                              value={this.state.totalHours}
                                              onChange={this.onChangeHandler}
                                            />
                                            {this.state.errors && (
                                              <AlertMessage
                                                error={
                                                  this.state.errors.totalHours
                                                }
                                                type="danger"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <label
                                            htmlFor="cost"
                                            className="cost"
                                          >
                                            Cost{" "}
                                            <i className="fa fa-money"> USD</i>
                                          </label>
                                          <input
                                            disabled={true}
                                            className="form-control"
                                            id="cost"
                                            type="number"
                                            name="cost"
                                            value={this.state.cost}
                                          />
                                        </div>
                                        <i className="font-weight-bold">
                                          This cost is subject to change and
                                          does not include applicable taxes or
                                          service fees.
                                        </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {this.state.errors && (
                              <AlertMessage
                                error={this.state.errors.pricingDetail}
                                type="danger"
                              />
                            )}
                          </div>

                          {this.state.flatRateBox && (
                            <div>
                              <input
                                value={this.state.flatRate}
                                type="number"
                                id="compensation"
                                name="flatRate"
                                onChange={this.onChangeHandler}
                                className="form-control"
                                placeholder="Enter Flat Rate"
                              />
                            </div>
                          )}

                          <div>
                            {this.state.errors && (
                              <AlertMessage
                                error={this.state.errors.compensation}
                                type="danger"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <div className="form-groups required">
                        <div>
                          <label className="form-label control-label">
                            Estimated Hours Per Day
                          </label>
                          <div
                            id="estimatedHoursPerDay"
                            className="atbdp-radio-list"
                            onChange={(e) =>
                              this.setState({
                                estimatedHoursPerDay: e.target.value,
                                errors: {
                                  ...this.state.errors,
                                  estimatedHoursPerDay: null,
                                },
                              })
                            }
                          >
                            <div className="custom-control">
                              <input
                                type="radio"
                                name="hours"
                                value="5+ hours / day"
                                className="custom-control-input"
                                id="5hours"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="5hours"
                              >
                                5+ hours / day
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                value="under4hours"
                                name="hours"
                                className="custom-control-input"
                                id="under4hours"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="under4hours"
                              >
                                Under 4 hours/day
                              </label>
                            </div>
                            {this.state.errors && (
                              <AlertMessage
                                error={this.state.errors.estimatedHoursPerDay}
                                type="danger"
                              />
                            )}
                          </div>
                        </div>

                        <div
                          onChange={(e) =>
                            this.setState({
                              typeOfWork: e.target.value,
                              errors: {
                                ...this.state.errors,
                                typeOfWork: null,
                              },
                            })
                          }
                          id="typeOfWork"
                        >
                          <label className="form-label control-label">
                            Type of Work
                          </label>
                          <div className="atbdp-radio-list">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="consulting"
                                name="work"
                                value="consulting"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="consulting"
                              >
                                Consulting
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="temporary"
                                name="work"
                                className="custom-control-input"
                                value="temporary"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="temporary"
                              >
                                Temporary - Short Term
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="Continuous"
                                name="work"
                                value="Continuous"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="Continuous"
                              >
                                Continuous Employment
                              </label>
                            </div>
                            {this.state.errors && (
                              <AlertMessage
                                error={this.state.errors.typeOfWork}
                                type="danger"
                              />
                            )}
                          </div>
                        </div>
                      </div> */}

                      <div className="form-group">
                        <label htmlFor="industry" className="form-label">
                          Relevant Industries{" "}
                          <i
                            onClick={this.handleIndustriesDetailsOpen}
                            className="fa fa-question-circle"
                          ></i>
                        </label>
                        <div className="select-basic">
                          <Multiselect
                            options={searchCategories}
                            displayValue={this.state.relevantIndustries}
                            isObject={false}
                            placeholder="Select Industries"
                            onSelect={(data) =>
                              this.setState({
                                relevantIndustries: data.join(","),
                                errors: {
                                  ...this.state.errors,
                                  relevantIndustries: null,
                                },
                              })
                            }
                            onRemove={this.onRemoveIndustries}
                            hidePlaceholder={true}
                            style={{
                              chips: { background: "#97e5e5" },
                              searchBox: {
                                width: "100%",
                                padding: "12px 20px",
                                margin: "1px 0",
                                display: "inline-block",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                boxSizing: "border-box",
                                height: "3.5rem",
                              },
                            }}
                          />
                        </div>
                        {this.state.errors && (
                          <AlertMessage
                            error={this.state.errors.relevantIndustries}
                            type="danger"
                          />
                        )}
                      </div>
                      <IndustrySubcategories
                        open={this.state.openIndustriesDetails}
                        handleOpen={this.handleIndustriesDetailsOpen}
                        handleClose={this.handleIndustriesDetailsClose}
                      />
                    </form>
                  </div>
                  {/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>
                {/*<!-- ends: .atbd_content_module -->*/}
              </div>
              {/*<!-- ends: .col-lg-10 -->*/}

              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-calendar-check-o"></span> File
                        (Image, Audio, Video)
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents">
                    <div id="_listing_gallery">
                      <div
                        className="add_listing_form_wrapper"
                        id="gallery_upload"
                      >
                        <div className="form-group text-center">
                          {/*<!--  add & remove image links -->*/}

                          <ImageUploader
                            imageRemover={this.imageRemover}
                            imageUploader={this.imageUploader}
                          />
                          {/* {this.state.image.map((image) => (
                              <img
                                src={`data:image/jpg;base64,${image.data}`}
                                alt="from test"
                              />
                            ))} */}
                        </div>
                        <div className="d-flex flex-column">
                          {/*<!-- image container, which can be manipulated with js -->*/}
                          <div className="d-flex justify-content-around align-content-center ">
                            {" "}
                            {this.state.imageandvideo.image.map((item) => (
                              <div key={item.file}>
                                <img
                                  width="150"
                                  loading="lazy"
                                  style={{
                                    height: "100%",
                                    border:
                                      this.state.selectedDefaultListingImage ===
                                        item && " 3px solid green",
                                  }}
                                  src={window.URL.createObjectURL(item.file)}
                                  className="img-fluid rounded-top rounded-right rounded-bottom rounded-left border-1"
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>

                          {this.state.imageandvideo.image &&
                            !this.state.imageandvideo.image.length && (
                              <div>
                                <span>Select Default Image or Upload </span>
                                <br />
                              </div>
                            )}
                          {this.state.imageandvideo.image &&
                            !this.state.imageandvideo.image.length && (
                              <>
                                <div className="d-flex justify-content-around align-content-center ">
                                  {" "}
                                  {[
                                    "https://defaultimages-soci.s3.us-west-1.amazonaws.com/lisiting__default_2.JPG",
                                    "https://defaultimages-soci.s3.us-west-1.amazonaws.com/lisitng__default_1.jpg",
                                    "https://defaultimages-soci.s3.us-west-1.amazonaws.com/listing__default_3.jpg",
                                    "https://defaultimages-soci.s3.us-west-1.amazonaws.com/listing_default_4.jpg",
                                    "https://defaultimages-soci.s3.us-west-1.amazonaws.com/listing_default_5.jpg",
                                  ].map((item) => (
                                    <div
                                      key={item}
                                      className="pr-3"
                                      style={{ maxHeight: "150px" }}
                                    >
                                      <img
                                        loading="lazy"
                                        onClick={() =>
                                          this.handleSelectDefaultImage(item)
                                        }
                                        style={{
                                          height: "100%",
                                          border:
                                            this.state
                                              .selectedDefaultListingImage ===
                                              item && " 3px solid green",
                                        }}
                                        width="150"
                                        src={item}
                                        className="img-fluid rounded-top rounded-right rounded-bottom rounded-left border-1 "
                                        alt=""
                                      />
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                      {/*<!--ends add_listing_form_wrapper-->*/}
                    </div>
                  </div>
                  {/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>
                {/*<!-- ends: .atbd_content_module -->*/}
              </div>
              {/*<!-- ends: .col-lg-10 -->*/}

              <div className="col-lg-10 offset-lg-1 text-center">
                <div className="atbd_term_and_condition_area custom-control  checkbox-outline-primary"></div>
                <div className="btn_wrap list_submit m-top-25">
                  {this.props.loading ? (
                    <div className="btn btn-block btn-lg btn-gradient btn-gradient-two">
                      <Spinner />
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={this.onSubmitHandler}
                      className="btn btn-primary btn-lg listing_submit_btn"
                    >
                      Submit listing
                    </button>
                  )}
                </div>
              </div>
              {/*<!-- ends:    .col-lg-10 -->*/}
            </div>
          </div>
        </section>
        {/*<!--   ends: .add-listing-wrapper -->*/}

        <Footer />
      </Fragment>
    );
  }
}
AddListing.propTypes = {
  addListing: PropTypes.func.isRequired,
  loaduser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    loading: state.process.isloading,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  addListing,
  loaduser,
  getCurrentLocation,
})(AddListing);
