import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import "filepond/dist/filepond.min.css";

registerPlugin(
  //FilePondPluginImageExifOrientation,
  //FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileMetadata,
  FilePondPluginFileValidateType
);

const ImageUploader = ({ imageUploader, imageRemover, options }) => {
  return (
    <>
      <FilePond
        labelIdle={options && options.label ? options.label : "Upload Here"}
        allowMultiple={true}
        allowFileEncode={true}
        acceptedFileTypes={
          options && options.acceptedFileTypes ? options.acceptedFileTypes : []
        }
        allowFileTypeValidation={
          options && options.allowFileTypeValidation
            ? options.allowFileTypeValidation
            : false
        }
        // imageResizeTargetWidth={300}
        // imageResizeTargetHeight={300}
        //imageTransformOutputQuality={100}
        // allowImageResize={true}
        //  allowImageTransform={true}
        allowReorder={true}
        onremovefile={(error, file) => {
          imageRemover(file.file);
        }}
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve, reject) => {
            resolve(type);
          })
        }
        imageTransformVariantsIncludeOriginal={false}
        //Can be used for future Transformation
        // imageTransformVariants={{
        // 	thumb_medium: (transforms) => {
        // 		transforms.resize.size.width = 100;
        // 		transforms.resize.size.height = 100;
        // 		return transforms;
        // 	},
        // }}
        onpreparefile={(file, output) => {
          imageUploader(output);
        }}
        server={{
          process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort
          ) => {
            // let temp = thumb_medium;
            load();
          },
        }}
      />
    </>
  );
};

export default ImageUploader;
