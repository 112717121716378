import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  getCertifications,
  deleteCertification,
} from "../../redux/action/craftProfile";
import UploadCertifications from "../content/modal/uploadCertifications";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Spinner from "../content/modal/spinnermodal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
  heading: {
    margin: "12px 0",
  },

  content: "",
}));

const Certifications = ({
  viewType,
  getCertifications,
  certifications,
  deleteCertification,
}) => {
  const styles = {
    view: {
      visibility: viewType === "view-profile" ? "hidden" : "visible",
    },
  };
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [showUploadCertification, setShowUploadCertification] = useState(false);
  const loading = useSelector((state) => state.craftProfile.loading);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleCreateCertification = () => {
    setShowUploadCertification(!showUploadCertification);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getCertifications();
  }, [dispatch, getCertifications]);

  return (
    <Fragment>
      {loading ? (
        <div className="d-flex">
          <Spinner />
        </div>
      ) : (
        <div>
          {" "}
          <div className="atbd_content_module atbd_listing_gallery">
            <Accordion expanded={expanded} onChange={handleChange("panel1")}>
              <Grid container className={classes.root}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>Certifications and Licenses</h4>
                    </div>
                  </div>
                </AccordionSummary>
              </Grid>
              <AccordionDetails className={classes.root}>
                <div className="atbdb_content_module_contents">
                  <div
                    className="certification-container"
                    style={{ overflow: "auto", overflowY: "hidden" }}
                  >
                    {certifications.userCertifications.length &&
                    certifications.userCertifications !== "" ? (
                      certifications.userCertifications.map((cert) => {
                        return (
                          <div key={cert._id} className="certification-item">
                            <div
                              style={styles.view}
                              className="cert-delete-top-right"
                            >
                              <i
                                onClick={() => deleteCertification(cert._id)}
                                className="far fa-trash-alt fa-2x"
                              ></i>{" "}
                            </div>
                            <ul>
                              <b>Certification Name:</b> {cert.name}
                            </ul>
                            <ul>
                              <b>Organization:</b> {cert.office}
                            </ul>
                            <ul>
                              <b>Credential ID:</b> {cert.credentialId}
                            </ul>
                            <ul>
                              <b>Issue Date:</b> {cert.issueDate}
                            </ul>
                            <ul>
                              <b>Expiration Date:</b> {cert.expireDate}
                            </ul>
                            <ul>
                              <b>Does Not Expire:</b>
                              {cert.isExpire ? " Yes" : " No"}
                            </ul>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        Show Case Your Accomplishments....
                      </p>
                    )}
                  </div>
                  <br />
                </div>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid style={styles.view} item>
                    <button
                      onClick={toggleCreateCertification}
                      className="btn btn-xs btn-gradient btn-gradient-two"
                    >
                      <i className=" fas fa-plus"> Add Certification</i>
                    </button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
          <UploadCertifications
            showUploadCertification={showUploadCertification}
            toggleCreateCertification={toggleCreateCertification}
          />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    gallery: state.gallery,
    gallery2: state.gallery2,
    user: state.auth.user,
    certifications: state.craftProfile,
  };
};
export default connect(mapStateToProps, {
  getCertifications,
  deleteCertification,
})(Certifications);
