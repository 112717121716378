import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  uploadVideos,
  uploadAudios,
} from "../../../../redux/action/craftProfile";
import {
  updateImageInAlbum,
  updateVideoInAlbum,
  updateAudioInAlbum,
  updatePhotoAlbum,
  updateAudioAlbum,
  updateVideoAlbum,
} from "../../../../redux/action/craftProfile";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AudioPlayerCard from "../../../postFeed/audioPlayer/audioPlayerCard";

const UpdateContent = (props) => {
  console.log("form update content " + props.contentInfo.title);
  const users = useSelector((state) => state.auth.user);
  const closeUploadPhoto = () => {
    props.toggleUploadPhoto();
  };
  const [showPublic, setshowPublic] = useState(props.contentInfo.isPublic);
  const albumTypes = ["photoalbum", "videoalbum", "audioalbum"];
  const isAlbum = albumTypes.includes(props.contentInfo.type);
  const [updateForm, setUpdateForm] = useState({
    url: props.contentInfo.url,
    tags: props.contentInfo.tags,
    description: props.contentInfo.description,
    title: props.contentInfo.title,
    public: props.contentInfo.isPublic,
  });

  const handleSwitchChange = (event) => {
    setshowPublic(event.target.checked);
    updateForm.public = !updateForm.public;
  };

  const onChangeHandler = (e) => {
    let updatedValue = {};
    updatedValue = { [e.target.name]: e.target.value };

    setUpdateForm((updatedForm) => ({
      ...updatedForm,
      ...updatedValue,
    }));
  };

  const updateContents = () => {
    if (props.contentInfo.type === "photo") {
      props.updateImageInAlbum(
        updateForm,
        props.contentInfo.id,
        props.contentInfo.selectedAlbumId
      );
    }
    if (props.contentInfo.type === "video") {
      props.updateVideoInAlbum(
        updateForm,
        props.contentInfo.id,
        props.contentInfo.selectedAlbumId
      );
    }
    if (props.contentInfo.type === "audio") {
      props.updateAudioInAlbum(
        updateForm,
        props.contentInfo.id,
        props.contentInfo.selectedAlbumId
      );
    }
    if (props.contentInfo.type === "photoalbum") {
      props.updatePhotoAlbum(updateForm, props.contentInfo.selectedAlbumId);
    }
    if (props.contentInfo.type === "videoalbum") {
      props.updateVideoAlbum(updateForm, props.contentInfo.selectedAlbumId);
    }
    if (props.contentInfo.type === "audioalbum") {
      props.updateAudioAlbum(updateForm, props.contentInfo.selectedAlbumId);
    }
    closeUploadPhoto();
  };

  return (
    <>
      <Modal
        show={props.showUploadPhoto}
        animation={true}
        onHide={closeUploadPhoto}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 id="claim_listing_label">
              <i className="la la-plus"></i> Update {isAlbum ? "Album" : "File"}
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <form action="/">
              <div className="form-group">
                <div className="col">
                  {" "}
                  <div className="photo-upload-preview-container">
                    <>
                      <div className="photo-upload-preview-inner-container">
                        <div className="upload-photo-form">
                          <div className=" inner-button">
                            <div className="col-md-12">
                              <label id="tags"> </label>
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <input
                                  className="img-tag"
                                  style={{
                                    borderRadius: "2px 2px",
                                    height: "47px",
                                    background: "white",
                                    marginLeft: "130px",
                                    maxWidth: "234px",
                                  }}
                                  id={""}
                                  name="title"
                                  type="text"
                                  onChange={onChangeHandler}
                                  placeholder="Title"
                                  value={updateForm.title}
                                />

                                <textarea
                                  rows="4"
                                  cols="30"
                                  className="img-tag"
                                  style={{
                                    borderRadius: "2px 2px",
                                    maxWidth: "234px",
                                    marginLeft: "130px",
                                  }}
                                  id={""}
                                  name="description"
                                  type="text"
                                  onChange={onChangeHandler}
                                  placeholder="Description"
                                  value={updateForm.description}
                                />

                                {isAlbum === false && (
                                  <input
                                    className="img-tag"
                                    style={{
                                      borderTopRightRadius: "0px",
                                      borderBottomRightRadius: "0px",
                                      height: "47px",
                                      marginLeft: "130px",
                                      maxWidth: "234px",
                                    }}
                                    id={""}
                                    name="tags"
                                    type="text"
                                    onChange={onChangeHandler}
                                    placeholder="insert tags comma ',' separated"
                                    value={updateForm.tags}
                                  />
                                )}
                                {isAlbum === false && (
                                  <div
                                    style={{
                                      borderTopRightRadius: "0px",
                                      borderBottomRightRadius: "0px",
                                      height: "47px",
                                      marginLeft: "130px",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      style={{ fontSize: "12px" }}
                                      className="fas fa-info-circle"
                                    ></i>{" "}
                                    separate the tags with{" "}
                                    <b style={{ fontSize: "20px" }}>, </b>
                                    sign
                                  </div>
                                )}

                                {props.updatingAlbum && (
                                  <FormGroup
                                    style={{
                                      marginLeft: "130px",
                                      maxWidth: "234px",
                                    }}
                                    color="success"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          color="primary"
                                          checked={showPublic}
                                          onChange={handleSwitchChange}
                                        />
                                      }
                                      label="Publish to public profile"
                                    />
                                  </FormGroup>
                                )}
                                <br />
                              </div>
                              <div className="list-tags"></div>
                            </div>
                          </div>
                        </div>
                        <div className="album-preview-photo">
                          <div className="single-thumb text-center">
                            <div className="album-thumb-container">
                              {props.contentInfo.type !== "audio" &&
                              props.contentInfo.type !== "video" ? (
                                <img src={props.contentInfo.url} alt="" />
                              ) : (
                                <div></div>
                              )}

                              {props.contentInfo.type === "video" ? (
                                <video controls>
                                  {" "}
                                  <source
                                    src={props.contentInfo.url}
                                    type="video/mp4"
                                  />{" "}
                                </video>
                              ) : (
                                <div></div>
                              )}
                              {props.contentInfo.type === "audio" && (
                                <AudioPlayerCard
                                  audios={[props.contentInfo.url]}
                                  albumImage={users.userAvatar}
                                  albumImageKey={users.userAvatarKey}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>{" "}
                </div>
              </div>

              <button
                onClick={updateContents}
                type="button"
                className="btn btn-secondary btn-sm mt-3"
              >
                Update
              </button>
            </form>
          </Fragment>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listings: state.listings,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  updateImageInAlbum,
  updatePhotoAlbum,
  updateAudioAlbum,
  updateVideoAlbum,
  updateVideoInAlbum,
  updateAudioInAlbum,
  uploadVideos,
  uploadAudios,
})(UpdateContent);
