import React from "react";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { red } from "@mui/material/colors";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postComment } from "../../../redux/action/discovery";
import "../postFeedstyles.css";
import { postCommentOnAllListing } from "../../../redux/action/userpost";

export default function CommentInputField({ showAvatar, postId, commentFrom, incrementCommentCount }) {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [count, setCommentCount] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const handleComment = (value) => {
    setComment(value);
  };
  const handlePostComment = () => {
    if (commentFrom == 'allListing') {
      dispatch(postCommentOnAllListing(postId, comment));
    }
    else {
      dispatch(postComment(postId, comment));
      setCommentCount(count+1);
      incrementCommentCount(count);
    }
    setComment("");
  };

  return (
    <div className="input-group">
      {showAvatar && (
        <Avatar className="avatar" sx={{ bgcolor: red[500] }} alt="Remy Sharp">
          {user.userAvatarKey ? (
            <img className="discovery_user_avatar" src={user.userAvatar} />
          ) : (
            user.userName[0].toUpperCase()
          )}
        </Avatar>
      )}
      <input
        className="commentInput"
        onChange={(e) => handleComment(e.target.value)}
        value={comment}
      />
      {comment && (
        <Button
          className="commentButton"
          variant="text"
          onClick={handlePostComment}
        >
          Post
        </Button>
      )}
    </div>
  );
}
