import React from "react";

const AlertMessage = ({ error, type }) => {
  return (
    <div className={`text-${type}`}>
      <p>{error}</p>
    </div>
  );
};

export default AlertMessage;
