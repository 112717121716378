import * as React from "react";
import Popover from "@mui/material/Popover";

export default function Details({ title, description, tags }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {(title.length > 25 || description.length > 25) && (
        <p style={{ color: "teal" }} onClick={handleClick}>
          Details
        </p>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "10px" }}>
          <div>
            <span style={{ fontWeight: "bold" }}>Title :</span> {title}
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Description : </span>
            {description}
          </div>
          {tags && tags.length > 1 && (
            <div>
              <span style={{ fontWeight: "bold" }}>Tags : </span>
              {tags.map((tag) => (
                <span>#{tag} </span>
              ))}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}
