import { LOAD_ADVERTS } from "../action/types";

const initialState = {
  adverts: [],
};

const advertReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_ADVERTS:
      return {
        ...state,
        adverts: payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default advertReducer;
