import React from "react";

export default function MediaElement({
  videoUrl,
  videoTag,
  videoDescription,
  videoTitle,
  videofunc,
}) {
  const videoSizer = {
    marginTop: "5px",
    inlineSize: "100%",
    aspectRatio: "16/9",
    borderRadius: "3%",
    objectFit: "cover",
    marginBottom: "10px",
    height: "auto",
  };

  return (
    <>
      <div className="media-element">
        <video
          key={videoUrl}
          style={videoSizer}
          id="small-img"
          onClick={() =>
            videofunc(videoUrl, videoTag, videoDescription, videoTitle)
          }
          alt="socicraft users video"
        >
          {" "}
          <source src={videoUrl} type="video/mp4" />{" "}
        </video>
      </div>
    </>
  );
}
