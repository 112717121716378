import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import PostCard from "./postCard";
import AllListings from "./listingsCard/allListings";
import Personal from "./personalCard/personal";
import Advertisement from "./advertisment";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import Navigation from "./navigation";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostContent from "./upload/postContent";
import StatusDisplay from "./statusDisplay";
import { navigationMap } from "../../services/consts";
import { setNavigation } from "../../redux/action/discovery";
import {
  getPost,
  getPosts,
  getDiscoveryAbout,
  getSaveForLater,
  getCommentsPost,
  getUpVotedContents,
} from "../../redux/action/discovery";
import SearchInputs from "../layout/headerv2/dropdownFilter/searchInputs";
import SkeletonLoader from "./skeletonLoader";
// import { NewListings } from "./newListings";
import "./postFeedstyles.css";
import zIndex from "@mui/material/styles/zIndex";
import { display } from "@mui/system";
import PostingSpinner from "./upload/postingSpinner";
import WelcomeModal from "../content/modal/welcome/welcome";

export default function PostFeed(props) {
  const dispatch = useDispatch();
  const limit = 5;
  const [skip, setSkip] = useState(0);
  const discovery = useSelector((state) => state.discoveryReducer);
  const isUserWelcomed = useSelector((state) => state.auth.isUserWelcomed);
  const user = useSelector((state) => state.auth.user);
  const currentNavigationState = useSelector(
    (state) => state.discoveryReducer.navigationState
  );
  const reloadNavigationState = useSelector(
    (state) => state.discoveryReducer.reloadNavigationState
  );
  const isLoading = useSelector((state) => state.process.isloading);

  const posts = discovery.contents;
  const error = discovery.errorMessage;
  const uploadStatus = discovery.uploadStatus;
  const postsCount = discovery.postsCount;
  const [verticalScroll, setVerticalScroll] = useState(false);
  const handleVerticalScroll = (show) => {
    setVerticalScroll(show);
  };
  const handleNavigationClick = (navigationItem) => {
    if (navigationItem === navigationMap.get(navigationItem)) {
      dispatch(setNavigation(navigationItem));
    }
  };
  useEffect(() => {
    setHasMore(true);
    if (currentNavigationState === "home" && props.postId) {
      dispatch(getPost(props.postId));
    }
    if (currentNavigationState === "home") {
      dispatch(getPosts({ limit: limit, skip: posts.length }));
    }
    if (currentNavigationState === "saveforlater") {
      window.scrollTo(0, 0);
      dispatch(
        getSaveForLater({
          limit: limit,
          skip: posts.length,
        })
      );
    }
    if (currentNavigationState === "comments") {
      window.scrollTo(0, 0);
      dispatch(
        getCommentsPost({
          limit: limit,
          skip: posts.length,
        })
      );
    }
    if (currentNavigationState === "votes") {
      window.scrollTo(0, 0);
      dispatch(
        getUpVotedContents({
          limit: limit,
          skip: posts.length,
        })
      );
    }

    dispatch(getDiscoveryAbout());
  }, [currentNavigationState, reloadNavigationState]);

  const [newPost, setNewPost] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const showSearchBtn = () => {
    setShowSearch(!showSearch);
  };
  const [value, setValue] = useState(0);
  const [items, setItems] = useState(posts);
  const [hasMore, setHasMore] = useState(true);

  const fetchItems = () => {
    if (postsCount === posts.length) {
      setHasMore(false);
    } else {
      if (currentNavigationState === "home") {
        dispatch(getPosts({ limit: limit, skip: posts.length }));
      }
      if (currentNavigationState === "saveforlater") {
        dispatch(
          getSaveForLater({
            limit: limit,
            skip: posts.length,
          })
        );
      }
      if (currentNavigationState === "comments") {
        dispatch(
          getCommentsPost({
            limit: limit,
            skip: posts.length,
          })
        );
      }
      if (currentNavigationState === "votes") {
        dispatch(
          getUpVotedContents({
            limit: limit,
            skip: posts.length,
          })
        );
      }
    }
  };

  return (
    <div className="main_container">
      {props.showOnboarding && isUserWelcomed === false && <WelcomeModal />}

      {isLoading && (
        <PostingSpinner message="Posting..." isLoading={isLoading} />
      )}

      <div className="post_feed_container">
        <div className="personal_navigation">
          {" "}
          {(discovery.about &&
            discovery.numberOfContents.hasOwnProperty("photos")) ||
          discovery.numberOfContents.hasOwnProperty("videos") ||
          discovery.numberOfContents.hasOwnProperty("audios") ? (
            <Personal
              about={discovery.about}
              numberOfContents={discovery.numberOfContents}
            />
          ) : (
            <div style={{ width: "85%", marginTop: "86px" }}>
              <SkeletonLoader height={243} />
            </div>
          )}
          {user && <Navigation userId={user.id} />}
        </div>
        <div
          className="postcontent_postcard"
          // style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          {" "}
          <PostContent />
          {newPost && (
            <div className="new_post_notification">
              <span>New Post</span>
            </div>
          )}
          {error && (
            <StatusDisplay
              message={error}
              openSnackBar={error ? true : false}
            />
          )}
          {uploadStatus && uploadStatus.isUploadSuccessful && (
            <StatusDisplay
              message={uploadStatus.message}
              openSnackBar={uploadStatus.isUploadSuccessful ? true : false}
            />
          )}
          {/* infinite scroll start */}
          {posts.length > 0 ? (
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchItems}
              hasMore={hasMore}
              loader={
                postsCount !== posts.length && (
                  <div style={{ width: "90%", margin: "0 auto" }}>
                    <SkeletonLoader height={400} />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {posts.map(
                (post) => (
                  <PostCard key={post._id} className="post_card" post={post} />
                )

                // {/* {postsCount === posts.length && <h1>You seen it all!</h1>} */}
              )}{" "}
            </InfiniteScroll>
          ) : (
            <div style={{ width: "90%", margin: "0 auto" }}>
              <SkeletonLoader height={400} />
            </div>
          )}
          {/* infinite scroll end*/}{" "}
        </div>{" "}
        {/* <div>
          <AllListings className="listings" />
          <Advertisement />
        </div> */}
        <div className="trending_advertisement">
          <AllListings className="listings" />
          <Advertisement />
        </div>
      </div>

      <Box
        sx={{ display: { lg: "none", sm: "block" } }}
        className="bottom_nav_bar"
      >
        {showSearch && <SearchInputs />}
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            className="bottom_nav"
            icon={<HomeIcon />}
            onClick={() => handleNavigationClick("home")}
          />
          <BottomNavigationAction
            className="bottom_nav"
            icon={<ArrowUpwardIcon />}
            onClick={() => handleNavigationClick("votes")}
          />
          <BottomNavigationAction
            className="bottom_nav"
            onClick={() => handleNavigationClick("comments")}
            icon={<ModeCommentIcon />}
          />
          <BottomNavigationAction
            onClick={showSearchBtn}
            className="bottom_nav"
            icon={<SearchIcon />}
          />
          <BottomNavigationAction
            onClick={() => handleNavigationClick("saveforlater")}
            className="bottom_nav"
            icon={<BookmarkIcon />}
          />
        </BottomNavigation>
      </Box>
    </div>
  );
}
