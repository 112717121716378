import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { compensationType } from "../../services/consts";
import { Link } from "react-router-dom";
import LinkCopier from "../linkCopier/linkCopier";

var moment = require("moment"); // require

const ListingCardGrid4 = () => {
  const { listings } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  let image = "./assets/img/author-thumb2.jpg";

  return (
    <Fragment>
      {listings &&
        listings
          .sort((a, b) => (a.projectStartDate < b.projectStartDate ? a : b))
          .map((post) => {
            return (
              <div className="col-lg-4 col-sm-6" key={post.id}>
                <div className="atbd_single_listing ">
                  <article className="atbd_single_listing_wrapper">
                    <figure className="atbd_listing_thumbnail_area">
                      <div className="atbd_listing_image">
                        <img
                          style={{ maxHeight: "200px", minHeight: "200px" }}
                          src={
                            post.listingImageUrl.length &&
                            post.listingImageUrl[0].url
                              ? post.listingImageUrl[0].url
                              : "/assets/img/p1.jpg"
                          }
                          alt="listingimage"
                        />
                      </div>

                      <div className="atbd_author atbd_author--thumb">
                        <a href=" ">
                          <img
                            width="50px"
                            height="50px"
                            src={
                              post.userId.userAvatar
                                ? post.userId.userAvatar
                                : image
                            }
                            alt="AuthorImage"
                          />
                          <span className="custom-tooltip">
                            {post.userId.firstName} {post.userId.lastName}
                          </span>
                        </a>
                      </div>
                      <div className="atbd_thumbnail_overlay_content">
                        <ul className="atbd_upper_badge">
                          <li>
                            <span className="atbd_meta atbd_listing_rating">
                              {post.votes}{" "}
                              <i className="fa fa-arrow-alt-circle-up"></i>
                            </span>
                          </li>
                          <li>
                            <span className="atbd_meta atbd_listing_rating">
                              {post.views} <i className="fa fa-eye"></i>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </figure>

                    <div className="atbd_listing_info">
                      <Fragment>
                        <div className="atbd_content_upper">
                          <h4 className="atbd_listing_title">
                            {" "}
                            {post.title.length >= 25
                              ? `${post.title.slice(0, 25)} ...`
                              : post.title}{" "}
                          </h4>
                          <div className="atbd_card_action">
                            <div className="atbd_listing_meta">
                              {post.projectLabel.length >= 15 ? (
                                <>{post.projectLabel.slice(0, 10)} ...</>
                              ) : (
                                post.projectLabel
                              )}
                            </div>

                            <div className="d-flex p-0 justify-content-center gap-5">
                              <Link
                                to={`/view-listing-details/${post.id}`}
                                className="btn btn-outline-dark btn-sm m-2"
                              >
                                More
                              </Link>{" "}
                              <LinkCopier id={post.id} />
                            </div>
                          </div>
                        </div>
                        {/*<!-- end .atbd_content_upper -->*/}
                        <div className="atbd_listing_bottom_content">
                          <div className="listing-meta">
                            <p>
                              <span>Expiration:</span>{" "}
                              {post.projectEndDate
                                ? moment(post.projectEndDate).format(
                                    "MMM DD YYYY"
                                  )
                                : "Continuous"}
                            </p>
                            <p>
                              <span>Project Location :</span>{" "}
                              {post.projectLocation
                                ? `${post.projectLocation[0].toUpperCase()}${post.projectLocation.slice(
                                    1
                                  )}`
                                : "Not Available"}
                            </p>{" "}
                            <p>
                              <span>Compensation Type:</span>{" "}
                              {compensationType[post.compensationType]}
                            </p>{" "}
                            <p>
                              <span>Compensation :</span>{" "}
                              {post.compensation === "" ? 0 : post.compensation}
                            </p>{" "}
                            <p>
                              <span>Total Hours :</span>{" "}
                              {post.compensationType === "ratePerHour"
                                ? post.totalHours
                                : "N/A"}
                            </p>
                            <p>
                              <span>Cost :</span>{" "}
                              {post.compensationType === "ratePerHour"
                                ? post.cost
                                : "N/A"}
                            </p>{" "}
                            <p>
                              <p>
                                <span className="la la-calendar-check-o"></span>
                                Posted {moment(post.createdAt).fromNow()}
                              </p>
                            </p>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </article>
                </div>
              </div>
            );
          })}
    </Fragment>
  );
};

export default ListingCardGrid4;
