import {
  LOAD_POPULAR_LISTINGS,
  LOAD_POPULAR_CLIENT,
  LOAD_POPULAR_CONTRACTORS,
} from "./types";
import API from "../../api/api";
import { formatError } from "../../services/formatError";

export const getPopularListings = () => async (dispatch) => {
  try {
    const response = await API.get(`/home/popular-listings`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_POPULAR_LISTINGS, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);
    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getPopularClients = () => async (dispatch) => {
  try {
    const response = await API.get(`/home/popular-clients`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_POPULAR_CLIENT, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};

export const getPopularContractors = () => async (dispatch) => {
  try {
    const response = await API.get(`/home/popular-contractors`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_POPULAR_CONTRACTORS, payload: response.data });
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
  }
};
