import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import "./Conversation-List.css";
import { getConversation, getMessage } from "../../../redux/action/messaging";
import socibotImage from "../socibotImage/Socibot.jpg";
import moment from "moment";

function ConversationList({
  conversation,
  getConversation,
  getMessage,
  conversationId,
  active,
  typingUsers,
  user,
}) {
  useEffect(() => {
    getConversation();

    return () => {};
  }, [getConversation]);

  const [hide] = useState(true);

  const callLoadMessage = (data) => {
    getMessage(data);
  };

  const isUserTyping = (data) => {
    let typingUser = typingUsers.find(
      (typers) => typers === data.UserId || typers === data.toId
    );
    if (typingUser) {
      return true;
    } else {
      return false;
    }
  };

  return (
    hide && (
      <div id="conversation-list">
        {conversation.map((data) => (
          <div
            // className={`conversation ${getActiveMessage(data) && "active"}`}
            className="conversation"
            onClick={() => callLoadMessage(data)}
            key={data.id.toString()}
          >
            <img
              height="40px"
              width="40px"
              src={data.subject === "SOCIBOT" ? socibotImage : data.userAvatar}
              style={{ borderColor: data.onlineStatus ? "green" : "red" }}
              alt=""
            />

            <div className="title-text"> {data.subject}</div>
            <div className="created-date">
              {moment(
                data.lastMessage && data.lastMessage.createdAt
                  ? new Date(data.lastMessage.createdAt).toISOString()
                  : new Date(data.time).toISOString()
              ).fromNow("dd") + " ago"}
            </div>
            <div className="conversation-message">
              {isUserTyping(data) && (
                <>
                  <img
                    style={{ width: "50px", height: "50px" }}
                    src="/assets/img/images/profiles/profile.png"
                    alt=""
                  />{" "}
                  <span>typing</span>
                </>
              )}
              {data.lastMessage && data.lastMessage.text}
            </div>
          </div>
        ))}
      </div>
    )
  );
}
ConversationList.propTypes = {
  conversation: PropTypes.array,
  conversationId: PropTypes.string,
  getConversation: PropTypes.func.isRequired,
  getMessage: PropTypes.func.isRequired,
  checkMessage: PropTypes.func,
  active: PropTypes.string,
  typingUsers: PropTypes.array,
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  conversation: state.messaging.conversation,
  conversationId: state.messaging.conversationId,
  active: state.messaging.active,
  typingUsers: state.messaging.typingUsers,
  user: state.auth.user,
  messageStatus: state.messaging.messageStatus,
});
export default connect(mapStateToProps, {
  getConversation,
  getMessage,
})(ConversationList);
