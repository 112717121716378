import * as React from "react";
import Popper from "@mui/material/Popper";
import ListingRating from "../listingRating/listingRating";
import ReviewContent from "./reviewContet";
import "./style.css";

export default function ReviewsPopper(props) {
  const contractorRatings = props.contractorRatings;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const nums = [1, 2, 3];

  return (
    <div>
      <button
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        className="landing_page_listing_card_review_popper"
      >
        <ListingRating
          contractorRating={props.contractorRating}
          totalContractorReviews={props.totalContractorReviews}
        />
      </button>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        {contractorRatings.map((rating) => (
          <ReviewContent rating={rating} />
        ))}
      </Popper>
    </div>
  );
}
