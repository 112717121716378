import { SHOW_CART } from "../action/types";

const initialState = {
  showCart: [],
};

const cart = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SHOW_CART:
      return {
        ...state,
        showCart: payload,
        loading: true,
      };

    default:
      return state;
  }
};

export default cart;
