// authentication action types
export const LOGINSUCCESS = "LOGINSUCCESS";
export const LOGINFAIL = "LOGINFAIL";
export const REGISTERSUCCESS = "REGISTERSUCCESS";
export const REGISTERFAIL = "REGISTERFAIL";
export const LOGOUT = "LOGOUT";
export const LOADUSER = "LOADUSER";
export const LOADINGSTARTED = "ISLOADING";
export const LOADINGCOMPLETED = "LOADINGCOMPLETED";
export const PASSWORDRECOVER = "PASSWORDRECOVER";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const REGISTRATION_COMPLETED = "REGISTRATION_COMPLETED";
export const REGISTRATION_STARTED = "REGISTRATION_STARTED";
//alert action types

export const ALERTTOSTIFY = "ALERTTOSTIFY";

//-----------Messaging----------------//

export const GET_MESSAGE = "GET_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const GET_CONVERSATION = "GET_CONVERSATION";
export const CHECK_MESSAGE = "CHECK_MESSAGE";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const CREATE_NEW_CONVERSATION = "CREATE_NEW_CONVERSATION";
export const CREATE_THREAD = "CREATE_THREAD";
export const SET_SOCKET = "SET_SOCKET";
export const USER_TYPING = "USER_TYPING";
export const USER_STOP_TYPING = "USER_STOP_TYPING";
export const USER_SEARCH_COMPLETED = "USER_SEARCH_COMPLETED";
export const USER_SEARCH_INITIATED = "SEARCH_INITIATED";
export const TOGGLE_CONVERSATION_LIST = "TOGGLE_CONVERSATION_LIST";
//----------------END-----------------//
//--------------User Listing Related----------------//

export const ADDLISTINGSUCCESS = "ADDLISTINGSUCCESS";
export const REMOVELISTINGSUCCESS = "REMOVELISTINGSUCCESS";
export const GET_ALL_LISTINGS = "GET_ALL_LISTINGS";
export const GET_USER_LISTINGS = "GET_USER_LISTINGS";
export const GET_APPLIED_LISTINGS = "GET_APPLIED_LISTINGS";
export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_UPVOTES = "GET_UPVOTES";
export const RESPONSE_APPLICANT_LIST = "RESPONSE_APPLICANT_LIST";
export const GET_FAVORITE_LISTINGS = "GET_FAVORITE_LISTINGS";
export const SAVE_FAVORITE_LISTINGS = "SAVE_FAVORITE_LISTINGS";
export const ADD_TO_CART = "ADD_TO_CART";
export const SHOW_CART = "SHOW_CART";
export const RESET_LISTING_SEARCH = "RESET_LISTING_SEARCH ";
export const UPDATE_VOTE = "UPDATE_VOTE ";
export const RESPONSE_PAYED_PROJECTS = "RESPONSE_PAYED_PROJECTS ";
export const RESPONSE_COMPLETED_DOCUMENTS = "RESPONSE_COMPLETED_DOCUMENTS ";
export const RESPONSE_PENDING_DOCUMENTS = "RESPONSE_PENDING_DOCUMENTS ";
//---------------------END------------------------//

export const GET_CURRENT_LOCATION = "GET_CURRENT_LOCATION";

export const REDEEM_SILVER = "REDEEM_SILVER";

//****    Saving Craft Profile / CONTENT ********
export const SET_USER_ID = "SET_USER_ID";
export const SAVE_CRAFT_PROFILE = "SAVE_CRAFT_PROFILE";
export const UPVOTE = "UPVOTE";
export const GET_HEADER_IMAGE = "GET_HEADER_IMAGE";
export const CLEAR_ALBUM_PHOTOS = "CLEAR_ALBUM_PHOTOS";
export const CLEAR_ALBUM_VIDEOS = "CLEAR_ALBUM_VIDEOS";
export const GET_PHOTO_GALLERY = "GET_PHOTO_GALLERY";
export const GET_VIDEO_ALBUM = "GET_VIDEO_ALBUM";
export const GET_ALBUM_VIDEOS = "GET_ALBUM_VIDEOS";
export const GET_ALBUM_PHOTOS = "GET_ALBUM_PHOTOS";
export const GET_ALBUM_AUDIOS = "GET_ALBUM_AUDIOS";
export const GET_AUDIO_ALBUM = "GET_AUDIO_ALBUM";
export const CLEAR_ALBUM_AUDIOS = "CLEAR_ALBUM_AUDIOS";
export const GET_AUDIO_PLAYLIST = "GET_AUDIO_PLAYLIST";
export const ADD_CERTFICATION = "ADD_CERTFICATION";
export const GET_CERTFICATION = "GET_CERTFICATION";
export const DELETE_CERTIFICATION = " DELETE_CERTIFICATION";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const GET_CRAFT_INFORMATION = "GET_CRAFT_INFORMATION";
export const GET_LISTING_DETAILS = "GET_LISTING_DETAILS";
export const GET_UPDATED_ALBUM_PHOTOS = "GET_UPDATED_ALBUM_PHOTOS";
export const GET_UPDATED_ALBUM_VIDEOS = "GET_UPDATED_ALBUM_VIDEOS";
export const GET_UPDATED_PHOTO_ALBUM = "GET_UPDATED_PHOTO_ALBUM";
export const GET_UPDATED_AUDIO_ALBUM = "GET_UPDATED_AUDIO_ALBUM";
export const GET_UPDATED_VIDEO_ALBUM = "GET_UPDATED_VIDEO_ALBUM";
export const SHOW_CONNECTION = "SHOW_CONNECTION";
export const GET_COMMENT_DETAILS = "GET_COMMENT_DETAILS";

//*******************    END     ******************
//About Seciton

export const GET_ABOUT = "GET_ABOUT";
export const GET_ABOUT_ERROR = "GET_ABOUT_ERROR";

//NOTIFICATION

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const NEW_NOTIFICATION_RECEIVED = "NEW_NOTIFICATION_RECEIVED";

//VIDEO CALLING

export const INCOMING_CALL = "INCOMING_CALL";
export const IN_MEETING = "IN_MEETING";
export const CALLING = "CALLING";
export const CALL_ENDED = "CALL_ENDED";
export const CALL_DECLINED = "CALL_DECLINED";
export const ANSWER_CALL = "ANSWER_CALL";
export const CALL_ANSWERED = "CALL_ANSWERED";
export const REMOTE_AUDIO_TOGGLER = "AUDIO_TOGGLER";
export const REMOTE_VIDEO_TOGGLER = "VIDEO_TOGGLER";
export const USER_BUSY = "USER_BUSY";

//Public Reducer Types

export const GET_ALL_LISTINGS_PUBLIC = "GET_ALL_LISTINGS_PUBLIC";
export const SAVE_CRAFT_PROFILE_PUBLIC = "SAVE_CRAFT_PROFILE";
export const GET_CERTIFICATION_PUBLIC = "GET_CERTIFICATION_PUBLIC";
export const GET_ABOUT_PUBLIC = "GET_ABOUT_PUBLIC";
export const GET_PHOTO_GALLERY_PUBLIC = "GET_PHOTO_GALLERY_PUBLIC";
export const GET_ALBUM_PHOTOS_PUBLIC = "GET_ALBUM_PHOTOS_PUBLIC";
export const GET_VIDEO_ALBUM_PUBLIC = "GET_VIDEO_ALBUM_PUBLIC";
export const GET_ALBUM_VIDEOS_PUBLIC = "GET_ALBUM_VIDEOS_PUBLIC";
export const GET_ALBUM_AUDIOS_PUBLIC = "GET_ALBUM_AUDIOS_PUBLIC";
export const GET_AUDIO_ALBUM_PUBLIC = "GET_AUDIO_ALBUM_PUBLIC";
export const GET_HEADER_IMAGE_PUBLIC = "GET_HEADER_IMAGE_PUBLIC";
export const GET_USER_PROFILE_PUBLIC = "GET_USER_PROFILE_PUBLIC";
export const GET_USER_CONNECTIONS_PUBLIC = "GET_USER_CONNECTIONS_PUBLIC";
export const GET_LISTING_DETAILS_PUBLIC = "GET_LISTING_DETAILS_PUBLIC";

//Stripe  Payment

export const PAYMENT_ON_PROCESS = "PAYMENT_ON_PROCESS";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_RESET = "PAYMENT_RESET";
export const PAYMENT_HISTORY = "PAYMENT_HISTORY";

//Stripe Account

export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const GET_ONBOARDING_LINK = "GET_ONBOARDING_LINK";
export const VALIDATE_ONBOARDING = "VALIDATE_ONBOARDING";
export const GET_ACCOUNT_BALANCE = "GET_ACCOUNT_BALANCE";
export const ONBOARDING_PROCESS_STARTED = "ONBOARDING_PROCESS_STARTED";
export const ONBOARDING_PROCESS_COMPLETED = "ONBOARDING_PROCESS_COMPLETED";

// Loading

export const DOCUSIGN_LOADING = "DOCUSIGN_LOADING";

// Discovery
export const LOAD_ABOUT_AND_LISTINGS = " LOAD_ABOUT_AND_LISTINGS";
export const LOAD_DISCOVERY_CONTENTS = "LOAD_DISCOVERY_CONTENTS";
export const LOAD_COMMENTS = "LOAD_COMMENTS";
export const LOAD_REPLIES = "LOAD_REPLIES";
export const LOAD_LATEST_REPLY = "LOAD_LATEST_REPLY";
export const LOAD_LATEST_COMMENT = "LOAD_LATEST_COMMENT";
export const LOAD_SAVE_FOR_LATER = "LOAD_SAVE_FOR_LATER";
export const LOAD_ADS = "LOAD_ADS";
export const CLEAN_CONTENTS = "CLEAN_CONTENTS";
export const CLEAN_ABOUT_AND_LISTINGS = "CLEAN_ABOUT_AND_LISTINGS";
export const CLEAN_COMMENTS = "CLEAN_COMMENTS";
export const CLEAR_REPLIES = "CLEAR_REPLIES";
export const FAILED_REQUEST = "FAILED_REQUEST";
export const LOAD_DISCOVERY_ABOUT = "LOAD_DISCOVERY_ABOUT";
export const LOAD_TRENDING_LISTINGS = "LOAD_TRENDING_LISTINGS";
export const LOAD_NEW_LISTINGS = "LOAD_NEW_LISTINGS";
export const SET_NAVIGATION = "SET_NAVIGATION";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const LOAD_UPVOTED_CONTENTS_IDS = "LOAD_UPVOTED_CONTENTS_IDS";

// home (Landing Page)

export const LOAD_POPULAR_CLIENT = "LOAD_POPULAR_CLIENT";
export const LOAD_POPULAR_LISTINGS = "LOAD_POPULAR_LISTINGS";
export const LOAD_POPULAR_CONTRACTORS = "LOAD_POPULAR_CONTRACTORS";

// home (langing page ) public
export const LOAD_POPULAR_CLIENT_PUBLIC = "LOAD_POPULAR_CLIENT_PUBLIC";
export const LOAD_POPULAR_LISTINGS_PUBLIC = "LOAD_POPULAR_LISTINGS_PUBLIC";
export const LOAD_POPULAR_CONTRACTORS_PUBLIC =
  "LOAD_POPULAR_CONTRACTORS_PUBLIC";

// adverts
export const LOAD_ADVERTS = "LOAD_ADVERTS";
