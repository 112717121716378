import React from "react";

export default function TaxInformation() {
  return (
    <div className="">
      <h3 style={{ textAlign: "center" }}>
        <span>
          At Socicraft we chose Stripe as our processing provider. Our users can
          manage their tax forms and track earnings. To learn more about the
          1099 tax form, visit the Stripe Express help page.
        </span>{" "}
        <br />
        <span>
          <a
            href="https://support.stripe.com/express/topics/1099-tax-forms"
            target="_blank"
          >
            Stripe : Help & Support
          </a>
        </span>
      </h3>
    </div>
  );
}
