import { NEW_NOTIFICATION_RECEIVED, GET_NOTIFICATION } from "./types";

import API from "../../api/api";
// import setAuthToken from "../../api/setAuthToken";
// import { setAlert } from "./alert";
// import { toast } from "react-toastify";

//*************************** GET UPDATED NOTIFICATION MESSAGE  ***************************//

export const updateUserNotification = () => async (dispatch) => {
  try {
    dispatch({
      type: NEW_NOTIFICATION_RECEIVED,
    });
  } catch (error) {
    console.log(error.message);
  }

  //
};
//*************************** GET NOTIFICATIONS   ***************************//
export const getNotification = () => async (dispatch) => {
  try {
    let response = await API.get("/notifications", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    //dispatch notification reducer here
    if (response.data) {
      dispatch({
        type: GET_NOTIFICATION,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error.message);
    console.log("Unable to load notifications ");
  }
};
//***************************** END  *******************************//
//*************************** Clear Notification  ***************************//

export const clearNotification = () => async (dispatch) => {
  try {
    await API.delete("/notifications");

    //dispatch notification reducer here
    dispatch({
      type: GET_NOTIFICATION,
      payload: [],
    });
  } catch (error) {
    console.log(error.message);
    console.log("Unable to load notifications ");
  }
};
//***************************** END  *******************************//
