import React from "react";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import moment from "moment";

function Contractors({ appliedListing }) {
  const [history, setHistory] = React.useState({});

  React.useEffect(() => {
    appliedListing.history.forEach((history) => {
      setHistory({ ...history, [history.status]: history });
    });
  }, [appliedListing]);
  console.log(history);
  return (
    appliedListing && (
      <List
        sx={{
          width: "100%",
          maxWidth: 400,
          bgcolor: "background.paper",
          color: "black",
        }}
      >
        <ListItem
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
          alignItems="flex-start"
        >
          {/* <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={"userAvatar"} />
        </ListItemAvatar> */}
          <ListItemText
            secondary={
              <React.Fragment>
                <b>Project </b> : <b>{appliedListing.title} </b>
                <br />
                <b>{"Project Posted"} : </b>
                <b>
                  {" "}
                  {moment(appliedListing.createdAt).format("DD MMM YYYY")}{" "}
                </b>
                <br />
                <b>{"Accepted Date"} : </b>
                <b>
                  {" "}
                  {moment(
                    history["ACCEPTED"] && history["ACCEPTED"]["date"]
                  ).format("DD MMM YYYY")}{" "}
                </b>
                <br />
                <b>{"Contract Date"} : </b>
                <b>
                  {" "}
                  {moment(
                    appliedListing.document.recipients.contractor.signedDate
                  ).format("DD MMM YYYY")}{" "}
                </b>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem
          style={{
            borderTop: "1px solid #e9e7e3",
            borderBottom: "1px solid #e9e7e3",
            display: "flex",
            alignItems: "center",
          }}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar
              alt="Remy Sharp"
              src={appliedListing.document.recipients.client.userId.userAvatar}
            />
          </ListItemAvatar>
          <ListItemText
            secondary={
              <React.Fragment>
                {" "}
                <Typography>
                  <b>Client </b> :{" "}
                  <b>
                    {appliedListing.document.recipients.client.userId.firstName}{" "}
                    {appliedListing.document.recipients.client.userId.lastName}{" "}
                  </b>{" "}
                  <br />
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem
          style={{
            borderTop: "1px solid #e9e7e3",
            borderBottom: "1px solid #e9e7e3",
            display: "flex",
            alignItems: "center",
          }}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar
              alt={
                appliedListing.document.recipients.contractor.userId.firstName
              }
              src={
                appliedListing.document.recipients.contractor.userId.userAvatar
              }
            />
          </ListItemAvatar>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography sx={{ display: "inlin" }}>
                  <b>Contractor </b> :{" "}
                  <b>
                    {
                      appliedListing.document.recipients.contractor.userId
                        .firstName
                    }{" "}
                    {
                      appliedListing.document.recipients.contractor.userId
                        .lastName
                    }
                  </b>{" "}
                  <br />
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        {/* <ListItem
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
          alignItems="flex-start"
        >
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>
                  <b>Project Start </b> :{" "}
                  <b>
                    <b> {moment().format("DD MMM YYYY")} </b>
                  </b>{" "}
                  <br />
                  <b>{"Estimated End"} </b> :{" "}
                  <b> {moment().format("DD MMM YYYY")} </b>
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem> */}
      </List>
    )
  );
}

export default Contractors;
