import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CommentInputField from "./commentInputField";
import Comment from "./comment";
import { useDispatch, useSelector } from "react-redux";
import { getCommentListing } from "../../../redux/action/userpost";
import { clearComments } from "../../../redux/action/discovery";


export default function CommentOpenForAllListing ({ postId, commentsCount, commentFrom }) {

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(1);
  const [skip, setSkip] = useState(0);
  const [commentOpened, setCommentOpened] = useState(false);
  const discoveryComments = useSelector(
    (state) => state.discoveryReducer.comments
  ).filter((comment) => comment.listingContent === postId);

useEffect(() => {
    dispatch(clearComments());
    if (!commentOpened) {
    dispatch(getCommentListing(postId, limit, skip));
    }
    setCommentOpened(true);
}, [])


const getMoreComments = () => {
    dispatch(getCommentListing(postId, limit+2, discoveryComments.length));
    setLimit(limit+2);
  };

return (
    <><CardContent sx={{ minWidth: 300 }}>
        <Typography paragraph style={{color: "black"}}>Comment :</Typography>

        <CommentInputField showAvatar={true} postId={postId} commentFrom={commentFrom} />
        {discoveryComments &&
            discoveryComments.map((comment) => (
                <Comment key={comment._id} postComment={comment} postId={postId} commentFrom={commentFrom} />
            ))}
    </CardContent>
    {discoveryComments.length !== 0 && (<div>
            {commentsCount === discoveryComments.length ? (
                <p className="more_comments">all comments seen</p>
            ) : (
                <div className="more_comments">
                    {" "}
                    <Typography
                        paragraph
                        onClick={() => {
                            getMoreComments();
                        } }
                    >
                        {commentsCount > 1 && <p>show more comments</p>}
                    </Typography>
                </div>
            )}
        </div>)}
        </>
  
)
}
