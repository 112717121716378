import {
  PAYMENT_ON_PROCESS,
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  PAYMENT_RESET,
} from "../action/types";

const initialState = {
  paymentProcessing: false,
  paymentSuccess: false,
  paymentFailed: false,
  message: null,
};

const payment = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case PAYMENT_ON_PROCESS:
      return {
        ...state,
        paymentProcessing: true,
        message:
          " Your Checkout Session is Created Please Provide Payment Details on The new Tab.",
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        paymentProcessing: false,
        paymentSuccess: true,
        message: "Your Payment is Successful, Thank You!",
      };
    case PAYMENT_FAILED:
      return {
        ...state,
        showCart: payload,
        loading: true,
      };
    case PAYMENT_RESET:
      return {
        paymentProcessing: false,
        paymentSuccess: false,
        paymentFailed: false,
        message: null,
      };
    default:
      return state;
  }
};

export default payment;
