import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";

import FolderBar from "../../folder/folderBar";
import { ImageBar } from "../../image/ImageBar";
import {
  getGallery,
  deleteGalleryAlbum,
  clearAlbumPhotos,
} from "../../../redux/action/craftProfile";
import CreateAlbum from "../modal/gallery/createAlbum";
import UploadImage from "../modal/gallery/uploadImage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
  heading: {
    margin: "12px 0",
  },
  content: "",
}));

const Gallery = ({
  viewType,
  userId,
  isAuthenticated,
  getGallery,
  clearAlbumPhotos,
  gallery,
}) => {
  const styles = {
    view: {
      visibility: viewType === "view-profile" ? "hidden" : "visible",
    },
  };
  useEffect(() => {
    getGallery(userId, isAuthenticated);
  }, [getGallery, isAuthenticated, userId]);

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const [showCreateAlbum, setShowCreateAlbum] = useState(false);
  const [showUploadPhoto, setShowUploadPhoto] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleCreateAlbum = () => {
    setShowCreateAlbum(!showCreateAlbum);
  };
  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
  };

  const [view, setView] = useState("gallery");
  const [selectedAlbumId, setSelectedAlbumId] = useState("");

  const [showAlbums, setShowAlbums] = useState(true);

  // const toggleView = (type, id) => {
  //   setView(type);
  //   setSelectedAlbumId(id);
  //   let images = gallery.galleryAlbums.filter(
  //     (data) => data.GalleryPhotos && data.id === id
  //   )[0];
  //   setSelectedImages(images);
  // };

  const togglePhotoView = (type, id) => {
    setView(type);
    let albumShowState = showAlbums;
    setShowAlbums(!albumShowState);

    setSelectedAlbumId(id);
    clearAlbumPhotos();
  };

  //console.log(gallery[0]._id);

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>{view === "gallery" ? "Gallery" : "Photos"}</h4>{" "}
                </div>{" "}
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div>
              {gallery.length && showAlbums === true ? (
                <div>
                  {" "}
                  <FolderBar
                    viewType={viewType}
                    type="photoalbum"
                    albumInfo={gallery}
                    togglePhotoView={togglePhotoView}
                  />
                </div>
              ) : (
                <p></p>
              )}
            </div>

            {!showAlbums ? (
              <ImageBar
                viewType={viewType}
                userId={userId}
                selectedAlbumId={selectedAlbumId}
              />
            ) : (
              <p></p>
            )}

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                {view !== "gallery" && (
                  <div className="custom-control custom-switch">
                    <button
                      className="btn  btn-sm btn-info"
                      onClick={() => togglePhotoView("gallery")}
                    >
                      <i className="fa fa-backward" aria-hidden="true"></i>
                      {""}
                      {""} Back
                    </button>
                  </div>
                )}
              </Grid>

              <Grid item style={styles.view}>
                {view === "gallery" ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleCreateAlbum}
                  >
                    <i className="fas fa-plus "> </i>{" "}
                    {view === "gallery" ? "Create Album" : "Upload Photo"}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleUploadPhoto}
                  >
                    <i className="fas fa-plus "> </i>{" "}
                    {view === "gallery" ? "Create Album" : "Upload Photo"}
                  </button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Put the audio player here */}
      </div>
      <CreateAlbum
        showCreateAlbum={showCreateAlbum}
        toggleCreateAlbum={toggleCreateAlbum}
      />{" "}
      <UploadImage
        showUploadPhoto={showUploadPhoto}
        toggleUploadPhoto={toggleUploadPhoto}
        selectedAlbumId={selectedAlbumId}
        type={"photo"}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    gallery: state.gallery.galleryAlbums,
    gallery2: state.gallery2,
    user: state.auth.user,
    galleryAlbums: state.auth.galleryAlbums,
    craftAlbumInformation: state.craftProfile.album,
  };
};
export default connect(mapStateToProps, {
  getGallery,
  deleteGalleryAlbum,
  clearAlbumPhotos,
})(Gallery);

// <div>
//   {imageAndVideo.image &&
//     imageAndVideo.image.map((img) => {
//       let url = window.URL.createObjectURL(img.file);
//       return (
//         <>
//           <img src={url} alt="" />
//           <label style={{ display: "block" }} for="tags">
//             Tags:{" "}
//           </label>
//           <input
//             onChange={onChangeImagesTags}
//             type="text"
//             id="tags"
//             name={img.filename.toString()}
//           />{" "}
//           <label
//             style={{ display: "block" }}
//             for="description"
//           >
//             Description:{" "}
//           </label>
//           <input type="text" id="description" />{" "}
//         </>
//       );
//     })}
//   {/*  URL.createObjectURL(myBlob); */}
// </div>

/* <label class<div className="custom-control-label" for="toggleSwitchGallery">
  Publish to Public Profile
</label>; */
