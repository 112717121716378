import "./callingNotification.css";
import { connect } from "react-redux";
import TwilioVideo from "../twilioVideo/twilioVideo";
import {
  call,
  answerCall,
  declineCall,
} from "../../../redux/action/videoCalling";

const CallingNotification = ({
  call,
  answerCall,
  declineCall,
  calling,
  room,
  videoCalling,
}) => {
  const handleDeclineCall = () => {
    // document.getElementById("notification_sound").play();

    room.stream.disconnect();
    declineCall();
  };

  return (
    <div>
      <div id="video-call-notification-container">
        <div>{room.streaming && <TwilioVideo />}</div>
        <div id="video-call-notification-options pb-3">
          {!videoCalling.inMeeting && (
            <div>
              <section id="calling">
                {" "}
                <span>
                  <i className="fa fa-light fa-phone"></i>
                </span>
                <span>
                  <h3>Calling {room && room.userName}...</h3>{" "}
                </span>{" "}
              </section>
            </div>
          )}
        </div>
        <div className="pt-3">
          <h5 className="text-secondary  video-screen-rotate">
            For better view please rotate your phone{" "}
            <i className="fa fa-mobile fa-rotate-90 ml-2"></i>
          </h5>
        </div>

        <div className="end-call" onClick={handleDeclineCall}>
          <h3>
            END CALL <i className="fa fa-light fa-phone "></i>
          </h3>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  calling: state.videoCalling.calling,
  room: state.videoCalling.room,
  videoCalling: state.videoCalling,
});

export default connect(mapStateToProps, { call, declineCall, answerCall })(
  CallingNotification
);
