import React, { Fragment } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import BreadcrumbWraper from "../content/element/breadcrumb";
import ViewSupportList from "../container/view-support-list";
import { connect } from "react-redux";

const ViewSupportListProp = (props) => {
  const light = props.logo[0].light;
  const userId = props.match.params.userId;
  const getSearchTerms = () => {
    return (
      props.location && props.location.state && props.location.state.searchTerms
    );
  };

  return (
    <Fragment>
      <section className="header-breadcrumb bgimage overlay overlay--dark">
        <div className="bg_image_holder">
          <img src="./assets/img/breadcrumb1-blank-alt.jpg" alt="" />
        </div>
        <div className="mainmenu-wrapper">
          <Header logo={light} class="menu--light" />
        </div>

        <BreadcrumbWraper title="Network List" />
      </section>

      <ViewSupportList searchTerms={getSearchTerms()} userId={userId} />
      <Footer />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
  };
};

export default connect(mapStateToProps)(ViewSupportListProp);
