import React, { useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Cart from "./cart/cart";

import PendingProject from "./pendingProject/pendingProject";
import SignedDocument from "./signedDocuments/signedDocuments";

import "./projectActivity.css";
import { useEffect } from "react";
import CartIcon from "./cart/cartIcon";
import { useSelector, useDispatch } from "react-redux";
import { showItemsInCart } from "../../redux/action/cart";
import { useLocation } from "react-router";

import PayedProject from "./payedProjects/payedProjects";
import { URL_MAPPING_PROJECT_ACTIVITY_SUB1 } from "../../services/consts";
import { useHistory } from "react-router-dom";
import WaitingForSignature from "./waitingForSignature/waitingForSignature";
export default function ProjectActivity() {
  const history = useHistory();
  const [value, setValue] = useState("1");
  const tabStyle = {
    outline: "none",
    width: "200px",
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showItemsInCart());
  }, [dispatch]);

  const numberOfItemsInCart = useSelector((state) => state.cart.showCart);
  const location = useLocation().search;
  useEffect(() => {
    const st = new URLSearchParams(location).get("st");

    if (st) {
      history.push({
        search: location + st,
      });

      switch (st) {
        case "pendingProjects":
          handleChange(null, "1");
          break;
        case "cart":
          handleChange(null, "2");
          break;
        case "paidProjects":
          handleChange(null, "3");
          break;
        case "waitingForSignature":
          handleChange(null, "4");
          break;
        case "signedDocuments":
          handleChange(null, "5");
          break;
        default:
          break;
      }
    }
  }, []);

  const handleChange = (e, newValue) => {
    setValue(newValue);

    history.push({
      search: URL_MAPPING_PROJECT_ACTIVITY_SUB1[newValue],
    });
  };

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            scrollBehavior: "auto",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            className="mb-3"
          >
            <Tab
              style={tabStyle}
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              label="Pending Projects"
              value="1"
            />
            {/* <Tab style={tabStyle} label="Payed Projects" value="2" /> */}
            <Tab
              style={tabStyle}
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              icon={
                <CartIcon
                  numberOfItemsInCart={numberOfItemsInCart.totalItems}
                />
              }
              iconPosition="end"
              label="Cart"
              value="2"
            />
            {/* <Tab
              style={tabStyle}
              label="Prepare Documents"
              value="3"
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
            />
            <Tab
              style={tabStyle}
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              label="Waiting for Signature"
              value="4"
            />
            <Tab
              style={tabStyle}
              className="mr-3 border border-primary p-3 text-black-50 font-weight-bolder"
              label="Competed Documents"
              value="5"
            /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <PendingProject />
        </TabPanel>{" "}
        <TabPanel value="2">
          <Cart />
        </TabPanel>
        {/* <TabPanel value="3">
          <PayedProject />
        </TabPanel>
        <TabPanel value="4">
          <WaitingForSignature />
        </TabPanel>{" "}
        <TabPanel value="5">
          <SignedDocument />
        </TabPanel>{" "} */}
      </TabContext>
    </div>
  );
}
