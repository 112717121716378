import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "./style/mediaElement.css";
export default function MediaElement({
  imageId,
  imageUrl,
  imageTag,
  imageDescription,
  imageTitle,
  imagefunc,
  selectedAlbumId,
  viewType,
}) {
  const imageSizer = {
    marginTop: "3px",
    inlineSize: "100%",
    aspectRatio: "16/9",
    borderRadius: "3%",
    objectFit: "contain",
    marginBottom: "5px",
  };
  const styles = {
    view: {
      visibility: viewType === "view-profile" ? "hidden" : "visible",
      marginLeft: "4px",
    },
  };
  const [showUploadPhoto, setShowUploadPhoto] = useState(false);
  const dispatch = useDispatch();

  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
  };

  return (
    <>
      <div className="media-element" style={{ display: "flex" }}>
        <img
          style={imageSizer}
          id="small-img"
          onClick={() =>
            imagefunc(imageUrl, imageTag, imageDescription, imageTitle)
          }
          src={imageUrl}
          alt="socicraft"
        />

        <div style={styles.view}>
          <i
            className="edit-button fa fa-pencil"
            onClick={() => toggleUploadPhoto()}
          >
            {" "}
          </i>
        </div>
      </div>
      {/* <UpdateContent
        showUploadPhoto={showUploadPhoto}
        toggleUploadPhoto={toggleUploadPhoto}
        contentInfo={{
          url: imageUrl,
          tags: imageTag,
          description: imageDescription,
          title: imageTitle,
          id: imageId,
          selectedAlbumId: selectedAlbumId,
          type: "photo",
        }}
      /> */}
    </>
  );
}
