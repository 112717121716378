import "./App.css";
import React, { Component } from "react";
import Room from "./room";
import { connect } from "react-redux";




class TwilioVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      identity: "",
      room: null,
    };
    this.inputRef = React.createRef();
    this.returnToLobby = this.returnToLobby.bind(this);
    this.updateIdentity = this.updateIdentity.bind(this);
    this.removePlaceholderText = this.removePlaceholderText.bind(this);
  }

  returnToLobby() {
    this.setState({ room: null });
  }
  removePlaceholderText() {
    this.inputRef.current.placeholder = "";
  }
  updateIdentity(event) {
    this.setState({
      identity: event.target.value,
    });
  }
  render() {
    return (
      <div className="twilio-app">
        {this.props.room.stream === null ? (
          <div className="lobby">
            {/* <input
              onChange={this.updateIdentity}
              ref={this.inputRef}
              value={this.state.identity}
              placeholder="What's your name?"
              onClick={this.removePlaceholderText}
            /> */}
            {/* <button disabled={disabled} onClick={this.joinRoom}>
              Join Room
            </button> */}
          </div>
        ) : (
          <Room returnToLobby={this.returnToLobby} room={this.props.stream} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  room: state.videoCalling.room,
  user: state.auth.user,
  stream: state.videoCalling.room.stream,
});
export default connect(mapStateToProps, null)(TwilioVideo);
