import {
  GET_CONVERSATION,
  GET_MESSAGE,
  CHECK_MESSAGE,
  GET_USERS_LIST,
  USER_TYPING,
  USER_STOP_TYPING,
  USER_SEARCH_COMPLETED,
  USER_SEARCH_INITIATED,
  TOGGLE_CONVERSATION_LIST,
} from "../action/types";

const initialState = {
  conversation: [],
  usersList: [],
  messageStatus: {
    currentConversation: null,
    messages: [],
    title: null, //UserName !== Current User
    loaded: true,
    participants: null,
    onlineStatus: false,
  },
  title: "",
  conversationId: "",
  loading: true,
  active: "",
  typingUsers: [],
  userSearchResult: [],
  searching: false,
  settings: {
    toggleConversationList: false,
    containerClass: "chat-container",
  },
};

const messaging = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_CONVERSATION:
      return {
        ...state,
        conversation: payload,
        loading: false,
      };
    case GET_MESSAGE:
      return {
        ...state,
        messageStatus: {
          messages: payload.messages,
          currentConversation: payload.threadId,
          title: payload.title, //UserName !== Current User
          loaded: true,
          loading: true,
          participants: payload.participants,
          onlineStatus: payload.onlineStatus,
        },
      };
    case CHECK_MESSAGE:
      return {
        ...state,
        // message: payload.sort((a, b) =>
        //   moment(b.createdAt).diff(moment(a.createdAt))
        // ),
        // loading: true,
      };
    case GET_USERS_LIST:
      return {
        ...state,
        usersList: payload,
        loading: true,
      };

    case USER_TYPING:
      return {
        ...state,
        typingUsers: !state.typingUsers.find((data) => data === payload)
          ? state.typingUsers.concat(payload)
          : state.typingUsers,

        loading: true,
      };
    case USER_STOP_TYPING:
      return {
        ...state,
        typingUsers: state.typingUsers.filter((data) => data !== payload),
        loading: true,
      };
    case USER_SEARCH_INITIATED:
      return {
        ...state,
        searching: true,
        loading: true,
      };
    case USER_SEARCH_COMPLETED:
      return {
        ...state,
        searching: false,
        userSearchResult: payload,
        loading: true,
      };
    case TOGGLE_CONVERSATION_LIST:
      return {
        ...state,
        settings: {
          containerClass: state.settings.toggleConversationList
            ? "chat-container"
            : "chat-container-conversation-list",
          toggleConversationList: !state.settings.toggleConversationList,
        },
      };
    default:
      return state;
  }
};

export default messaging;
