import React from "react";
import { useDispatch } from "react-redux";
import { deleteItemsInCart } from "../../../redux/action/cart";

export default function PaymentWaiting({
  ProjectImg,
  ProjectTitle,
  ProjectDate,
  ProjectPrice,
  applicantFirstName,
  applicantLastName,
  cartItemId,
}) {
  const dispatch = useDispatch();

  const deleteItemFromCart = (cartItemId) => {
    dispatch(deleteItemsInCart(cartItemId));
  };

  return (
    <>
      <div className="courses-container mb-5">
        <div className="course m-0">
          <div className="course-preview"></div>
          <div className="course-info">
            <div>
              {" "}
              <h6 className="card-data">Project Title : </h6>{" "}
              <h5>{ProjectTitle}</h5>{" "}
            </div>
            <div>
              {" "}
              <h6 className="card-data">Applicant Name : </h6>{" "}
              <h5>
                {applicantFirstName} {applicantLastName}
              </h5>{" "}
            </div>
            <div>
              {" "}
              <h6 className="card-data">Price : </h6> <h5>$ {ProjectPrice}</h5>
            </div>{" "}
            <button
              className="card-btn"
              onClick={() => deleteItemFromCart(cartItemId)}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
