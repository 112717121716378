import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  getCertifications,
  deleteCertification,
} from "../../redux/action/craftProfile";

import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
  heading: {
    margin: "12px 0",
  },

  content: "",
}));

const Certifications = (props) => {
  const { userCertifications } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>Certifications and Licenses</h4>
                </div>
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div className="atbdb_content_module_contents">
              <div
                className="certification-container"
                style={{ overflow: "auto", overflowY: "hidden" }}
              >
                {userCertifications.length ? (
                  userCertifications.map((cert) => {
                    return (
                      <div className="certification-item card">
                        <ul>
                          <b>Certification Name:</b> {cert.name}
                        </ul>
                        <ul>
                          <b>Organization:</b> {cert.office}
                        </ul>
                        <ul>
                          <b>Credential ID:</b> {cert.credentialId}
                        </ul>
                        <ul>
                          <b>Issue Date:</b> {cert.issueDate}
                        </ul>
                        <ul>
                          <b>Expiration Date:</b> {cert.expireDate}
                        </ul>
                        <ul>
                          <b>Does Expire:</b>
                          {cert.isExpire ? " Yes" : " No"}
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <p style={{ textAlign: "center" }}>
                    Show Case Your Accomplishments....
                  </p>
                )}
              </div>
              <br />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    gallery: state.gallery,
    gallery2: state.gallery2,
    user: state.auth.user,
    certifications: state.craftProfile,
  };
};
export default connect(mapStateToProps, {
  getCertifications,
  deleteCertification,
})(Certifications);
