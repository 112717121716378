import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  updateCraftInformation,
  getAbout,
} from "../../redux/action/craftProfile";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import "react-widgets/dist/css/react-widgets.css";

import Typography from "@material-ui/core/Typography";

const ListingCraftInfo = (props) => {
  const { publicCraftProfile } = useSelector((state) => state.publicReducer);

  useEffect(() => {
    // props.getAbout();
  }, []);

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_features">
        <div className="atbd_content_module__tittle_area">
          <div className="atbd_content_module__tittle_area">
            <h3>About</h3>
          </div>
          <div className="atbdb_content_module_contents">
            {publicCraftProfile.about && (
              <>
                {publicCraftProfile.about.about ? (
                  <p>{publicCraftProfile.about.about}</p>
                ) : (
                  <p>N/A</p>
                )}
                <ul className="atbd_custom_fields features-table">
                  <li>
                    <div className="atbd_custom_field_title">
                      <p>Interest Level: </p>
                    </div>
                    <div className="atbd_custom_field_content">
                      <div>
                        {publicCraftProfile.about.interestLevel ? (
                          <p>{publicCraftProfile.about.interestLevel}</p>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="atbd_custom_field_title">
                      <p>Years of Experience:</p>
                    </div>
                    <div className="atbd_custom_field_content">
                      <div>
                        {publicCraftProfile.about.yearsOfExperience ? (
                          <Typography>
                            {publicCraftProfile.about.yearsOfExperience}
                          </Typography>
                        ) : (
                          <Typography>N/A</Typography>
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="atbd_custom_field_title">
                      <p>Reason for Joining Socicraft:</p>
                    </div>
                    <div className="atbd_custom_field_content">
                      <div>
                        {publicCraftProfile.about.reasonForJoining.length ? (
                          <>
                            {publicCraftProfile.about.reasonForJoining.map(
                              (reason) => (
                                <span className="badge badge-pill badge-primary mr-2 mt-1">
                                  {reason}
                                </span>
                              )
                            )}
                          </>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="atbd_custom_field_title">
                      <p style={{ marginTop: 0 }}>Interested Industries:</p>
                    </div>
                    <div className="atbd_custom_field_content">
                      {publicCraftProfile.about.interestedIndustries.length ? (
                        <>
                          {publicCraftProfile.about.interestedIndustries.map(
                            (industries) => (
                              <span className="badge badge-pill badge-primary mr-2 mt-1">
                                {industries}
                              </span>
                            )
                          )}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  </li>
                </ul>
              </>
            )}
          </div>

          <br />
          {/* <div styles={"float:right;"}>
              <button className="btn btn-xs btn-gradient btn-gradient-two">
                Save Craft Information
              </button>
            </div> */}
        </div>
      </div>
      <br />
    </Fragment>
  );
};

ListingCraftInfo.propTypes = {
  updateCraftInformation: PropTypes.func.isRequired,
  getAbout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  craftInformation: state.craftProfile.about,
});

export default connect(mapStateToProps, { updateCraftInformation, getAbout })(
  ListingCraftInfo
);
