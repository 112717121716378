import { combineReducers } from "redux";
import catReducer from "./catReducer";
import advertReducer from "./advertReducer";
import listReducer from "./listingReducer";
import placeReducer from "./placeReducer";
import testiReducer from "./testiReducer";
import clientReducer from "./clientReducer";
import galleryReducer from "./galleryReducer";
import gallery2Reducer from "./gallery2Reducer";
import blogReducer from "./blogReducer";
import loginReducer from "./loginReducer";
import logoReducer from "./logoReducer";
import auth from "./auth";
import userListing from "./userListing";
import processcontrol from "./processcontrol";
import messaging from "./messaging";
import awards from "./awards";
import listings from "./listingSearch";
import craftProfile from "./craftProfileReducer";
import discoveryReducer from "./discoveryReducer";
import homeReducer from "./homeReducer";
import publicReducer from "./public";
import audioPlaylistReducer from "./audioPlaylistReducer";
import notifications from "./notification";
import videoCalling from "./videoCalling";
import socket from "./socket";
import cart from "./cart";
import connections from "./connections";
import payment from "./payment";
import stripe from "./stripe";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const rootPersistConfig = {
  key: "listings",
  storage: storage,
  whitelist: ["listings"],
};
const rootReducer = combineReducers({
  category: catReducer,
  list: listReducer,
  place: placeReducer,
  testimonial: testiReducer,
  client: clientReducer,
  gallery: galleryReducer,
  gallery2: gallery2Reducer,
  blog: blogReducer,
  login: loginReducer,
  logo: logoReducer,
  process: processcontrol,
  listings: listings,
  userListing,
  auth,
  messaging,
  awards,
  craftProfile,
  audioPlaylistReducer,
  socket,
  notifications: notifications,
  videoCalling,
  publicReducer,
  cart,
  connections,
  payment,
  stripe,
  discoveryReducer,
  homeReducer,
  advertReducer,
});
export default persistReducer(rootPersistConfig, rootReducer);
