import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import "../postFeedstyles.css";

export default function PostingSpinner({ message, isLoading }) {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <div className="posting_spinner">
          <CircularProgress color="inherit" style={{ marginLeft: "19px" }} />

          <Typography variant="h5" gutterBottom color="white">
            {message}
          </Typography>
        </div>
      </Backdrop>
    </div>
  );
}
