import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Chip, ListItemAvatar } from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";
import { Avatar } from "@mui/material";

export default function CompleteProjectInfo() {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 360,
        position: "relative",
        overflow: "auto",
        maxHeight: 300,
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <List style={{ marginBottom: "50px" }}>
        {[
          "Did you check the clients expectations and requirements?",
          "Using the Socicraft Chat feature, did you communicate in a timely and effective manner?",
          "Was feedback given from the client, did you try to resolve any raised issues?",
          "Was feedback given from the client, did you try to resolve any raised issues?",
          "Did you deliver services or handover deliverables?",
        ].map((item) => (
          <ListItem>
            {" "}
            <ListItemAvatar>
              <Avatar>
                <DoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item} />
          </ListItem>
        ))}{" "}
      </List>
    </div>
  );
}
