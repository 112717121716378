import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { ContentStory } from "../../element/listing-details-story";
import { ContactInfo } from "../../element/contact-info";
import Gallery from "./listingGallery";
import SellerInfo from "../../element/widget";
import Apply from "./apply";
import Report from "../report";
import Clime from "../clime-list";
import Modal from "react-bootstrap/Modal";
import { getListingDetails } from "../../../../redux/action/userpost";
import Spinner from "./../spinnermodal";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

const InternalListingDetails = (props) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [showApply, setShowApply] = useState(false);

  const toggleApply = () => {
    if (
      isAuthenticated &&
      user &&
      user.connectedAccounts.stripe.onboardingCompleted
    ) {
      setShowApply(!showApply);
    }
  };
  const webAddress =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
      ? "https://workshop.socicraft.us/"
      : "https://workshop.socicraft.us/";

  return (
    <>
      {" "}
      <Modal
        show={props.showInternalDetails}
        animation={true}
        onHide={() => props.toggleInternalDetails()}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        {props.isLoading ? (
          <Spinner />
        ) : (
          props.listingDetails && (
            <>
              {" "}
              <Modal.Header
                style={{ display: "flex", flexDirection: "column" }}
                closeButton
              >
                <Modal.Title>
                  {" "}
                  <h2>{props.listingDetails.title}</h2> <br />
                </Modal.Title>

                <Modal.Title>
                  {props.listingDetails.userId.firstName}{" "}
                  {props.listingDetails.userId.lastName}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Fragment>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        {props.listingDetails && (
                          <ContentStory
                            listing={props.listingDetails}
                            location={props.listingDetails.projectLocation}
                            projectTitle={props.listingDetails.projectLabel}
                            firstName={props.listingDetails.userId.firstName}
                            lastName={props.listingDetails.userId.lastName}
                          />
                        )}

                        <div className="col-lg-12">
                          {props.listingDetails.images &&
                            props.listingDetails.images.length && (
                              <Gallery listing={props.listingDetails} />
                            )}
                        </div>
                      </div>

                      {isAuthenticated ? (
                        <div className="col-lg-4 ">
                          <div className="widget atbd_widget widget-card">
                            <div className="atbd_widget_title">
                              <h4>
                                <span className="la la-user"></span>
                                Company Info
                              </h4>
                            </div>

                            <div
                              className="company-info"
                              style={{
                                width: "70%",
                                margin: "auto",
                              }}
                            >
                              <div>
                                <br />
                                {props.internalListings[0].userId &&
                                  props.internalListings[0].userId.address &&
                                  props.internalListings[0].userId.address
                                    .state && (
                                    <span>
                                      {" "}
                                      <b style={{ color: "#008080" }}>
                                        State :
                                      </b>{" "}
                                      {
                                        props.internalListings[0].userId.address
                                          .state
                                      }
                                    </span>
                                  )}
                                <br />
                                {props.internalListings[0].userId &&
                                  props.internalListings[0].userId.address &&
                                  props.internalListings[0].userId.address
                                    .country && (
                                    <span>
                                      {" "}
                                      <b style={{ color: "#008080" }}>
                                        Country :
                                      </b>{" "}
                                      {
                                        props.internalListings[0].userId.address
                                          .country
                                      }
                                    </span>
                                  )}

                                {props.internalListings[0].userId &&
                                  props.internalListings[0].userId.address &&
                                  props.internalListings[0].userId.address
                                    .phoneNumber && (
                                    <span>
                                      {" "}
                                      <b style={{ color: "#008080" }}>
                                        PhoneNumber :{" "}
                                      </b>{" "}
                                      {
                                        props.internalListings[0].userId.address
                                          .phoneNumber
                                      }
                                    </span>
                                  )}
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "10px",
                                }}
                                className="atbd_director_social_wrap"
                              >
                                {props.internalListings[0].userId.socialNetwork
                                  .facebook && (
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                      "https://facebook.com/" +
                                      props.internalListings[0].userId
                                        .socialNetwork.facebook
                                    }
                                  >
                                    <span className="fab fa-facebook-f"></span>
                                  </a>
                                )}
                                {props.internalListings[0].userId.socialNetwork
                                  .twitter && (
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                      "https://twitter.com/" +
                                      props.internalListings[0].userId
                                        .socialNetwork.twitter
                                    }
                                  >
                                    <span className="fab fa-twitter"></span>
                                  </a>
                                )}
                                {props.internalListings[0].userId.socialNetwork
                                  .youtube && (
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                      "https://youtube.com/" +
                                      props.internalListings[0].userId
                                        .socialNetwork.youtube
                                    }
                                  >
                                    <span className="fab fa-youtube"></span>
                                  </a>
                                )}
                                {props.internalListings[0].userId.socialNetwork
                                  .instagram && (
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                      "https://instagram.com/" +
                                      props.internalListings[0].userId
                                        .socialNetwork.instagram
                                    }
                                  >
                                    <span className="fab fa-instagram"></span>
                                  </a>
                                )}
                                {props.internalListings[0].userId.socialNetwork
                                  .pinterest && (
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={
                                      "https://pinterest.com/" +
                                      props.internalListings[0].userId
                                        .socialNetwork.pinterest
                                    }
                                  >
                                    <span className="fab fa-pinterest"></span>
                                  </a>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                height:
                                  isAuthenticated &&
                                  user &&
                                  !user.connectedAccounts.stripe
                                    .onboardingCompleted
                                    ? "350px"
                                    : "200",
                              }}
                            >
                              <SellerInfo
                                listing={props.listingDetails}
                                toggleApply={toggleApply}
                                isAuthenticated={props.isAuthenticated}
                                userName={
                                  props.internalListings[0].userId.userName
                                }
                                user={user}
                                websiteAddress={webAddress}
                              />
                            </div>
                          </div>
                          {/* end seller info */}

                          {(props.listingDetails.state ||
                            props.listingDetails.country ||
                            props.listingDetails.facebook ||
                            props.listingDetails.instagram ||
                            props.listingDetails.tumblr ||
                            props.listingDetails.linkedin ||
                            props.listingDetails.youtube ||
                            props.listingDetails.facebook) && (
                            <ContactInfo listing={props.listingDetails} />
                          )}
                        </div>
                      ) : (
                        <Button>Details</Button>
                      )}
                    </div>
                  </div>

                  <Report />
                  <Clime />
                </Fragment>
              </Modal.Body>
              <Modal.Footer>
                {/* //<Button onClick={() => submitAgreement()} variant="secondary">
            Agree
          </Button>
         // <Button onClick={() => toggleModal(false)} variant="danger">
            Close
          </Button> */}
              </Modal.Footer>{" "}
            </>
          )
        )}
      </Modal>
      <Apply
        id={props.listing.id}
        showApply={showApply}
        toggleApply={toggleApply}
        toggleInternalDetails={props.toggleInternalDetails}
      />{" "}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listingDetails: state.userListing.listingDetails,
    isAuthenticated: state.auth.isAuthenticated,
    internalListings: state.listings.internalListings,
    isLoading: state.userListing.isLoading,
  };
};

export default connect(mapStateToProps, { getListingDetails })(
  InternalListingDetails
);
