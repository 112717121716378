import React from "react";
import { useSelector } from "react-redux";

export default function CartIcon() {
  let cartItems = useSelector((state) => state.cart.showCart);

  return (
    <div>
      <i style={{ marginRight: "5px" }} className="la la-2x la-shopping-cart">
        {" "}
        <span
          style={{
            position: "absolute",
            fontSize: "xx-small",
            marginLeft: "-5px",
            marginTop: "-10px",
            backgroundColor: "var(--orange)",
            color: "white",
          }}
          className="badge badge-light badge-sm"
        >
          {cartItems && cartItems.totalItems ? cartItems.totalItems : 0}
        </span>
      </i>
    </div>
  );
}
