import React from "react";
import MediaElement from "./mediaElement";
import "./style/mediaScroller.css";
import { useState } from "react";
import { useSelector } from "react-redux";

export const ImageBar = ({ viewType, selectedAlbumId, type }) => {
  const imageSizer = {
    marginTop: "5px",
    inlineSize: "100%",
    aspectRatio: "16/9",
    borderRadius: "3%",
    objectFit: "contain",
    marginBottom: "5px",
  };

  const { photos } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  const handleImageChange = (image, tag, title, description) => {
    setShowFirstImage(false);
    setcurrentImage(image);
    setcurrentTag(tag);
    setCurrentTitle(title);
    setcurrentDescription(description);
    setIsTruncated(true);
  };

  const [currentImage, setcurrentImage] = useState("");
  const [currentTag, setcurrentTag] = useState("");
  const [currentDescription, setcurrentDescription] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [isTruncated, setIsTruncated] = useState(true);
  const [showFirstImage, setShowFirstImage] = useState(true);

  const resultDescription =
    isTruncated && currentTitle ? currentTitle.slice(0, 150) : currentTitle;

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      {photos.length ? (
        <div>
          <div>
            <div className="big-image">
              <img
                style={imageSizer}
                src={showFirstImage ? photos[0].url : currentImage}
                alt="soci-craft user images"
              />
            </div>

            <div style={{ minHeight: "30px" }} className="info">
              <b>
                <h2
                  style={{
                    fontWeight: "bolder",
                    marginBottom: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  {showFirstImage ? photos[0].title : currentDescription}
                </h2>
              </b>

              <p>
                {" "}
                {showFirstImage ? photos[0].description : resultDescription}
                <span>
                  {resultDescription &&
                  resultDescription.length > 100 &&
                  isTruncated === true ? (
                    <span>...</span>
                  ) : (
                    <></>
                  )}{" "}
                </span>
              </p>

              {resultDescription &&
              resultDescription.length > 100 &&
              isTruncated ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => toggleIsTruncated()}
                >
                  {isTruncated ? <b>...see more</b> : "see less"}
                </button>
              ) : (
                <span></span>
              )}

              <p>
                {" "}
                {showFirstImage
                  ? photos[0].tags.map((tag) => (
                      <span style={{ color: "#005fb9" }}>#{tag} </span>
                    ))
                  : currentTag.map((tag) => (
                      <span style={{ color: "#005fb9" }}>#{tag} </span>
                    ))}
              </p>
            </div>
          </div>

          <div className="media-scroller">
            {photos.map((image, index) => (
              <MediaElement
                key={index}
                imagefunc={handleImageChange}
                imageId={image._id}
                imageUrl={image.url}
                imageTag={image.tags}
                imageDescription={image.description}
                imageTitle={image.title}
                selectedAlbumId={selectedAlbumId}
                viewType={viewType}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Empty Album
        </div>
      )}
    </div>
  );
};
