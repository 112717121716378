import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import "./card-style.css";
import moment from "moment";

export default function ExternalListingDetailsCard({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <button
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        className="btn btn-xs btn-gradient btn-gradient-two"
      >
        Details
      </button>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        {data && (
          <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
            <span className="listing-details-fields">Position Title : </span>
            {data.MatchedObjectDescriptor.PositionTitle
              ? data.MatchedObjectDescriptor.PositionTitle
              : "unavailable"}
            {/* 
            {<span>Organization Name : </span> &&
              data.MatchedObjectDescriptor.OrganizationName} */}
            <br />
            <span className="listing-details-fields">Organization Name : </span>
            {data.MatchedObjectDescriptor.OrganizationName
              ? data.MatchedObjectDescriptor.OrganizationName
              : "unavailable"}
            <br />
            <span className="listing-details-fields">
              Position Start Date :{" "}
            </span>
            {data.MatchedObjectDescriptor.PositionStartDate
              ? moment(data.MatchedObjectDescriptor.PositionStartDate).format(
                  "ll"
                )
              : "unavailable"}
            <br />
            <span className="listing-details-fields">Position End Date : </span>
            {data.MatchedObjectDescriptor.PositionEndDate
              ? moment(data.MatchedObjectDescriptor.PositionEndDate).format(
                  "ll"
                )
              : "unavailable"}
          </Box>
        )}
      </Popper>
    </div>
  );
}
