import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
export class PlaceList extends Component {

    render() {
        return (
            <Fragment>
                <div className="col-lg-12">
                    <div className="place-list-wrapper">
                        <ul className="list-unstyled">
                            <li><NavLink onClick={noAction} to="/at_demo" >Dubai (45)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Melbourne (95)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Sydney (90)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Brisbane (73)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Perth (97)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Toronto (960)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Vancouver (46)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Montreal (46)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Calgary (16)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Edmonton (6)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Ottawa (53)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Atlantic Canada (83)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Berlin (71)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Munich (46)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Hamburg Area (727)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Frankfurt Area (655)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Stuttgart Area (9)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Barcelona (46)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Madrid (790)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Spain (52)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Dublin (657)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Galway (12)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Limerick (6)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Tokyo, JP (24)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Kanagawa (276)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Osaka (146)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Kyoto (70)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Nagoya (64)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Mexico City (195)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Cancun (328)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Monterrey (27)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Baja California Sur (42)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Amsterdam (446)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Maastricht (3)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >London (25)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Yorkshire (967)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Edinburgh (923)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Kent (80)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Manchester (60)</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo" >Glasgow (52)</NavLink></li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export class LocationList extends Component {

    render() {
        return (
            <Fragment>
               <div className="atbd_location_grid_wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">London (1)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">UK (2)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Portsmouth (1)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Los Angels (0)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">CA (0)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">USA (1)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Sydney (17)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Durban (11)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Perth (13)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Newyork (21)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Bangkok (17)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Australia (15)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Calgary (45)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Mosco (35)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Paris (23)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Canada (47)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Washington (78)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Neverland (46)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Scotland (53)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <NavLink to="/at_demo" onClick={noAction} className="atbd_location_grid">Newzealand (39)</NavLink>
                            </div>{/*<!-- ends: .col-lg-3 -->*/}
                        </div>
                    </div>{/*<!-- ends: .atbd_location_grid_wrap -->*/}
                </div>
                   
            </Fragment>
        )
    }
}
