import React from "react";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import ReplyInputField from "./replyInputField";
import { getReply } from "../../../redux/action/discovery";
import { useDispatch, useSelector } from "react-redux";
import Reply from "./reply";
import "../postFeedstyles.css";
import { getReplyOnAllListing } from "../../../redux/action/userpost";

export default function ReplyDisplay({ commentId, repliesCount, commentFrom, postId }) {
  const [showReplyField, setShowReply] = useState(false);
  const [replyOpened, setReplyOpened] = useState(false);
  const [addReplyCount, setAddReplyCount] = useState(0);
  const repliesLimit = 3;
  const replies = useSelector((state) => state.discoveryReducer.replies)
    .filter((reply) => reply.commentId === commentId)
    .reverse();

  const dispatch = useDispatch();
  const showReplyHandler = () => {
    setShowReply(true);

    if (!replyOpened) {
      if (commentFrom == 'allListing') {
        dispatch(getReplyOnAllListing(commentId, repliesLimit, replies.length));
      } else {
        dispatch(getReply(commentId, repliesLimit, replies.length));
      }
    }
    setReplyOpened(true);
  };
  const showMoreReplies = () => {
    if (commentFrom == 'allListing') {
      dispatch(getReplyOnAllListing(commentId, repliesLimit, replies.length));
    } else {
      dispatch(getReply(commentId, repliesLimit, replies.length));
    }
  };

 const handleReplesAdded = (value) => {
  setTimeout(() => {
    setAddReplyCount(value);
  },2000);
  }

  return (
    <div>
      <div className="reply">
        <Typography onClick={showReplyHandler}>Reply {repliesCount + addReplyCount}</Typography>
      </div>

      <div className="reply_section">
        {showReplyField && (
          <ReplyInputField showAvatar={true} commentId={commentId} postId={postId} commentFrom={commentFrom} incrementReplyCount={handleReplesAdded}/>
        )}

        <div className="reverse-reply" id="reverse_reply_body">
          {showReplyField &&
            replies &&
            replies.map((reply) => (
              <Reply key={reply._id} replyInfos={reply} color={900} />
            ))}
        </div>
        {showReplyField && (
          <p
            style={{ marginTop: "3px" }}
            onClick={showMoreReplies}
            className="more_replies"
          >
            {repliesCount > 0 && (
              <div>
                {replies.length === repliesCount ? (
                  <p>all replies seen</p>
                ) : (
                  <p>see more replies</p>
                )}
              </div>
            )}
          </p>
        )}
      </div>
    </div>
  );
}
