import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useEffect } from "react";
import {
  connection,
  deleteConnection,
} from "../../../redux/action/connection.js";
import { Link } from "react-router-dom";
import moment from "moment";
import defaultProjectImg from "../../project-wide-images/default_project.jpg";
import Spinner from "../modal/spinnermodal.jsx";
import "./card-style.css";

const IconDiv = styled.div`
  margin: 1rem;
`;

const StyledImg = styled.img`
  border-radius: 50%;
  width: 225px;
  height: 170px;
`;

export const ListingCardGrid12NetworkList = ({ userId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(userId)
    dispatch(connection("", "", "", userId));
  }, [dispatch, userId]);
  const connections = useSelector(
    (state) => state.connections.showConnection.connections
  );
  const loading = useSelector((state) => state.connections.loading);
  const account = useSelector((state) => state.connections);

  const deleteUserConnection = (connectedUserId) => {
    dispatch(deleteConnection(connectedUserId));
    dispatch(connection("", "", "", userId));
  };
  const webAddress =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
      ? "https://workshop.socicraft.us"
      : "https://workshop.socicraft.us";
  return (
    <div>
      {connections && connections.length !== 0 ? (
        <Fragment>
          {connections &&
            connections.map((user) => (
              <div>
                {user.userId && (
                  <div className="col-lg-12" key="">
                    <div className="atbd_single_listing atbd_listing_list">
                      <article className="atbd_single_listing_wrapper">
                        <figure className="atbd_listing_thumbnail_area">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="atbd_listing_image"
                          >
                            <StyledImg
                              src={
                                user.userId.userAvatar
                                  ? user.userId.userAvatar
                                  : defaultProjectImg
                              }
                              alt="listingimage"
                            />
                            <Button>
                              {" "}
                              <a
                                href={`${webAddress}/${user.userId.userName}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                View Profile
                              </a>
                            </Button>
                          </div>
                        </figure>
                        <div className="atbd_listing_info">
                          <div className="atbd_content_upper">
                            <h4 className="atbd_listing_title">
                              <p>
                                {user.userId.firstName} {user.userId.lastName}
                              </p>
                            </h4>
                            <div className="atbd_listing_meta">
                              {/* <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> */}
                              {/* <span className="atbd_meta atbd_listing_price">{"$ "+price}</span> */}
                              {/* <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span> */}
                            </div>

                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="flex-start"
                            >
                              <ul>
                                <div>
                                  <p>
                                    <span className="la la-map-marker"></span>
                                    {user.userId.address.country
                                      ? user.userId.address.country
                                      : "country unavailable"}{" "}
                                    {user.userId.address.state
                                      ? user.userId.address.state
                                      : "state unavailable"}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="la la-phone"></span>
                                    {user.userId.address.phoneNumber
                                      ? user.userId.address.phoneNumber
                                      : "phone number unavailable"}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="la la-calendar-check-o"></span>

                                    {moment(
                                      user.time ? user.time : user.time
                                    ).fromNow("dd") + " ago"}
                                  </p>
                                </div>
                              </ul>
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="flex-end"
                            >
                              {user.userId.industries ? (
                                user.userId.industries.map((industry) => (
                                  <Grid item xs={6}>
                                    {industry}
                                  </Grid>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                          </div>
                        </div>
                        {account && account.selfAccount && (
                          <IconDiv>
                            <Link to="/chat">
                              <div>
                                <EmailOutlinedIcon
                                  fontSize="large"
                                  style={{ fontSize: "60", marginLeft: "3rem" }}
                                />
                                <p style={{ marginLeft: "2rem" }}>
                                  Send Message
                                </p>
                              </div>
                            </Link>

                            <div
                              onClick={() =>
                                deleteUserConnection(user.userId.id)
                              }
                              style={{ marginLeft: "1rem" }}
                            >
                              <CancelOutlinedIcon
                                fontSize="large"
                                style={{
                                  color: "red",
                                  fontSize: "60",
                                  marginLeft: "2rem",
                                }}
                              />
                              <p>Remove Connection</p>
                            </div>
                          </IconDiv>
                        )}

                        {/* <div className="atbd_listing_bottom_content">
         <div className="atbd_content_left">
             <div className="atbd_listing_category">
                 <a href=" "><span className={"la "+icon}></span>{category}</a>
             </div>
         </div> 
        <ul className="atbd_content_right">
           <li className="atbd_count"><span className="la la-eye"></span>900+</li>
           <li className="atbd_save">
               <span className="la la-heart-o"></span>
           </li>
           </ul>
         </div> */}
                        {/* <div className="atbd_listing_bottom_content">
               <div className="atbd_content_left">
                   <div className="atbd_listing_category">
                       <a href=" "><span className={"la "+icon}></span>{category}</a>
                   </div>
               </div>
               <ul className="atbd_content_right">
                   <li className="atbd_count"><span className="la la-eye"></span>900+</li>
                   <li className="atbd_save">
                       <span className="la la-heart-o"></span>
                   </li>
               </ul>
            </div> */}
                      </article>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </Fragment>
      ) : (
        <div>
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <div class="alert alert-primary" role="alert">
              {" "}
              No Connection Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};
