import React, { useState } from "react";
import "./loadingPercentage.css";

const LoadingPercentage = ({ done }) => {
  //   const [style, setStyle] = useState({});
  return (
    <>
      {" "}
      <div className="progress">
        <div
          className="progress-done"
          style={{ opacity: 1, width: `${done}%` }}
        >
          {done}% Processing
          <br />
        </div>
      </div>
    </>
  );
};
export default LoadingPercentage;
