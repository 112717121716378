import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListingCardGrid12NetworkList } from "../content/card/card-listing-network-list";
import { categories } from "../../services/consts.js";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import { connection } from "../../redux/action/connection";
import IndustrySubcategories from "../industrySubcategories/industry-subcategories";

export default function ViewSupportList({ userId }) {
  const dispatch = useDispatch();
  const connections = useSelector(
    (state) => state.connections.showConnection.connections
  );

  const industries = [];

  const [checkBoxState, setCheckBoxState] = React.useState(categories);

  const [inputState, setInputState] = useState({
    userName: "",
    address: "",
  });

  const {
    Art,
    Culinary,
    Cosmetics,
    Fashion,
    FilmandVideo,
    Dance,
    Literature,
    Management,
    MediacommunicationsandTechnology,
    Music,
  } = checkBoxState;

  if (Art) {
    industries.push("Art");
  }

  if (Culinary) {
    industries.push("Culinary");
  }

  if (Cosmetics) {
    industries.push("Cosmetics");
  }

  if (Fashion) {
    industries.push("Fashion");
  }

  if (FilmandVideo) {
    industries.push("Film & Video");
  }

  if (Dance) {
    industries.push("Dance");
  }

  if (Literature) {
    industries.push("Literature");
  }

  if (Management) {
    industries.push("Management");
  }

  if (MediacommunicationsandTechnology) {
    industries.push("Media communications and Technology");
  }

  if (Music) {
    industries.push("Music");
  }

  const handleCheckBoxChange = (event) => {
    setCheckBoxState({
      ...checkBoxState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const newInput = { [e.target.name]: value };
    setInputState((inputState) => ({
      ...inputState,
      ...newInput,
    }));
  };

  const filter = () => {
    dispatch(connection(inputState.userName, inputState.address, industries));
  };

  const search = (e) => {
    e.preventDefault();
    filter();
  };

  return (
    <Fragment>
      <section className="all-listing-wrapper section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="atbd_generic_header">
                <div className="atbd_generic_header_title">
                  <h4>All Connections</h4>
                  <p>Total Connections: {connections && connections.length}</p>
                </div>

                <div
                  className="atbd_listing_action_btn btn-toolbar"
                  role="toolbar"
                >
                  <div className="dropdown">
                    <a
                      className="action-btn dropdown-toggle"
                      href=" "
                      role="button"
                      id="dropdownMenuLink2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort Connections by <span className="caret"></span>
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      <a className="dropdown-item" href="a-z">
                        A to Z ( title )
                      </a>
                      <a className="dropdown-item" href="z-a">
                        Z to A ( title )
                      </a>
                      <a className="dropdown-item" href="new">
                        Latest listings
                      </a>
                      <a className="dropdown-item" href="old">
                        Oldest listings
                      </a>
                      <a className="dropdown-item" href="low">
                        Price ( low to high )
                      </a>
                      <a className="dropdown-item" href="price">
                        Price ( high to low )
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-12 listing-items">
              <div className="row">
                <div className="col-lg-4 order-lg-0 order-1 mt-5 mt-lg-0">
                  <div className="listings-sidebar">
                    <div className="search-area default-ad-search">
                      <form action="#">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search Connections by user name"
                            className="form-control"
                            onChange={handleChange}
                            name="userName"
                            value={inputState.userName}
                          />
                        </div>

                        <div className="form-group">
                          <div className="position-relative">
                            <input
                              onChange={handleChange}
                              type="text"
                              placeholder="City, state or zip code"
                              className="form-control location-name"
                              name="address"
                              value={inputState.address}
                            />
                            <button type="submit" className="locator">
                              <span className="la la-crosshairs"></span>
                            </button>
                          </div>
                        </div>

                        <div className="filter-checklist">
                          <h5>Filter by Industry</h5>
                          <IndustrySubcategories />
                          <div className="checklist-items tags-checklist">
                            <FormControl
                              sx={{ m: 3 }}
                              component="fieldset"
                              variant="standard"
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Art}
                                      onChange={handleCheckBoxChange}
                                      name="Art"
                                      color="default"
                                    />
                                  }
                                  label="Art"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Culinary}
                                      onChange={handleCheckBoxChange}
                                      name="Culinary"
                                      color="default"
                                    />
                                  }
                                  label="Culinary"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Cosmetics}
                                      onChange={handleCheckBoxChange}
                                      name="Cosmetics"
                                      color="default"
                                    />
                                  }
                                  label="Cosmetics"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Fashion}
                                      onChange={handleCheckBoxChange}
                                      name="Fashion"
                                      color="default"
                                    />
                                  }
                                  label="Fashion"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={FilmandVideo}
                                      onChange={handleCheckBoxChange}
                                      name="FilmandVideo"
                                      color="default"
                                    />
                                  }
                                  label="Film & Video"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Dance}
                                      onChange={handleCheckBoxChange}
                                      name="Dance"
                                      color="default"
                                    />
                                  }
                                  label="Dance"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Literature}
                                      onChange={handleCheckBoxChange}
                                      name="Literature"
                                      color="default"
                                    />
                                  }
                                  label="Literature"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Management}
                                      onChange={handleCheckBoxChange}
                                      name="Management"
                                      color="default"
                                    />
                                  }
                                  label="Management"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={MediacommunicationsandTechnology}
                                      onChange={handleCheckBoxChange}
                                      name="MediacommunicationsandTechnology"
                                      color="default"
                                    />
                                  }
                                  label="Media Communication & Technology"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Music}
                                      onChange={handleCheckBoxChange}
                                      name="Music"
                                      color="default"
                                    />
                                  }
                                  label="Music"
                                />
                              </FormGroup>
                              `
                            </FormControl>
                          </div>
                        </div>

                        <a
                          href=" "
                          onClick={search}
                          className="btn btn-gradient btn-gradient-two btn-block btn-icon icon-right m-top-40"
                        >
                          Search Filter{" "}
                          <span className="la la-long-arrow-right"></span>
                        </a>
                      </form>
                      {/*<!-- ends: form -->*/}
                    </div>
                  </div>
                </div>{" "}
                {/* wiget */}
                <div className="col-lg-8 order-lg-1 order-0">
                  <div className="row">
                    <ListingCardGrid12NetworkList userId={userId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
