import React from "react";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import "./style.css";

export default function Save() {
  return (
    <div className="landing_page_listing_card_save">
      <BookmarkBorderIcon className="landing_page_listing_card_save_icon" />
      <p className="landing_page_listing_card_save_btn">Save</p>
    </div>
  );
}
