import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useSelector } from "react-redux";
import { getAboutAndListings } from "../../../redux/action/discovery";
import SkeletonLoader from "../skeletonLoader";
import Spinner from "../../content/modal/spinnermodal";
import "./profileImageCard.css";

export default function ProfileImageCard({ authorInfo, showCard }) {
  const dispatch = useDispatch();
  const authorId = authorInfo.userId.id;
  const aboutInfos = useSelector(
    (state) => state.discoveryReducer.aboutAndListings
  );
  const [showOnCardHover, setShowOnCardHover] = useState(false);
  const handleOnCardEnter = () => {
    setShowOnCardHover(true);
    dispatch(getAboutAndListings(authorId));
  };
  const handleOnCardLeave = () => {
    setTimeout(() => {
      setShowOnCardHover(false);
    }, 500);
  };
  return (
    <div>
      {/* {aboutInfos.about ? ( */}
      <div>
        {(showCard || showOnCardHover) && (
          <Card
            className="profileImgCard"
            sx={{ maxWidth: 345 }}
            onMouseEnter={() => handleOnCardEnter()}
            onMouseLeave={() => handleOnCardLeave()}
          >
            {aboutInfos.userName ? (
              <CardActionArea>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {aboutInfos.about}
                  </Typography>

                  <div className="projects">
                    <Typography className="listed">
                      {aboutInfos.listedProjects} Listed Projects
                    </Typography>
                    <Typography className="listed">
                      {aboutInfos.completedProjects} Completed Projects
                    </Typography>
                    <Typography className="listed">
                      {aboutInfos.paidProjects} Paid Projects
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
            ) : (
              <div style={{ display: "flex", width: "200px", height: "150px" }}>
                <Spinner />
              </div>
            )}
          </Card>
        )}
      </div>
    </div>
  );
}
