import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Rating, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { postRating } from "../../../../redux/action/auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  p: 4,
};

export default function RatingModal({
  openRatingModal,
  handleOpenRatingModal,
  currentApplicant,
  handleOpenReviewContainer,
}) {
  const dispatch = useDispatch();
  const [rating, setRating] = React.useState(1);
  const [recommendation, setRecommendation] = React.useState();
  const onChangeHandler = ({ currentTarget }) => {
    const { value } = currentTarget;
    setRecommendation(value);
  };
  const onChangeHandlerRating = (value) => {
    setRating(value);
  };
  const handleRating = () => {
    console.log(currentApplicant);
    currentApplicant.body = { recommendation: recommendation, rating: rating };
    dispatch(postRating(currentApplicant));
    handleOpenRatingModal();
  };

  return (
    <div>
      <Modal
        sx={{
          backdropFilter: "blur(5px)",
        }}
        open={openRatingModal}
        // onClose={handleOpenRatingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Overall rating for the services delivered.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, alignSelf: "center" }}
          >
            <Rating
              size="large"
              sx={{
                "& .MuiRating-iconFilled": {
                  color: "#33cccc",
                },
                "& .MuiRating-iconHover": {
                  color: "purple",
                },
                "& .MuiRating-decimal:hover": {
                  transform: "scale(3.5)",
                },
                "& .MuiRating-icon": {
                  transform: "scale(3)",
                  padding: "20px",
                },
              }}
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                onChangeHandlerRating(newValue);
              }}
            />
          </Typography>{" "}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, alignSelf: "center" }}
          >
            <p>Review</p>
            <textarea
              onChangeHandler={onChangeHandler}
              rows="5"
              cols="60"
              value={recommendation}
            />
          </Typography>{" "}
          <Typography
            id="modal-modal-description"
            sx={{ m: 2, alignSelf: "center" }}
          >
            <button onClick={handleRating} className="btn btn-primary">
              Submit
            </button>
          </Typography>{" "}
        </Box>
      </Modal>
    </div>
  );
}
