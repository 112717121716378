import {
  SAVE_CRAFT_PROFILE_PUBLIC,
  GET_CERTIFICATION_PUBLIC,
  GET_ABOUT_PUBLIC,
  GET_PHOTO_GALLERY_PUBLIC,
  GET_ALBUM_PHOTOS_PUBLIC,
  GET_VIDEO_ALBUM_PUBLIC,
  GET_ALBUM_VIDEOS_PUBLIC,
  GET_ALBUM_AUDIOS_PUBLIC,
  GET_AUDIO_ALBUM_PUBLIC,
  GET_HEADER_IMAGE_PUBLIC,
  GET_USER_PROFILE_PUBLIC,
  GET_ALL_LISTINGS_PUBLIC,
  GET_USER_CONNECTIONS_PUBLIC,
  GET_LISTING_DETAILS_PUBLIC,
  LOAD_POPULAR_LISTINGS_PUBLIC,
} from "../action/types";

const initialState = {
  publicCraftProfile: {
    user: null,
    about: null,
    photos: [],
    photoAlbums: [],
    videoAlbums: null,
    albumVideos: [],
    audioAlbums: [],
    albumAudios: [],
    userCertifications: [],
    headerImage: null,
    listings: [],
    connections: [],
  },
  publicListingDetails: null,
  publicListingLoading: true,

  popularListings: [],
};

const publicReducer = (state = initialState, action) => {
  let gallery = null;
  switch (action.type) {
    case SAVE_CRAFT_PROFILE_PUBLIC:
    case GET_ABOUT_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          about: action.payload,
        },
      };
    case GET_USER_PROFILE_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          user: action.payload,
        },
      };
    case GET_HEADER_IMAGE_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          headerImage: action.payload,
        },
      };
    case GET_VIDEO_ALBUM_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          videoAlbums: action.payload,
        },
      };
    case GET_ALBUM_VIDEOS_PUBLIC:
      gallery = state.publicCraftProfile.videoAlbums.find(
        (album) => album._id === action.payload
      );
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          albumVideos: gallery ? gallery.gallery : [],
        },
      };
    case GET_PHOTO_GALLERY_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          photoAlbums: action.payload,
        },
      };
    case GET_ALBUM_PHOTOS_PUBLIC:
      gallery = state.publicCraftProfile.photoAlbums.find(
        (album) => album._id === action.payload
      );

      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          photos: gallery ? gallery.gallery : [],
        },
      };

    case GET_AUDIO_ALBUM_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          audioAlbums: action.payload,
        },
      };
    case GET_ALBUM_AUDIOS_PUBLIC:
      gallery = state.publicCraftProfile.audioAlbums.find(
        (album) => album._id === action.payload
      );
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          albumAudios: gallery ? gallery.gallery : [],
        },
      };
    case GET_CERTIFICATION_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          userCertifications: action.payload,
        },
      };
    case GET_ALL_LISTINGS_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          listings: action.payload,
        },
      };
    case GET_USER_CONNECTIONS_PUBLIC:
      return {
        publicCraftProfile: {
          ...state.publicCraftProfile,
          connections: action.payload,
        },
      };
    case GET_LISTING_DETAILS_PUBLIC:
      return {
        ...state,
        publicListingDetails: action.payload,
        publicListingLoading: false,
      };

    case LOAD_POPULAR_LISTINGS_PUBLIC:
      return {
        ...state,
        popularListings: action.payload,
      };
    default:
      return state;
  }
};

export default publicReducer;
