import { useState, useEffect } from "react";
import Player from "./player";
import "./audioplayer.css";

function AudioPlayerCard({ audios, albumImage, albumImageKey }) {
  console.log("audios ", audios);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [nextSongIndex, setNextSongIndex] = useState(0);

  useEffect(() => {
    setNextSongIndex(() => {
      if (currentSongIndex + 1 > audios.length - 1) {
        return 0;
      } else {
        return currentSongIndex + 1;
      }
    });
  }, [currentSongIndex, audios.length]);

  return (
    <div>
      <Player
        currentSongIndex={currentSongIndex}
        setCurrentSongIndex={setCurrentSongIndex}
        nextSongIndex={nextSongIndex}
        audios={audios}
        albumImage={albumImage}
        albumImageKey={albumImageKey}
      />
    </div>
  );
}

export default AudioPlayerCard;
