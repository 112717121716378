import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import "./online-users.css";

function OnlineUsers({ title, active, messageStatus }) {
  const conversation = useSelector((state) => state.messaging.conversation);

  return (
    <>
      <div id="online-users">
        <div id="list-online-users">
          {conversation &&
            conversation
              .filter((user) => user.onlineStatus)
              .map((user) => (
                <section>
                  <img
                    width="50px"
                    height="50px"
                    src={user.userAvatar}
                    alt=""
                    className="rounded-circle-online"
                  />
                  <p>{user.subject}</p>
                </section>
              ))}
        </div>
      </div>
    </>
  );
}
OnlineUsers.propTypes = {
  title: PropTypes.string,
};
const mapStateToProps = (state) => ({
  title: state.messaging.title,
  active: state.messaging.active,
});
export default connect(mapStateToProps)(OnlineUsers);
