import React from "react";
import "./container.css";
import SwipeableButton from "./swipeButton";
import Contractors from "./contractorsInfo";

function Container({
  appliedListing,
  startJobHandler,
  updateStartProjectHandle,
  title,
  containerBody: ContainerBody,
  buttonTitle,
}) {
  return (
    <div className="swipe-button-container">
      <div className="job-info-block">
        {" "}
        <div className="d-flex justify-content-center">
          {" "}
          <h1> {title} </h1>
          <br />
        </div>{" "}
        <div className="d-flex justify-content-center">
          <ContainerBody appliedListing={appliedListing} />
        </div>
      </div>

      <div className="swipe-button-block">
        <SwipeableButton
          color="#33cccc"
          buttonTitle={buttonTitle}
          startJobHandler={startJobHandler}
          updateStartProjectHandle={updateStartProjectHandle}
        />
      </div>
    </div>
  );
}

export default Container;
