import React, { Component, Fragment } from "react";
import Divider from "@mui/material/Divider";
import { NavLink } from "react-router-dom";
import LogIn from "../../content/modal/signIn";
import Register from "../../content/modal/signUp";
import socicraftlogo from "../../project-wide-images/socicraft logo.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

import "./style.css";
const noAction = (e) => e.preventDefault();

export class Footer extends Component {
  render() {
    return (
      <>
        <footer className="footer__container-wrapper">
          <div className="footer__container">
            <div className="footer__row">
              <div className="footer__col">
                <h4>Resources</h4>
                <ul>
                  <li>
                    <a href="https://support.socicraft.com/portal/en/kb">
                      Support
                    </a>
                  </li>
                  <li>
                    <a href="https://support.socicraft.com/portal/en/kb/getting-started">
                      Resource
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/facets-of-art">Publication</a>
                  </li>
                </ul>
              </div>
              <div className="footer__col">
                <h4>Company</h4>
                <ul>
                  <li>
                    <a href="https://socicraft.com/about">About Us</a>
                  </li>
                  <li>
                    <a href="https://support.socicraft.com/portal/en/newticket?departmentId=746075000000221049&layoutId=746075000000226336">
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="#">Press</a>
                  </li>
                </ul>
              </div>
              <div className="footer__col">
                <h4>Terms</h4>
                <ul>
                  <li>
                    <a href="https://socicraft.com/terms-of-use">
                      Terms of Conditions
                    </a>
                  </li>
                  <li>
                    <a href="http://socicraft-mvp-frontend-stg.s3-website-us-east-1.amazonaws.com/private-policy">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer__col">
                <h4>Partners</h4>
                <ul>
                  <li>
                    <a href="https://stripe.com/">Stripe</a>
                  </li>
                  <li>
                    <a href="https://www.docusign.com/">Docusign</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Divider className="footer_divider" />
          <div className="footer__bottom">
            <div className="footer__bottom_logo">
              <img src={socicraftlogo} width="100%" />
            </div>
            <p>Follow us on social media</p>
            <div className="footer__social_links">
              <EmailIcon /> <LinkedInIcon /> <InstagramIcon /> <TwitterIcon />
            </div>
            <p>
              {" "}
              © Copyright Socicraft {new Date().getFullYear()}. All rights
              reserved.
            </p>
          </div>
        </footer>
      </>
    );
  }
}
