import React from "react";
import Avatar from "@mui/material/Avatar";
import { Button } from "@mui/material";
import { red } from "@mui/material/colors";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postReply } from "../../../redux/action/discovery";
import "../postFeedstyles.css";
import { postReplyOnAllListing } from "../../../redux/action/userpost";

export default function ReplyInputField({ showAvatar, postId, commentId, commentFrom, incrementReplyCount}) {
  const dispatch = useDispatch();
  const [reply, setReply] = useState("");
  const [count, setReplyCount] = useState(1);
  const user = useSelector((state) => state.auth.user);
  const handleReply = (value) => {
    setReply(value);
  };
  const handlePostReply = () => {
    if (commentFrom == 'allListing' ) {
    dispatch(postReplyOnAllListing(commentId, reply, postId));
    } else {
    dispatch(postReply(commentId, reply));
    }
    setReplyCount(count+1);
    incrementReplyCount(count);
    setReply("");
  };

  return (
    <div>
      <div className="input-group">
        {showAvatar && (
          <Avatar
            className="avatar"
            sx={{ bgcolor: red[500] }}
            alt="Remy Sharp"
          >
            {user.userAvatarKey ? (
              <img className="discovery_user_avatar" src={user.userAvatar} />
            ) : (
              user.userName[0].toUpperCase()
            )}
          </Avatar>
        )}
        <input
          className="commentInput"
          onChange={(e) => handleReply(e.target.value)}
          value={reply}
        />
        {reply && (
          <Button
            className="commentButton"
            variant="text"
            onClick={handlePostReply}
          >
            Post
          </Button>
        )}
      </div>
    </div>
  );
}
