import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { getGalleries } from "../../redux/action/public";
import FolderBar from "./folder/folderBar";
import { ImageBar } from "./image/ImageBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
  heading: {
    margin: "12px 0",
  },
  content: "",
}));

const Gallery = (props) => {
  const dispatch = useDispatch();
  const { photoAlbums } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [view, setView] = useState("gallery");

  const [showAlbums, setShowAlbums] = useState(true);

  const togglePhotoView = (type, id) => {
    setView(type);

    setShowAlbums(!showAlbums);

    dispatch(getGalleries("Photo", id));
  };

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>{view === "gallery" ? "Gallery" : "Photos"}</h4>{" "}
                </div>{" "}
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div>
              {!!photoAlbums.length && showAlbums === true && (
                <div>
                  {" "}
                  <FolderBar
                    viewType={props.viewType}
                    type="photoalbum"
                    albumInfo={photoAlbums}
                    togglePhotoView={togglePhotoView}
                  />
                </div>
              )}
            </div>

            {!showAlbums && (
              <ImageBar viewType={props.viewType} userId={props.userId} />
            )}

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {view !== "gallery" && (
                  <div className="custom-control custom-switch">
                    <button
                      className="btn  btn-sm btn-info"
                      onClick={() => togglePhotoView("gallery")}
                    >
                      <i className="fa fa-backward" aria-hidden="true"></i>
                      {""}
                      {""} Gallery
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Put the audio player here */}
      </div>
      {/* <CreateAlbum
        showCreateAlbum={showCreateAlbum}
        toggleCreateAlbum={toggleCreateAlbum}
      />{" "}
      <UploadImage
        showUploadPhoto={showUploadPhoto}
        toggleUploadPhoto={toggleUploadPhoto}
        selectedAlbumId={selectedAlbumId}
      /> */}
    </Fragment>
  );
};

export default Gallery;

// <div>
//   {imageAndVideo.image &&
//     imageAndVideo.image.map((img) => {
//       let url = window.URL.createObjectURL(img.file);
//       return (
//         <>
//           <img src={url} alt="" />
//           <label style={{ display: "block" }} for="tags">
//             Tags:{" "}
//           </label>
//           <input
//             onChange={onChangeImagesTags}
//             type="text"
//             id="tags"
//             name={img.filename.toString()}
//           />{" "}
//           <label
//             style={{ display: "block" }}
//             for="description"
//           >
//             Description:{" "}
//           </label>
//           <input type="text" id="description" />{" "}
//         </>
//       );
//     })}
//   {/*  URL.createObjectURL(myBlob); */}
// </div>

/* <label class="custom-control-label" for="toggleSwitchGallery">
  Publish to Public Profile
</label>; */
