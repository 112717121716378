import React from "react";
import Typography from "@mui/material/Typography";

export default function ProjectTitle({ title }) {
  return (
    <Typography variant="h6" gutterBottom component="div">
      {title}
    </Typography>
  );
}
