import React, { Component } from "react";

import TermsAndConditions from "../modal/termsAndCondition";
import PrivacyPolicy from "../modal/privacyPolicy";
import { connect } from "react-redux";
import {
  registeruser,
  loaduser,
  completeprofile,
} from "../../../redux/action/auth";
import { PropTypes } from "prop-types";
import API from "../../../api/api";
import AlertMessage from "../controls/AlertMessage";
import { validateUserForm } from "../../../services/validator";
import { isPasswordValid } from "../../../services/validator";
import { Redirect, withRouter } from "react-router";
import PasswordRequisite from "./password-requisite";
import Spinner from "./../modal/spinnermodal";

class RegistrationForm extends Component {
  state = {
    user: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      email: "",
    },

    disableAnswer: true,
    passwordRequisite: false,
    checks: {},
    passwordCheck: "",
    errors: {},
    msgAlert: {},
    modalTermsOfUse: false,
    agreeTermsOfUse: false,
    modalPrivacyPolicy: false,
    agreePrivacyPolicy: false,
    checkYourPasswordInput: false,
    passwordCondition: true,
  };

  onFocusHandler = () => {
    this.setState({ passwordRequisite: true });

    if (this.passwordCondition === false) {
      this.setState({ checkYourPasswordInput: false });
    }
  };

  onBlurHandler = () => {
    this.setState({ passwordRequisite: false });
  };

  onChangeHandler = ({ currentTarget: input }) => {
    const { name, value } = input;
    let user = { ...this.state.user };
    user[name] = value;
    this.setState({ user });

    if (name === "password") {
      /// password validation logic
      const capsLetterCheck = /[A-Z]/.test(value);
      const numberCheck = /[0-9]/.test(value);
      const pwdLengthCheck = value.length >= 5;
      const specialCharacterCheck = /[!@#$%^&*]/.test(value);
      this.setState({
        checks: {
          capsLetterCheck,
          numberCheck,
          pwdLengthCheck,
          specialCharacterCheck,
        },
      });

      let passwordCondition =
        capsLetterCheck === true &&
        numberCheck === true &&
        pwdLengthCheck === true &&
        specialCharacterCheck === true
          ? true
          : false;
      this.setState({ passwordCondition: passwordCondition });
      ///
      isPasswordValid(passwordCondition);
    }

    const result = validateUserForm(user);

    if (result) {
      if (result[name]) {
        this.setState({ errors: { [name]: result[name] } });
      }

      !result[name] && this.setState({ errors: { [name]: null } });
    }
    if (!result) this.setState({ errors: {} });
  };

  toggleModalTermsOfUse = (status) => {
    this.setState({ modalTermsOfUse: status });
  };
  toggleModalPrivacyPolicy = (status) => {
    this.setState({ modalPrivacyPolicy: status });
  };
  termsOfUseSubmitAgreement = () => {
    this.setState({
      agreeTermsOfUse: true,
      modalTermsOfUse: false,
      errors: { ...this.state.errors, agreeTermsOfUse: null },
    });
  };
  privacyPolicySubmitAgreement = () => {
    this.setState({
      agreePrivacyPolicy: true,
      modalPrivacyPolicy: false,
      errors: { ...this.state.errors, agreePrivacyPolicy: null },
    });
  };
  onSubmitHandler = async (e) => {
    e.preventDefault();

    const body = { ...this.state.user };
    const result = validateUserForm(body);
    if (result !== 1) {
      if (this.state.passwordCondition === false) {
        this.state.checkYourPasswordInput = true;
      }

      return this.setState({ errors: result });
    }

    if (!this.state.agreeTermsOfUse || !this.state.agreePrivacyPolicy) {
      return this.setState({
        errors: {
          ...this.state.errors,
          agreeTermsOfUse:
            !this.state.agreeTermsOfUse && "Read and Agree Terms Of Uses",
          agreePrivacyPolicy:
            !this.state.agreePrivacyPolicy && "Read and Agree Privacy Policy",
        },
      });
    }

    if (result === 1) {
      this.props.registeruser(body);
    }
  };

  completeProfile = async (e) => {
    e.preventDefault();
    const body = { ...this.state.user };
    const result = validateUserForm(body);
    console.log(result);
    if (result) {
      return this.setState({
        errors: result,
      });
    }
    this.props.completeprofile(body);
  };
  populateForm = async () => {
    try {
      let populatedInfo = await API.get("/auth/google/userProfile", {
        withCredentials: true,
      });

      const { firstName, lastName, email } = {
        ...populatedInfo.data,
      };
      const today = new Date();
      let userName =
        populatedInfo.data.firstName.replace(" ", "", "g") + today.getDate();

      this.setState({
        user: {
          firstName,
          lastName,
          email,
          userName,
        },
      });
    } catch (error) {
      this.props.history.push({ pathname: "/register" });
    }
  };
  componentDidMount() {
    this.props.withprovider && this.populateForm();
  }

  render() {
    if (this.props.profile === "pending") {
      return <Redirect to="/register" />;
    }
    if (
      this.props.isAuthenticated &&
      this.props.processingRegistration === false
    ) {
      return (
        <Redirect
          to={{
            pathname: "/social/discovery",
            state: { showOnboarding: true },
          }}
        />
      );
    }
    return (
      <>
        <form
          onSubmit={(e) => this.onSubmitHandler(e)}
          className="needs-validation"
          noValidate
          name="registration"
        >
          <div className="form-excerpts">
            {" "}
            <br />
            <ul className="list-unstyled">
              <li>
                Already a member? <a href="/">Sign in</a>
              </li>
            </ul>
          </div>
          <div className="form-row">
            <div className="col-md-12 mb-3 col-lg-6">
              {/* <label for="firstName">First name</label> */}
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={this.state.user.firstName}
                onChange={this.onChangeHandler}
                placeholder="First name"
                required
              ></input>
              {this.state.errors && (
                <AlertMessage
                  error={this.state.errors.firstName}
                  type="danger"
                />
              )}
            </div>
            <div className="col-md-12 mb-3 col-lg-6">
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={this.state.user.lastName}
                onChange={this.onChangeHandler}
                placeholder="Last name"
                required
              ></input>
              {this.state.errors && (
                <AlertMessage
                  error={this.state.errors.lastName}
                  type="danger"
                />
              )}
            </div>
            <div className="col-md-12 mb-3 col-lg-12">
              <input
                disabled={this.props.withprovider ? true : false}
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={this.state.user.email.replace(" ", "", "g")}
                onChange={this.onChangeHandler}
                aria-describedby="emailHelp"
                placeholder="Enter email"
                required
              ></input>
              {this.state.errors && (
                <AlertMessage error={this.state.errors.email} type="danger" />
              )}

              {this.state.msgAlert.email && (
                <AlertMessage error={this.state.msgAlert.email} type="danger" />
              )}

              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="col-md-12 mb-3 col-lg-12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text " id="userNamePrepend">
                    @
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  name="userName"
                  value={this.state.user.userName.replace(" ", "", "g")}
                  onChange={this.onChangeHandler}
                  placeholder="Username"
                  aria-describedby="userNamePrepend"
                  required
                ></input>
                {this.state.errors && (
                  <>
                    <div className="input-group">
                      {" "}
                      <AlertMessage
                        error={this.state.errors.userName}
                        type="danger"
                      />
                    </div>
                  </>
                )}
                <div className="input-group">
                  {" "}
                  {this.state.msgAlert.user && (
                    <AlertMessage
                      error={this.state.msgAlert.user}
                      type="danger"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-3">
              <input
                type="password"
                className="form-control"
                name="password"
                value={this.state.user.password}
                id="password"
                onChange={this.onChangeHandler}
                placeholder="Choose a password"
                onFocus={this.onFocusHandler}
                onBlur={this.onBlurHandler}
                onKeyUp={this.onKeyUpHandler}
              ></input>

              {this.state.passwordRequisite ||
              this.state.checkYourPasswordInput ? (
                <PasswordRequisite
                  capsLetterFlag={
                    this.state.checks.capsLetterCheck ? "valid" : "invalid"
                  }
                  numberFlag={
                    this.state.checks.numberCheck ? "valid" : "invalid"
                  }
                  pwdLengthFlag={
                    this.state.checks.pwdLengthCheck ? "valid" : "invalid"
                  }
                  specialCharFlag={
                    this.state.checks.specialCharacterCheck
                      ? "valid"
                      : "invalid"
                  }
                />
              ) : (
                <div></div>
              )}

              {this.state.errors && (
                <AlertMessage
                  error={this.state.errors.password}
                  type="danger"
                />
              )}
            </div>

            {this.state.errors && (
              <AlertMessage error={this.state.errors.answer} type="danger" />
            )}
          </div>
          <div className="col-lg-10 offset-lg-1 text-center">
            <div className="atbd_term_and_condition_area custom-control  checkbox-outline-primary">
              <div className="btn_wrap list_submit m-top-25">
                <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                  <input
                    checked={this.state.agreeTermsOfUse}
                    type="checkbox"
                    className="custom-control-input"
                    name="agreeTermsOfUse"
                    id="agreeTermsOfUse"
                    value={this.state.agreeTermsOfUse}
                    onClick={() => this.toggleModalTermsOfUse(true)}
                  />
                  <label
                    className="custom-control-label control"
                    htmlFor="agreeTermsOfUse"
                  >
                    <i
                      style={{
                        textDecoration: "underline",
                        color: `${
                          this.state.agreeTermsOfUse ? "green" : "red"
                        } `,
                      }}
                    >
                      {" "}
                      Read and Agree with all Terms of Use
                      {/* <a
                        style={{
                          color: `${
                            this.state.agreeTermsOfUse ? "green" : "red"
                          } `,
                        }}
                        href="#/"
                        onClick={() => this.toggleModalTermsOfUse(true)}
                      >
                        Terms of Use
                      </a> */}
                    </i>
                  </label>{" "}
                  {this.state.errors && (
                    <AlertMessage
                      error={this.state.errors.agreeTermsOfUse}
                      type="danger"
                    />
                  )}
                </div>
                <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                  <input
                    checked={this.state.agreePrivacyPolicy}
                    type="checkbox"
                    className="custom-control-input "
                    name="agreePrivacyPolicy"
                    id="agreePrivacyPolicy"
                    value={this.state.agreePrivacyPolicy}
                    onClick={() => this.toggleModalPrivacyPolicy(true)}
                  />
                  <label
                    className="custom-control-label control"
                    htmlFor="agreePrivacyPolicy"
                  >
                    <i
                      style={{
                        textDecoration: "underline",
                        color: `${
                          this.state.agreePrivacyPolicy ? "green" : "red"
                        } `,
                      }}
                    >
                      Read and Agree with all Privacy Policy
                      {/* <a
                        style={{
                          color: `${
                            this.state.agreePrivacyPolicy ? "green" : "red"
                          } `,
                        }}
                        href="#/"
                        onClick={() => this.toggleModalPrivacyPolicy(true)}
                      >
                        Privacy Policy
                      </a> */}
                    </i>
                  </label>{" "}
                  {this.state.errors && (
                    <AlertMessage
                      error={this.state.errors.agreePrivacyPolicy}
                      type="danger"
                    />
                  )}
                </div>
                {/* // */}

                {this.props.withprovider === "true" ? (
                  <button
                    className="btn btn-dark"
                    onClick={this.completeProfile}
                    type="button"
                  >
                    Complete Profile
                  </button>
                ) : (
                  <>
                    {" "}
                    <button
                      className="btn btn-primary"
                      onClick={this.onSubmitHandler}
                      type="button"
                      disabled={this.props.processingRegistration}
                    >
                      {this.props.processingRegistration ? (
                        <Spinner />
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
        <TermsAndConditions
          toggleModal={this.toggleModalTermsOfUse}
          show={this.state.modalTermsOfUse}
          submitAgreement={this.termsOfUseSubmitAgreement}
        />{" "}
        <PrivacyPolicy
          toggleModal={this.toggleModalPrivacyPolicy}
          show={this.state.modalPrivacyPolicy}
          submitAgreement={this.privacyPolicySubmitAgreement}
        />
      </>
    );
  }
}
RegistrationForm.propTypes = {
  registeruser: PropTypes.func.isRequired,
  loaduser: PropTypes.func.isRequired,
  user: PropTypes.object,
  completeprofile: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  processingRegistration: state.auth.processingRegistration,
});
export default connect(mapStateToProps, {
  registeruser,
  loaduser,
  completeprofile,
})(withRouter(RegistrationForm));
