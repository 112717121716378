import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { red } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import ContentUploaderModal from "./contentUploaderModal";
import { useSelector } from "react-redux";
import "../postFeedstyles.css";

export default function PostContent() {
  const user = useSelector((state) => state.auth.user);

  return (
    <Card
      sx={{ m: "auto", borderRadius: 3.5, maxWidth: 650 }}
      className="post-content"
      variant="outlined"
    >
      <div className="header_uploader">
        <ContentUploaderModal />
      </div>
    </Card>
  );
}
