import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmationDialog({
  deleteAlbumFunc,
  handleClickOpen,
  handleClose,
  open,
  imageId,
}) {
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      {/* <i
        onClick={handleClickOpen}
        className="fas fa-trash-alt"
        style={{ marginTop: "10px" }}
      ></i> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Album Deletion Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete an album, which may include multiple images
            are you sure you want to delete all the image in this album ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary text-dark" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn btn-danger text-dark"
            onClick={() => deleteAlbumFunc(imageId)}
          >
            Delete All
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
