import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

export default function AudioPlayer({ audioSrc }) {
  return (
    <Card sx={{ display: "flex", mt: 2 }} style={{ maxHeight: "70px" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column" }}
        style={{ width: "100%" }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1, mt: 1.2 }}
        >
          <audio
            controls
            style={{ background: "#f1f3f4", marginRight: "10px" }}
          >
            <source src={audioSrc} type="audio/mpeg" />
          </audio>
        </Box>
      </Box>
    </Card>
  );
}
