import React from "react";
import PaymentWaiting from "../pendingProject/paymentWaiting";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

import {
  paymentSuccessNotification,
  createCheckOutSession,
} from "../../../redux/action/payment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";

export default function Cart() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(showItemsInCart());
  // }, [dispatch]);

  let cartItems = useSelector((state) => state.cart.showCart);
  let { paymentProcessing, paymentSuccess, message } = useSelector(
    (state) => state.payment
  );

  const handleCheckout = () => {
    dispatch(createCheckOutSession())
      .then((data) => {
        const { url } = data;
        window.open(url, "_blank");
      })
      .catch((err) => {
        console.log("Something Went Wrong.");
      });
  };
  const location = useLocation().search;
  useEffect(() => {
    const status = new URLSearchParams(location).get("status");

    if (status === "success") {
      dispatch(paymentSuccessNotification());
      const updatedUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: updatedUrl }, "", updatedUrl);
    }
  }, [location, dispatch]);

  return (
    <>
      <div className="cart">
        {!paymentProcessing &&
          cartItems.items &&
          cartItems.items.map((item) => (
            <PaymentWaiting
              ProjectImg={
                item.listingId.listingImageUrl.length
                  ? item.listingId.listingImageUrl[0].url
                  : ""
              }
              ProjectTitle={item.listingId.title}
              ProjectPrice={item.listingId.cost}
              applicantFirstName={item.applicant.firstName}
              applicantLastName={item.applicant.lastName}
              cartItemId={item._id}
            />
          ))}
      </div>
      {paymentProcessing && (
        <h3 className="alert alert-warning" role="alert">
          {message}
        </h3>
      )}
      {!paymentSuccess && cartItems.totalItems === 0 && (
        <h3
          className="alert alert-info  project_activity_cart_alert"
          role="alert"
        >
          Empty Cart , Add Items!
        </h3>
      )}
      {!paymentProcessing && !!cartItems.totalItems && (
        <>
          <Button
            className="bg-primary text-white text-dark p-3"
            variant="outlined"
            onClick={handleCheckout}
          >
            Proceed To Checkout
          </Button>
        </>
      )}{" "}
      {paymentSuccess && (
        <>
          <h3 className="alert alert-success" role="alert">
            {message}
          </h3>
        </>
      )}
    </>
  );
}
