import * as React from "react";
import { NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ShareIcon from "@mui/icons-material/Share";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ProfileImageCard from "./profileImageCard/profileImageCard";
import Comment from "./comment/comment";
import CommentInputField from "./comment/commentInputField";
import PostOptions from "./postOptions";
import Slider from "./slider/slider";
import {
  getComments,
  getAboutAndListings,
  clearReplies,
  upvote,
  clearAboutAndListings,
} from "../../redux/action/discovery";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./postFeedstyles.css";
import "./slider/sliderstyle.css";
import AudioPlayerCard from "./audioPlayer/audioPlayerCard";
import PostLinkCopy from "./disocerPostLinkCopy/postLinkCopy";

export default function PostCard({ post }) {
  const {
    authorInfo,
    contentInfo,
    _id,
    createdAt,
    views,
    votes,
    shares,
    contentUrl,
    contentType,
    comments,
  } = post;
  const discoveryComments = useSelector(
    (state) => state.discoveryReducer.comments
  ).filter((comment) => comment.discoveryContent === _id);

  const upVotedContentsIds = useSelector(
    (state) => state.discoveryReducer.upVotedContentsIds
  );

  const todayDate = new Date().getDate();
  const commentCreatedAt = new Date(createdAt).getDate();

  const voteStatus = upVotedContentsIds.includes(_id)
    ? "upvote removed"
    : "up voted";
  const [expanded, setExpanded] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [upVote, setupVote] = useState(upVotedContentsIds);
  const [votesCounter, setVotesCounter] = useState(votes);
  const [commentOpened, setCommentOpened] = useState(false);
  const [limit, setLimit] = useState(1);
  const [skip, setSkip] = useState(0);
  const [addCommentCount, setAddCommentCount] = useState(0);
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    const getCommentNumber = 3;
    dispatch(clearReplies());
    if (!commentOpened) {
      dispatch(getComments(_id, limit, skip));
    }
    setCommentOpened(true);
    setExpanded(!expanded);
    setLimit(getCommentNumber);
  };

  const getMoreComments = () => {
    dispatch(getComments(_id, limit, discoveryComments.length));
  };

  const [showPopover, setShowPopover] = useState(false);

  const handleUpVote = (postId, voteStatus) => {
    // setupVote();
    if (upVotedContentsIds.includes(postId)) {
      let upvoteIds = upVotedContentsIds;
      upvoteIds = upvoteIds.filter((contentId) => contentId !== postId);
      setupVote(upvoteIds);
      setVotesCounter(votesCounter - 1);
      dispatch(upvote(postId, voteStatus));
    } else {
      let upVoteIds = upVotedContentsIds;
      upVoteIds.push(_id);

      setupVote(upVoteIds);
      setVotesCounter(votesCounter + 1);
      dispatch(upvote(postId, voteStatus));
    }
  };

  const handleMouseEnter = () => {
    dispatch(clearAboutAndListings());
    dispatch(getAboutAndListings(authorInfo.userId.id));
    setShowPopover(true);
  };
  const handleMouseLeave = () => {
    setTimeout(() => {
      setShowPopover(false);
    }, 500);
  };

  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  const handleCommentsAdded = (value) => {
    setTimeout(() => {
    setAddCommentCount(value);
    },2000);
  }

  return (
    <Card
      variant="outlined"
      className="post_card"
      sx={{ m: "auto", borderRadius: 3.5, maxWidth: 650 }}
    >
      <CardHeader
        avatar={
          <div>
            <NavLink to={`/${authorInfo.userId.userName}`} target="_blank">
              <Avatar
                sx={{ bgcolor: red[500] }}
                aria-label="user avatar"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
              >
                {authorInfo.userId.userAvatarKey ? (
                  <img
                    className="discovery_user_avatar"
                    src={authorInfo.userId.userAvatar}
                    alt="user avatar"
                  />
                ) : (
                  authorInfo.userId.userName[0].toUpperCase()
                )}
              </Avatar>
            </NavLink>
          </div>
        }
        action={
          <IconButton aria-label="settings" className="icon_btn">
            <PostOptions postId={_id} />
          </IconButton>
        }
        title={authorInfo.userId.userName}
        // subheader={moment(createdAt).format("MMM Do YY")}

        subheader={
          todayDate > commentCreatedAt
            ? moment(createdAt).format("ll")
            : moment(createdAt).fromNow("dd") + " ago"
        }
      />{" "}
      <ProfileImageCard authorInfo={authorInfo} showCard={showPopover} />
      {contentType === "photo" && (
        <div>
          {contentUrl.length > 1 ? (
            <div className="containerStyles">
              {" "}
              <Slider
                slides={contentUrl}
                contentType={contentType}
                style={{ marginBottom: "10px" }}
              />
            </div>
          ) : (
            <CardMedia
              component="img"
              height="400"
              image={contentUrl[0].url}
              alt="Paella dish"
            />
          )}
        </div>
      )}
      {/* <div> {contentType === "video" && (
        <video width="320" height="240" controls>
          <source src={contentUrl[0].url} type="video/mp4" />
          Error Message
        </video>
      )}</div> */}
      {contentType === "video" && (
        <div>
          {contentUrl.length > 1 ? (
            <Slider
              slides={contentUrl}
              contentType={contentType}
              style={{ marginBottom: "10px" }}
            />
          ) : (
            <video
              width="320"
              height="240"
              controls
              autoPlay="autoplay"
              loop="loop"
              muted="muted"
            >
              <source src={contentUrl[0].url} type="video/mp4" />
              Error Message
            </video>
          )}
        </div>
      )}
      {contentType === "audio" && (
        <div>
          <AudioPlayerCard
            audios={contentUrl}
            albumImage={authorInfo.userId.userAvatar}
            albumImageKey={authorInfo.userId.userAvatarKey}
          />
        </div>
      )}
      <CardContent>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{ marginTop: "10px", fontWeight: "bold" }}
        >
          {contentInfo.title}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          style={{ marginTop: "10px" }}
        >
          {contentInfo.description.length > 300
            ? contentInfo.description.slice(0, seeMore ? Infinity : 300)
            : contentInfo.description}{" "}
          {!seeMore && contentInfo.description.length > 300 && <span>...</span>}
        </Typography>

        {contentInfo.description.length > 300 && (
          <p onClick={handleSeeMore} className="see_more">
            {seeMore ? "show less" : "read more"}
          </p>
        )}
        <div className="tags">
          {" "}
          {contentInfo.tags.map((tag, key) => (
            <span key={key}>#{tag.toString()}</span>
          ))}
        </div>
      </CardContent>
      <CardActions disableSpacing sx={{ justifyContent: "space-around" }}>
        <IconButton
          onClick={handleExpandClick}
          aria-label="add to favorites"
          className="icon_btn"
        >
          <ModeCommentIcon
            className="post_icon"
            expand={expanded.toString()}
            aria-expanded={expanded}
            aria-label="show more"
          />
          <span>{comments + addCommentCount}</span>
        </IconButton>

        <IconButton aria-label="share" className="icon_btn">
          <RemoveRedEyeIcon className="post_icon" />
          <span>{views}</span>
        </IconButton>

        <IconButton
          aria-label="share"
          className="icon_btn"
          onClick={() => handleUpVote(_id, voteStatus)}
        >
          <ArrowUpwardIcon
            className="post_icon"
            style={{
              color: upVotedContentsIds.includes(_id) ? "#4dd0e1" : "grey",
            }}
          />
          <span>{votesCounter}</span>
        </IconButton>

        <PostLinkCopy id={_id} />
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ minWidth: 300 }}>
          <Typography paragraph>Comment :</Typography>

          <CommentInputField showAvatar={true} postId={_id} incrementCommentCount={handleCommentsAdded}/>

          {discoveryComments &&
            discoveryComments.map((comment) => (
              <Comment key={comment._id} postComment={comment} postId={_id} />
            ))}
        </CardContent>
        {discoveryComments.length !== 0 && (
          <div>
            {comments === discoveryComments.length ? (
              <p className="more_comments">all comments seen</p>
            ) : (
              <div className="more_comments">
                {" "}
                <Typography
                  paragraph
                  onClick={() => {
                    getMoreComments();
                  }}
                >
                  {comments > 1 && <p>show more comments</p>}
                </Typography>
              </div>
            )}
          </div>
        )}
      </Collapse>
    </Card>
  );
}
