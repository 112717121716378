import * as React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import { setNavigation } from "../../redux/action/discovery";
import { useDispatch } from "react-redux";
import { navigationMap } from "../../services/consts";
import "./postFeedstyles.css";

export default function Navigation({ userId }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("home");
  const handleNavigationClick = (navigationItem) => {
    if (navigationItem === navigationMap.get(navigationItem)) {
      setSelected(navigationItem);
      dispatch(setNavigation(navigationItem));
    }
  };

  return (
    <Card
      sx={{ minWidth: 250, maxWidth: 250 }}
      variant="outlined"
      className="personal navigation"
    >
      <CardContent>
        <div
          onClick={() => handleNavigationClick("home")}
          className="routediv"
          sx={{ mt: 7 }}
        >
          <HomeIcon
            style={{ color: selected === "home" ? "#97e5e5" : "black" }}
          />
          <Typography variant="h6" gutterBottom sx={{ ml: 2 }}>
            Home
          </Typography>
        </div>

        <div
          onClick={() => handleNavigationClick("saveforlater")}
          className="routediv"
          sx={{ mt: 3 }}
        >
          <BookmarkIcon
            style={{ color: selected === "saveforlater" ? "#97e5e5" : "black" }}
          />
          <Typography variant="h6" gutterBottom sx={{ ml: 2 }}>
            Save For Later
          </Typography>
        </div>

        <div
          className="routediv"
          sx={{ mt: 3 }}
          onClick={() => handleNavigationClick("comments")}
        >
          <ModeCommentIcon
            style={{ color: selected === "comments" ? "#97e5e5" : "black" }}
          />
          <Typography variant="h6" gutterBottom sx={{ ml: 2 }}>
            Comments
          </Typography>
        </div>

        <div
          className="routediv"
          sx={{ mt: 3 }}
          onClick={() => handleNavigationClick("votes")}
        >
          <ArrowUpwardIcon
            style={{ color: selected === "votes" ? "#97e5e5" : "black" }}
          />
          <Typography variant="h6" gutterBottom sx={{ ml: 2 }}>
            Votes
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
