import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  uploadPhoto,
  uploadVideos,
  uploadAudios,
} from "../../../../redux/action/craftProfile";
import { useSelector } from "react-redux";
import AudioPlayerCard from "../../../postFeed/audioPlayer/audioPlayerCard";
import ImageUploader from "../../controls/ImageUploader";

const UploadImage = (props) => {
  const album = useSelector((state) => state.auth.user);
  const closeUploadPhoto = () => {
    props.toggleUploadPhoto();
  };
  let [image, setImage] = useState([]);
  let [error, setError] = useState([]);

  const imageRemover = async (blob) => {
    let check = image.filter(
      (img) => img.filename !== blob.name.split(/(?=jpg|png|jpeg|gif)/g)[0]
    );
    setImage([...check]);
  };

  const imageUploader = async (blob) => {
    // let imageBase = await blobToBase64(blob);

    let imageTemp = {
      filename: blob.name.split(/(?=jpg|png|jpeg|gif)/g)[0],
      file: blob,
      tag: "",
      title: "",
      description: "",
      tags: [],
    };
    console.log("upload", blob);
    setImage([...image, imageTemp]);
  };
  const imageUrl = (blob) => {
    let img = window.URL.createObjectURL(blob);
    return img;
  };

  const onChangeHandler = (event) => {
    setError([]);
    const id = event.target.id;
    const value =
      event.target.name === "tag"
        ? event.target.value.trim()
        : event.target.value; //description //text //tag
    const name = event.target.name;

    let imageIndex = image.map((data) => data.filename).indexOf(id);
    let tempImage = [...image];
    tempImage[imageIndex][name] = value;
    setImage(tempImage);
  };

  const uploadPhoto = () => {
    let photos = new FormData();
    let photoInformation = image.map((img) => {
      photos.append("gallery", img.file, img.filename);
      return {
        filename: img.filename,
        tag: img.tag,
        tags: img.tags,
        title: img.title,
        description: img.description,
      };
    });

    photos.append("data", JSON.stringify(photoInformation));

    if (props.type === "video") {
      props.uploadVideos(photos, props.selectedAlbumId);
    }
    if (props.type === "audio") {
      props.uploadAudios(photos, props.selectedAlbumId);
    }
    if (props.type === "photo") {
      props.uploadPhoto(photos, props.selectedAlbumId);
    }

    closeUploadPhoto();
    setImage([]);
    setError([]);
  };

  const addTag = (e, filename) => {
    //Rule No Space Between tags
    //Dont Repeat words

    e.preventDefault();
    let imageTag = image.find((data) => data.filename === filename);
    let imageIndex = image.map((data) => data.filename).indexOf(filename);
    let tempImage = [...image];

    if (imageTag["tag"] === "" || imageTag["tags"] === "") {
      let errorTemp = [...error];
      let errorObject = {
        filename,
        error: "Empty tag is not allowed",
      };
      setTimeout(() => {
        setError([]);
      }, 5000);
      return setError([...errorTemp, errorObject]);
    }
    if (imageTag["tag"].includes(" ") || imageTag["tags"].includes(" ")) {
      let errorTemp = [...error];
      let errorObject = {
        filename,
        error: "Space in a tag is not allowed",
      };
      setTimeout(() => {
        setError([]);
      }, 5000);
      return setError([...errorTemp, errorObject]);
    }
    if (tempImage[imageIndex]["tags"].length === 5) {
      let errorTemp = [...error];
      let errorObject = {
        filename,
        error: "Only Five(5) Tags Allowed",
      };
      setTimeout(() => {
        setError([]);
      }, 5000);
      return setError([...errorTemp, errorObject]);
    }
    if (tempImage[imageIndex]["tags"].includes(imageTag.tag)) {
      let errorTemp = [...error];
      let errorObject = {
        filename,
        error: "Duplicate tags are not allowed!",
      };
      setTimeout(() => {
        setError([]);
      }, 5000);
      return setError([...errorTemp, errorObject]);
    }

    tempImage[imageIndex]["tags"].push(imageTag.tag.trim());
    tempImage[imageIndex]["tag"] = "";
    setImage(tempImage);
  };

  return (
    <>
      <Modal
        show={props.showUploadPhoto}
        animation={true}
        onHide={() => closeUploadPhoto()}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 id="claim_listing_label">
              <i className="la la-plus"></i> Upload File
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <form action="/">
              <div className="form-group">
                <div className="col">
                  {" "}
                  <ImageUploader
                    imageRemover={imageRemover}
                    imageUploader={imageUploader}
                    options={{
                      acceptedFileTypes: `${
                        props.type === "photo" ? "image" : props.type
                      }/*`,
                      label: props.type,
                      allowFileTypeValidation: true,
                    }}
                  />
                  <div className="photo-upload-preview-container">
                    {image.map((item, index) => (
                      <div
                        className="photo-upload-preview-inner-container"
                        key={item.filename}
                      >
                        <div className="upload-photo-form">
                          <div className=" inner-button">
                            <div className="col-md-12">
                              <label id="tags"> </label>
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <input
                                  className="img-tag"
                                  style={{
                                    borderRadius: "2px 2px",
                                    height: "47px",
                                    background: "white",
                                    marginLeft: "130px",
                                    maxWidth: "234px",
                                  }}
                                  // key={item.filename}
                                  id={item.filename}
                                  name="title"
                                  type="text"
                                  onChange={onChangeHandler}
                                  placeholder="Title"
                                  value={
                                    image.find(
                                      (data) => data.filename === item.filename
                                    ).title
                                  }
                                />

                                <textarea
                                  rows="4"
                                  cols="30"
                                  className="img-tag"
                                  style={{
                                    borderRadius: "2px 2px",
                                    maxWidth: "234px",
                                    marginLeft: "130px",
                                  }}
                                  // key={item.filename}
                                  id={item.filename}
                                  name="description"
                                  type="text"
                                  onChange={onChangeHandler}
                                  placeholder="Description"
                                  value={
                                    image.find(
                                      (data) => data.filename === item.filename
                                    ).description
                                  }
                                />

                                <input
                                  className="img-tag"
                                  style={{
                                    borderTopRightRadius: "0px",
                                    borderBottomRightRadius: "0px",
                                    height: "47px",
                                    marginLeft: "130px",
                                    maxWidth: "210px",
                                  }}
                                  pattern="[A-Za-z0-9]"
                                  // key={item.filename}
                                  id={item.filename}
                                  name="tag"
                                  type="text"
                                  onChange={onChangeHandler}
                                  placeholder="Tags"
                                  value={
                                    image.find(
                                      (data) => data.filename === item.filename
                                    ).tag
                                  }
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      addTag(e, item.filename);
                                    }
                                  }}
                                />
                                <br />

                                <button
                                  style={{
                                    height: "48px",
                                    marginTop: "7px",
                                    borderRadius: "0px 0px",
                                    paddingLeft: "7px",
                                    paddingRight: "7px",
                                  }}
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => addTag(e, item.filename)}
                                >
                                  {" "}
                                  <i className="la la-plus"></i>
                                </button>
                              </div>
                              <div className="list-tags">
                                {error.find(
                                  (err) => err.filename === item.filename
                                ) && (
                                  <i>
                                    {" "}
                                    Error:{" "}
                                    {
                                      error.find(
                                        (err) => err.filename === item.filename
                                      ).error
                                    }
                                  </i>
                                )}
                                <p>
                                  <strong>
                                    {" "}
                                    {!!image.find(
                                      (tags) => tags.filename === item.filename
                                    ).tags.length &&
                                      "#" +
                                        image
                                          .find(
                                            (tags) =>
                                              tags.filename === item.filename
                                          )
                                          .tags.join("  #")}
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="album-preview-photo">
                          <div
                            //   onClick={() => toggleView("photo")}
                            className="single-thumb text-center"
                            //   key={key}
                          >
                            <div className="album-thumb-container">
                              {props.type === "video" ? (
                                <video controls style={{ height: "30%" }}>
                                  {" "}
                                  <source
                                    src={imageUrl(item.file)}
                                    type="video/mp4"
                                  />{" "}
                                </video>
                              ) : (
                                <div></div>
                              )}
                              {props.type !== "audio" &&
                              props.type !== "video" ? (
                                <img src={imageUrl(item.file)} alt="" />
                              ) : (
                                <div></div>
                              )}
                              {props.type === "audio" ? (
                                <div>
                                  <AudioPlayerCard
                                    audios={[imageUrl(item.file)]}
                                    albumImage={album.userAvatar}
                                    albumImageKey={album.userAvatarKey}
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>{" "}
                </div>
              </div>

              <button
                onClick={uploadPhoto}
                type="button"
                className="btn btn-secondary btn-sm mt-3"
              >
                Upload
              </button>
            </form>
          </Fragment>
        </Modal.Body>

        <Modal.Footer>
          {/* //<Button onClick={() => submitAgreement()} variant="secondary">
            Agree
          </Button>
         // <Button onClick={() => toggleModal(false)} variant="danger">
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listings: state.listings,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  uploadPhoto,
  uploadVideos,
  uploadAudios,
})(UploadImage);
