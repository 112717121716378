// import { connect } from "react-redux";
// import { getConversation } from "../redux/action/messaging";
// import store from "../redux/store";

import { store } from "../../redux/store";
import { getMessage, getConversation } from "../../redux/action/messaging";
import { incomingCall, callAnswered } from "../../redux/action/videoCalling";
import { getNotification } from "../../redux/action/notification";
import { paymentSuccessNotification } from "../../redux/action/payment";
import { loaduser } from "./../../redux/action/auth";

const socketProcessor = (socket, action, state) => {
  socket.on("MESSAGE_SENT", (data) => {
    let message = {
      id: data.id,
    };

    store.dispatch(getMessage(message));
    store.dispatch(getConversation());
  });
  socket.on("INCOMING_CALL", (data) => {
    console.log(data);
    store.dispatch(incomingCall(data));
    console.log("data");
  });

  socket.on("NOTIFICATION_PUSHED", () => {
    // let message = {
    //   id: data.id,
    // };

    store.dispatch(getNotification());
  });

  socket.on("CALL_ANSWERED", (data) => {
    store.dispatch(callAnswered());
  });

  socket.on("PAYMENT_SUCCEED", (data) => {
    store.dispatch(paymentSuccessNotification());
  });
  socket.on("CAPABILITY_UPDATED", (data) => {
    store.dispatch(loaduser());
  });
  // socket.on("GET_CONVERSATION", function (data) {
  //   console.log("Conversation Received");
  //   action.getConversation(data);
  //   let conversationId = store.getState().messaging.conversationId;
  //   if (conversationId !== "") {
  //     let message = data.filter(
  //       (conversation) => conversation.id === conversationId
  //     )[0];
  //     action.getMessage(message);
  //   }
  // });
  // socket.on("USER_TYPING", function (data) {
  //   action.userTyping(data);
  // });
  // socket.on("USER_STOP_TYPING", function (data) {
  //   action.userStopTyping(data);
  //   console.log("This User stops typing", data);
  // });
};

export default socketProcessor;
