import React, { useState } from "react";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { compensationType } from "../../../services/consts";
import { Link } from "react-router-dom";

var moment = require("moment");

export default function ProjectCardItem({
  id,
  listingImageUrl,
  title,
  label,
  projectEndDate,
  projectLocation,
  compType,
  compensation,
  uniqueKey,
  createdAt,
  cost,
  totalHours,
}) {
  const cardStyle = {
    marginLeft: "20px",
    marginTop: "20px",
    maxWidth: "300px",
    minWidth: "300px",
  };

  const projectListingStyle = {
    color: "teal",
    padding: "10px 0px",
  };
  const [copyLink, setCopyLink] = useState(false);

  const copyLinkHandler = (link) => {
    const webAddress =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
        ? "https://workshop.socicraft.us"
        : "https://workshop.socicraft.us";
    setCopyLink(true);

    setTimeout(() => {
      setCopyLink(false);
    }, 1500);
    navigator.clipboard.writeText(`${webAddress}${link}`);
  };

  return (
    <div
      // style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      key={uniqueKey}
    >
      <div>
        <>
          <Card className="account__listing-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={
                listingImageUrl.length > 0
                  ? listingImageUrl[0].url
                  : "/assets/img/p1.jpg"
              }
            />
            <CardContent
              style={{
                maxHeight: "250px",
                marginBottom: "10px",
              }}
            >
              <span>
                <h3>
                  {title.length >= 25 ? `${title.slice(0, 25)} ...` : title}{" "}
                </h3>
              </span>
              <br />

              <span>
                <h6>
                  {label.length >= 25 ? `${label.slice(0, 20)} ...` : label}
                </h6>
              </span>

              <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />

              <Typography variant="body2" color="text.secondary">
                <span style={projectListingStyle}>
                  <b>Expiration : </b>
                </span>{" "}
                {projectEndDate
                  ? moment(projectEndDate).format("MMM DD YYYY")
                  : "Continuous"}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <span style={projectListingStyle}>
                  {" "}
                  <b> Project Location : </b>
                </span>
                {projectLocation
                  ? `${projectLocation[0].toUpperCase()}${projectLocation.slice(
                      1
                    )}`
                  : "Not Available"}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <span style={projectListingStyle}>
                  {" "}
                  <b>Compensation Type : </b>
                </span>
                {compensationType[compType]}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <span style={projectListingStyle}>
                  {" "}
                  <b> Compensation : </b>
                </span>
                {compType !== "nonPaid" ? (
                  <i className="fa fa-dollar"></i>
                ) : null}
                {compType !== "nonPaid" ? compensation : "N/A"}{" "}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <span style={projectListingStyle}>
                  {" "}
                  <b> Total Hours : </b>
                </span>
                {compType === "ratePerHour" ? totalHours : "N/A"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <span style={projectListingStyle}>
                  {" "}
                  <b> Total Cost : </b>
                </span>
                {compType !== "nonPaid" ? (
                  <i className="fa fa-dollar"></i>
                ) : null}
                {compType !== "nonPaid" ? compensation : "N/A"}{" "}
              </Typography>

              <Typography
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
                variant="body2"
                color="text.secondary"
              >
                <h6>Posted on {moment(createdAt).format("MMM Do YY")}</h6>
              </Typography>
            </CardContent>
            <div className="d-flex p-0 justify-content-center gap-5">
              <Link
                to={`/view-listing-details/${id}`}
                className="btn  btn-secondary m-2"
              >
                See More
              </Link>{" "}
              <button
                disabled={copyLink}
                onClick={() => copyLinkHandler(`/view-listing-details/${id}`)}
                className="btn btn-info m-2"
              >
                {copyLink ? (
                  <span className="d-flex align-items-center justify-content-center ">
                    <i className="fa fa-info-circle"></i>{" "}
                    <span className="pl-1"> Copied</span>
                  </span>
                ) : (
                  <>
                    {" "}
                    <span className="d-flex align-items-center justify-content-center ">
                      <i className="fa fa-link"></i>{" "}
                      <span className="pl-3">Copy</span>
                    </span>
                  </>
                )}
              </button>
            </div>
          </Card>
        </>
      </div>
    </div>
  );
}
