import {
  GET_MESSAGE,
  GET_CONVERSATION,
  GET_USERS_LIST,
  USER_TYPING,
  USER_STOP_TYPING,
  USER_SEARCH_COMPLETED,
  USER_SEARCH_INITIATED,
  TOGGLE_CONVERSATION_LIST,
} from "./types";

import API from "../../api/api";

export const getConversation = (data) => async (dispatch) => {
  try {
    let conversations = await API.get("/message/conversation", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({
      type: GET_CONVERSATION,
      payload: conversations.data ? conversations.data : [],
    });
  } catch (error) {
    // Handle Error send of notification
  }
};

//*************************** GET MESSAGE  ***************************//

export const getMessage = (message) => async (dispatch) => {
  try {
    let response = await API.get(`/message/conversation/${message.id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({
      type: GET_MESSAGE,
      payload: response.data,
    });
  } catch (error) {
    console.log(error.message);
    console.log("Unable to Load Messaging ");
  }

  //
};

//***************************** END  *******************************//

//*************************** SEND MESSAGE   ***************************//

export const sendMessage = (messageContent) => async (dispatch) => {
  try {
    let formData = new FormData();
    formData.append('message', messageContent.message);
    formData.append('conversationId', messageContent.conversationId);
    // const body = {
    //   message: messageContent.message,
    //   conversationId: messageContent.conversationId,
    // };
    await API.post(
      `/message/sendMessage?id=${messageContent.conversationId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    //TODO Check if the user active on that thread and call getMessage unless do nothing

    dispatch(getMessage({ id: messageContent.conversationId }));
  } catch (error) {
    console.log("Unable to Load Messaging ");
  }

  //
};

export const sendMessageForFiles = (messageContent, dispatch) => {
  try {
    let formData = new FormData();
    formData.append('conversationId', messageContent.conversationId);
    for (let i=0; i<messageContent.attachement.length; i++) {
      console.log(messageContent.attachement[i].file)
      formData.append('attachement', messageContent.attachement[i].file);
    }
    formData.append('fileType', messageContent.FileType);
    formData.append('message', messageContent.message ? messageContent.message : '');
    // const body = {
    //   conversationId: messageContent.conversationId,
    //   attachement: formData,
    //   FileType: messageContent.FileType
    // };
     API.post(
      `/message/sendMessage?id=${messageContent.conversationId}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    //TODO Check if the user active on that thread and call getMessage unless do nothing

    setTimeout(() => {
      dispatch(getMessage({ id: messageContent.conversationId }));
    }, 4000);
    
  } catch (error) {
    console.log("Unable to Load Messaging ");
  }

  //
};

//***************************** END  *******************************//

export const getUsers = () => async (dispatch) => {
  let response = await API.get(`/message/get-users`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-auth-user": localStorage.getItem("token"),
    },
  });

  dispatch({ type: GET_USERS_LIST, payload: response.data });
};
export const createConversation = () => async (dispatch) => {
  let response = await API.get(`/message/get-users`, {
    withCredentials: true,

    headers: {
      "Content-Type": "application/json",
      "x-auth-user": localStorage.getItem("token"),
    },
  });

  dispatch({ type: GET_USERS_LIST, payload: response.data });
};
export const createThread = (toUser, fromUser) => async (dispatch) => {
  let threadBody = {
    fromUserName: fromUser.firstName + " " + fromUser.lastName,
    toUserId: toUser._id,
    toUserName: toUser.firstName + " " + toUser.lastName,
  };

  let response = await API.post(`/message`, threadBody, {
    withCredentials: true,

    headers: {
      "Content-Type": "application/json",
      "x-auth-user": localStorage.getItem("token"),
    },
  });

  dispatch(getConversation());
  dispatch({
    type: GET_MESSAGE,
    payload: {
      messages: [],
      title: toUser.userName,
      conversationId: response.data.id,
    },
  });
};
export const searchUser = (searchTerm) => async (dispatch) => {
  dispatch({
    type: USER_SEARCH_INITIATED,
  });
  let response = await API.get(
    `/message/user/search?searchTerm=${searchTerm}`,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    }
  );
  dispatch({
    type: USER_SEARCH_COMPLETED,
    payload: response.data,
  });
};

export const userTyping = (data) => async (dispatch) => {
  dispatch({
    type: USER_TYPING,
    payload: data,
  });
};
export const userStopTyping = (data) => async (dispatch) => {
  dispatch({
    type: USER_STOP_TYPING,
    payload: data,
  });
};

//***************************  Conversation List Toggler  ***************************//

export const toggleConversationList = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_CONVERSATION_LIST,
    });
  } catch (error) {
    console.log(error.message);
  }

  //
};

//***************************** END  *******************************//
//*************************** Clear  Conversation  ***************************//

export const clearConversation = (id) => async (dispatch) => {
  try {
    await API.put(`/message/clear-conversation/${id}`, {
      withCredentials: true,

      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(getMessage({ id }));
  } catch (error) {
    console.log(error.message);
  }

  //
};

//***************************** END  *******************************//
//*************************** Delete  Conversation  ***************************//

export const deleteConversation = (id) => async (dispatch) => {
  try {
    await API.delete(`/delete-conversation/${id}`, {
      withCredentials: true,

      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log(error.message);
  }

  //
};

//***************************** END  *******************************//
