export const compensationType = {
  ratePerHour: "Rate Per Hour",
  flatRate: "Flat Rate",
  nonPaid: "Non Paid",
};
export const ProjectStatusStepper = {
  PENDING: {
    label: "Pending",
    step: 0,
  },
  ACCEPTED: { label: "Accepted", step: 1 }, //2
  PAYMENT_RECEIVED: { label: "Payment Received", step: 2 },
  WAITING_DOCUMENT: { label: "Waiting Document", step: 3 },
  HIRED: { label: "Hired", step: 4 },
  IN_PROGRESS: { label: "Inprogress", step: 5 },
  PENDING_REVIEW: { label: "Pending Review", step: 6 },
  REOPENED: { label: "Project ReOpened", step: 7 },
  APPROVED: { label: "Approved", step: 8 },
};
export const ProjectStatus = {
  PENDING: {
    label: "Pending",
    step: 0,
  },
  ACCEPTED: { label: "Accepted", step: 1 }, //2
  DECLINED: { label: "Declined", step: 1 }, //2
  SCREENING: { label: "Screening", step: 1 }, //2
  PAYMENT_RECEIVED: { label: "Payment Received", step: 2 },
  WAITING_DOCUMENT: { label: "Waiting Document", step: 3 },
  HIRED: { label: "Hired", step: 4 },
  IN_PROGRESS: { label: "Inprogress", step: 5 },
  PENDING_REVIEW: { label: "Pending Review", step: 6 },
  REOPENED: { label: "Project ReOpened", step: 7 },
  APPROVED: { label: "Approved", step: 8 },
};
export const contentType = {
  photoalbum: "imageAlbums",
  audioalbum: "audioAlbums",
  videoalbum: "videoAlbums",
};

export const FileType = {
  photo: "photo",
  audio: "audio",
  video: "video",
};

export const FileUploaderLabel = {
  photo: "Upload Photo",
  audio: "Upload Audio",
  video: "Upload Video",
};

export const FileValidationType = {
  photo: ["image/*"],
  audio: ["audio/*"],
  video: ["video/*"],
};

export const searchCategories = [
  "Art",
  "Culinary",
  "Cosmetics",
  "Fashion",
  "Film & Video",
  "Dance",
  "Literature",
  "Management",
  "Media communication & Tech",
  "Music",
].sort();
export const categories = {
  Art: false,
  Culinary: false,
  Cosmetics: false,
  Fashion: false,
  FilmandVideo: false,
  Dance: false,
  Literature: false,
  Management: false,
  MediacommunicationandTech: false,
  Music: false,
};
export const stripeRequiredFields = {
  "individual.address.city": "City",
  "individual.address.postal_code": "Zipcode",
  "individual.address.state": "State",
  "individual.dob.day": "DOB Day",
  "individual.dob.month": "DOB Month",
  "individual.dob.year": "DOB Year",
};
export const stateListDeprecated = [
  "AL Alabama",
  "AK Alaska",
  "AS American Samoa",
  "AZ Arizona",
  "AR Arkansas",
  "CA California",
  "CO Colorado",
  "CT Connecticut",
  "DE Delaware",
  "DC District Of Columbia",
  "FM Federated States Of Micronesia",
  "FL Florida",
  "GA Georgia",
  "GU Guam",
  "HI Hawaii",
  "ID Idaho",
  "IL Illinois",
  "IN Indiana",
  "IA Iowa",
  "KS Kansas",
  "KY Kentucky",
  "LA Louisiana",
  "ME Maine",
  "MH Marshall Islands",
  "MD Maryland",
  "MA Massachusetts",
  "MI Michigan",
  "MN Minnesota",
  "MS Mississippi",
  "MO Missouri",
  "MT Montana",
  "NE Nebraska",
  "NV Nevada",
  "NH New Hampshire",
  "NJ New Jersey",
  "NM New Mexico",
  "NY New York",
  "NC North Carolina",
  "ND North Dakota",
  "MP Northern Mariana Islands",
  "OH Ohio",
  "OK Oklahoma",
  "OR Oregon",
  "PW Palau",
  "PA Pennsylvania",
  "PR Puerto Rico",
  "RI Rhode Island",
  "SC South Carolina",
  "SD South Dakota",
  "TN Tennessee",
  "TX Texas",
  "UT Utah",
  "VT Vermont",
  "VI Virgin Islands",
  "VA Virginia",
  "WA Washington",
  "WV West Virginia",
  "WI Wisconsin",
  "WY Wyoming",
].sort();
export const stateList = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
].sort();
export const socialMedias = [
  "behance",
  "dribbble",
  "facebook",
  "flickr",
  "github",
  "google-plus",
  "instagram",
  "linkedin",
  "pinterest",
  "reddit",
  "snapchat-ghost",
  "soundcloud",
  "stack-overflow",
  "tumblr",
  "twitter",
  "vimeo",
  "vine",
  "youtube",
];

export const searchSecurityQuestions = [
  "What is your favorite book?",
  "What is the name of the road you grew up on?",
  "What is your mother’s maiden name?",
  "What was the name of your first/current/favorite pet?",
  "What was the first company that you worked for?",
  "Where did you meet your spouse?",
  "Where did you go to high school/college?",
  "What is your favorite food?",
  "What city were you born in?",
  "Where is your favorite place to vacation?",
].sort();

export const reasonForJoining = [
  "Looking for next Gig",
  "Learn Tips & Tricks for craft",
  "Share Tips & Tricks",
  "Inspiration",
  "Collaboration",
  "Entertainment & Discovery",
  "Working on craft",
  "Need work experience",
  "Academia - Student",
  "Academia - Teacher",
];

export const mockUsers = [
  {
    userName: "Walt Disney",
    tags: ["crafts", "fishing"],
    yearsOfExperience: 5,
    city: "Toontown",
    state: "CA",
    id: 1,
  },

  {
    userName: "Roger Rabbit",
    tags: ["crafts", "fishing"],
    yearsOfExperience: 3,
    city: "Toontown",
    state: "CA",
    id: 2,
  },
  {
    userName: "Mickey Mouse",
    tags: ["crafts", "fishing"],
    yearsOfExperience: 10,
    city: "Disney",
    state: "CA",
    id: 3,
  },
  {
    userName: "Minnie Mouse",
    tags: ["crafts", "fishing"],
    yearsOfExperience: 1,
    city: "Los Angelas",
    state: "CA",
    id: 4,
  },
  {
    userName: "Donald Duck",
    tags: ["crafts", "fishing"],
    yearsOfExperience: 4,
    city: "Toontown",
    state: "CA",
    id: 5,
  },
];
export const estimatedHours = {
  under4hours: "Under 4 Hours",
  "5+ hours / day": "5+ hours / day",
};
export const positions = ["Remote", "In-Person"];

export const workType = ["Full-Time", "Part-Time", "Consultant", "Internship"];

export const jobListings = [
  {
    data: [
      {
        company: "Google",
        title: "Software Engineer",
        post_date: "09/20/2003",
      },
      {
        company: "Apple",
        title: "Software Engineer",
        post_date: "04/20/2020",
      },
    ],
  },
];

export const UrlParamMapping = {
  profile: "?t=profile",
  myListings: "?t=myListings",
  favoriteListing: "?t=favoriteListing",
  appliedProjects: "?t=appliedProjects",
  applicantList: "?t=applicantList",
  projectActivity: "?t=projectActivity",
  documents: "?=documents",
  earnings: "?t=earnings",
};
export const URL_MAPPING_PROJECT_ACTIVITY_SUB1 = {
  1: "?t=projectActivity&st=pendingProjects",
  2: "?t=projectActivity&st=cart",
  // 3: "?t=projectActivity&st=paidProjects",
  // 4: "?t=projectActivity&st=waitingForSignature",
  // 5: "?t=projectActivity&st=signedDocuments",
};
export const URL_MAPPING_DOCUMENTS_SUB1 = {
  1: "?t=documents&st=paidProjects",
  2: "?t=documents&st=waitingForSignature",
  3: "?t=documents&st=signedDocuments",
};
export const URL_MAPPING_EARNINGS_SUB1 = {
  1: "?t=earnings&st=earnings",
  2: "?t=earnings&st=invoice",
  3: "?t=earnings&st=taxInfo",
};

export const navigationMap = new Map();
navigationMap.set("saveforlater", "saveforlater");
navigationMap.set("home", "home");
navigationMap.set("comments", "comments");
navigationMap.set("votes", "votes");
