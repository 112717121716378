import {
  LOADINGSTARTED,
  LOADINGCOMPLETED,
  DOCUSIGN_LOADING,
} from "../action/types";

const initialState = {
  isloading: false,
  docusign_loading: false,
};

const processorControl = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADINGCOMPLETED:
      return {
        ...state,
        isloading: false,
      };

    case LOADINGSTARTED:
      return {
        ...state,
        isloading: true,
      };
    case DOCUSIGN_LOADING:
      return {
        ...state,
        docusign_loading: payload,
      };
    default:
      return state;
  }
};

export default processorControl;
