import React, { useEffect } from "react";
import Header from "../layout/header";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListingCraftInfo from "./listing-craft-info";
import ContactInfoPrivate from "./contactInfo";
import { Supporters } from "./supporters";
import { Video } from "./video";
import Gallery from "./gallery";
import ProjectListing from "./projectListings";
import { Audio } from "./audio";
import Certifications from "./certifications";
import { getCraftProfilePublic } from "../../redux/action/public";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import SignIn from "../content/modal/login/signin";
import { useState } from "react";
import { Rating } from "@mui/material";

const ViewProfile = ({ match, getCraftProfilePublic }) => {
  const [openSignIn, setOpenSignIn] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const toggleApply = () => {
    if (isAuthenticated) {
      setShowApply(!showApply);
    } else if (!isAuthenticated) {
      setOpenSignIn(!openSignIn);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const userName = match.params.username;
    getCraftProfilePublic(userName);
  }, [dispatch, match, getCraftProfilePublic]);

  const { user } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  const image = "../../../public/assets/img/author-profile.jpg";
  const { listings } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );
  //const userListings = useSelector(state=>state.userListing.userListings);

  const { headerImage } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );
  const getAverageRatings = (user) => {
    console.log(user);
    if (user && user.ratings.length) {
      const totalRatings = user.ratings.reduce((a, b) => a + b.rate, 0);
      return totalRatings / user.ratings.length;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Fragment>
        {/* Header section start */}
        <section className="header-breadcrumb bgimage overlay overlay--dark">
          <div className="mainmenu-wrapper">
            <Header class="menu--light" toggleApply={toggleApply} />
          </div>
          {/* <!-- ends: .mainmenu-wrapper --> */}
          <div
            className="breadcrumb-wrapper content_above"
            style={{
              backgroundImage: `url('${headerImage}')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      {/* <li className="breadcrumb-item">
                      <NavLink to="/">Home</NavLink>
                    </li> */}
                      {/* <li className="breadcrumb-item active" aria-current="page">
                      {this.props.title}
                    </li> */}
                    </ol>
                  </nav>
                  <div
                    style={{ visibility: "profile-view" }}
                    className="custom-file"
                  ></div>
                  {/* <CreateAlbum
                        showCreateAlbum={showCreateAlbum}
                        type={"headerImage"}
                        toggleCreateAlbum={toggleCreateAlbum}
                      /> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="directory_listiing_detail_area single_area section-bg p-top-30 p-bottom-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="atbd_auhor_profile_area">
                  <div className="col-lg-2">
                    <div className="atbd_author_avatar">
                      <div className="col">
                        <img
                          width="120px"
                          height="120px"
                          src={
                            user && user.userAvatar ? user.userAvatar : image
                          }
                          alt="profile"
                          id="image"
                          className="profile-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <h2>
                      {user && user.firstName} {user && user.lastName}
                    </h2>
                    <p style={{ margin: "0px" }}>
                      {" "}
                      <strong> {user && user.userName}</strong>{" "}
                    </p>
                    <div className="btn  btn-shadow-socicraft mt-3  text-dark font-weight-bold">
                      Listed Projects{" "}
                      <span className="badge badge-light ml-3  ">
                        {listings && listings.length}
                      </span>
                    </div>{" "}
                    <div className="btn btn-shadow-socicraft mt-3 text-dark font-weight-bold">
                      Achieved Projects{" "}
                      <span className="badge badge-light ml-3 disabled ">
                        {user && user.achievements.completedProjects}
                      </span>
                    </div>{" "}
                    <div className="btn btn-shadow-socicraft mt-3 text-dark font-weight-bold">
                      Paid Projects{" "}
                    </div>{" "}
                    <div className="btn btn-shadow-socicraft mt-3  text-dark font-weight-bold">
                      <Rating
                        sx={{ paddingTop: "10px" }}
                        name="read-only"
                        value={getAverageRatings(user)}
                        readOnly
                      />
                      <span className="badge badge-light ml-3 disabled ">
                        {user && user.ratings.length}
                      </span>
                    </div>{" "}
                  </div>
                  {/* <div className="col-lg-3">
                        <div className="silver-awarded"></div>
                      </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {/* MAINBAR */}
              <div className="col-lg-8">
                <ListingCraftInfo view="view-profile" />
              </div>
              <div>
                <br />
              </div>

              {/* SIDEBAR */}
              <div className="col-lg-4" onClick={() => toggleApply()}>
                <ContactInfoPrivate
                  viewType="view-profile"
                  isAuthenticated={isAuthenticated}
                />
                <Supporters
                  viewType="view-profile"
                  isAuthenticated={isAuthenticated}
                />
              </div>
              <SignIn toggleSignIn={toggleApply} modalOpen={openSignIn} />
              <div className="col-lg-12">
                <Video />
                <Gallery
                  viewType="view-profile"
                  isAuthenticated={isAuthenticated}
                />
                <Audio
                  viewType="view-profile"
                  isAuthenticated={isAuthenticated}
                />
                <Certifications
                  viewType="view-profile
                      isAuthenticated={isAuthenticated}
                    />
                    <ProjectListing
                      viewType="
                  view-profile
                  isAuthenticated={isAuthenticated}
                />{" "}
                <ProjectListing />
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    </>
  );
};

ViewProfile.propTypes = {
  getCraftProfilePublic: PropTypes.func,
};

export default connect(null, { getCraftProfilePublic })(ViewProfile);
