import React from "react";
import "./container.css";
import SwipeableButton from "./swipeButton";
import Contractors from "./contractorsInfo";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Chip, ListItemAvatar } from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";
import { Avatar, ListItemButton } from "@mui/material";
import RatingModal from "../modal/ratingModal";
function Container({
  appliedListing,
  startJobHandler,
  updateStartProjectHandle,
  handleReopenModal,
  handleOpenRatingModal,
  currentApplicant,
  handleApproveProject,
}) {
  console.log(currentApplicant);
  return (
    <div className="swipe-button-container">
      <div className="job-info-block">
        {" "}
        <div className="d-flex justify-content-center">
          {" "}
          <h1> Review and Approve </h1>
          <br />
        </div>{" "}
        <div className="d-flex justify-content-center">
          <div
            style={{
              width: "100%",
              maxWidth: 360,
              position: "relative",
              // overflow: "auto",
              maxHeight: 600,
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <List style={{ marginBottom: "50px" }}>
              {[
                "Did you review your expectations and requirements with the contractor?",
                "Using the Socicraft Chat feature, did you communicate in a timely and effective manner?",
                "Was feedback given to the contractor to resolve any raised issues?",
                "Did you receive services and/or deliverables?",
              ].map((item) => (
                <ListItem>
                  {" "}
                  <ListItemAvatar>
                    <Avatar>
                      <DoneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item} />
                </ListItem>
              ))}{" "}
              {currentApplicant &&
                !currentApplicant.applicant.document.jobInformation
                  .reopened && (
                  <ListItem>
                    <button
                      className="btn btn-danger"
                      onClick={handleReopenModal}
                    >
                      Reopen
                    </button>
                  </ListItem>
                )}
            </List>
          </div>
        </div>
      </div>
      <div className="swipe-button-block">
        <SwipeableButton
          color="#33cccc"
          handleRatingModal={handleOpenRatingModal}
          handleApproveProject={handleApproveProject}
          currentApplicant={currentApplicant}
        />
      </div>{" "}
    </div>
  );
}

export default Container;
