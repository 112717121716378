import React, { Fragment, useState, useRef } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import { connect } from "react-redux";
import { BreadcrumbDashboard } from "../content/element/breadcrumb";
import Gallery from "../content/carousel/gallery";
import ListingCraftInfo from "./listing-craft-info";
import { Video } from "./video";
import { Audio } from "./audio";
import ContactInfoPrivate from "../content/element/contact-info";
import { Supporters } from "../content/element/widget";
import Report from "../content/modal/report";
import Clime from "../content/modal/clime-list";
import ProjectListing from "./projectListings";
import Certifications from "./certifications";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// import mp3 from './assets/audio/Big_O_Opening_US_Version.mp3';

const Profile = (props) => {
  const myRef = useRef(null);
  const userId = useSelector((state) => state.auth.user.id);

  const userListings = useSelector((state) => state.userListing.userListings);

  const light = props.logo[0].light;

  let firstName = props.user ? props.user.firstName : "";
  let lastName = props.user ? props.user.lastName : "";
  let userName = props.user ? props.user.userName : "";

  const [image] = useState("./assets/img/author-profile.jpg");

  const componentName = "profile";

  return (
    <div>
      <Fragment>
        {/* Header section start */}
        <section className="header-breadcrumb bgimage overlay overlay--dark">
          <div className="bg_image_holder">
            <img src="" alt="" />
          </div>
          <div className="mainmenu-wrapper">
            <Header
              logo={light}
              class="menu--light"
              componentName={componentName}
            />
          </div>
          {/* <!-- ends: .mainmenu-wrapper --> */}
          <BreadcrumbDashboard
            title="Craft Profile"
            userId={userId ? userId : null}
          />
        </section>
        {/* Header section end */}

        <section className="directory_listiing_detail_area single_area section-bg p-top-30 p-bottom-100 craft__profile-background">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="atbd_auhor_profile_area ">
                  <div className="col-lg-2">
                    <div className="atbd_author_avatar">
                      <div className="col">
                        <img
                          width="120px"
                          height="120px"
                          src={
                            props.user && props.user.userAvatar
                              ? props.user.userAvatar
                              : image
                          }
                          alt="profile"
                          id="image"
                          className="profile-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-lg-6">
                    <h2>
                      {firstName} {lastName}{" "}
                    </h2>
                    <div>
                      <p style={{ margin: "0px" }}>{userName}</p>
                    </div>
                    <div className="btn btn-primary mt-3">
                      Listed Projects{" "}
                      <span className="badge badge-light ml-3 disabled ">
                        {userListings ? userListings.length : 0}
                      </span>
                    </div>{" "}
                    <div className="btn btn-primary mt-3">
                      Completed Projects{" "}
                      <span className="badge badge-light ml-3 disabled ">
                        {props.user.achievements.completedProjects}
                      </span>
                    </div>{" "}
                    <div className="btn btn-primary mt-3">
                      Paid Projects{" "}
                      <span className="badge badge-light ml-3 disabled ">
                        {props.user.achievements.completedProjects}
                      </span>
                    </div>{" "}
                  </div>
                  <div className="mt-3">
                    <Link
                      to={`/${userName}`}
                      className="btn btn-xs btn-gradient btn-gradient-one  mt-5"
                    >
                      View Profile
                    </Link>
                  </div>
                  <div>{/* <div className="silver-awarded">fg</div> */}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <ListingCraftInfo userId={userId} />
              </div>
              <div>
                <br />
              </div>

              {/* SIDEBAR */}
              <div className="col-lg-4">
                <ContactInfoPrivate />
                <Supporters props={props.user} />
              </div>

              <div className="col-lg-12">
                <Video userId={userId} />
                <Gallery userId={userId} />
                <Audio userId={userId} />
                <Certifications userId={userId} />
                <ProjectListing ref={myRef} />
              </div>
            </div>
          </div>
        </section>
        <Report />
        <Clime />
      </Fragment>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    user: state.auth.user,
    userListing: state.userListing,
  };
};
export default connect(mapStateToProps, {})(Profile);
