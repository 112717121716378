import React, { Component, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { searchCategories } from "../../../services/consts.js";
import { Grid, Box } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import { connection } from "../../../redux/action/connection.js";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import { Link } from "react-router-dom";
import { completeOnboarding } from "../../../redux/action/stripe.js";

const noAction = (e) => e.preventDefault();

const array = searchCategories;
const Items = array.map((item) => (
  // <li><NavLink onClick={noAction} to="/at_demo">{item}</NavLink></li>
  <option>{item}</option>
));

class SellerInfo extends Component {
  handleOnboarding = () => {
    if (
      this.props.user &&
      !this.props.user.connectedAccounts.stripe.onboardingCompleted
    ) {
      this.props
        .completeOnboarding()
        .then((data) => {
          const { url } = data;
          window.open(url, "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  render() {
    return (
      <Fragment>
        <div
          style={{
            paddingLeft: "3rem",
            paddingRight: "2rem",
            paddingTop: "0rem",
          }}
          className="widget-body atbd_author_info_widget"
        >
          <div className="atbd_avatar_wrapper">
            {/* <div className="atbd_review_avatar">
                            <img src="./assets/img/avatar-60x60.jpg" alt="AvatarImage" />
                        </div> */}
            <div className="atbd_name_time">
              {/* <h4>
                {this.props.listing.firstname} {this.props.listing.lastname}
                <span
                  className="verified"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Verified"
                ></span>
              </h4> */}
              <span className="review_time">
                {/* // Posted {dateDifference} days ago */}
              </span>
            </div>
          </div>
          {/* <!-- ends: .atbd_avatar_wrapper --> */}
          <div className="atbd_widget_contact_info">
            <ul>
              {/* <li>
                                <span className="la la-phone"></span>
                                <span className="atbd_info">(213) 995-7799</span>
                            </li>
                            <li>
                                <span className="la la-envelope"></span>
                                <span className="atbd_info">widget@aazztech.com</span>
                            </li>
                            <li>
                                <span className="la la-globe"></span>
                                <NavLink to="/at_demo" className="atbd_info" onClick={noAction}>www.aazztech.com</NavLink>
                            </li> */}
            </ul>
          </div>
          {/* <!-- ends: .atbd_widget_contact_info --> */}
          {/* <div className="atbd_social_wrap">
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-facebook-f"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-twitter"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-google-plus-g"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-linkedin-in"></span></NavLink></p>
                        <p><NavLink to="/at_demo" onClick={noAction}><span className="fab fa-instagram"></span></NavLink></p>
                    </div> */}
          {/* <!-- ends: .atbd_social_wrap --> */}
          {(this.props.user &&
            !this.props.user.connectedAccounts.stripe.onboardingCompleted) ||
          (this.props.user && !this.props.user.emailVerified) ? (
            <Box textAlign="center">
              {" "}
              <Button variant="contained" disabled alignSelf="center">
                Apply
              </Button>
            </Box>
          ) : (
            <button
              className={
                this.props.listingDetails.applied
                  ? "btn btn-gradient btn-gradient-three btn-block btn-disabled"
                  : "btn btn-gradient btn-gradient-two btn-block"
              }
              disabled={this.props.listingDetails.applied}
            >
              <a
                href="#/"
                data-toggle={this.props.isAuthenticated ? null : "modal"}
                data-target={
                  this.props.isAuthenticated ? null : "#/login_modal"
                }
                onClick={() => {
                  !this.props.listingDetails.applied &&
                    this.props.toggleApply();
                }}
              >
                <div>
                  {/* If applied change the color to green */}
                  {this.props.listingDetails.applied ? "Applied" : "Apply"}
                </div>
              </a>{" "}
            </button>
          )}
          {this.props.user && !this.props.user.emailVerified && (
            <div
              className="alert alert-warning mt-1 d-flex flex-column"
              role="alert"
            >
              <p className="text-center">
                We have sent verification link to Your Email, click the link and
                verify your email to start onboarding process.
              </p>
            </div>
          )}
          {this.props.user &&
            this.props.user.emailVerified &&
            !this.props.user.connectedAccounts.stripe.onboardingCompleted && (
              <div
                className="alert alert-warning mt-1 d-flex flex-column"
                role="alert"
              >
                <p className="text-center">
                  Compete Onboarding and Start Earning Money
                </p>
                <button
                  className="btn btn-primary m-0"
                  onClick={this.handleOnboarding}
                >
                  {" "}
                  Complete Onboarding{" "}
                </button>
              </div>
            )}

          <div className="btn btn-block font-weight-bold">
            {" "}
            <a
              rel="noopener noreferrer"
              href={"https://workshop.socicraft.us/" + this.props.userName}
              target="_blank"
            >
              {" "}
              View Profile
            </a>
          </div>
        </div>{" "}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  appliedListings: state.userListing.appliedListings,
  listingDetails: state.userListing.listingDetails,
});

export default connect(mapStateToProps, { completeOnboarding })(SellerInfo);
export class BusinessInfo extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget atbd_widget widget-card">
          <div className="atbd_widget_title">
            <h4>
              <span className="la la-clock-o"></span> Business Hours
            </h4>
            <span className="badge badge-success">Open Now</span>
          </div>
          {/* <!-- ends: .atbd_widget_title --> */}
        </div>
      </Fragment>
    );
  }
}

export class CategoryWithIcon extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget atbd_widget widget-card">
          <div className="atbd_widget_title">
            <h4>
              <span className="la la-bookmark"></span> Category with Icon
            </h4>
          </div>
          {/*<!-- ends: /.atbd_widget_title -->*/}
          <div className="widget-body atbdp-widget-categories">
            <ul className="atbdp_parent_category">
              <li>
                <NavLink onClick={noAction} to="/at_demo">
                  <span className="la la-money"></span>
                  Business
                </NavLink>
              </li>
              <li>
                <NavLink onClick={noAction} to="/at_demo">
                  <span className="la la-heartbeat"></span>
                  Health Care
                </NavLink>
              </li>
              <li>
                <NavLink onClick={noAction} to="/at_demo">
                  <span className="la la-laptop"></span>
                  Technology
                </NavLink>
              </li>
              <li>
                <NavLink onClick={noAction} to="/at_demo">
                  <span className="la la-eject"></span>
                  Conference
                </NavLink>
                <span className="cat-trigger"></span>
                <ul className="atbdp_child_category">
                  <li>
                    <NavLink to="/at_demo">Event</NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo">Meeting</NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo">Sports</NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo">Business</NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/at_demo">
                  <span className="la la-leaf" onClick={noAction}></span>
                  Agriculture
                </NavLink>
              </li>
              <li>
                <NavLink to="/at_demo">
                  <span className="la la-calculator" onClick={noAction}></span>
                  Food and Fitness
                </NavLink>
              </li>
            </ul>
          </div>
          {/*<!-- ends: .atbdp -->*/}
        </div>
        {/*<!-- ends: .widget -->                */}
      </Fragment>
    );
  }
}

export class TagStyle extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget atbd_widget widget-card">
          <div className="atbd_widget_title">
            <h4>
              <span className="la la-tags"></span> Tag Style
            </h4>
          </div>
          <div className="widget-body atbdp-widget-tags">
            <ul className="list-unstyled">
              <div className="form-group">
                <select className="form-control" id="exampleFormControlSelect1">
                  {Items}
                </select>
              </div>

              {/* <li><NavLink onClick={noAction} to="/at_demo">New York</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Car Parking</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Food</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Travelling</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Home</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Restaurant</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Place</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Office</NavLink></li>
                            <li><NavLink onClick={noAction} to="/at_demo">Bike Parking</NavLink></li> */}
            </ul>
          </div>
          {/*<!-- ends: .widget-body -->*/}
        </div>
        {/*<!-- ends: .widget -->                */}
      </Fragment>
    );
  }
}

export const Supporters = ({ user }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user.id);
  useEffect(() => {
    dispatch(connection("", "", "", userId));
  }, [dispatch, userId]);

  const connections = useSelector(
    (state) => state.connections.showConnection.connections
  );

  return (
    <Fragment>
      <div
        style={{ marginTop: "30px" }}
        className="widget atbd_widget widget-card"
      >
        <div className="atbd_widget_title">
          <h4>Connections</h4>
          <span className="badge badge-light  disabled ">
            {connections && connections.length}
          </span>
          <Link
            to={`/view-network-list/${userId}`}
            className="btn btn-xs ml-5 btn-gradient btn-gradient-two"
          >
            <span className="element_widget_details"> Details</span>
          </Link>
        </div>
        <div
          style={{
            maxHeight: "232px",
            overflow: "scroll",
            overflowX: "hidden",
          }}
          className="widget-body atbdp-widget-tags"
        >
          {connections &&
            connections.map((user) => {
              return (
                <ul styles="list-style-type:none;">
                  <Grid
                    container
                    justify="center"
                    direction="row"
                    alignItems="flex-start"
                  >
                    <Avatar
                      alt="user avatar"
                      src={
                        user.userId
                          ? user.userId.userAvatar
                          : "./assets/img/avatar-60x60.jpg"
                      }
                      sx={{ width: 56, height: 56, mt: 3 }}
                      style={{ marginTop: "16px" }}
                    />

                    <Grid
                      item={true}
                      xs={7}
                      style={{ marginTop: "35px", marginLeft: "20px" }}
                    >
                      {user.userId.firstName} {user.userId.lastName}
                    </Grid>
                  </Grid>
                </ul>
              );
            })}
        </div>
        {/*<!-- ends: .widget-body -->*/}
      </div>
      {/*<!-- ends: .widget -->                */}
    </Fragment>
  );
};

export class ViewProfile extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget atbd_widget widget-card">
          <div className="atbd_widget_title">
            <h4>
              <span className="la la-tags"></span> Want More?{" "}
            </h4>
          </div>
          <div className="widget-body atbdp-widget-tags">
            <ul className="list-unstyled">
              <NavLink to="/author-profile">View Author Profile</NavLink>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class RecentlyViewed extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget atbd_widget widget-card">
          <div className="atbd_widget_title">
            <h4>
              <span className="la la-tags"></span> Recently Viewed
            </h4>
          </div>
          <div className="widget-body atbdp-widget-tags">
            <ul className="list-group">
              <li className="list-group-item no-card-border">
                <h4>Title1</h4>
                <p>description1</p>
              </li>
              <br />
              <li className="list-group-item no-card-border">
                <h4>Title2</h4>
                <p>description2</p>
              </li>
            </ul>
          </div>
          {/*<!-- ends: .widget-body -->*/}
        </div>
        {/*<!-- ends: .widget -->                */}
      </Fragment>
    );
  }
}

export class SimilarListing extends Component {
  render() {
    return (
      <Fragment>
        <ul className="listings">
          {Object.values(this.props.list)
            .slice(0, 4)
            .map((value, key) => {
              return (
                <li key={key}>
                  <div className="atbd_left_img">
                    <NavLink to={"listing-details" + value.id}>
                      <img
                        src={value.img}
                        style={{ width: "90px" }}
                        alt="listingimage"
                      />
                    </NavLink>
                  </div>
                  <div className="atbd_right_content">
                    <div className="cate_title">
                      <h4>
                        <NavLink to={"listing-details" + value.id}>
                          {value.title}
                        </NavLink>
                      </h4>
                    </div>
                    <p className="listing_value">
                      <span>$25,800</span>
                    </p>
                    <p className="directory_tag">
                      <span className="la la-cutlery" aria-hidden="true"></span>
                      <span>
                        <NavLink to="/at_demo" onClick={noAction}>
                          Food & Drink
                        </NavLink>
                        <span className="atbd_cat_popup">
                          +3
                          <span className="atbd_cat_popup_wrapper">
                            <span>
                              <NavLink to="/at_demo" onClick={noAction}>
                                Food
                                <span>,</span>
                              </NavLink>
                              <NavLink to="/at_demo" onClick={noAction}>
                                Others
                                <span>,</span>
                              </NavLink>
                              <NavLink to="/at_demo" onClick={noAction}>
                                Service
                                <span>,</span>
                              </NavLink>
                            </span>
                          </span>
                        </span>
                        {/*<!-- ends: .atbd_cat_popup -->*/}
                      </span>
                    </p>
                  </div>
                </li>
              );
            })}
        </ul>
      </Fragment>
    );
  }
}

export class PopularListing extends Component {
  render() {
    return (
      <Fragment>
        <ul className="listings">
          {Object.values(this.props.list)
            .slice(0, 4)
            .map((value, key) => {
              return (
                <li key={key}>
                  <div className="atbd_left_img">
                    <NavLink to={"listing-details" + value.id}>
                      <img
                        src={value.img}
                        style={{ width: "90px" }}
                        alt="listingimage"
                      />
                    </NavLink>
                  </div>
                  <div className="atbd_right_content">
                    <div className="cate_title">
                      <h4>
                        <NavLink to={"listing-details" + value.id}>
                          {value.title}
                        </NavLink>
                      </h4>
                    </div>
                    <p className="listing_value">
                      <span>$25,800</span>
                    </p>
                    <p className="directory_tag">
                      <span className="la la-cutlery" aria-hidden="true"></span>
                      <span>
                        <NavLink to="/at_demo" onClick={noAction}>
                          Food & Drink
                        </NavLink>
                        <span className="atbd_cat_popup">
                          +3
                          <span className="atbd_cat_popup_wrapper">
                            <span>
                              <NavLink to="/at_demo" onClick={noAction}>
                                Food
                                <span>,</span>
                              </NavLink>
                              <NavLink to="/at_demo" onClick={noAction}>
                                Others
                                <span>,</span>
                              </NavLink>
                              <NavLink to="/at_demo" onClick={noAction}>
                                Service
                                <span>,</span>
                              </NavLink>
                            </span>
                          </span>
                        </span>
                        {/*<!-- ends: .atbd_cat_popup -->*/}
                      </span>
                    </p>
                  </div>
                </li>
              );
            })}
        </ul>
      </Fragment>
    );
  }
}

export class WidgetContactInfo extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-body atbd_author_info_widget">
          <div className="atbd_widget_contact_info">
            <ul>
              <li>
                <span className="la la-map-marker"></span>
                <span className="atbd_info">Walnut Creek, CA</span>
              </li>
              <li>
                <span className="la la-phone"></span>
                <span className="atbd_info">(800)287-0339</span>
              </li>
              <li>
                <span className="la la-envelope"></span>
                <span className="atbd_info">info@socicraft.com</span>
              </li>
              <li>
                <span className="la la-globe"></span>
                <NavLink to="/at_demo" onClick={noAction} className="atbd_info">
                  www.socicraft.com
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class Category extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-wrapper">
          <div className="widget-default">
            <div className="widget-header">
              <h6 className="widget-title">Categories</h6>
            </div>
            <div className="widget-content">
              <div className="category-widget">
                <ul>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Business
                    </NavLink>
                  </li>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Finance
                    </NavLink>
                  </li>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Industry Reports
                    </NavLink>
                  </li>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Strategy
                    </NavLink>
                  </li>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Technology
                    </NavLink>
                  </li>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Marketing
                    </NavLink>
                  </li>
                  <li className="arrow-list4">
                    <NavLink onClick={noAction} to="/at_demo">
                      Strategy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export class PopularPost extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-wrapper">
          <div className="widget-default">
            <div className="widget-header">
              <h6 className="widget-title">Popular Post</h6>
            </div>
            <div className="widget-content">
              <div className="sidebar-post">
                {Object.values(this.props.blog)
                  .slice(0, 4)
                  .map((value, key) => {
                    return (
                      <div className="post-single" key={key}>
                        <div className="d-flex align-items-center">
                          <NavLink to={"blog-details" + value.id}>
                            <img
                              src={value.imgSrc}
                              alt=""
                              style={{
                                width: "90px",
                              }}
                            />
                          </NavLink>
                          <p>
                            <span>{value.date}</span>{" "}
                            <span>
                              by <a href="http://aazztech.com">Aazztech</a>
                            </span>
                          </p>
                        </div>
                        <NavLink
                          to={"blog-details" + value.id}
                          className="post-title"
                        >
                          {value.title.split("").slice(0, 30)}
                        </NavLink>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class RecentPost extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-wrapper">
          <div className="widget-default">
            <div className="widget-header">
              <h6 className="widget-title">Recent Post</h6>
            </div>
            <div className="widget-content">
              <div className="sidebar-post">
                {Object.values(this.props.blog)
                  .slice(0, 4)
                  .map((value, key) => {
                    return (
                      <div className="post-single" key={key}>
                        <div className="d-flex align-items-center">
                          <NavLink to={"blog-details" + value.id}>
                            <img
                              src={value.imgSrc}
                              alt=""
                              style={{
                                width: "90px",
                              }}
                            />
                          </NavLink>
                          <p>
                            <span>{value.date}</span>{" "}
                            <span>
                              by <a href="http://aazztech.com">Aazztech</a>
                            </span>
                          </p>
                        </div>
                        <NavLink
                          to={"blog-details" + value.id}
                          className="post-title"
                        >
                          {value.title.split("").slice(0, 30)}
                        </NavLink>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class PopularTags extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-wrapper">
          <div className="widget-default">
            <div className="widget-header">
              <h6 className="widget-title">Popular Tags</h6>
            </div>
            <div className="widget-content">
              <div className="tags-widget">
                <ul>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Business
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Finance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Strategy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Global
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Marketing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Technology
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Wordpress
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Solution
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction}>
                      Bizillion
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class StayUpdate extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-wrapper">
          <div className="widget-default">
            <div className="widget-header">
              <h6 className="widget-title">Stay Updated</h6>
            </div>
            <div className="widget-content">
              <div className="subscribe-widget">
                <form action="#">
                  <input
                    type="email"
                    className="form-control m-bottom-20"
                    placeholder="Enter email"
                    required
                  />
                  <button
                    className="btn btn-sm btn-primary shadow-none"
                    type="submit"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class ConnentFollow extends Component {
  render() {
    return (
      <Fragment>
        <div className="widget-wrapper">
          <div className="widget-default">
            <div className="widget-header">
              <h6 className="widget-title">Connect &amp; Follow</h6>
            </div>
            <div className="widget-content">
              <div className="social social--small">
                <ul className="d-flex flex-wrap">
                  <li>
                    <NavLink
                      to="/at_demo"
                      onClick={noAction}
                      className="facebook"
                    >
                      <span className="fab fa-facebook-f"></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/at_demo"
                      onClick={noAction}
                      className="twitter"
                    >
                      <span className="fab fa-twitter"></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/at_demo"
                      onClick={noAction}
                      className="linkedin"
                    >
                      <span className="fab fa-linkedin-in"></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/at_demo" onClick={noAction} className="gplus">
                      <span className="fab fa-google-plus-g"></span>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/*<!-- ends: .social -->*/}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
