import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReportModal from "./reportModal";
import { useDispatch, useSelector } from "react-redux";
import {
  postSaveForLater,
  removeSaveForLater,
} from "../../redux/action/discovery";
import "./postFeedstyles.css";

export default function PostOptions({ postId }) {
  const discovery = useSelector((state) => state.discoveryReducer);
  const savedPostsIds = discovery.savedPostsId;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const saveForLaterHandler = () => {
    dispatch(postSaveForLater(postId));
    handleClose();
  };
  const removeSavedPostsHandler = () => {
    dispatch(removeSaveForLater(postId));
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <MoreVertIcon variant="contained" onClick={handleClick} />
      <Popover
        sx={{ minWidth: 900 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <ReportModal /> */}
        <a
          href="https://support.socicraft.com/portal/en/newticket?departmentId=746075000000006907&layoutId=746075000000476952"
          target="_blank"
        >
          <Typography
            sx={{ pl: 8, pr: 6, pt: 1, pb: 1 }}
            className="post_options_report_btn"
          >
            Report
          </Typography>
        </a>
        <Divider light />

        {savedPostsIds && savedPostsIds.includes(postId) ? (
          <Typography
            sx={{ pl: 8, pr: 6, pt: 1, pb: 1 }}
            onClick={() => removeSavedPostsHandler(postId)}
          >
            Remove post
          </Typography>
        ) : (
          <Typography
            sx={{ pl: 8, pr: 6, pt: 1, pb: 1 }}
            onClick={saveForLaterHandler}
          >
            Save post
          </Typography>
        )}
      </Popover>
    </div>
  );
}
