import React, { Component } from "react";
import "./App.css";
import Participant from "./participant";
import { connect } from "react-redux";

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remoteParticipants: Array.from(this.props.stream.participants.values()),
    };

    this.leaveRoom = this.leaveRoom.bind(this);
  }
  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.stream.on("participantConnected", (participant) =>
      this.addParticipant(participant)
    );
    this.props.stream.on("participantDisconnected", (participant) =>
      this.removeParticipant(participant)
    );

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  addParticipant(participant) {
    console.log(`${participant.identity} has joined the room.`);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
    });
  }

  removeParticipant(participant) {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
    });
  }
  leaveRoom() {
    this.props.room.disconnect();
    this.props.returnToLobby();
  }
  render() {
    return (
      <div className="room">
        <div className="participants">
          <Participant
            key={this.props.stream.localParticipant.identity}
            localParticipant="true"
            participant={this.props.stream.localParticipant}
          />

          {this.state.remoteParticipants.map((participant) => (
            <Participant key={participant.identity} participant={participant} />
          ))}
        </div>
        {/* <button id="leaveRoom" onClick={this.leaveRoom}>
          Leave Room
        </button> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  stream: state.videoCalling.room.stream,
});
export default connect(mapStateToProps, {})(Room);
