import { GET_PHOTO_GALLERY,GET_UPDATED_PHOTO_ALBUM,DELETE_ALBUM } from "../action/types";
const initState = {
  galleryAlbums: [],
};
const galleryReducer = (state = initState, action) => {

  switch (action.type) {
    case GET_PHOTO_GALLERY:
      return {
        ...state,
        galleryAlbums: action.payload,
        loading: true,
      };
      case GET_UPDATED_PHOTO_ALBUM:
        return{
   ...state,
   galleryAlbums:state.galleryAlbums.map(photo=>{
   return  photo._id === action.payload._id ? action.payload:photo
     
   })
        }
    case DELETE_ALBUM:
          return {
            ...state,
           galleryAlbums: [
              ...state.galleryAlbums.filter(
                (album) => album._id !== action.payload
              ),
            ],
          };
    default:
      return state;
  }
};
export default galleryReducer;
