import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import "./Chat-Form.css";
import { sendMessageForFiles } from "../../../redux/action/messaging";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from "react-player";
import { setAlert } from "../../../redux/action/alert";
import IconButton from "@mui/material/IconButton";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import PhotoIcon from "@mui/icons-material/Photo";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ControlPoint from "@mui/icons-material/ControlPoint";

function ChatForm({
  onChangeHandler,
  sendMessage,
  messageForm,
  messageStatus,
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [images, setimages] = useState([]);
  const [type, setType] = useState();
  const [message, setSendMessage] = useState();
  // const dispatch = useDispatch();
  // const messaging = useSelector((state) => state.messaging);

  // console.log("Use Selector ", messaging);

  const handleChangeFile = (event, type) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('file', file);
    sendMessageContent(file, type);
  }

  const sendMessageContent = (images, type) => {
    console.log(images, type)
    let messageContent = {
      conversationId: messageStatus.currentConversation,
      attachement: images,
      FileType: type,
      message: message
    };
    sendMessageForFiles(messageContent, dispatch);
    handleClose();
    setimages([]);
    setSendMessage();
  };

  const imageUploader = (event, type) => {
    if (images.length == 3) {
      dispatch(setAlert("info", "You can send only three files at a time"));
    } else {
      setType(type);
      const file = event.target.files[0];
      let image = {
        file: file,
      };
      setimages([...images, image]);
      console.log(images);
    }
    handleShow();
  };

  const handleChangeforAttachment = (event) => {
    const value = event.target.value;
    setSendMessage(value);
  };

  return (
    <>
      <div id="chat-form">
        {messageStatus.currentConversation && messageStatus.title !== "SOCIBOT" && (
          <>
            <div class="row">
              <div class="col-sm-3 flex">
                <span class="upload_file">
                  {/* <img
                    src={"assets/img/images/icons/document.png"}
                    alt=""
                    title="Document"
                  /> */}
                  <IconButton
                    title="Document"
                    className="icnBtn"
                  >
                    <LibraryBooks className="post_icon" fontSize="large" />
                  </IconButton>
                  <input type="file" class="documentFile" title="Document"
                    // onChange={e => { handleChangeFile(e, 4) }}
                    onChange={e => { imageUploader(e, 4) }}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                 text/plain, application/pdf"/>
                </span>
                <span class="upload_pic">
                  {/* <img
                    src={"assets/img/images/icons/image.png"}
                    alt=""
                    title="Photo"
                  /> */}
                  <IconButton
                    title="Picture"
                    className="icnBtn"
                  >
                    <PhotoIcon className="post_icon" fontSize="large" />
                  </IconButton>
                  <input type="file" class="photoFile" title="Picture"
                    // onChange={e => { handleChangeFile(e, 3) }} 
                    onChange={e => { imageUploader(e, 3) }}
                    accept="image/*" />
                </span>
                <span class="upload_audio">
                  {/* <img
                    src={"assets/img/images/icons/audio.png"}
                    alt=""
                    title="Audio"
                  /> */}
                  <IconButton
                    title="Audio"
                    className="icnBtn"
                  >
                    <AudiotrackIcon className="post_icon" fontSize="large" />
                  </IconButton>
                  <input type="file" class="audioFile" title="Audio"
                    // onChange={e => { handleChangeFile(e, 1) }}
                    onChange={e => { imageUploader(e, 1) }}
                    accept="audio/*" />
                </span>
                <span class="upload_video">
                  {/* <img
                    src={"assets/img/images/icons/add-video.png"}
                    alt=""
                    title="Video"
                  /> */}
                  <IconButton
                    title="Video"
                    className="icnBtn"
                  >
                    <VideoLibraryIcon className="post_icon" fontSize="large" />
                  </IconButton>
                  <input type="file" class="videoFile" title="Video"
                    // onChange={e => { handleChangeFile(e, 2) }}
                    onChange={e => { imageUploader(e, 2) }}
                    accept="video/*" />
                </span>
                <span>
                </span>
              </div>
              <div class="col-sm-8">
                <input
                  id="chat-input"
                  value={messageForm}
                  onKeyUp={(e) => sendMessage(e)}
                  onChange={(e) => onChangeHandler(e)}
                  type="text"
                  placeholder="type a message"
                />
              </div>
              <div class="col-sm-1 no_pad">
                <img
                  src={"assets/img/images/icons/send.png"}
                  alt=""
                  onClick={sendMessage}
                />
              </div>
            </div>
            {/* <div class="wrapper">
            <img
              src={"assets/img/images/icons/add-icon.png"}
              alt=""
            />
            <span class="button">
            <img
              src={"assets/img/images/icons/document.png"}
              alt=""
            />
            </span>
          </div>
          <input
            id="chat-input"
            value={messageForm}
            onKeyUp={(e) => sendMessage(e)}
            onChange={(e) => onChangeHandler(e)}
            type="text"
            placeholder="type a message"
          />
          <img
            src={"assets/img/images/icons/send.png"}
            alt=""
            onClick={sendMessage}
          /> */}
          </>
        )}
        {/* <img alt="chat" src={"assets/img/images/icons/attachment-logo.svg"} /> */}
      </div>

      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around align-content-center ">
            {" "}
            {images.map((item) => (
              <div key={item.file}>
                {type == 1 ? <ReactPlayer
                  url={window.URL.createObjectURL(item.file)}
                  width="250px"
                  height="50px"
                  playing={false}
                  controls={true}
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                /> : type == 2 ? <video class="chatVideo" controls controlsList="nodownload">
                  <source
                    src={window.URL.createObjectURL(item.file)}
                    type="video/mp4"
                  />
                </video> : type == 3 ? <img
                  width="150"
                  loading="lazy"
                  style={{
                    height: "100%",
                    border: "3px solid green",
                  }}
                  src={window.URL.createObjectURL(item.file)}
                  className="img-fluid rounded-top rounded-right rounded-bottom rounded-left border-1"
                  alt=""
                /> : type == 4 ? <iframe width="90%" height="300" frameborder="0" src={window.URL.createObjectURL(item.file)}></iframe> : ''}
              </div>
            ))}
          </div>
          {images.length < 3 ? <><div class="addFile">
            {/* <img
            width="40"
            src={"assets/img/images/icons/add-icon.png"}
            alt=""/> */}
            <IconButton
              className="icnBtn"
            >
              <ControlPoint className="post_icon addSize" fontSize="large" />
            </IconButton>
            <input type="file" class="inputIcn" title={type == 1 ? 'Audio' : type == 2 ? 'Video' : type == 3 ? 'Picture' : type == 4 ? 'Document' : ''} onChange={e => { imageUploader(e, type) }}
              accept={type == 1 ? 'audio/*' : type == 2 ? 'video/*' : type == 3 ? 'image/*' : type == 4 ? 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf' : ''} />
          </div>
          </> : ''}
        </Modal.Body>
        <Modal.Footer>
          <input
            id="chat-input"
            onChange={handleChangeforAttachment}
            value={message}
            type="text"
            placeholder="type a message"
          />
          {/* <Button variant="primary" onClick={e => { sendMessageContent(images, type) }}>
            Send
          </Button> */}
          <img className='sendMultiple'
            src={"assets/img/images/icons/send.png"}
            alt=""
            onClick={e => { sendMessageContent(images, type) }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChatForm;
