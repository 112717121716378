import React from "react";


export default function MediaElement({
  showUploadPhoto,
  key,
  toggleUploadPhoto,
  url,
  description,
  tag,
  selectedAlbumId,
  title,
  id,
}) {


  return <></>;
}
