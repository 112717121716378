import { useState } from "react";
import "./sliderstyle.css";

const Slider = ({ slides, contentType }) => {
  const [blur, setBlur] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  function blurTransition() {
    setBlur({
      filter: "blur(5px)",
    });
    setTimeout(() => {
      setBlur({});
    }, 250);
  }
  const goToPrevious = () => {
    blurTransition();
    const isFirstSlide = currentIndex === 0;

    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    blurTransition();
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    blurTransition();
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...blur,
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  return (
    <div className="sliderStyles">
      <div>
        <div onClick={goToPrevious} className="prev leftArrowStyles">
          ❰
        </div>
        <div onClick={goToNext} className="next rightArrowStyles">
          ❱
        </div>
      </div>
      {contentType === "photo" && (
        <div style={slideStylesWidthBackground} className="sliderStyles"></div>
      )}

      {contentType === "video" && (
        <video width="320" height="240" controls key={currentIndex}>
          <source src={slides[currentIndex].url} type="video/mp4" />
          Error Message
        </video>
      )}

      <div className="dotsContainerStyles">
        {slides.map((slide, slideIndex) => (
          <div
            className="dotStyle"
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            {slideIndex === currentIndex ? (
              <div style={{ color: "cyan" }}> ● </div>
            ) : (
              <div> ●</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
