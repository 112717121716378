import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
export const Supporters = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { connections, user } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  const userId = user && user.id;

  return (
    <Fragment>
      <div className="widget atbd_widget widget-card">
        <div className="atbd_widget_title">
          <h4>Connections</h4>
          <span className="badge badge-light disabled ">
            {connections && connections.length}
          </span>
          <a
            data-toggle={isAuthenticated ? null : "modal"}
            data-target={isAuthenticated ? null : "#login_modal"}
            href={isAuthenticated ? `/view-network-list/${userId}` : "#/"}
            // type="button"
            className="btn btn-xs btn-gradient btn-gradient-two m-2 w-50 "
            // onClick={handleOpen}
          >
            <span className="element_widget_details"> View Details</span>
          </a>
        </div>
        {isAuthenticated && (
          <div
            style={{
              maxHeight: "232px",
              overflow: "scroll",
              overflowX: "hidden",
            }}
            className="widget-body atbdp-widget-tags"
          >
            {connections &&
              connections.map((user) => {
                return (
                  <ul styles="list-style-type:none;">
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-evenly"
                      alignItems="flex-end"
                      spacing={2}
                    >
                      <Avatar
                        alt="user avatar"
                        src={
                          user.userId
                            ? user.userId.userAvatar
                            : "./assets/img/avatar-60x60.jpg"
                        }
                        sx={{ width: 56, height: 56, mt: 3 }}
                        style={{ marginTop: "16px" }}
                      />

                      <Grid item={true} xs={7}>
                        {user.userId.firstName} {user.userId.lastName}
                      </Grid>
                    </Grid>
                  </ul>
                );
              })}
          </div>
        )}
      </div>
    </Fragment>
  );
};
