import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { getNewListings } from "../../../redux/action/discovery";
import "../postFeedstyles.css";
import SkeletonLoader from "../skeletonLoader";

export const FilteredListings = ({
  selectedListing,
  trendingListings,
  newListings,
}) => {
  const [mount, setMount] = useState(true);
  const [index, setIndex] = useState(0);
  const listingImagePlaceholder =
    "https://images.unsplash.com/photo-1542621334-a254cf47733d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fHByb2plY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";

  const newListDisplayTime = 5000;

  console.log("selectedListings ", selectedListing);
  console.log("trending listings ", trendingListings);
  console.log("newListings ", newListings);

  // const [trendingListings, newListings] = newAndTrendingListings;
  const [currentListing, setCurrentListing] = useState(selectedListing);

  const [listings, setListings] = useState(
    selectedListing === "trending" ? trendingListings : newListings
  );

  const newListingTimeOut = setTimeout(() => {
    index === newListings.length - 1 ? setIndex(0) : setIndex(index + 1);
  }, newListDisplayTime);

  if (!mount) {
    clearTimeout(newListingTimeOut);
  }

  function listingChanger(type) {
    setCurrentListing(type);
    if (type === "new") {
      setListings(newListings);
    }
    if (type === "trending") {
      setListings(trendingListings);
    }
  }

  // if (currentListing === "trending") {
  //   setCurrentListing(trendingListings);
  // }

  // if (currentListing === "new") {
  //   setListings(newListings);
  // }

  return (
    <div>
      <Card
        variant="outlined"
        className="new_listing"
        sx={{ minWidth: 350, maxWidth: 350 }}
      >
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              style={{
                color: currentListing === "trending" ? "black" : "#B8B8B8",
              }}
              onClick={() => listingChanger("trending")}
            >
              Trending
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              style={{
                color: currentListing === "new" ? "black" : "#B8B8B8",
              }}
              onClick={() => listingChanger("new")}
            >
              New
            </Typography>
          </div>
          <span className="listing_title">{listings[index].listingTitle}</span>
          <CardMedia
            component="img"
            height="60"
            image={
              listings[index].listingImage.url
                ? listings[index].listingImage.url
                : listingImagePlaceholder
            }
            alt="socicraft user listing"
          />
          <Typography variant="body1" component="div" sx={{ mb: 1, mt: 2 }}>
            learn more about the author of this listing{" "}
            <NavLink to={`/${listings[index].username}`}>
              <span className="username">@{listings[index].username}</span>
            </NavLink>
          </Typography>
          <div>
            <Typography variant="body1" component="div">
              {listings[index].views} views posted on{" "}
              {moment(listings[index].postedDate).format("ll")}
            </Typography>
            <NavLink
              style={{ marginLeft: "-7px" }}
              to={`/view-listing-details/${listings[index].listingId}`}
            >
              <span sx={{ ml: -1 }} className="viewMore">
                view more
              </span>
            </NavLink>{" "}
          </div>
        </CardContent>

        <div className="dotsContainerStyles">
          {listings.map((slide, slideIndex) => (
            <div
              className="dotStyle"
              key={slideIndex}
              //   onClick={() => goToAd(slideIndex)}
            >
              {slideIndex === index ? (
                <div style={{ color: "cyan" }}> ● </div>
              ) : (
                <div> ●</div>
              )}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};
