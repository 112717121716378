import API from "../../api/api";
import { PAYMENT_ON_PROCESS, PAYMENT_SUCCESS, PAYMENT_RESET } from "./types";

//*************************** Create Check Out Session  ***************************//

export const createCheckOutSession = () => async (dispatch) => {
  try {
    const checkOutSession = await API.post("/payment/checkout-session", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    const { data } = checkOutSession;
    // start processing
    dispatch({ type: PAYMENT_ON_PROCESS });
    return data;
  } catch (error) {
    console.log(error.message);
  }

  //
};

export const paymentFailed = () => (dispatch) => {};
export const paymentSuccessNotification = () => (dispatch) => {
  dispatch({ type: PAYMENT_SUCCESS });
  setTimeout(() => {
    dispatch({ type: PAYMENT_RESET });
  }, 5000);
};
export const paymentHistory = () => async (dispatch) => {
  try {
    const response = await API.get("/payment/history", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({
      type: "PAYMENT_HISTORY",
      payload: {
        paymentHistory: response.data.data.paymentHistory,
        availablePayout: response.data.data.availablePayout,
        payoutHistory: response.data.data.payoutHistory,
        pendingPayoutHistory: response.data.data.pendingPayoutHistory,
      },
    });
  } catch (e) {
    console.log(e);
  }
};
// export const checkCheckOutStatus =
//   (token, checkoutSession) => async (dispatch) => {
//     try {
//       // Check If Token is the same with local

//       if (token === localStorage.getItem("token")) {
//         // const checkStatus = await API.post("/payment/checkout-status", {
//         //   headers: {
//         //     "Content-Type": "application/json",
//         //     "x-auth-user": localStorage.getItem("token"),
//         //   },
//         // });
//         // console.log(checkStatus);
//         // const { data } = checkStatus;

//         //
//         return {
//           loading: false,
//           msg: "Thank You For Your Payment",
//           status: "Success",
//         };
//       } else {
//         return {
//           loading: false,
//           msg: "Ops Something Wrong",
//           status: "Danger",
//         };
//       }
//     } catch (error) {
//       console.log(error.message);
//     }

//     //
//   };
//*************************** GET NOTIFICATIONS   ***************************//
