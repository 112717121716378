import { SHOW_CONNECTION } from "../action/types";

const initialState = {
  showConnection: [],
  selfAccount: false,
  loading: true,
};

const connections = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SHOW_CONNECTION:
      return {
        ...state,
        showConnection: payload.allConnection[0],
        selfAccount: payload.selfAccount,
        loading: false,
      };

    default:
      return state;
  }
};

export default connections;
