import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { Footer } from "../../layout/footer";
import { PropTypes } from "prop-types";
import socicraftLogo from "../../project-wide-images/socicraft logo.png";
import {
  updatePassword,
  validatePasswordResetLink,
} from "../../../redux/action/auth";

const ChangePassword = ({
  validatePasswordResetLink,
  isAuthenticated,
  match,
  history,
  updatePassword,
}) => {
  const [user, setUser] = useState({
    valid: false,
    email: "",
    password: "",
    password2: "",
    id: "",
  });
  let { valid, password, password2 } = user;

  const onChangeHandler = ({ currentTarget }) => {
    let { value, name } = currentTarget;
    setUser({ ...user, [name]: value });
  };
  useEffect(() => {
    const validateUser = async (token) => {
      try {
        let response = await validatePasswordResetLink(token);
        let { email, id } = response.data.msg;
        setUser({
          valid: true,
          email: email,
          id: id,
        });
      } catch (e) {
        return history.push({ pathname: "/" });
      }
    };
    validateUser(match.params.token);
  }, [validatePasswordResetLink, history, match.params.token]);
  const onsumbithandler = async () => {
    if (valid) {
      updatePassword(user);
    }
  };
  if (isAuthenticated) return <Redirect to="/social/discovery" />;

  return (
    <Fragment>
      {/* Header section start */}

      <section
        className="bgimage overlay  reset-password-background-color"
        Style="overflow: auto;"
      >
        <div className="bg_image_holder">
          <img src="/assets/img/intro1.jpg" Style="object-fit: cover;" alt="" />
        </div>

        <div className="directory_content_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-2">
                <form action="/" className="search_form">
                  <div
                    className="atbd_seach_fields_wrapper col-lg-8 offset-lg-2 col-md-6 offset-md-3"
                    Style="background-color: rgba(0,0,0,.0001);"
                  >
                    <form
                      //    onSubmit={(e) => this.onsumbithandler(e)}
                      className="needs-validation "
                      name="registration"
                    >
                      <div className="form-col  " align="center">
                        <div className="search_title_area">
                          <img
                            src={socicraftLogo}
                            // className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
                            width="300"
                            alt=""
                          />
                        </div>
                        <div className="search_title_area  rounded">
                          <h3 className="title">Reset Password</h3>
                        </div>

                        <div className="row-md-12   mb-3 row-lg-6 ">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={onChangeHandler}
                            placeholder="New Password"
                            required
                          />
                        </div>
                        <div className="row-md-12  mb-3 row-lg-6">
                          <input
                            type="password"
                            className="form-control"
                            name="password2"
                            value={password2}
                            onChange={onChangeHandler}
                            placeholder="Confirm Password"
                            required
                          ></input>
                        </div>
                        <br />
                        <div className="row-md-12    mb-3 row-lg-6">
                          {" "}
                          <button
                            className="btn btn-dark"
                            onClick={onsumbithandler}
                            type="button"
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </form>
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
validatePasswordResetLink.propTypes = {
  validatePasswordResetLink: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  validatePasswordResetLink,
  updatePassword,
})(withRouter(ChangePassword));
