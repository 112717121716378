import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import Spinner from "../../../components/content/modal/spinnermodal";
import { loaduser, providerLoaduser } from "../../../redux/action/auth";
class Loader extends Component {
  state = {
    page: this.props.page,
    count: 0,
    perPage: this.props.perPage,
  };
  loader = async () => {
    const response = await this.props.providerLoaduser();
    if (!response) return this.props.history.push({ pathname: "/" });
  };
  componentWillMount() {
    this.loader();
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/craft-profile" />;
    }
    return (
      <section className="section-title p-top-150 fa-10x">
        {" "}
        <h1>"To lose patience is to lose the battle.”</h1>
        <Spinner />
      </section>
    );
  }
}
Loader.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  providerLoaduser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { loaduser, providerLoaduser })(
  withRouter(Loader)
);
