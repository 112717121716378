import API from "../../api/api";

import { SHOW_CART } from "./types";

export const addToCart =
  (compensation, listingId, applicantId) => async (dispatch) => {
    try {
      const body = {
        compensation: compensation,
        listingId: listingId,
        applicantId: applicantId,
      };

      await API.post(`/cart`, body, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      });

      dispatch(showItemsInCart());
    } catch (error) {
      console.log("error message " + error);
    }
  };

export const showItemsInCart = () => async (dispatch) => {
  try {
    const response = await API.get(`/cart`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: SHOW_CART, payload: response.data[0] });
  } catch (error) {
    console.log("Error " + error);
  }
};

export const deleteItemsInCart = (itemId) => async (dispatch) => {
  try {
    await API.put(`/cart/${itemId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(showItemsInCart());
  } catch (error) {
    console.log("Error " + error);
  }
};
export const clearCart = () => async (dispatch) => {
  try {
    await API.delete(`/cart`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(showItemsInCart());
  } catch (error) {
    console.log("Error " + error);
  }
};
