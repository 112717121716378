import React from "react";
import MediaElement from "./mediaElement";
import "./style/mediaScroller.css";
import { useState } from "react";
import { useSelector } from "react-redux";

export const VideoBar = ({ viewType, selectedAlbumId, type, userId }) => {
  const imageSizer = {
    marginTop: "5px",
    inlineSize: "100%",
    aspectRatio: "16/9",
    borderRadius: "3%",
    objectFit: "cover",
    marginBottom: "5px",
  };

  const videos = useSelector(
    (state) => state.publicReducer.publicCraftProfile.albumVideos
  );

  const handleVideoChange = (video, tag, title, description) => {
    setShowFirstVideo(false);
    setcurrentVideo(video);
    setcurrentTag(tag);
    setCurrentTitle(title);
    setcurrentDescription(description);
    setIsTruncated(true);
  };

  const [currentVideo, setcurrentVideo] = useState("");
  const [currentTag, setcurrentTag] = useState("");
  const [currentDescription, setcurrentDescription] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [isTruncated, setIsTruncated] = useState(true);
  const [showFirstVideo, setShowFirstVideo] = useState(true);

  const resultDescription =
    isTruncated && currentTitle ? currentTitle.slice(0, 150) : currentTitle;

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      {videos.length ? (
        <div>
          <div className="big-image">
            <video
              key={currentVideo}
              controls
              style={imageSizer}
              alt="soci-craft user videos"
            >
              {" "}
              <source
                src={showFirstVideo ? videos[0].url : currentVideo}
              />{" "}
            </video>
          </div>

          <div style={{ minHeight: "30px" }} className="info">
            <b>
              <h2
                style={{
                  fontWeight: "bolder",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                }}
              >
                {" "}
                {showFirstVideo ? videos[0].title : currentDescription}
              </h2>
            </b>

            <p>
              {" "}
              {showFirstVideo ? videos[0].description : resultDescription}
              <span>
                {resultDescription &&
                resultDescription.length > 100 &&
                isTruncated === true ? (
                  <span>...</span>
                ) : (
                  <></>
                )}{" "}
              </span>
            </p>

            {resultDescription &&
            resultDescription.length > 100 &&
            isTruncated ? (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => toggleIsTruncated()}
              >
                {isTruncated ? <b>...see more</b> : "see less"}
              </button>
            ) : (
              <span></span>
            )}

            <p>
              {" "}
              {showFirstVideo != null && showFirstVideo
                ? videos[0].tags.map((tag) => (
                    <span style={{ color: "#005fb9" }}>#{tag} </span>
                  ))
                : currentTag.map((tag) => (
                    <span style={{ color: "#005fb9" }}>#{tag} </span>
                  ))}
            </p>
          </div>

          <div className="media-scroller">
            {videos.map((video, index) => (
              <MediaElement
                viewType={viewType}
                key={index}
                videofunc={handleVideoChange}
                videoId={video._id}
                selectedAlbumId={selectedAlbumId}
                videoUrl={video.url}
                videoTag={video.tags}
                videoDescription={video.description}
                videoTitle={video.title}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          {" "}
          Empty Album
        </div>
      )}
    </div>
  );
};
