import Joi from "joi-browser";

let password =null;

export function isPasswordValid (passwordCondition){
 
  password = passwordCondition;
}
export const validateUserForm = (user) => {
  const schema = {
    firstName: Joi.string().required().min(2).max(20).label("First Name"),
    lastName: Joi.string().required().min(2).max(20).label("Last Name"),
    userName: Joi.string().required().min(3).max(20).label("User Name"),
    password: Joi.string().max(20).label("password"),
    email: Joi.string().email().required().label("Email Address"),
    // city: Joi.string().required().min(6).max(20).label("City"),
    // company: Joi.string().required().min(6).max(20).label("Company"),
    //industries: Joi.Array().required().min(6).max(20).label('Industires'),
    // state: Joi.string().required().max(20).label("State"),
    // zip: Joi.string().required().max(5).label("Zip"),
    // tagLine: Joi.string().required().min(6).max(20).label("Tagline"),
    // country: Joi.string().required().min(6).max(20).label("Country"),
    // question: Joi.string().required().min(1).label("Question"),
    // answer: Joi.string().required().max(20).label("Answer"),
    // year: Joi.string().required().label("Year"),
    // industries: Joi.array().required().label("Industries"),
  };
  const result = Joi.validate(user, schema, {
    abortEarly: false,
  });

  //If everything is ok
  if (result.error === null ) {
    if(password===true){
      return 1;
    } 
  }
  else{
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
  }
 
 
};
export const validateUserLogin = (user, isemail) => {
  const schema = {
    password: Joi.string().required().min(6).max(20).label("password"),
  }; // userName: Joi.string().required().min(3).max(20).label('User Name'),
  // email: Joi.string().email().required().label('Email Address'),
  isemail
    ? (schema.userName = Joi.string().email().required().label("Email Address"))
    : (schema.userName = Joi.string()
        .required()
        .min(3)
        .max(20)
        .label("User Name"));
  const result = Joi.validate(user, schema, {
    abortEarly: false,
  });

  if (!result.error) return null;
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
};
export const validatePRone = (user) => {
  const schema = {
    userName: Joi.string().required().min(3).max(20).label("User Name"),
    email: Joi.string().email().required().label("Email"),
    zip: Joi.string().required().min(5).max(20).label("Zipcode "),
  }; // userName: Joi.string().required().min(3).max(20).label('User Name'),
  // email: Joi.string().email().required().label('Email Address'),
  const result = Joi.validate(user, schema, {
    abortEarly: false,
  });
  if (!result.error) return null;
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
};
export const validatePRtwo = (answer) => {
  const schema = {
    answer: Joi.string().required().label("User Name"),
  }; // userName: Joi.string().required().min(3).max(20).label('User Name'),
  // email: Joi.string().email().required().label('Email Address'),
  const result = Joi.validate(answer, schema, {
    abortEarly: false,
  });
  if (!result.error) return null;
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
};
export const validatePRthree = (password) => {
  const schema = {
    password: Joi.string().required().min(6).label("User Name"),
  }; // userName: Joi.string().required().min(3).max(20).label('User Name'),
  // email: Joi.string().email().required().label('Email Address'),
  const result = Joi.validate(password, schema, {
    abortEarly: false,
  });
  if (!result.error) return null;
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
};
