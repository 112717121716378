import React from 'react'

export default function PasswordRequisite({capsLetterFlag,numberFlag,pwdLengthFlag,specialCharFlag}) {
    


  return (
      <div>
          <p className={capsLetterFlag}> Must Contain 1 Capital Letter</p>
          <p className={numberFlag}> Must Contain a number</p>
          <p className={specialCharFlag}> Must contain special character ( ! @ # $ % ^ & * )</p>
          <p className={pwdLengthFlag}> password has to longer than 5 characters </p>
    </div>
  )
}
