export const adData = [
  {
    adImage:
      "https://images.unsplash.com/photo-1660856514115-3e6d43e51021?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDF8Ym84alFLVGFFMFl8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
    adTitle: "Nature One",
    adTime: 2000,
    adDescription:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or ",
  },
  {
    adImage:
      "https://images.unsplash.com/photo-1661183977745-e71963f46e02?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDV8Ym84alFLVGFFMFl8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
    adTitle: "Nature Two",
    adTime: 2000,
    adDescription:
      " giving information on its origins, as well as a random Lipsum generator.",
  },
  {
    adImage:
      "https://images.unsplash.com/photo-1660935313203-d041e7d31fc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEyfGJvOGpRS1RhRTBZfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    adTitle: "Nature Three",
    adTime: 500,
    adDescription:
      " print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    adImage:
      "https://images.unsplash.com/photo-1660854003923-ed7bd08a5c47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDIyfGJvOGpRS1RhRTBZfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    adTitle: "Nature Four",
    adTime: 2000,
    adDescription:
      "The Lorem Ipsum online text generator creates fictitious, fake, causal, or placeholder text.",
  },
];
