import API from "../../api/api";
import { formatError } from "../../services/formatError";
import { setAlert } from "./alert";
import { clearComments, clearReplies } from "./discovery";

import {
  LOADINGCOMPLETED,
  LOADINGSTARTED,
  GET_ALL_LISTINGS,
  GET_USER_LISTINGS,
  GET_APPLIED_LISTINGS,
  GET_APPLICANTS,
  GET_UPVOTES,
  RESPONSE_APPLICANT_LIST,
  GET_LISTING_DETAILS,
  GET_FAVORITE_LISTINGS,
  RESET_LISTING_SEARCH,
  UPDATE_VOTE,
  RESPONSE_PAYED_PROJECTS,
  RESPONSE_PENDING_DOCUMENTS,
  DOCUSIGN_LOADING,
  RESPONSE_COMPLETED_DOCUMENTS,
  GET_COMMENT_DETAILS,
  LOAD_LATEST_REPLY,
  LOAD_REPLIES,
  LOAD_LATEST_COMMENT,
  LOAD_COMMENTS
} from "./types";

export const addListing = (posts) => async (dispatch) => {
  dispatch({ type: LOADINGSTARTED });
  const imagedata = new FormData();

  if (posts.imageandvideo) {
    posts.imageandvideo.image.map((image) =>
      imagedata.append("file", image.file)
    );
  }

  let post = JSON.stringify(posts);
  imagedata.set("data", post);

  const response = await API.post(
    "/listing",
    imagedata,
    {
      headers: {
        "x-auth-user": localStorage.getItem("token"),
        "Content-Type": `multipart/form-data; boundary=${imagedata._boundary}`,
      },
    },
    { withCredentials: true }
  );

  if (response.data.success) {
    dispatch(setAlert("success", "Listing Posted Successfully "));
    dispatch({ type: LOADINGCOMPLETED });
    return true;
  } else {
    dispatch(setAlert("error", "Unable to Post Listing "));
    return false;
  }
};

export const getAllListings = (searchTerm) => async (dispatch) => {
  try {
    let internalListings = await API.get(
      `/listing/search/?keyTerm=${searchTerm.keyword}&category=${searchTerm.category}&role=${searchTerm.role}&typeOfWork=${searchTerm.typeOfWork}&address=${searchTerm.address}`
    );
    let externalListings = await API.get(
      "/external/usJobs?keyTerm=" + searchTerm.keyword
    );

    const listings = {
      externalListings: externalListings.data,
      internalListings: internalListings.data,
    };

    dispatch({
      type: GET_ALL_LISTINGS,
      payload: {
        listings,
        keyTerm: searchTerm.keyword,
        address: searchTerm.address,
        category: searchTerm.category,
        role: searchTerm.role,
        typeOfWork: searchTerm.typeOfWork,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
export const resetListingSearch = () => async (dispatch) => {
  dispatch({
    type: RESET_LISTING_SEARCH,
  });
};
export const favoriteListing = (body) => async (dispatch) => {
  await API.put(`/listing/favorite/${body}`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-user": localStorage.getItem("token"),
    },
  });

  dispatch(getFavoriteListings());
};
export const getFavoriteListings = () => async (dispatch) => {
  let response = await API.get(`/listing/listingAction/favorite`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-user": localStorage.getItem("token"),
    },
  });

  dispatch({ type: GET_FAVORITE_LISTINGS, payload: response.data });
};
export const upVote = (listingId, votedListing) => async (dispatch) => {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    };
    await API.post(`/listing/upvote/${listingId}`, {}, config);
    const voted = votedListing.find((listing) => listing === listingId);
    if (voted) {
      dispatch({ type: UPDATE_VOTE, payload: { listingId, value: -1 } });
    } else {
      dispatch({ type: UPDATE_VOTE, payload: { listingId, value: 1 } });
    }
    dispatch({ type: UPDATE_VOTE, payload: listingId });
    dispatch(getUpVotes());
    // dispatch(getAllListings(keyTerm));
  } catch (error) {
    console.log(error);
  }
};
export const applyListing = (listingId, body) => async (dispatch) => {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    };

    await API.post(`/listing/apply/${listingId}`, { body: body }, config);

    dispatch(getAppliedListings());
    dispatch(getListingDetails(listingId));
  } catch (error) {
    console.log(error);
  }
};
export const getUserListings = (userId) => async (dispatch) => {
  try {
    let response = await API.get("/listing", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_USER_LISTINGS, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};
export const getAppliedListings = () => async (dispatch) => {
  try {
    let response = await API.get(`/listing/applied`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_APPLIED_LISTINGS, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};

export const getApplicants = () => async (dispatch) => {
  try {
    dispatch({ type: LOADINGSTARTED });
    let response = await API.get(`/listing/applicants`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_APPLICANTS, payload: response.data });
    dispatch({ type: LOADINGCOMPLETED });
  } catch (error) {
    console.log(error);
  }
};
export const getUpVotes = () => async (dispatch) => {
  try {
    let response = await API.get(`/listing/up-votes`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_UPVOTES, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};
export const getListingDetails = (id) => async (dispatch) => {
  try {
    let response = await API.get(`/listing/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_LISTING_DETAILS, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};
export const postResponseForApplicant = (item) => async (dispatch) => {
  let body = {
    status: item.status,
    applicantId: item.userId,
  };

  try {
    let response = await API.put(`/listing/apply/${item.postId}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(getApplicants());

    dispatch({ type: RESPONSE_APPLICANT_LIST, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};
export const getPayedProjects = (page) => async (dispatch) => {
  try {
    dispatch({ type: DOCUSIGN_LOADING, payload: true });
    let response = await API.get(`/listing/payed/?limit=3&page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: RESPONSE_PAYED_PROJECTS, payload: response.data });
    dispatch({ type: DOCUSIGN_LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};
export const getPendingDocuments = (page) => async (dispatch) => {
  try {
    dispatch({ type: DOCUSIGN_LOADING, payload: true });
    let response = await API.get(
      `/listing/pending-documents/?limit=3&page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );

    dispatch({ type: RESPONSE_PENDING_DOCUMENTS, payload: response.data });
    dispatch({ type: DOCUSIGN_LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};
export const getCompletedDocuments = (page) => async (dispatch) => {
  try {
    dispatch({ type: DOCUSIGN_LOADING, payload: true });
    let response = await API.get(
      `/listing/completed-documents/?limit=3&page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );

    dispatch({ type: RESPONSE_COMPLETED_DOCUMENTS, payload: response.data });
    dispatch({ type: DOCUSIGN_LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};
export const updateStartProject =
  (listingId, documentId) => async (dispatch) => {
    try {
      await API.put(`/listing/start-project/${listingId}/${documentId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      });

      dispatch(getAppliedListings());
    } catch (error) {
      console.log(error);
    }
  };
export const updateCompletedProject =
  (listingId, documentId) => async (dispatch) => {
    try {
      await API.put(`/listing/complete-project/${listingId}/${documentId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      });

      dispatch(getAppliedListings());
    } catch (error) {
      console.log(error);
    }
  };
export const updateProjectApprove =
  ({ applicant, listing }) =>
    async (dispatch) => {
      try {
        console.log(applicant, listing);
        await API.post(
          `/listing/approve-project/${listing.listingId.id}/${applicant.document._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-user": localStorage.getItem("token"),
            },
          }
        );

        dispatch(getApplicants());
      } catch (error) {
        console.log(error);
      }
    };
export const updateProjectReopened =
  ({ applicant, listing, body }) =>
    async (dispatch) => {
      try {
        await API.post(
          `/listing/open-project/${listing.listingId.id}/${applicant.document._id}`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-user": localStorage.getItem("token"),
            },
          }
        );

        dispatch(getApplicants());
      } catch (error) {
        console.log(error);
      }
    };

export const getCommentListing =
  (postId, limit, skip, isCommentPosted, replyLimit = 1) =>
    async (dispatch) => {
      try {
        const response = await API.get(
          `/listing/${postId}/comment?limit=${limit}&skip=${skip}&replyLimit=${replyLimit}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-user": localStorage.getItem("token"),
            },
          }
        );

        if (isCommentPosted) {
          dispatch({ type: LOAD_LATEST_COMMENT, payload: response.data });
        } else {
          dispatch({ type: LOAD_COMMENTS, payload: response.data });
        }
      } catch (error) {
        const errorMessage = formatError(error.response.data.msg);

        // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
      }
    };

export const postCommentOnAllListing = (postId, comment) => async (dispatch) => {
  const limit = 1;
  const skip = 0;
  const isCommentPosted = true;
  const body = {
    comment: comment,
  };

  try {
    const response = await API.post(
      `/listing/${postId}/comment`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    const searchKeyword = JSON.parse(localStorage.getItem('searchKeywordsOnListing'));
    dispatch(getCommentListing(postId, limit, skip, isCommentPosted));
    dispatch(getAllListings(searchKeyword));
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

  }
};

export const postReplyOnAllListing = (commentId, reply, postId) => async (dispatch) => {
  const body = {
    reply: reply,
  };
  const limit = 1;
  const skip = 0;
  const isReplyPosted = true;
  try {
    const response = await API.post(
      `/listing/reply/${commentId}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    dispatch(getReplyOnAllListing(commentId, limit, skip, isReplyPosted));
    // dispatch(clearReplies());
    // dispatch(clearComments());

    // dispatch(getCommentListing(postId, limit, skip, true));
  } catch (error) {
    const errorMessage = formatError(error.response.data.msg);

    // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
    console.log("error ", error);
  }
};

export const getReplyOnAllListing =
  (commentId, limit = 1, skip = 0, isReplyPosted, replyLimit = 1) =>
    async (dispatch) => {
      try {
        const response = await API.get(
          `/listing/reply/${commentId}?limit=${limit}&skip=${skip}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-user": localStorage.getItem("token"),
            },
          }
        );
        if (isReplyPosted) {
          dispatch({ type: LOAD_LATEST_REPLY, payload: response.data });
        } else {
          dispatch({ type: LOAD_REPLIES, payload: response.data });
        }
      } catch (error) {
        const errorMessage = formatError(error.response.data.msg);

        // dispatch({ type: FAILED_REQUEST, payload: errorMessage });
      }
    };
