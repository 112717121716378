import Folder from "./folder";
import "./styles/mediaScroller.css";
import { useSelector } from "react-redux";
import Spinner from "../content/modal/spinnermodal";
import FolderCard from "./folderCard";

export default function FolderBar({
  isAuthenticated,
  viewType,
  type,
  albumInfo,
  togglePhotoView,
}) {
  const loading = useSelector((state) => state.craftProfile.loading);
  // overflow-x: auto;
  //overscroll-behavior-inline: contain;
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="scroll">
          {albumInfo.map((image, index) => (
            <FolderCard
              key={index}
              viewType={viewType}
              isAuthenticated={isAuthenticated}
              type={type}
              image={image}
              togglePhotoView={togglePhotoView}
            />
          ))}
        </div>
      )}
    </>
  );
}
