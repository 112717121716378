import Joi from "joi-browser";
export const validateAddListing = (post) => {
  const schema = {
    title: Joi.string().required().label("Title"),
    longDescription: Joi.string().required().label("Long Description"),
    // typeOfWork: Joi.string().required().label("Type of Work"),
    // videoUrl: Joi.string().required().label("Video URL"),
    projectStartDate: Joi.string().required().label("Project Start Date"),
    compensation: Joi.string().required().label("Compensation"),
    relevantIndustries: Joi.string().required().label("Relevant Industries"),
    projectLabel: Joi.string().required().label("Project Label"),
    totalHours: Joi.number().min(1).required().label("Total Hours"),
    flatRate: Joi.number().min(50).required().label("Flat Rate"),
    // ratePerHour: Joi.number().min(50).required().label("Rate Per Hour"),
    // estimatedHoursPerDay: Joi.string()
    //   .required()
    //   .label("Estimated Hours Per Day"),
  };
  if (!post.remote) {
    schema.state = Joi.string().required().label("State ");
    schema.city = Joi.string().required().label("City ");
    schema.country = Joi.string().required().label("Country");
    schema.zipcode = Joi.number().required().label("Zip Code");
    schema.street = Joi.string().required().label("Street ");
  }
  delete post.remote;

  if (post.compensation !== "ratePerHour") {
    delete schema.totalHours;
  }
  if (post.compensation !== "flatRate") {
    delete schema.flatRate;
  }
  console.log(schema);
  const result = Joi.validate(post, schema, {
    abortEarly: false,
  });

  if (!result.error) return null;
  const errors = {};
  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
};
