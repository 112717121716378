import * as React from "react";
import Modal from "@mui/material/Modal";
import "./signIn.css";
import LogIn from "../signIn";

export default function SignIn({ modalOpen, toggleSignIn }) {
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={toggleSignIn}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LogIn handleClose={toggleSignIn} manualTrigger={true} />
      </Modal>
    </div>
  );
}
