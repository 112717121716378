import "./callNotification.css";
import Modal from "react-overlays/Modal";
import styled from "styled-components";
import { connect } from "react-redux";
import { answerCall, declineCall } from "../../../redux/action/videoCalling";

const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  width: 400px;
  height: 200px;
  z-index: 1040;
  overflow: auto;
  top: 15%;
  left: 40%;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;
const IncomingCallNotification = ({
  answerCall,
  declineCall,
  incomingCall,
  room,
}) => {
  const handleDeclineCall = () => {
    declineCall();
  };
  const handleAcceptCall = () => {
    console.log(room);
    answerCall(room);
  };

  return (
    <div>
      <RandomlyPositionedModal
        show={incomingCall && !room.streaming}
        aria-labelledby="modal-label"
      >
        <div id="video-call-notification-container">
          <div id="video-call-notification-options">
            <div>
              <section id="answer" onClick={handleAcceptCall}>
                <span>
                  <h3>Answer</h3>{" "}
                </span>{" "}
                <span>
                  <i className="fa fa-light fa-phone"></i>
                </span>
              </section>
            </div>{" "}
            <div>
              <section id="decline" onClick={handleDeclineCall}>
                <span>
                  <h3>Decline</h3>{" "}
                </span>{" "}
                <span>
                  <i className="fa fa-light fa-phone"></i>
                </span>
              </section>
            </div>
          </div>
          <div id="video-caller-info">
            <h3>{room && room.userName} Is Calling ...</h3>
          </div>
        </div>
      </RandomlyPositionedModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  incomingCall: state.videoCalling.incomingCall,
  room: state.videoCalling.room,
});

export default connect(mapStateToProps, { declineCall, answerCall })(
  IncomingCallNotification
);
