import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { postCertification } from "../../../redux/action/craftProfile";
// import { getGallery } from "../../../../redux/action/craftProfile";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const UploadCertifications = (props) => {

  const [form, setForm] = useState({
    name: "",
    organization: "",
    issueDate: "",
    expirationDate: "",
    credentialID: "",
  });

  const initialState = {
    name: "",
    organization: "",
    issueDate: "",
    expirationDate: "",
    credentialID: ""
  }

  const resetForm = () => {
    setForm({ ...initialState });
    setDoesNotExpire((doesNotExpire)=>{
      doesNotExpire = false;
    })
  };

  const [doesNotExpire, setDoesNotExpire] = useState(false);

  const onChangeHandler = (e) => {
    // const { value, name } = currentTarget;
    // setForm({ ...form, [name]: value }); 

    const value = e.target.value;
    const newInput = {[e.target.name] : value}
    setForm((form)=>({
      ...form,
      ...newInput
    }))
  };
  const onChangeExpire = () => {
    setDoesNotExpire(!doesNotExpire);
  };

  const submitHandler = () => {
    
   let bodyForm = {
     name: form.name,
     organization: form.organization,
     issueDate: form.issueDate,
     expirationDate: form.expirationDate,
     credentialID: form.credentialID,
     doesNotExpire: doesNotExpire,
     isPublic:showPublic
   }
   if(doesNotExpire === true){
    bodyForm.expirationDate = "-";
   }

   props.postCertification(bodyForm);
   resetForm();
   props.toggleCreateCertification();
  };

  const closeCreateAlbum = (e) => {
    props.toggleCreateCertification(); 
    resetForm();
  };
  const [showPublic, setShowPublic] = useState(true);

  const handleSwitchChange = (event) => {
    setShowPublic(event.target.checked);
  };

  return (
    <>
      <Modal 
        show={props.showUploadCertification}
        animation={true}
        onHide={() => closeCreateAlbum()}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
    
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 id="claim_listing_label">
              <i className="la la-plus"></i> Add License or Certification
            </h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body     className="col-lg-12">
          <Fragment>
            <form action="/">
              <div className="form-group">
                <div className="col"  style={{ margin: "auto", width: "75%"}} >
                  <div className="col-md-12">
                    <label id="name">
                      {" "}
                      <h5 id="claim_listing_label">Name<span style={{color:"red"}}>*</span></h5>{" "}
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      onChange={onChangeHandler}
                      className="form-control "
                      value={form.name}
                      size="50"
                    />
                  </div>
                  <div className="col-md-12">
                    <label id="description">
                      {" "}
                      <h5 id="claim_listing_label">
                        Official Organization:
                      </h5>{" "}
                    </label>

                    <input
                      id="organization"
                      type="text"
                      onChange={onChangeHandler}
                      name="organization"
                      className="form-control "
                      value={form.organization}
                    ></input>
                  </div>
                  <div className="col-md-12">
                    <label id="description">
                      {" "}
                      <h5 id="claim_listing_label">Issue Date:</h5>{" "}
                    </label>
                    <input
                      id="issueDate"
                      type="date"
                      onChange={onChangeHandler}
                      name="issueDate"
                      className="form-control "
                      value={form.issueDate}
                    />
                  </div>
                  <br />
                  {doesNotExpire ? (
                    <div className="col-md-12">
                      <label id="description">
                        {" "}
                        <h5 id="claim_listing_label">Expiration Date:</h5>{" "}
                      </label>
                      <input
                         disabled
                        id="expirationDate"
                        type="date"
                        onChange={onChangeHandler}
                        name="expirationDate"
                        className="form-control "
                        value={form.expirationDate}
                      />
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          name="doesNotExpire"
                          value={doesNotExpire}
                          onChange={onChangeExpire}
                        />{" "}
                       <span>Does Not Expire</span>
                      </label>
                    </div>
                  ) : (
                    <div className="col-md-12">
                      <label id="description">
                        {" "}
                        <h5 id="claim_listing_label">Expiration Date:</h5>{" "}
                      </label>
                      <input
                        id="expirationDate"
                        type="date"
                        onChange={onChangeHandler}
                        name="expirationDate"
                        className="form-control "
                        value={form.expirationDate}
                      />
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          name="doesNotExpire"
                          value={doesNotExpire}
                          onChange={onChangeExpire}
                        />{" "}
                        <span>Does Not Expire</span>
                      </label>
                    </div>
                  )}
                  <br />
                  <div className="col-md-12">
                    <label id="credentialID">
                      {" "}
                      <h5 id="claim_listing_label">Credential ID:</h5>{" "}
                    </label>
                    <input
                      id="credentialID"
                      type="text"
                      onChange={onChangeHandler}
                      name="credentialID"
                      className="form-control "
                      value={form.credentialID}
                    />
                  </div>
                
                </div>
                <FormGroup style={{ marginTop:'10px',maxWidth: '100%'}} color="success">
                   <FormControlLabel  control={<  Switch color="primary" checked={showPublic} onChange={handleSwitchChange} />} label="Publish to public profile" />
                 </FormGroup>
              </div>

              <Button
                onClick={submitHandler}
                // type="button"
                // className="btn btn-secondary"
                variant="primary"
              >
                Save
              </Button>
            </form>
          </Fragment>
        </Modal.Body>

        <Modal.Footer>
          {/* //<Button onClick={() => submitAgreement()} variant="secondary">
            Agree
          </Button>
         // <Button onClick={() => toggleModal(false)} variant="danger">
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    listings: state.listings,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  postCertification
})(UploadCertifications);
