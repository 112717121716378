import {
  LOGINSUCCESS,
  LOGINFAIL,
  REGISTERSUCCESS,
  REGISTERFAIL,
  LOGOUT,
  LOADUSER,
  REGISTRATION_COMPLETED,
  REGISTRATION_STARTED,
} from "../action/types";

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  processingRegistration: false,
  isUserWelcomed: false,
};
const auth = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOGINSUCCESS:
    case REGISTERSUCCESS:
    case LOADUSER:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        loading: true,
      };
    case REGISTERFAIL:
    case LOGOUT:
    case LOGINFAIL:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: true,
      };
    case REGISTRATION_STARTED:
      return {
        ...state,
        processingRegistration: true,
      };
    case REGISTRATION_COMPLETED:
      return {
        ...state,
        processingRegistration: false,
      };

    default:
      return state;
  }
};

export default auth;
