import React, { Component, Fragment } from "react";
import { useSelector } from "react-redux";

import ProfileInfoList from "./profile-info-list";

export class ContactInfo extends Component {
  render() {
    return (
      <Fragment>
        <div className="atbd_content_module atbd_contact_information_module">
          <div className="atbd_content_module__tittle_area">
            <div className="atbd_area_title">
              <h4>
                <span className="la la-headphones"></span>Contact Information
              </h4>
            </div>
          </div>
          <div className="atbdb_content_module_contents">
            <div className="atbd_contact_info">
              <ul>
                <li>
                  <div className="atbd_info_title atbd_info">
                    <span className="la la-map-marker"></span>
                    {this.props.listing.state}, {this.props.listing.country}
                  </div>
                </li>
                <li>
                  <div className="atbd_info_title atbd_info">
                    <span className="la la-envelope"></span>
                    {this.props.listing.email}
                  </div>
                </li>
                {/* <li>
                  <div className="atbd_info_title">
                    <span className="la la-globe"></span>
                    <a
                      onClick={noAction}
                      href="http://aazztech.com"
                      className="atbd_info"
                    >
                      www.aazztech.com
                    </a>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className="atbd_director_social_wrap">
              {this.props.listing.linkedin && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://" + this.props.listing.linkedin}
                >
                  <span className="fab fa-linkedin">
                    {this.props.listing.linkedin}
                  </span>
                </a>
              )}
              {this.props.listing.tumblr && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://" + this.props.listing.tumblr}
                >
                  <span className="fab fa-tumblr"></span>
                </a>
              )}
              {this.props.listing.facebook && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://" + this.props.listing.facebook}
                >
                  <span className="fab fa-facebook-f"></span>
                </a>
              )}
              {this.props.listing.twitter && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://" + this.props.listing.twitter}
                >
                  <span className="fab fa-twitter"></span>
                </a>
              )}
              {this.props.listing.youtube && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://" + this.props.listing.youtube}
                >
                  <span className="fab fa-youtube"></span>
                </a>
              )}
              {this.props.listing.instagram && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://" + this.props.listing.instagram}
                >
                  <span className="fab fa-instagram"></span>
                </a>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function ContactInfoPrivate(isAuthenticated) {
  const contactInfo = useSelector((state) => state.auth.user.address);
  const user = useSelector((state) => state.auth.user);
  const socialNetwork = useSelector((state) => state.auth.user.socialNetwork);

  return (
    <div>
      {isAuthenticated && (
        <Fragment>
          <div className="atbd_content_module atbd_contact_information_module">
            <div className="atbd_content_module__tittle_area">
              <div className="atbd_area_title">
                <h4>Contact Information</h4>
              </div>
            </div>
            <div>
              <ProfileInfoList
                street={contactInfo.street && contactInfo.street + ","}
                state={contactInfo.state && contactInfo.state + ","}
                country={contactInfo.country && contactInfo.country}
                city={contactInfo.city && contactInfo.city}
                email={user && user.email}
                zipCode={contactInfo.zipCode && contactInfo.zipCode}
                phoneNumber={contactInfo.phoneNumber && contactInfo.phoneNumber}
                webSite={user.userName && user.userName}
              />
              <div
                style={{ padding: "20px", marginTop: "0px" }}
                className="atbd_director_social_wrap"
              >
                {socialNetwork.facebook && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://facebook.com/" + socialNetwork.facebook}
                  >
                    <span className="fab fa-facebook-f"></span>
                  </a>
                )}
                {socialNetwork.twitter && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://twitter.com/" + socialNetwork.twitter}
                  >
                    <span className="fab fa-twitter"></span>
                  </a>
                )}
                {socialNetwork.youtube && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://youtube.com/" + socialNetwork.youtube}
                  >
                    <span className="fab fa-youtube"></span>
                  </a>
                )}
                {socialNetwork.instagram && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://instagram.com/" + socialNetwork.instagram}
                  >
                    <span className="fab fa-instagram"></span>
                  </a>
                )}
                {socialNetwork.tumblr && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={"https://tumblr.com/" + socialNetwork.tumblr}
                  >
                    <span className="fab fa-tumblr"></span>
                  </a>
                )}{" "}
                {socialNetwork.portfolio && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={socialNetwork.portfolio}
                  >
                    <span className="fa fa-globe"></span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ContactInfoPrivate;
