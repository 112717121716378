import React from "react";
import Folder from "./folder";
import "./styles/mediaScroller.css";
import FolderCard from "./folderCard";
export default function FolderBar({
  isAuthenticated,
  viewType,
  type,
  albumInfo,
  togglePhotoView,
}) {
  return (
    <>
      <div className="scroll">
        {albumInfo.map((image, index) => (
          // <Folder
          //   viewType={viewType}
          //   isAuthenticated={isAuthenticated}
          //   type={type}
          //   image={image}
          //   togglePhotoView={togglePhotoView}
          // />
          <FolderCard
            key={index}
            viewType={viewType}
            isAuthenticated={isAuthenticated}
            type={type}
            image={image}
            togglePhotoView={togglePhotoView}
          />
        ))}
      </div>
    </>
  );
}
// {albumInfo.map((image, index) => (
//   <FolderCard
//     key={index}
//     viewType={viewType}
//     isAuthenticated={isAuthenticated}
//     type={type}
//     image={image}
//     togglePhotoView={togglePhotoView}
//   />
// ))}
