import {
  INCOMING_CALL,
  CALLING,
  CALL_DECLINED,
  ANSWER_CALL,
  CALL_ANSWERED,
  REMOTE_AUDIO_TOGGLER,
  REMOTE_VIDEO_TOGGLER,
} from "./types";
import API from "../../api/api";
import { connect as twilioConnect } from "twilio-video";
export const call = (callInfo) => async (dispatch) => {
  try {
    let response = await API.post("/video/call", callInfo);

    const room = await twilioConnect(response.data.token, {
      name: response.data.id,
      audio: true,
      maxAudioBitrate: 16000, //For music remove this line
      video: { height: 720, frameRate: 24, width: 1280 },
    });
    dispatch({ type: CALLING, payload: { ...response.data, stream: room } });
  } catch (e) {
    console.log("Error While Calling ", e);
  }
};
export const incomingCall = (callerInfo) => async (dispatch) => {
  try {
    dispatch({ type: INCOMING_CALL, payload: callerInfo });
  } catch (e) {
    console.log("Error While Calling ", e);
  }
};
export const declineCall = () => async (dispatch) => {
  try {
    // let response = await API.post("/video/end-call");

    dispatch({ type: CALL_DECLINED });
  } catch (e) {
    console.log("Error While Calling ", e);
  }
};
export const answerCall = (body) => async (dispatch) => {
  try {
    const response = await API.post("/video/answer", body);

    const room = await twilioConnect(response.data.token, {
      name: response.data.room,
      audio: true,
      maxAudioBitrate: 16000, //For music remove this line
      video: { height: 720, frameRate: 24, width: 1280 },
      networkQuality: true,
    });
    dispatch({
      type: ANSWER_CALL,
      payload: { ...response.data, stream: room },
    });
  } catch (e) {
    console.log("Error While Answering A Call ", e);
  }
};
export const callAnswered = () => async (dispatch) => {
  try {
    dispatch({ type: CALL_ANSWERED });
  } catch (e) {
    console.log("Error While Answering A Call ", e);
  }
};

export const remoteAudioToggler = (info) => (dispatch) => {
  dispatch({ type: REMOTE_AUDIO_TOGGLER, payload: info });
};
export const remoteVideoToggler = (info) => (dispatch) => {
  dispatch({ type: REMOTE_VIDEO_TOGGLER, payload: info });
};
