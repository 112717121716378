import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Portal } from "@mui/material";
import "./dropdownfilter.css";
import Popper from "@mui/material/Popper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import "./dropdownfilter.css";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { getPosts } from "../../../../redux/action/discovery";
import { cleanContents } from "../../../../redux/action/discovery";

export default function SearchInputs() {
  //content video, audio
  const [content, setContent] = useState("");
  // search key word
  const [searchKeyWord, setSearchKeyWord] = useState("");

  const portalRef = useRef(null);
  const dispatch = useDispatch();

  const searchClickHandler = () => {
    if (content === "" && searchKeyWord === "") {
      return;
    }
    dispatch(cleanContents());

    dispatch(getPosts({ fileType: content, searchKeyWord: searchKeyWord }));
  };

  const handleSearch = (word) => {
    setSearchKeyWord(word);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleClose = (content) => {
    setAnchorEl(null);
    setContent(content);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ display: "flex" }} className="search_inputs">
      <div className="dropdown_btn">
        <Button
          style={{ paddingLeft: "25px" }}
          className="content-button"
          variant="standard"
          sx={{
            width: "20px",
            height: "32px",
          }}
          startIcon={content ? content : <KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          {/* {content ? content : " "} */}
        </Button>

        <Popper
          className="popper"
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose("")}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="popover-container">
            <div className="content-container">
              <span className="fa fa-user bg-secondary  content-icon"> </span>
              <span onClick={() => handleClose("user")}>
                {" "}
                <b> User</b>
              </span>
            </div>

            <div className="content-container">
              <span className="fa fa-user bg-success content-icon"> </span>
              <span onClick={() => handleClose("audio")}>
                {" "}
                <b>Audio</b>
              </span>
            </div>

            <div className="content-container">
              <span className="fa fa-image bg-primary content-icon"> </span>
              <span onClick={() => handleClose("photo")}>
                {" "}
                <b> Photo</b>
              </span>
            </div>
            <div>
              <span className="fa fa-video bg-info content-icon"> </span>
              <span onClick={() => handleClose("video")}>
                {" "}
                <b>Video</b>
              </span>
            </div>
          </div>
        </Popper>
      </div>
      <div className="box dropdown">
        <div className="formcontainer" ref={portalRef}>
          {/* <Portal container={portalRef.current}> */}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name=""
              id="searchInput"
              placeholder="Search for Reflections"
              value={searchKeyWord}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  searchClickHandler();
                }
              }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <SearchIcon id="searchIcon" onClick={searchClickHandler} />
          </form>
          {/* </Portal> */}
        </div>
      </div>
      <div className="box tablet">
        <div className="formcontainer" ref={portalRef}>
          {/* <Portal container={portalRef.current}> */}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name=""
              id="searchInput"
              placeholder="Search for Reflections"
              value={searchKeyWord}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  searchClickHandler();
                }
              }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <SearchIcon id="searchIcon" onClick={searchClickHandler} />
          </form>
          {/* </Portal> */}
        </div>
      </div>
    </div>
  );
}
