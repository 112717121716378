import { RESPONSE_COMPLETED_DOCUMENTS } from "../action/types";

import {
  GET_USER_LISTINGS,
  RESPONSE_PENDING_DOCUMENTS,
  GET_APPLIED_LISTINGS,
  GET_APPLICANTS,
  GET_UPVOTES,
  RESPONSE_APPLICANT_LIST,
  GET_LISTING_DETAILS,
  GET_FAVORITE_LISTINGS,
  RESPONSE_PAYED_PROJECTS,
} from "../action/types";

const initialState = {
  favoriteListings: [],
  appliedListings: [],
  userListings: [],
  applicants: [],
  loading: true,
  votedListings: [],
  listingDetails: null,
  acceptedListings: [],
  payedProjects: {
    data: [],
    info: {
      count: 0,
      page: 1,
    },
  },
  pendingDocuments: {
    data: [],
    info: {
      count: 0,
      page: 1,
      totalPage: 1,
    },
  },
  completedDocuments: {
    data: [],
    info: {
      count: 0,
      page: 1,
      totalPage: 1,
    },
  },
};
const userListings = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_USER_LISTINGS:
      return {
        ...state,
        userListings: payload,

        loading: false,
      };
    case GET_APPLIED_LISTINGS:
      return {
        ...state,
        appliedListings: payload,
        loading: false,
      };
    case GET_APPLICANTS:
      return {
        ...state,
        applicants: payload.applicantListings,
        acceptedListings: payload.acceptedListings,
        loading: false,
      };
    case GET_UPVOTES:
      return {
        ...state,
        votedListings: payload,
        loading: false,
      };
    case RESPONSE_APPLICANT_LIST:
      return {
        ...state,
        applicants: payload,
        loading: false,
      };
    case GET_LISTING_DETAILS:
      return {
        ...state,
        listingDetails: payload,
        loading: false,
      };
    case GET_FAVORITE_LISTINGS:
      return {
        ...state,
        favoriteListings: payload,
        loading: false,
      };
    case RESPONSE_PAYED_PROJECTS:
      return {
        ...state,
        payedProjects: payload,
        loading: false,
      };
    case RESPONSE_PENDING_DOCUMENTS:
      return {
        ...state,
        pendingDocuments: payload,
        loading: false,
      };
    case RESPONSE_COMPLETED_DOCUMENTS:
      return {
        ...state,
        completedDocuments: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default userListings;
