import API from "../../api/api";
import { loaduser } from "./auth";
export const postAccountInformation =
  (body, accountType) => async (dispatch) => {
    // let coordinates = (lat, lo)

    try {
      await API.post("account/" + accountType, body, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      });

      dispatch(loaduser());
      // dispatch({ type: REDEEM_SILVER, payload: response.data.silverReward });
    } catch (error) {
      // return error.response.data.msg;
      console.log(error);
    }
  };
