import * as React from "react";
import { useLocation } from "react-router-dom";
import { NavLink, Redirect } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import HeaderV2Notification from "./headerV2notification";
import MenuItem from "@mui/material/MenuItem";
import LogoImg from "./Socicraft_discovery_logo.png";
import NotificationBadge from "../../notification/notification";
import { logoutuser } from "../../../redux/action/auth";
import Search from "./dropdownFilter/search";
import "./headerV2.css";
import { useDispatch, useSelector } from "react-redux";

const HeaderV2 = () => {
  const location = useLocation();
  const currentLocation = location.pathname;

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const user = useSelector((state) => state.auth.user);

  const handleOpenUserMenu = (event) => {
    anchorElUser ? setAnchorElUser(null) : setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const dispatch = useDispatch();

  const logOut = (e) => {
    dispatch(logoutuser());
    return <Redirect to="/#" />;
  };

  const supportPage = () => {
    const url = 'https://support.socicraft.com/portal/en/kb'
    window.open(url, "_self");
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className="app-bar"
      sx={{ backgroundColor: "#F9FEFF" }}
    >
      <Container className="navbar-container" maxWidth="auto">
        <Toolbar className="tool-bar">
          <div className="logo-size">
            <img src={LogoImg} width="100%" alt="Socicraft INC Logo" />
          </div>

          <Box
            className="search_box"
            sx={{
              flexGrow: 10,
              ml: "10%",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Search className="search" />
          </Box>

          <Box
            className="addlisting_box"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            <div className="nav_button">
              <NavLink
                // sx={{ lineHeight: "2.0rem" }}
                to="/add-listing"
                className="btn btn-primary btn-sm"
                style={{ marginBottom: "9px", lineHeight: "2.25rem" }}
              >
                <span className="la la-plus"></span> Add Listing
              </NavLink>
            </div>
          </Box>

          {user && (
            <Box
              className="avatar-box"
              sx={{
                ml: "auto",
              }}
            >
              <HeaderV2Notification />
              <p className="user-name">{user && user.userName}</p>

              <IconButton
                onClick={handleOpenUserMenu}
                className="user_avatar"
                sx={{ p: 0 }}
              >
                <Avatar
                  alt="socicraft_user avatar"
                  src={user && user.userAvatar}
                />
              </IconButton>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {currentLocation !== "/social/discovery" && (
                  <NavLink to="/social/discovery">
                    <MenuItem>
                      {" "}
                      <Typography textAlign="center">
                        Discover Reflections
                      </Typography>
                    </MenuItem>
                  </NavLink>
                )}
                <NavLink to="/craft-profile">
                  <MenuItem>
                    <Typography textAlign="center">My Profile</Typography>
                  </MenuItem>
                </NavLink>

                <NavLink to="/dashboard-listings">
                  <MenuItem>
                    <Typography textAlign="center">Dashboard</Typography>
                  </MenuItem>
                </NavLink>

                {currentLocation !== "/" && (
                  <NavLink to="/">
                    <MenuItem>
                      <Typography textAlign="center">Find Listings</Typography>
                    </MenuItem>
                  </NavLink>
                )}

                <NavLink to="/chat">
                  <MenuItem>
                    <Typography textAlign="center">Messages</Typography>
                  </MenuItem>
                </NavLink>
                <NavLink to={`/view-network-list/${user.id}`}>
                  <MenuItem>
                    <Typography textAlign="center">Connections</Typography>
                  </MenuItem>
                </NavLink>
                <NavLink to={``}>
                  <MenuItem>
                    <Typography textAlign="center" onClick={(e) => supportPage(e)}>Support</Typography>
                  </MenuItem>
                </NavLink>
                <NavLink to={`/`}>
                  <MenuItem>
                    <Typography onClick={logOut} textAlign="center">
                      Logout
                    </Typography>
                  </MenuItem>
                </NavLink>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderV2;
