import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { upVote, getListingDetails } from "../../../redux/action/userpost";
import { favoriteListing } from "../../../redux/action/userpost";
import { getFavoriteListings } from "../../../redux/action/userpost";
import { getUpVotes } from "../../../redux/action/userpost";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import IconButton from "@mui/material/IconButton";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";


import InternalListingDetails from "../modal/internListingDetails/internalListing";
import Spinner from "./../modal/spinnermodal";
import CommentInputField from "../../postFeed/comment/commentInputField";
import { getCommentListing } from "../../../redux/action/userpost";
import API from "../../../api/api";
import axios from "axios";
import Comment from "../../postFeed/comment/comment";
import CommentOpenForAllListing from "../../postFeed/comment/commentOpenForAllListing";


const style = {
  color: "#33cccc",
  "text-decoration": "none",
  "background-color": "transparent",
  width: "100%",
};

class InternalLIstings extends Component {

  state = {
    showInternalDetails: false,
    expanded: false,
    post: []
  };

  toggleInternalDetails() {
    this.setState({ showInternalDetails: !this.state.showInternalDetails });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });

    // axios({
    //   url: 'https://server.socicraft.us/api/' + `listing/${this.props.data.id}/comment`,
    //   method: 'get',
    //   headers: {
    //     "Content-Type": "application/json",
    //     "x-auth-user": localStorage.getItem("token"),
    //   }
    // })
    //   .then(response => {
    //     this.setState({
    //       post: response.data.comments
    //     });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });

  };

  render() {
    const { data } = this.props;
    const toggleInternalDetails = (id) => {
      if (this.props.isAuthenticated) {
        if (!this.state.showInternalDetails) {
          this.props.getListingDetails(id);
        }

        this.setState({ showInternalDetails: !this.state.showInternalDetails });
      } else {
        const webAddress =
          process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
            ? "https://workshop.socicraft.us"
            : "https://workshop.socicraft.us";
        window.open(`${webAddress}/view-listing-details/${id}`, "_self");
      }
    };
    if (this.props.isLoading) {
      return <Spinner width={"100"} />;
    }

    return (
      <Fragment>
        <div className="col-lg-12">
          <div className="atbd_single_listing atbd_listing_list ">
            <article className="atbd_single_listing_wrapper">
              {/* <figure className="atbd_listing_thumbnail_area">
                                <div className="atbd_listing_image">
                                    <a href=" ">
                                        <img src={img} alt="listingimage" />
                                    </a>
                                </div>
                                                                            
                                <div className="atbd_thumbnail_overlay_content">
                                    <ul className="atbd_upper_badge">
                                        <li><span className={"text-capitalize atbd_badge atbd_badge_"+badge}>{badge}</span></li>
                                    </ul>
                                </div>
                            </figure> */}

              <div className="atbd_listing_info">
                <div className="atbd_content_upper">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <h4 className="atbd_listing_title">{data.title}</h4>{" "}
                    <span
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "#33cccc",
                      }}
                      onClick={() => this.props.favoriteListing(data.id)}
                      data-toggle={this.props.isAuthenticated ? null : "modal"}
                      data-target={
                        this.props.isAuthenticated ? null : "#login_modal"
                      }
                    >
                      {this.props.favoriteListings &&
                        !this.props.favoriteListings.filter(
                          (fav) => fav.id.toString() === data.id.toString()
                        ).length ? (
                        <BookmarkBorderOutlinedIcon
                          style={{ fontSize: "40px" }}
                        />
                      ) : (
                        <BookmarkIcon style={{ fontSize: "40px" }} />
                      )}
                    </span>
                    <div
                      data-toggle={this.props.isAuthenticated ? null : "modal"}
                      data-target={
                        this.props.isAuthenticated ? null : "#login_modal"
                      }
                      style={{
                        cursor: "pointer",
                      }}
                      className="btn btn-xs  btn-gradient btn-gradient-two"
                    >
                      Message{" "}
                      <i
                        style={{
                          fontSize: "20px",
                          color: "#33cccc",
                          paddingLeft: "10px",
                        }}
                        className="far fa-envelope"
                      ></i>
                    </div>
                    <div
                    // data-toggle={this.props.isAuthenticated ? null : "modal"}
                    // data-target={
                    //   this.props.isAuthenticated ? null : "#login_modal"
                    // }
                    >
                      {/* <a
                        href="#"
                        // onClick={() => favoriteListing}
                        className="atbdp-favourites"
                      > */}{" "}
                      <div
                        onClick={() => toggleInternalDetails(data.id)}
                        style={{ cursor: "pointer" }}
                        className="btn btn-xs btn-gradient btn-gradient-two"
                      >
                        Details
                      </div>{" "}
                      {/* //</a> */}
                    </div>{" "}
                  </Grid>

                  <div className="atbd_listing_meta">
                    {/* <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> */}
                    {/* <span className="atbd_meta atbd_listing_price">{"$ "+price}</span> */}
                  </div>

                  <div className="atbd_listing_data_list">
                    <ul>
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <span className="la la-user"></span> User:{" "}
                          <strong> {data.userId.firstName}</strong>{" "}
                          <strong>{data.userId.lastName}</strong> <br />
                        </p>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <p>
                          <i
                            style={{ color: "#33cccc" }}
                            className="fas fa-info-circle"
                          ></i>{" "}
                          <span className="ml-1" style={{ color: "#000000" }}>Details:</span> <strong>{data.projectLabel}</strong>
                        </p>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <p>
                          <span className="la la-map-marker"></span>
                          Location:{" "}
                          <strong>{data.projectLocation.toUpperCase()}</strong>
                        </p>{" "}
                      </li>
                      <li>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex"
                        >
                          {" "}
                          <p>
                            <span className="la la-calendar-check-o"></span>
                            Posted on:{" "}
                            <strong>
                              {" "}
                              {moment(data.createdAt).format("MM-DD-YYYY  LT")}
                            </strong>
                          </p>
                          <Grid>
                            {" "}
                            <div
                              style={{
                                paddingRight: "20px",
                                fontSize: "30px",
                                color: "#33cccc",
                              }}
                            >
                              <section className="la la-eye">
                                {" "}
                                <span
                                  style={{
                                    cursor: "none",
                                    fontSize: "25px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {data.views}
                                </span>
                              </section>
                              <section
                                style={{
                                  cursor: "pointer",
                                  color:
                                    this.props.votedListings.length &&
                                      this.props.votedListings.filter(
                                        (vote) => vote === data.id
                                      ).length
                                      ? "#33cccc"
                                      : "red",
                                }}
                                data-toggle={
                                  this.props.isAuthenticated ? null : "modal"
                                }
                                data-target={
                                  this.props.isAuthenticated
                                    ? null
                                    : "#login_modal"
                                }
                                onClick={() =>
                                  this.props.upVote(
                                    data.id,
                                    this.props.votedListings
                                  )
                                }
                                className="la la-arrow-up"
                              >
                                {" "}
                                <span
                                  style={{
                                    cursor: "none",
                                    fontSize: "25px",
                                  }}
                                >
                                  {data.votes ? data.votes : 0}
                                </span>
                              </section>

                              {/* <IconButton
                                onClick={this.handleExpandClick}
                                aria-label="add to favorites"
                                className="icon_btn"
                              >
                                <ModeCommentIcon
                                  className="post_icon"
                                  expand={this.state.expanded.toString()}
                                  aria-expanded={this.state.expanded}
                                  aria-label="show more"
                                />
                                <span
                                  style={{
                                    cursor: "none",
                                    fontSize: "25px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {this.props?.data?.comments}
                                </span>
                              </IconButton> */}
                              <section className="la la-comment ml-3" onClick={this.handleExpandClick} expand={this.state.expanded.toString()}
                                aria-expanded={this.state.expanded} style={{
                                  cursor: "pointer"
                                }}>
                                {" "}
                                {/* <ModeCommentIcon
                                  className="post_icon"
                                  expand={this.state.expanded.toString()}
                                  aria-expanded={this.state.expanded}
                                  aria-label="show more"
                                /> */}
                                <span
                                  style={{
                                    cursor: "none",
                                    fontSize: "25px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {this.props?.data?.comments}
                                </span>
                              </section>
                            </div>
                          </Grid>
                        </Grid>
                      </li>
                    </ul>
                  </div>
                </div>

                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                  <CommentOpenForAllListing postId={this.props.data.id} commentsCount={this.props.data.comments} commentFrom='allListing' />

                  {/* <CardContent sx={{ minWidth: 300 }}>
                    <Typography paragraph>Comment :</Typography>

                    <CommentInputField showAvatar={true} postId={this.props.data.id} commentFrom='allListing'/>
                    {this.state.post &&
                      this.state.post.map((comment) => (
                        <Comment key={comment._id} postComment={comment} postId={this.props.data.id} commentFrom='allListing' />
                      ))}
                  </CardContent> */}
                </Collapse>

                <div className="atbd_listing_bottom_content">
                  {" "}
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex"
                  >
                    {data.relevantIndustries.split(",").map((data) => (
                      <div className="border-secondary ">
                        <span className="p-xl-3">{data}</span>
                      </div>
                    ))}
                  </Grid>
                </div>
              </div>
            </article>
          </div>
        </div>
        <InternalListingDetails
          listing={data}
          toggleInternalDetails={toggleInternalDetails}
          showInternalDetails={this.state.showInternalDetails}
        />
      </Fragment>
    );
  }
}
InternalLIstings.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  favoriteListing: PropTypes.func.isRequired,
  favorites: PropTypes.array,
  upVote: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  keyTerm: state.listings.keyword,
  votedListings: state.userListing.votedListings,
  favoriteListings: state.userListing.favoriteListings,
  commentListings: state,
  isLoading: state.listings.isLoading,
});
export default connect(mapStateToProps, {
  favoriteListing,
  upVote,
  getListingDetails,
  getFavoriteListings,
  getUpVotes,
  getCommentListing
})(InternalLIstings);

export class InternalLIstingsViewApplicants extends Component {
  render() {
    const { list } = this.props;
    return (
      <Fragment>
        {Object.values(list).map((value, key) => {
          const { img, title, location, id } = value;
          return (
            <div className="col-lg-12" key={key}>
              <div className="atbd_single_listing atbd_listing_list">
                <article className="atbd_single_listing_wrapper">
                  <figure className="atbd_listing_thumbnail_area">
                    <div className="listing_image">
                      <img
                        src={img}
                        alt="listingimage"
                        className="view-applicant-profile-img"
                      />
                    </div>
                  </figure>

                  <div className="atbd_listing_info">
                    <div className="atbd_content_upper">
                      <h4 className="atbd_listing_title">
                        <NavLink to={"/listing-details" + id}>{title}</NavLink>
                      </h4>
                      {/* <div className="atbd_listing_meta">
                        <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> 
                        <span className="atbd_meta atbd_listing_price">{"$ "+price}</span>
                         <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span> 
                      </div> */}

                      <div className="atbd_listing_data_list">
                        <ul>
                          <li>
                            <p>
                              <span className="la la-map-marker"></span>
                              {location}
                            </p>
                          </li>
                          <li>
                            {/* <p><span className="la la-phone"></span>{phone}</p> */}
                          </li>
                          <li>
                            <p>
                              <span className="la la-calendar-check-o"></span>
                              Applied Date: 2 months ago
                            </p>
                          </li>
                          <li>
                            <label htmlFor="jobStatus">Applicant Status:</label>
                            <select name="jobStatus">
                              <option value="pending">Pending</option>
                              <option value="accepted">Accepted</option>
                              <option value="declined">Declined</option>
                            </select>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="atbd_listing_bottom_content">
                      <div className="atbd_content_left">
                        {/* <div className="atbd_listing_category">
                          <a href=" ">
                            <span className={"la " + icon}></span>
                            {category}
                          </a>
                        </div> */}
                      </div>
                      {/* <ul className="atbd_content_right">
                        <li className="atbd_count">
                          <span className="la la-eye"></span>900+
                        </li>
                        <li className="atbd_save">
                          <span className="la la-heart-o"></span>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export class InternalLIstingsViewAppliedProjects extends Component {
  render() {
    const { list } = this.props;
    return (
      <Fragment>
        {Object.values(list).map((value, key) => {
          const { img, badge, title, location, id } = value;
          return (
            <div className="col-lg-12" key={key}>
              <div className="atbd_single_listing atbd_listing_list">
                <article className="atbd_single_listing_wrapper">
                  <figure className="atbd_listing_thumbnail_area">
                    <div className="atbd_listing_image">
                      <a href=" ">
                        <img src={img} alt="listingimage" />
                      </a>
                    </div>

                    <div className="atbd_thumbnail_overlay_content">
                      <ul className="atbd_upper_badge">
                        <li>
                          <span
                            className={
                              "text-capitalize atbd_badge atbd_badge_" + badge
                            }
                          >
                            {badge}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </figure>

                  <div className="atbd_listing_info">
                    <div className="atbd_content_upper">
                      <h4 className="atbd_listing_title">
                        <NavLink to={"/listing-details" + id}>{title}</NavLink>
                      </h4>
                      <div className="atbd_listing_meta">
                        {/* <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> */}
                        {/* <span className="atbd_meta atbd_listing_price">{"$ "+price}</span> */}
                        {/* <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span> */}
                      </div>
                      <div className="applicant_status_container">
                        <div className="atbd_listing_data_list">
                          <ul>
                            <li>
                              <p>
                                <span className="la la-map-marker"></span>
                                {location}
                              </p>
                            </li>
                            <li>
                              {/* <p><span className="la la-phone"></span>{phone}</p> */}
                            </li>
                            <li>
                              <p>
                                <span className="la la-envelope"></span>
                                Posted 2 months ago
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="applicant_status_item">
                          <h4 style={{ style }}>Application Status:</h4>
                          <p style={{ "margin-left": "1.5rem" }}>
                            Pending Review
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="atbd_listing_bottom_content">
                                            <div className="atbd_content_left">
                                                <div className="atbd_listing_category">
                                                    <a href=" "><span className={"la "+icon}></span>{category}</a>
                                                </div>
                                            </div>
                                            <ul className="atbd_content_right">
                                                <li className="atbd_count"><span className="la la-eye"></span>900+</li>
                                                <li className="atbd_save">
                                                    <span className="la la-heart-o"></span>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </article>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

// Mock data for Support List
