import * as React from "react";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider } from "@mui/material";
import ConfirmationDialog from "./confrimationDialogue";
import UpdateContent from "../content/modal/gallery/updateContent";
import { deleteAlbum } from "../../redux/action/craftProfile";
import { useDispatch } from "react-redux";

export default function MoreOptions({ image, type }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showUploadPhoto, setShowUploadPhoto] = useState(false);

  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteAlbumFunc = (albumId) => {
    dispatch(deleteAlbum(albumId, type));
    handleCloseModal();
    handleClose();
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" >
        Open Popover
      </Button> */}

      <MoreVertIcon onClick={handleClick} />

      <Popover
        style={{ width: "400px" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }} onClick={() => toggleUploadPhoto()}>
          Update
        </Typography>
        <Divider />
        <Typography sx={{ p: 2 }} onClick={() => setOpenModal(true)}>
          Delete
        </Typography>
      </Popover>

      <ConfirmationDialog
        deleteAlbumFunc={() => deleteAlbumFunc(image._id)}
        handleClose={handleCloseModal}
        open={openModal}
        imageId={image._id}
      />

      <UpdateContent
        showUploadPhoto={showUploadPhoto}
        toggleUploadPhoto={toggleUploadPhoto}
        contentInfo={{
          url: image.coverPhoto,
          tags: image.tags,
          description: image.description,
          title: image.albumTitle,
          selectedAlbumId: image._id,
          isPublic: image.public,
          type: type,
        }}
        updatingAlbum={true}
      />
    </div>
  );
}
