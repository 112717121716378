import React, { useState } from "react";
import styled from "styled-components";
import "./New-Conversation.css";
import Modal from "react-overlays/Modal";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import {
  getUsers,
  createThread,
  searchUser,
} from "../../../redux/action/messaging";

const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  width: 500px;
  height: 400px;
  z-index: 1040;
  overflow: auto;
  top: 20%;
  left: 35%;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;
function NewConversation({
  getUsers,
  usersList,
  createThread,
  user,
  searchUser,
  userSearchResult,
  searching,
}) {
  const [show, setShow] = useState(false);
  const newConversation = () => {
    getUsers();
    setShow(!show);
  };
  const [searchText, setSearchText] = useState("");
  const onSearchHandler = ({ currentTarget }) => {
    let { value } = currentTarget;
    setSearchText(value);

    searchUser(value);
  };
  const createThreadUser = (toUser) => {
    createThread(toUser, user);
    setShow(false);
  };

  return (
    <div id="new-message-container">
      <img
        onClick={() => newConversation()}
        src={"assets/img/images/icons/plus.png"}
        alt=""
      />
      <RandomlyPositionedModal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="modal-label"
      >
        <div className="user-list-container">
          <section id="new-conversation-header">
            <span>
              {/* <img src={"/assets/img/images/icons/new-conv.png"} alt="" /> */}
            </span>
            <span>
              <h4 id="modal-label"> Start Conversation</h4>{" "}
            </span>
            <span>
              <img
                onClick={() => setShow(false)}
                src={"/assets/img/images/icons/close-x.png"}
                alt=""
              />
            </span>
          </section>

          <div
            style={{ background: "none", "margin-bottom": "50px" }}
            id="search-container"
          >
            <input
              style={{ color: "black" }}
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={onSearchHandler}
            />
          </div>
          {searching && (
            <img
              width="100"
              src={"/assets/img/loading/purple-loading-spinner_1.gif"}
              alt=""
            />
          )}
          {!searching && userSearchResult.length
            ? userSearchResult.map((user) => (
                <div id="user-list">
                  {" "}
                  <img src={"assets/img/images/profiles/profile.png"} alt="" />
                  <span>{user.userName}</span>
                  <span onClick={() => createThreadUser(user)}>
                    <img
                      id="icon"
                      src={"assets/img/images/icons/conversation.png"}
                      alt=""
                    />
                  </span>
                </div>
              ))
            : !searching &&
              usersList.map((user) => (
                <div id="user-list">
                  {" "}
                  <img
                    id="avatar"
                    src={
                      user.userAvatar
                        ? user.userAvatar
                        : "assets/img/images/profiles/profile.png"
                    }
                    alt=""
                  />
                  <span>{user.userName}</span>
                  <span onClick={() => createThreadUser(user)}>
                    <img
                      id="icon"
                      src={"assets/img/images/icons/conversation.png"}
                      alt=""
                    />
                  </span>
                </div>
              ))}
        </div>
      </RandomlyPositionedModal>
    </div>
  );
}

NewConversation.propTypes = {
  usersList: PropTypes.array,
  getUsers: PropTypes.func.isRequired,
  createThread: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  usersList: state.messaging.usersList,
  userSearchResult: state.messaging.userSearchResult,
  searching: state.messaging.searching,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getUsers, createThread, searchUser })(
  NewConversation
);
