import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { Button } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";

const TermsAndConditions = ({ show, toggleModal, submitAgreement }) => {
  return (
    <>
      <Modal
        show={show}
        animation={true}
        // onHide={() => toggleModal(false)}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h2>Terms of Use</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Welcome to Socicraft.com (the “<em>Website</em>”). This Terms of Use
            Agreement (the “<em>Agreement</em>”) is made and entered into by and
            between you and Socicraft Inc. (the “<em>Company</em>”, “<em>us</em>
            ”, <em>“we</em>”, or “<em>our</em>”). This Agreement sets forth the
            terms and conditions that govern your use of and access to the
            Website and any products, materials, and services provided by or on
            the Website (collectively, the “<em>Services</em>
            ”).&nbsp;
          </p>
          <p>&zwj;</p>
          <p>
            <strong>1. Acceptance of this Agreement.&nbsp;</strong>
          </p>
          <p>
            <em>
              1.1 Acceptance Through Using or Accessing the Services.&nbsp;
            </em>
          </p>
          <p>
            Please review the following terms carefully. By accessing or using
            the Services (or by clicking on “accept” or “agree” to this
            Agreement when prompted), you agree to be bound by the terms and
            conditions of this Agreement on behalf of yourself or the entity or
            organization that you represent. If you do not agree to the terms
            and conditions of this Agreement, you may not use or access the
            Services and must exit the Website immediately.&nbsp;
          </p>
          <p>
            <em>
              1.2 Eligibility Requirements to Use or Access the Services.&nbsp;
            </em>
          </p>
          <p>
            To use the Website or any other Services, you must be (i) at least
            18 years old, (ii) a resident of the United States, and (iii) not a
            competitor of or using the Services for purposes that are
            competitive with the Company.&nbsp;
          </p>
          <p>
            By accessing or using the Services, you represent and warrant that
            you meet all the foregoing eligibility requirements. You also
            represent and warrant that you have the right, authority, and
            capacity to enter into this Agreement on your behalf or the entity
            or organization that you represent. If you do not meet all these
            requirements, you may not use or access the Services.&nbsp;
          </p>
          <p>
            <em>1.3 Changes to this Agreement.&nbsp;</em>
          </p>
          <p>
            The Company reserves the right to change this Agreement from time to
            time in its sole discretion without notice to you. The latest
            version of the Agreement will be posted on the Website and should be
            reviewed prior to accessing or using the Services. All changes will
            be effective immediately when posted on the Website and will apply
            to your use of and access to the Services from that point
            onward.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            Your continued use of or access to the Services following any
            changes to this Agreement shall constitute your acknowledgment of
            such changes and agreement to be bound by the terms and conditions
            of such changes. You should check this page frequently so that you
            are aware of any changes since they are binding on you.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>2. Access to the Services.&nbsp;</strong>
          </p>
          <p>
            <em>2.1 Changes to Your Access and the Services.&nbsp;</em>
          </p>
          <p>
            The Services may change from time to time as the Company evolves,
            refines, or adds more features to the Services. The Company reserves
            the right to modify, withdraw, or discontinue the Services, in whole
            or in part, at any time without notice to you. You agree that the
            Company shall have no liability to you or any third party for any
            losses or damages caused by the Services not being available, in
            whole or in part, at any time or for any period.&nbsp;
          </p>
          <p>
            <em>2.2 Creating an Account.&nbsp;</em>
          </p>
          <p>
            You may be required to register for an account and provide certain
            information about yourself to access the Services or certain
            features of the Services. You promise to provide us with accurate,
            complete, and updated information about yourself.&nbsp;
          </p>
          <p>
            The Company may have different types of accounts for different
            users. If you connect to any Services with a third-party service,
            you grant us permission to access and use your information from such
            service as permitted by that service to store your login credentials
            for that service.&nbsp;
          </p>
          <p>
            All information that you provide will be governed by our Privacy
            Policy. You consent to all actions that we may take with respect to
            your information consistent with our Privacy Policy.&nbsp;
          </p>
          <p>
            <em>2.3 Account Responsibilities.&nbsp;</em>
          </p>
          <p>
            You are entirely responsible for maintaining the confidentiality of
            your password and account. You are also entirely responsible for any
            and all activities associated with your account. Your account is
            personal to you and you agree not to provide any other person with
            access to the Services or any portions of it using your username,
            password, or other security information. You should ensure that you
            exit from your account at the end of each session. You should use
            extra caution when accessing your account from a public or shared
            computer so that others are not able to view or record your password
            or other personal information. You may not transfer your account to
            anyone else without our prior written permission.&nbsp;
          </p>
          <p>
            You agree to notify the Company immediately of any actual or
            suspected unauthorized use of your account or any other breach of
            security. The Company will not be liable for any losses or damages
            arising from your failure to comply with the above requirements. You
            will be held liable for losses or damages incurred by the Company or
            any third party due to someone else using your account or
            password.&nbsp;
          </p>
          <p>
            <em>2.4 Termination or Deletion of an Account.&nbsp;</em>
          </p>
          <p>
            The Company shall have the right to suspend or terminate your
            account at any time in our sole discretion for any or no reason,
            including if we determine that you have violated any terms or
            conditions of this Agreement. You may delete your account at any
            time, for any reason, by following the instructions on the
            Website.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>3. Policy for Using the Services.&nbsp;</strong>
          </p>
          <p>
            <em>3.1 Prohibited Uses.&nbsp;</em>
          </p>
          <p>
            You may use the Services for lawful purposes only and in accordance
            with this Agreement. You agree not to use the Services in any way
            that could damage the Services or general business of the Company.
            You may only use the Services for your own personal, non-commercial
            use, and not on behalf of or for the benefit of any third
            party.&nbsp;
          </p>
          <p>
            You further agree not to engage in any of the following prohibited
            activities in connection with using the Services:&nbsp;
          </p>
          <p>
            (i) <em>No Violation of Laws or Obligations. </em>Violate any
            applicable laws or regulations (including intellectual property laws
            and right of privacy or publicity laws) or any contractual
            obligations.&nbsp;
          </p>
          <p>
            (ii) <em>No Unsolicited Communications. </em>Send any unsolicited or
            unauthorized advertising, promotional materials, spam, junk mail,
            chain letters, or any other form of unsolicited communications,
            whether commercial or otherwise.&nbsp;
          </p>
          <p>
            (iii) <em>No Impersonation. </em>Impersonate others or otherwise
            misrepresent your affiliation with a person or entity in an attempt
            to mislead, confuse, or deceive others.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            (iv) <em>No Harming of Minors</em>. Exploit or harm minors in any
            way, including exposing inappropriate content or obtaining
            personally identifiable information.&nbsp;
          </p>
          <p>
            (v) <em>Compliance with Content Standards. </em>Upload, display,
            distribute, or transmit any material that does not comply with the
            Content Standards set out below in this Agreement.&nbsp;
          </p>
          <p>
            (vi) <em>No Interference with Others’ Enjoyment. </em>Harass or
            interfere with anyone’s use or enjoyment of the Services, or expose
            the Company or other users to liability or other harm.&nbsp;
          </p>
          <p>
            (vii) <em>No Interference or Disabling of the Services. </em>Use any
            device, software, or routine that interferes with the proper working
            of the Services, or take any action that may interfere with,
            disrupt, disable, impair, or create an undue burden on the
            infrastructure of the Services, including servers or networks
            connected to the Website.&nbsp;
          </p>
          <p>
            (viii) <em>No Monitoring or Copying Material. </em>Copy, monitor,
            distribute, or disclose any part of the Services by automated or
            manual processes, devices, or means. This includes, without
            limitation, using automatic devices such as robots, spiders, offline
            readers, crawlers, or scrapers to strip, scrape, or mine data from
            the Website; <em>provided, however, </em>that the Company
            conditionally grants to the operators of public search engines
            revocable permission to use spiders to copy materials from the
            Website for the sole purpose of and solely to the extent necessary
            for creating publicly available searchable indices of the materials,
            but not caches or archives of such materials.&nbsp;
          </p>
          <p>
            (ix) <em>No Viruses, Worms, or Other Damaging Software. </em>
            Upload, transmit, or distribute to or through the Services any
            viruses, Trojan horses, worms, logic bombs, or other materials
            intended to damage or alter the property of others, including
            attacking the Services via a denial-of-service or distributed
            denial-of- service attack.&nbsp;
          </p>
          <p>
            (x) <em>No Unauthorized Access or Violation of Security. </em>
            Violate the security of the Services through (i) any attempt to gain
            unauthorized access to the Services or to other systems or networks
            connected to the Services, (ii) the breach or circumvention of
            encryption or other security codes or tools, or (iii) data mining or
            interference to any server, computer, database, host, user, or
            network connected to the Services.&nbsp;
          </p>
          <p>
            (xi) <em>No Reverse Engineering. </em>Reverse engineer, decompile,
            or otherwise attempt to obtain the source code or underlying
            information of or relating to the Services.&nbsp;
          </p>
          <p>
            (xii) <em>No Collecting User Data. </em>Collect, harvest, or
            assemble any data or information regarding any other user without
            their consent. This includes, without limitation, their emails,
            usernames, or passwords.&nbsp;
          </p>
          <p>
            (xiii) <em>No Other Interference. </em>Otherwise attempt to
            interfere with the proper working of the Services.&nbsp;
          </p>
          <p>
            (xiv) <em>Attempt or Assist Others in Attempting. </em>Attempt any
            of the foregoing or assist, permit, or encourage others to do or
            attempt any of the foregoing.&nbsp;
          </p>
          <p>
            <em>3.2 Geographic Restrictions.&nbsp;</em>
          </p>
          <p>
            The Company is based in the United States. The Services are for use
            by persons located in the United States only. By choosing to access
            the Services from any location other than the United States, you
            accept full responsibility for compliance with all local laws. The
            Company makes no representations that the Services or any of its
            content are accessible or appropriate outside of the United
            States.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>4. Intellectual Property Rights.&nbsp;</strong>
          </p>
          <p>
            <em>4.1 Ownership of Intellectual Property.&nbsp;</em>
          </p>
          <p>
            You acknowledge that all intellectual property rights, including
            copyrights, trademarks, trade secrets, and patents, in the Services
            and its contents, features, and functionality (collectively, the “
            <em>Content</em>”), are owned by the Company, its licensors, or
            other providers of such material. The Content is protected by U.S.
            and international intellectual property or proprietary rights laws.
            Neither this Agreement nor your access to the Services transfers to
            you any right, title, or interest in or to such intellectual
            property rights. Any rights not expressly granted in this Agreement
            are reserved by the Company and its licensors.&nbsp;
          </p>
          <p>
            <em>4.2 License to Use the Services.&nbsp;</em>
          </p>
          <p>
            During the Term of this Agreement, the Company grants you a limited,
            non- exclusive, non-transferable, non-sublicensable, and revocable
            license to use and access the Content solely for personal and
            non-commercial use only in accordance with this Agreement. The
            Content may not be used for any other purpose. This license will
            terminate upon your cessation of use of the Services or at the
            termination of this Agreement.&nbsp;
          </p>
          <p>
            <em>4.3 Certain Restrictions.&nbsp;</em>
          </p>
          <p>
            The rights granted to you in this Agreement are subject to the
            following restrictions:&nbsp;
          </p>
          <p>
            (i) <em>No Copying or Distribution. </em>You shall not copy,
            reproduce, publish, display, perform, post, transmit, or distribute
            any part of the Content in any form or by any means except as
            expressly permitted herein or as enabled by a feature, product, or
            the Services when provided to you.&nbsp;
          </p>
          <p>
            (ii) <em>No Modifications. </em>You shall not modify, create
            derivative works from, translate, adapt, disassemble, reverse
            compile, or reverse engineer any part of the Content.&nbsp;
          </p>
          <p>
            (iii) <em>No Exploitation. </em>You shall not sell, license,
            sublicense, transfer, assign, rent, lease, loan, host, or otherwise
            exploit the Content or the Services in any way, whether in whole or
            in part.&nbsp;
          </p>
          <p>
            (iv) <em>No Altering of Notices. </em>You shall not delete or alter
            any copyright, trademark, or other proprietary rights notices from
            copies of the Content.&nbsp;
          </p>
          <p>
            (v) <em>No Competition</em>. You shall not access or use the Content
            in order to build a similar or competitive website, product, or
            service.&nbsp;
          </p>
          <p>
            (vi) <em>Systematic retrieval</em>. You shall not use any
            information retrieval system to create, compile, directly or
            indirectly, a database, compilation, collection or directory of the
            Content or other data from the Services.&nbsp;
          </p>
          <p>
            <em>4.4 Trademark Notice.&nbsp;</em>
          </p>
          <p>
            All trademarks, logos, and service marks displayed on the Services
            are either the Company’s property or the property of third parties.
            You may not use such trademarks, logos, or service marks without the
            prior written consent of their respective owners.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>5. User Content.&nbsp;</strong>
          </p>
          <p>
            <em>5.1 User Generated Content.&nbsp;</em>
          </p>
          <p>
            The Services may contain message boards, chatrooms, profiles,
            forums, and other interactive features that allow users to post,
            upload, submit, publish, display, or transmit to other users or
            other persons content or materials (collectively, “
            <em>User Content</em>”) on or through the Services.&nbsp;
          </p>
          <p>
            You are solely responsible for your User Content. Please consider
            carefully what you choose to share. All User Content must comply
            with the Content Standards set forth below. Any User Content you
            post on or through the Services will be considered non-confidential
            and non-proprietary. You assume all risks associated with the use of
            your User Content. This includes any reliance on its accuracy,
            completeness, reliability, or appropriateness by other users and
            third parties, or any disclosure of your User Content that
            personally identifies you or any third&nbsp;
          </p>
          <p>
            party. You agree that the Company shall not be responsible or liable
            to any third party for any User Content posted by you or any other
            user of the Services.&nbsp;
          </p>
          <p>
            You further agree that the Company shall not be responsible for any
            loss or damage incurred as the result of any interactions between
            you and other users. Your interactions with other users are solely
            between you and such users. If there is a dispute between you and
            any other user, we are under no obligation to become involved.&nbsp;
          </p>
          <p>
            <em>5.2 License.&nbsp;</em>
          </p>
          <p>
            You hereby grant to the Company an irrevocable, non-exclusive,
            royalty-free and fully paid, transferable, perpetual, and worldwide
            license to reproduce, distribute, publicly display and perform,
            prepare derivative works of, incorporate into other works, and
            otherwise use and exploit your User Content, and to grant
            sublicenses of the foregoing rights, in connection with the Services
            and the Company’s business including, without limitation, for
            promoting and redistributing part or all of the Services in any
            media formats and through any media channels.&nbsp;
          </p>
          <p>
            You represent and warrant that you have all the rights, power, and
            authority necessary to grant the rights granted herein to any User
            Content that you submit. You hereby irrevocably waive all claims and
            have no recourse against us for any alleged or actual infringement
            or misappropriation of any proprietary rights in any communication,
            content, or material submitted to us. Please note that all of the
            following licenses are subject to our Privacy Policy&nbsp; to the
            extent they relate to any User Content that contains any personally
            identifiable information.&nbsp;
          </p>
          <p>
            <em>5.3 Content Standards.&nbsp;</em>
          </p>
          <p>
            You agree not to send, knowingly receive, upload, transmit, display,
            or distribute any User Content that does not comply with the
            following standards (“<em>Content Standards</em>”). User Content
            must not:&nbsp;
          </p>
          <p>
            (a) <em>Violate Laws or Obligations. </em>Violate any applicable
            laws or regulations (including intellectual property laws and right
            of privacy or publicity laws), or any contractual or fiduciary
            obligations.&nbsp;
          </p>
          <p>
            (b) <em>Promote Illegal Activity or Harm to Others</em>. Promote any
            illegal activity; advocate, promote, or assist any unlawful act; or
            create any risk of any harm, loss, or damage to any person or
            property.&nbsp;
          </p>
          <p>
            (c) <em>Infringe Intellectual Property Rights. </em>Infringe any
            copyright, trademark, patent, trade secret, moral right, or other
            intellectual property rights of any other person.&nbsp;
          </p>
          <p>
            (d){" "}
            <em>Defamatory, Abusive, or Otherwise Objectionable Material. </em>
            Contain any information or material that we deem to be unlawful,
            defamatory, trade libelous, invasive of another’s privacy or
            publicity rights, abusive, threatening, harassing, harmful, violent,
            hateful, obscene, vulgar, profane, indecent, offensive,
            inflammatory, humiliating to other people (publicly or otherwise),
            or otherwise objectionable. This includes any information or
            material that we deem to cause annoyance, inconvenience, or needless
            anxiety, or be likely to upset, embarrass, alarm, or annoy another
            person.&nbsp;
          </p>
          <p>
            (e){" "}
            <em>Promotion of Sexually Explicit Material or Discrimination. </em>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation, or age.&nbsp;
          </p>
          <p>
            (f) <em>Fraudulent Information or Impersonation. </em>Contain any
            information or material that is false, intentionally misleading, or
            otherwise likely to deceive any person including, without
            limitation, impersonating any person, or misrepresenting your
            identity or affiliation with any person or organization.&nbsp;
          </p>
          <p>
            (g) <em>Commercial Activity. </em>Involve commercial activities or
            sales, such as contests, sweepstakes, and other sales promotions,
            barter, or advertising.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            (h) <em>Endorsement by the Company. </em>Represent or imply to
            others that it is in any way provided, sponsored, or endorsed by the
            Company or any other person or entity, if that is not the
            case.&nbsp;
          </p>
          <p>
            <em>5.4 Monitoring and Enforcement.&nbsp;</em>
          </p>
          <p>
            We reserve the right at all times, but are not obligated, to:&nbsp;
          </p>
          <p>
            (a) take any action with respect to any User Content that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such User Content violates the Content Standards or any
            other provision in this Agreement, or creates liability for the
            Company or any other person. Such action may include reporting you
            to law enforcement authorities.&nbsp;
          </p>
          <p>
            (b) remove or reject any User Content for any or no reason in our
            sole discretion.&nbsp;
          </p>
          <p>
            (c) disclose any User Content, your identity, or electronic
            communication of any kind to satisfy any law, regulation, or
            government request, or to protect the rights or property of the
            Company or any other person.&nbsp;
          </p>
          <p>
            (d) Terminate or suspend your access to all or part of the Services
            for any or no reason, including without limitation, any violation of
            this Agreement.&nbsp;
          </p>
          <p>
            We do not review User Content before it is posted on or through the
            Services, and therefore cannot ensure prompt removal of questionable
            User Content. Accordingly, the Company and its affiliates, and their
            respective officers, directors, employees or agents, assume no
            liability for any action or inaction regarding transmissions,
            communications, or content provided by any user or third party. The
            Company shall have no liability or responsibility to anyone for
            performance or non-performance of the activities described in this
            Section.&nbsp;
          </p>
          <p>
            <em>
              5.5 Copyright Infringement (Digital Millennium Copyright Act
              Policy).&nbsp;
            </em>
          </p>
          <p>
            The Company respects the intellectual property of others and expects
            users of the Services to do the same. It is the Company’s policy to
            terminate the users of our Services who are repeat infringers of
            intellectual property rights, including copyrights. If you believe
            that your work has been copied in a way that constitutes copyright
            infringement and wish to have the allegedly infringing material
            removed, please provide the following information in accordance with
            the Digital Millennium Copyright Act to our designated copyright
            agent:&nbsp;
          </p>
          <p>&nbsp;9/18&nbsp;</p>
          <p>
            (i) a physical or electronic signature of the copyright owner or a
            person authorized to act on their behalf;&nbsp;
          </p>
          <p>
            (ii) a description of the copyrighted work that you allege has been
            infringed;&nbsp;
          </p>
          <p>
            (iii) a description of the material that is claimed to be infringing
            or to be the subject of infringing activity and that is to be
            removed or access to which is to be disabled;&nbsp;
          </p>
          <p>
            (iv) a description of where the material that you claim is
            infringing is located;&nbsp;
          </p>
          <p>
            (v) your contact information, including your address, telephone
            number, and email address;&nbsp;
          </p>
          <p>
            (vi) a statement that you have a good faith belief that use of the
            objectionable material is not authorized by the copyright owner, its
            agent, or under the law; and&nbsp;
          </p>
          <p>
            (vii) a statement by you, made under penalty of perjury, that the
            above information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner’s
            behalf.&nbsp;
          </p>
          <p>
            Please note that pursuant to 17 U.S.C. § 512(f), any
            misrepresentation of material fact in a written notification
            automatically subjects the complaining party to liability for any
            damages, costs, and attorneys’ fees incurred by us in connection
            with the written notification and allegation of copyright
            infringement.&nbsp;
          </p>
          <p>
            Designated copyright agent for the Company: NAME: Chloe Leaaetoa
            <br />
            ADDRESS:&nbsp;
          </p>
          <p>
            1261 Locust Street, #221
            <br />
            Walnut Creek, California, CA, 94596&nbsp;
          </p>
          <p>TELEPHONE: 800-287-0339 EMAIL: info@socicraft.com&nbsp;</p>
          <p>
            <br />
          </p>
          <p>
            <em>5.6 Feedback to the Company.&nbsp;</em>
          </p>
          <p>
            If you provide the Company with any feedback or suggestions
            regarding the Services (“<em>Feedback</em>”), you hereby assign to
            the Company all rights in such Feedback and agree that the Company
            shall have the right to use and fully exploit such Feedback and
            related information in any manner it deems appropriate. The Company
            will treat any Feedback that you provide to the Company as non-
            confidential and non-proprietary. You agree that you will not submit
            to the Company any information or ideas that you consider to be
            confidential or proprietary.&nbsp;
          </p>
          <p>&zwj;</p>
          <p>
            <strong>6. Assumption of Risk.&nbsp;</strong>
          </p>
          <p>
            The information presented on or through the Services is made
            available for general information purposes only. The Company does
            not warrant the accuracy, completeness, suitability, or quality of
            any such information. Any reliance on such information is strictly
            at your own risk. The Company disclaims all liability and
            responsibility arising from any reliance placed on such information
            by you or any other user to the Services, or by anyone who may be
            informed of any of its contents.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>7. Privacy.&nbsp;</strong>
          </p>
          <p>
            For information about how the Company collects, uses, and shares
            your information, please review our Privacy Policy
            (https://socicraft.com/privacyPolicy). You agree that by using the
            Services you consent to the collection, use, and sharing (as set
            forth in the Privacy Policy) of such information.&nbsp;
          </p>
          <p>
            The Children’s Online Privacy Protection Act requires that online
            service providers obtain parental consent before they knowingly
            collect personally identifiable information online from children who
            are under 13 years old. We do not knowingly collect or solicit
            personally identifiable information from children under 13 years
            old. If you are a child under 13 years old, please do not attempt to
            register for the Services or send any personal information about
            yourself to us. If we learn we have collected personal information
            from a child under 13 years old, we will delete that information as
            quickly as possible. If you believe that a child under 13 years old
            may have provided us personal information, please contact us.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>8. Third-Party Links and Ads.&nbsp;</strong>
          </p>
          <p>
            The Services may contain links to third-party websites, resources,
            and services, as well as advertisements (collectively, “
            <em>Third-Party Links</em>”). Third-Party Links are provided for
            your convenience only. The Company does not review, approve,
            monitor, endorse, warrant, or make any representations with respect
            to Third-Party Links. The Company has no control over the contents,
            products, or services of any Third-Party Link and accepts no
            responsibility for them or for any loss or damage that may arise
            from your use of them. If you decide to access any Third-Party Link,
            you do so entirely at your own risk and subject to the terms and
            conditions of use for such Third-Party Link. You should make
            whatever investigation you feel necessary or appropriate before
            proceeding with any transaction in connection with any Third-Party
            Link.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>9. Termination.&nbsp;</strong>
          </p>
          <p>
            <em>9.1. Termination.&nbsp;</em>
          </p>
          <p>
            The Company may suspend or terminate your access or rights to use
            the Services at any time, for any reason, in our sole discretion,
            and without prior notice, including for any breach of the terms of
            this Agreement. Upon termination of your access or rights to use the
            Services, your right to access and use the Services will immediately
            cease. The Company will not have any liability whatsoever to you for
            any suspension or termination of your rights under this Agreement,
            including for termination of your account or deletion of your User
            Content. If you have registered for an account, you may terminate
            this Agreement at any time by contacting the Company and requesting
            termination.&nbsp;
          </p>
          <p>
            <em>9.2 Effect of Termination.&nbsp;</em>
          </p>
          <p>
            Upon termination of this Agreement, any provisions that by their
            nature should survive termination shall remain in full force and
            effect. This includes, without limitation, ownership or intellectual
            property provisions, warranty disclaimers, and limitations of
            liability. Termination of your access to and use of the Services
            shall not relieve you of any obligations arising or accruing prior
            to termination or limit any liability that you otherwise may have to
            the Company or any third party. You understand that any termination
            of your access to and use of the Services may involve deletion of
            your User Content associated with your account from our
            databases.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>10. No Warranty.&nbsp;</strong>
          </p>
          <p>
            THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS.
            USE OF THE SERVICES IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED WITHOUT
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
            OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
            ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.&nbsp;
          </p>
          <p>
            WITHOUT LIMITING THE FOREGOING, THE COMPANY AND ITS LICENSORS DO NOT
            WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE, COMPLETE, OR
            CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT THE
            SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
            UNINTERRUPTED, ERROR-FREE, OR SECURE; THAT ANY DEFECTS OR ERRORS
            WILL BE CORRECTED; THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS; OR THAT THE SERVICES OR ITEMS OBTAINED THROUGH
            THE SERVICES WILL OTHERWISE MEET YOUR REQUIREMENTS OR EXPECTATIONS.
            TO THE FULLEST EXTENT PROVIDED BY LAW. WE WILL NOT BE LIABLE FOR ANY
            LOSS OR DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE DEVICE, DATA, OR
            OTHER PROPRIETARY MATERIAL THAT MAY RESULT FROM YOUR USE OF THE
            SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR YOUR DOWNLOADING
            OF ANY MATERIAL POSTED ON THE SERVICES. WE DO NOT WARRANT, ENDORSE,
            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICES
            ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR
            THIRD-PARTY LINKS, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
            MONITOR ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
            PRODUCTS OR SERVICES OR ANY OTHER USER.&nbsp;
          </p>
          <p>
            THE SERVICES WOULD NOT BE PROVIDED WITHOUT THESE LIMITATIONS. NO
            ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
            US THROUGH THE SERVICES SHALL CREATE ANY WARRANTY, REPRESENTATION,
            OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO
            THE&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            ABOVE EXCLUSION MAY NOT APPLY TO YOU. IF APPLICABLE LAW REQUIRES ANY
            WARRANTIES WITH RESPECT TO THE SERVICES, ALL SUCH WARRANTIES ARE
            LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST
            USE.&nbsp;
          </p>
          <p>&zwj;</p>
          <p>
            <strong>11. Limitation of Liability.&nbsp;</strong>
          </p>
          <p>
            TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT SHALL
            THE COMPANY OR ITS AFFILIATES, OR THEIR RESPECTIVE LICENSORS,
            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
            LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES OF ANY KIND, UNDER
            ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OR
            INABILITY TO USE THE SERVICES, ANY THIRD-PARTY LINK, OR ANY CONTENT
            ON THE SERVICES OR SUCH THIRD-PARTY LINK, INCLUDING, WITHOUT
            LIMITATION, ANY LOSS OF USE, REVENUE, OR PROFIT, LOSS OF BUSINESS OR
            ANTICIPATED SAVINGS, LOSS OF DATA, LOSS OF GOODWILL, OR DIMINUTION
            IN VALUE, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
            SPECIAL, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF
            CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF
            WHETHER SUCH DAMAGE WAS FORESEEABLE AND WHETHER OR NOT THE COMPANY
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE
            REMEDY FOR DISSATISFACTION WITH THE SERVICES IS TO STOP USING THE
            SERVICES.&nbsp;
          </p>
          <p>
            SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
            DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO
            YOU.&nbsp;
          </p>
          <p>&zwj;</p>
          <p>
            <strong>12. Indemnification.&nbsp;</strong>
          </p>
          <p>
            You agree to indemnify, defend, and hold harmless the Company and
            its affiliates and their respective officers, directors, employees,
            agents, affiliates, successors, and permitted assigns (collectively,
            “<em>Indemnified Party</em>”) from and against any and all losses,
            claims, actions, suits, complaints, damages, liabilities, penalties,
            interest, judgments, settlements, deficiencies, disbursements,
            awards, fines, costs, fees, or expenses of whatever kind, including
            reasonable attorneys’ fees, fees and other costs of enforcing any
            right to indemnification under this Agreement, and the cost of
            pursuing any insurance providers, arising out of or relating to your
            breach&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            of this Agreement or your use or misuse of the Services including,
            but not limited to, your User Content or any actions taken by a
            third party using your account. The Company reserves the right, at
            your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to
            assist and cooperate with our defense or settlement of these
            claims.&nbsp;
          </p>
          <p>&zwj;</p>
          <p>
            <strong>13. Disputes.&nbsp;</strong>
          </p>
          <p>
            <em>13.1 Governing Law.&nbsp;</em>
          </p>
          <p>
            All matters relating to this Agreement, and all matters arising out
            of or relating to this Agreement, whether sounding in contract,
            tort, or statute are governed by, and construed in accordance with,
            the laws of the State of California, without giving effect to any
            conflict of law principles.&nbsp;
          </p>
          <p>
            <em>13.2 Dispute Resolution.&nbsp;</em>
          </p>
          <p>
            Any action or proceeding arising out of or related to this Agreement
            or the Services shall be brought only in a state or federal court
            located in the State
            <br />
            of California, Contra Costa County, although we retain the right to
            bring any suit, action, or proceeding against you for breach of this
            Agreement in your country of residence or any other relevant
            country. You hereby irrevocably submit to the jurisdiction of these
            courts and waive the defense of inconvenient forum to the
            maintenance of any action or proceeding in such venues.&nbsp;
          </p>
          <p>
            At the Company’s sole discretion, it may require any dispute, claim,
            or controversy arising out of or relating to this Agreement, or the
            breach, termination, enforcement, interpretation, or validity
            thereof, to be submitted to and decided by a single arbitrator by
            binding arbitration under the rules of the American Arbitration
            Association in Contra Costa County. The decision of the arbitrator
            shall be final and binding on the parties and may be entered and
            enforced in any court of competent jurisdiction by either party. The
            prevailing party in the arbitration proceedings shall be awarded
            reasonable attorneys’ fees, expert witness costs and expenses, and
            all other costs and expenses incurred directly or indirectly in
            connection with the proceedings, unless the arbitrator shall for
            good cause determine otherwise.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            All arbitrations shall proceed on an individual basis. You agree
            that you may bring claims against the Company in arbitration only in
            your individual capacities and in so doing you hereby waive the
            right to a trial by jury, to assert or participate in a class action
            lawsuit or class action arbitration (either as a named-plaintiff or
            class member), and to assert or participate in any joint or
            consolidated lawsuit or joint or consolidated arbitration of any
            kind. Notwithstanding anything to the contrary under the rules of
            the American Arbitration Association, the arbitrator may not
            consolidate more than one person's claims, and may not otherwise
            preside over any form of a representative or class proceeding. If a
            court decides that applicable law precludes enforcement of any of
            this paragraph's limitations as to a particular claim for relief,
            then that claim (and only that claim) must be severed from the
            arbitration and may be brought in court.&nbsp;
          </p>
          <p>
            YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU ARE
            WAIVING THE RIGHT TO TRIAL BY JURY OR TO&nbsp;PARTICIPATE IN A CLASS
            ACTION.&nbsp;
          </p>
          <p>
            <em>13.3 Limitation of Time to File Claims.&nbsp;</em>
          </p>
          <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THIS AGREEMENT OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1)
            YEAR AFTER THE CAUSE OF ACTION AROSE; OTHERWISE, SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY WAIVED AND BARRED.&nbsp;
          </p>
          <p>&zwj;</p>
          <p>
            <strong>14. Miscellaneous.&nbsp;</strong>
          </p>
          <p>
            <em>14.1 Waiver.&nbsp;</em>
          </p>
          <p>
            Except as otherwise set forth in this Agreement, no failure of the
            Company to exercise, or delay by the Company in exercising, any
            right, remedy, power, or privilege arising from this Agreement shall
            operate or be construed as a waiver thereof, nor shall any single or
            partial exercise of any right, remedy, power, or privilege hereunder
            preclude any other or further exercise thereof or the exercise of
            any other right, remedy, power, or privilege.&nbsp;
          </p>
          <p>
            <em>14.2 Severability.&nbsp;</em>
          </p>
          <p>
            If any term or provision of this Agreement is found by a court of
            competent jurisdiction to be invalid, illegal, or unenforceable,
            such invalidity, illegality, or unenforceability shall not affect
            any other term or provision of this Agreement or invalidate or
            render unenforceable such term or provision in any other
            jurisdiction.
          </p>
          <p>
            <em>14.3 Entire Agreement.&nbsp;</em>
          </p>
          <p>
            This Agreement, together with all documents referenced herein,
            constitutes the entire agreement between you and the Company with
            respect to the subject matter contained herein. This Agreement
            supersedes all prior and contemporaneous understandings, agreements,
            representations, and warranties, both written and oral, with respect
            to the subject matter hereof.&nbsp;
          </p>
          <p>
            <em>14.4 Headings.&nbsp;</em>
          </p>
          <p>
            Headings and titles of sections, clauses, and parts in this
            Agreement are for convenience only. Such headings and titles shall
            not affect the meaning of any provisions of the Agreement.&nbsp;
          </p>
          <p>
            <em>14.5 No Agency, Partnership or Joint Venture.&nbsp;</em>
          </p>
          <p>
            No agency, partnership, or joint venture has been created between
            you and the Company as a result of this Agreement. You do not have
            any authority of any kind to bind the Company in any respect
            whatsoever.&nbsp;
          </p>
          <p>
            <em>14.6 Assignment.&nbsp;</em>
          </p>
          <p>
            You shall not assign or delegate any of your rights or obligations
            under this Agreement without the prior written consent of the
            Company. Any purported assignment or delegation in violation of this
            Section shall be deemed null and void. No assignment or delegation
            shall relieve you of any of your obligations hereunder. The Company
            may freely assign or delegate its rights and obligations under this
            Agreement at any time. Subject to the limits on assignment stated
            above, this Agreement will inure to the benefit of, be binding on,
            and be enforceable against each of the parties hereto and their
            respective successors and assigns.&nbsp;
          </p>
          <p>
            <em>14.7 Export Laws.&nbsp;</em>
          </p>
          <p>
            The Services may be subject to U.S. export control laws and
            regulations. You agree to abide by these laws and their regulations
            (including, without limitation, the Export Administration Act and
            the Arms Export Control Act) and not to transfer, by electronic
            transmission or otherwise, any materials from the Services to either
            a foreign national or a foreign destination in violation of such
            laws or regulations.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>15. Contact Information.&nbsp;</strong>
          </p>
          <p>
            All notices of copyright infringement claims should be sent to the
            designated copyright agent as provided in Section 5 (
            <em>User Content</em>). All other feedback, comments, requests for
            technical support, and other communications relating to the Services
            should be directed to info@socicraft.com.&nbsp;
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => submitAgreement()} variant="secondary">
            Agree
          </Button>
          <Button onClick={() => toggleModal(false)} variant="danger">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TermsAndConditions.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(TermsAndConditions);
