import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserListings } from "../../../redux/action/userpost";
import ProjectCardItem from "./projectCardItem";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../modal/spinnermodal";
export default function ListingCardGrid4() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserListings());
  }, [dispatch]);

  const userListings = useSelector((state) => state.userListing.userListings);
  const isLoading = useSelector((state) => state.process.isloading);

  return (
    <div className="account__listing-container">
      {isLoading && <Spinner />}
      {userListings.length === 0 && isLoading === false && (
        <h2 className="alert alert-warning " role="alert">
          No Projects Found
        </h2>
      )}
      {userListings.map((post) => (
        <ProjectCardItem
          uniqueKey={uuidv4()}
          id={post.id}
          listingImageUrl={post.listingImageUrl}
          title={post.title}
          label={post.projectLabel}
          projectEndDate={post.projectEndDate}
          projectLocation={post.projectLocation}
          compType={post.compensationType}
          compensation={post.compensation}
          projectStartDate={post.projectStartDate}
          createdAt={post.createdAt}
          cost={post.cost}
          totalHours={post.totalHours}
        />
      ))}
    </div>
  );
}
