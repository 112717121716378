import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

export class BreadcrumbWraper extends Component {
  render() {
    return (
      <Fragment>
        <div className="breadcrumb-wrapper content_above">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h1 className="page-title">{this.props.title}</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.props.title}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class BreadcrumbSingle extends Component {
  render() {
    const { title } = this.props.filter[0];
    return (
      <Fragment>
        <div className="col-lg-12 col-md-12">
          {/* <ul className="list-unstyled listing-info--badges">
                        <li><span className={"atbd_badge atbd_badge_"+badge}>{badge}</span></li>
                        <li><span className="atbd_badge atbd_badge_popular">Popular</span></li>
                    </ul>
                    <ul className="list-unstyled listing-info--meta">
                        <li>
                            <div className="atbd_listing_category">
                                <a href=" "><span className="la la-glass"></span>{category}</a>
                            </div>
                        </li>
                    </ul> */}
          {/*<!-- ends: .listing-info-meta -->*/}
          <span className="" style={{ float: "right" }}>
            <input type="" id="edit" hidden></input>
            <label
              htmlFor="edit"
              className="btn btn-xs btn-gradient btn-gradient-two"
            >
              <i className="ri-edit-2-line"></i>
            </label>
          </span>
          <div className="atbd_author_avatar">
            <div className="container-for-atbd_author_avatar">
              <img
                alt=""
                src="./assets/img/author-profile.jpg"
                className="avatar avatar-32 photo avatar-round-corners"
              />
              <div className="round-on-hover">
                {/* this is what the file seelct button is */}
                <input type="file" id="profilePictureUpload" hidden></input>
                <label
                  htmlFor="profilePictureUpload"
                  className="btn btn-xs btn-gradient btn-gradient-two"
                >
                  <span className="la la-plus"></span>
                </label>
              </div>
            </div>
          </div>
          <h1>Strawberry Basil Lemonade</h1>
          <p className="subtitle">{title}</p>
          <i className="ri-home-3-line ri-lg"> 0</i>
          <br />
          <i className="ri-eye-line ri-lg"> 0</i>
          <br />
          <i className="ri-award-line ri-lg"> 0</i>

          <span className="" style={{ float: "right" }}>
            <input type="file" id="headerPictureUpload" hidden></input>
            <label
              htmlFor="headerPictureUpload"
              className="btn btn-xs btn-gradient btn-gradient-two"
            >
              <span className="">Upload Header Image</span>
            </label>
          </span>
        </div>
      </Fragment>
    );
  }
}

export class BreadcrumbAbout extends Component {
  render() {
    return (
      <Fragment>
        <div className="about-intro content_above">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <h1>Place your Businessor Explore Anything what you want</h1>
                <a
                  href="https://www.youtube.com/watch?v=0C4fX_x_Vsg"
                  className="video-iframe play-btn-two"
                >
                  <span className="icon">
                    <i className="la la-youtube-play"></i>
                  </span>
                  <span>Play our Video</span>
                </a>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-6 offset-md-0 col-sm-8 offset-sm-2">
                <img src="./assets/img/about-illustration.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
