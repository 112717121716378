import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Modal from "react-overlays/Modal";
import { toggleConversationList } from "../../../redux/action/messaging";
import Popper from "@material-ui/core/Popper";
import moment from "moment";
import "./Chat-Title.css";
import { call } from "../../../redux/action/videoCalling";
import {
  getMessage,
  getUsers,
  createThread,
  clearConversation,
} from "../../../redux/action/messaging";
function ChatTitle({
  title,
  active,
  messageStatus,
  toggleConversationList,
  getMessage,
  conversation,
  usersList,
  getUsers,
  createThread,
  user,
  call,
  clearConversation,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const togglePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const togglePopper2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const [show, setShow] = useState(false);
  // const newConversation = () => {
  //   getUsers();
  //   setShow(!show);
  // };

  const createThreadUser = (toUser) => {
    createThread(toUser, user);
    setShow(false);
  };
  const callUser = () => {
    let foundUser = messageStatus.participants.find(
      (participant) => participant.userId.id.toString() !== user.id.toString()
    );

    const callInfo = {
      userName: foundUser.userId.userName,
      id: foundUser.userId.id,
      callerName: user.userName,
    };

    call(callInfo);
  };
  const clearConversationHandler = () => {
    if (messageStatus.currentConversation) {
      clearConversation(messageStatus.currentConversation);
      togglePopper();
    }
  };

  const reportUserForm = () => {
    const url = 'https://support.socicraft.com/portal/en/newticket'
    window.open(url, "_blank");
  };

  return (
    <>
      <div id="chat-title">
        <div id="title-options">
          <span id="title-options-bar" onClick={(e) => togglePopper2(e)}>
            <i className="fas fa-bars"></i>
            <Popper
              id={"open ? simple-popper : undefined"}
              open={open2}
              anchorEl={anchorEl2}
              keepMounted={true}
            >
              <div className="popper-conversation">
                <div id="">
                  {conversation.map((data) => (
                    <div
                      // className={`conversation ${getActiveMessage(data) && "active"}`}
                      className="conversation"
                      onClick={() => getMessage(data)}
                      key={data.id.toString()}
                    >
                      <img
                        height="40px"
                        width="40px"
                        src={
                          data.userAvatar
                            ? data.userAvatar
                            : "assets/img/images/profiles/profile.png"
                        }
                        style={{
                          borderColor: data.onlineStatus ? "green" : "red",
                        }}
                        alt=""
                      />

                      <div className="title-text"> {data.subject}</div>
                      <div className="created-date">
                        {moment(
                          data.lastMessage && data.lastMessage.createdAt
                            ? new Date(data.lastMessage.createdAt).toISOString()
                            : new Date(data.time).toISOString()
                        ).fromNow("dd") + " ago"}
                      </div>
                      <div className="conversation-message">
                        {/* {isUserTyping(data) && (
                          <>
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src="/assets/img/images/profiles/profile.png"
                              alt=""
                            />{" "}
                            <span>typing</span>
                          </>
                        )} */}
                        {data.lastMessage && data.lastMessage.text}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div id="popper-message-container">
                <img
                  onClick={() => {
                    getUsers();
                    togglePopper2();
                    setShow(true);
                  }}
                  src={"assets/img/images/icons/plus.png"}
                  alt=""
                />
                <Modal
                  show={show}
                  onHide={() => setShow(false)}
                  aria-labelledby="modal-label"
                  className="new-conversation-modal"
                >
                  <div className="user-list-container">
                    <section id="new-conversation-header">
                      <span>
                        <img
                          src={"/assets/img/images/icons/new-conv.png"}
                          alt=""
                        />
                      </span>
                      <span>
                        <h4 id="modal-label"> Start Conversation</h4>{" "}
                      </span>
                      <span>
                        <img
                          id="close"
                          onClick={() => setShow(false)}
                          src={"/assets/img/images/icons/close-x.png"}
                          alt=""
                        />
                      </span>
                    </section>
                    <div
                      style={{ background: "none", "margin-bottom": "50px" }}
                      id="search-container"
                    >
                      <input
                        style={{ color: "black" }}
                        type="text"
                        placeholder="Search"
                        // value={searchText}
                        // onChange={onSearchHandler}
                      />
                    </div>
                    {/* {searching && (
                      <img
                        width="100"
                        src={"/assets/img/loading/purple-loading-spinner_1.gif"}
                        alt=""
                      />
                    )} */}
                    {/* {!searching && userSearchResult.length
                      ? userSearchResult.map((user) => (
                          <div id="user-list">
                            {" "}
                            <img
                              src={"assets/img/images/profiles/profile.png"}
                              alt=""
                            />
                            <span>{user.userName}</span>
                            <span onClick={() => createThreadUser(user)}>
                              <img
                                id="icon"
                                src={"assets/img/images/icons/conversation.png"}
                                alt=""
                              />
                            </span>
                          </div>
                        ))
                      : !searching && */}
                    {usersList.map((user) => (
                      <div id="user-list">
                        {" "}
                        <img
                          id="avatar"
                          src={
                            user.userAvatar
                              ? user.userAvatar
                              : "assets/img/images/profiles/profile.png"
                          }
                          alt=""
                        />
                        <span>{user.userName}</span>
                        <span onClick={() => createThreadUser(user)}>
                          <img
                            id="icon"
                            src={"assets/img/images/icons/conversation.png"}
                            alt=""
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </Modal>
              </div>
            </Popper>{" "}
          </span>
          <span>
            {messageStatus.currentConversation && messageStatus.title
              ? messageStatus.title
              : ""}
          </span>{" "}
          <span>
            {messageStatus.title !== "SOCIBOT" &&
              messageStatus.currentConversation &&
              messageStatus.onlineStatus && (
                <img
                  onClick={() => callUser()}
                  id="video-icon"
                  src={"assets/img/images/icons/video-icon.png"}
                  alt="Delete Conversation"
                  width={30}
                  height={30}
                />
              )}
          </span>{" "}
        </div>

        <>
          {" "}
          {messageStatus.title !== "SOCIBOT" &&
          messageStatus.currentConversation &&
          open ? (
            <img
              onClick={togglePopper}
              src={"assets/img/images/icons/close-x.png"}
              alt="Delete Conversation"
            />
          ) : (
            messageStatus.title !== "SOCIBOT" &&
            messageStatus.currentConversation && (
              <img
                onClick={togglePopper}
                src={"assets/img/images/icons/menu.png"}
                alt="Delete Conversation"
              />
            )
          )}
          <Popper
            id={"open ? simple-popper : undefined"}
            open={open}
            anchorEl={anchorEl}
          >
            <div className="popper-menu">
              <button onClick={() => clearConversationHandler()}>
                <i className="fas fa-eraser"></i>Clear Conversation
              </button>
              {/* <button>
                <i className="fas fa-trash-alt"></i>Delete Conversation
              </button> */}
              {/* <button>
                <i className="fas fa-ban"></i>Block User
              </button> */}
              <button onClick={() => reportUserForm()}>
                <i className="fas fa-exclamation-triangle"></i>Report
              </button>
            </div>
          </Popper>{" "}
        </>
      </div>
    </>
  );
}
ChatTitle.propTypes = {
  title: PropTypes.string,
  toggleConversationList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  title: state.messaging.title,
  active: state.messaging.active,
  conversation: state.messaging.conversation,
  usersList: state.messaging.usersList,
  user: state.auth.user,
  messageStatus: state.messaging.messageStatus,
});
export default connect(mapStateToProps, {
  toggleConversationList,
  getMessage,
  getUsers,
  createThread,
  call,
  clearConversation,
})(ChatTitle);
