import React, {  Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
  heading: {
    margin: "12px 0",
  },
  content: "",
}));

const Gallery = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [imageStep, setImageStep] = useState(0);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const nextHandler = () => {
    if (imageStep === props.listing.images.length - 1) {
      setImageStep(0);
    } else {
      setImageStep(imageStep + 1);
    }
  };

  const previousHandler = () => {
    if (imageStep === props.listing.images.length - 1) {
      setImageStep(0);
    } else if (imageStep > 0) {
      setImageStep(imageStep - 1);
    }
  };
  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>Gallery</h4>
                </div>
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div className="atbdb_content_module_contents">
              <div className="gallery-wrapper">
                {/* <div className="gallery-images">
                  {Object.values(gallery).map((value, key) => {
                    const { img } = value;
                    return (
                      <div className="single-image" key={key}>
                        <img src={img} alt="" />
                      </div>
                    );
                  })}
                </div> */}
                <div className="gallery-thumbs">
                  <div className="single-thumb">
                    <div className="container-listing-image">
                      {" "}
                      <img
                        className="listingImage"
                        src={
                          props.listing.images &&
                          "data:image/jpeg;base64," +
                            props.listing.images[imageStep].data
                        }
                        alt=""
                      />
                      <button
                        onClick={() => nextHandler()}
                        className="next-listing-image"
                      >
                        {">"}
                      </button>
                      <button
                        onClick={() => previousHandler()}
                        className="previous-listing-image"
                      >
                        {"<"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Put the audio player here */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    gallery: state.gallery,
    gallery2: state.gallery2,
  };
};
export default connect(mapStateToProps)(Gallery);
