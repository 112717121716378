import React from "react";

import "./Chat-Search.css";

function ChatSearch() {
  return (
    <div id="search-container">
      <input type="text" placeholder="Search Conversation" />
    </div>
  );
}

export default ChatSearch;
