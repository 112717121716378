import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";

import {
  updateCraftInformation,
  getAbout,
} from "../../redux/action/craftProfile";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import "react-widgets/dist/css/react-widgets.css";
import { searchCategories, reasonForJoining } from "../../services/consts.js";
import Typography from "@material-ui/core/Typography";
import IndustrySubcategories from "../industrySubcategories/industry-subcategories";
import Spinner from "../content/modal/spinnermodal";
import { useSelector } from "react-redux";

const ListingCraftInfo = ({
  view,
  getAbout,
  updateCraftInformation,
  craftInformation,
}) => {
  const styles = {
    view: {
      visibility: view === "view-profile" ? "hidden" : "visible",
    },
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getAbout();
  }, [dispatch, getAbout]);

  const [form, setForm] = useState({
    about: "",
    interestedIndustries: [],
    reasonForJoining: [],
    editInfo: false,
    yearsOfExperience: "",
    interestLevel: "",
  });

  const [openIndustriesDetails, setopenIndustriesDetails] = useState(false);

  const handleIndustriesDetailsOpen = () => {
    setopenIndustriesDetails(!openIndustriesDetails);
  };

  const onSelectInterestLevel = (event) => {
    setForm({ ...form, interestLevel: event.target.value });
  };

  const onExperienceChange = (event) => {
    setForm({ ...form, yearsOfExperience: event.target.value });
  };

  const onSelectIndustries = (selectedItem) => {
    //  let items = form.industries.concat(selectedItem).filter(item =>  item !== selectedItem);

    setForm({ ...form, interestedIndustries: selectedItem });
  };

  const onRemoveIndustries = (removedItem) => {
    setForm({ ...form, interestedIndustries: removedItem });
  };

  const onSelectJoining = (selectedItem) => {
    setForm({ ...form, reasonForJoining: selectedItem });
  };

  const onRemoveJoining = (removedItem) => {
    // let filtered = form.reasonForJoining.filter(
    //   (reason) => reason !== removedItem
    // );

    setForm({ ...form, reasonForJoining: removedItem });
  };

  const onChangeAbout = (event) => {
    setForm({ ...form, about: event.target.value });
  };

  const postAbout = () => {
    const {
      about,
      interestedIndustries,
      reasonForJoining,
      interestLevel,
      yearsOfExperience,
    } = form;

    let body = {
      about: about,
      yearsOfExperience: yearsOfExperience,
      interestLevel: interestLevel,
      reasonForJoining: reasonForJoining,
      interestedIndustries: interestedIndustries,
    };
    updateCraftInformation(body);
    // (body);
    setForm({ ...form, editInfo: false });
  };

  const toggleEditView = () => {
    if (craftInformation) {
      setForm({ ...craftInformation, editInfo: true });
    } else {
      setForm({ ...form, editInfo: true });
    }
  };

  const loading = useSelector((state) => state.craftProfile.loading);

  return (
    <div>
      <Fragment>
        <div>
          <div className="atbd_content_module atbd_listing_features">
            <div className="atbd_content_module__tittle_area">
              <div className="atbd_content_module__tittle_area">
                <h3>About</h3>
                <div style={styles.view} className="atbd_area_title">
                  <br />
                  {form.editInfo ? (
                    <button
                      onClick={() => postAbout()}
                      style={{
                        background: "none",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <h4>
                        <i className="la la-check p-right-10"></i> Save
                      </h4>
                    </button>
                  ) : (
                    <button
                      onClick={toggleEditView}
                      style={{
                        background: "none",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <h4>
                        <i className="la la-pencil p-right-10"></i> Edit
                      </h4>
                    </button>
                  )}
                </div>
              </div>
              {loading && (
                <div className="d-flex">
                  <Spinner />
                </div>
              )}
              <div className="atbdb_content_module_contents">
                {form.editInfo ? (
                  <>
                    <textarea
                      onChange={onChangeAbout}
                      value={form.about}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      placeholder="Tell People About Yourself..."
                    ></textarea>

                    <ul className="atbd_custom_fields features-table">
                      <li>
                        <div className="atbd_custom_field_title">
                          <p>Interest Level: </p>
                        </div>
                        <div className="atbd_custom_field_content">
                          <div className="form-group py-0">
                            <select
                              className="form-control"
                              id="interestLevel"
                              onChange={onSelectInterestLevel}
                              value={form.interestLevel}
                            >
                              <option
                                value="Novice"
                                selected={
                                  form.interestLevel &&
                                  form.interestLevel.toLowerCase() === "novice"
                                }
                              >
                                Novice
                              </option>
                              <option value="Intermediate">Intermediate</option>
                              <option value="Expert">Expert</option>
                            </select>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="atbd_custom_field_title">
                          <p>Years of Experience:</p>
                        </div>
                        <div className="atbd_custom_field_content">
                          <div className="form-group py-0">
                            <select
                              className="form-control"
                              id="yearsOfExperienceTextEntry"
                              onChange={onExperienceChange}
                              value={form.yearsOfExperience}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10+</option>
                            </select>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="atbd_custom_field_title">
                          <p>Reason for Joining Socicraft:</p>
                        </div>
                        <div className="atbd_custom_field_content">
                          <div className="form-group py-0">
                            <Multiselect
                              options={reasonForJoining} //dropdown options
                              selectedValues={form.reasonForJoining}
                              isObject={false}
                              placeholder="reason for Joining"
                              hidePlaceholder={true}
                              onSelect={onSelectJoining}
                              onRemove={onRemoveJoining}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="atbd_custom_field_title">
                          <p>Interested Industries:</p>
                          <IndustrySubcategories />
                        </div>
                        <div className="atbd_custom_field_content">
                          <Multiselect
                            options={searchCategories}
                            selectedValues={form.interestedIndustries}
                            isObject={false}
                            placeholder="Select Industries..."
                            hidePlaceholder={true}
                            onSelect={onSelectIndustries}
                            onRemove={onRemoveIndustries}
                          />
                        </div>
                      </li>
                    </ul>
                  </>
                ) : (
                  craftInformation && (
                    <>
                      {craftInformation.about ? (
                        <p>{craftInformation.about}</p>
                      ) : (
                        <p>Click edit and tell people about your self </p>
                      )}

                      <ul className="atbd_custom_fields features-table">
                        <li>
                          <div className="atbd_custom_field_title">
                            <p>Interest Level: </p>
                          </div>
                          <div className="atbd_custom_field_content">
                            <div>
                              {craftInformation.interestLevel ? (
                                <p>{craftInformation.interestLevel}</p>
                              ) : (
                                <p>Interest level</p>
                              )}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="atbd_custom_field_title">
                            <p>Years of Experience:</p>
                          </div>
                          <div className="atbd_custom_field_content">
                            <div>
                              {craftInformation.yearsOfExperience ? (
                                <Typography>
                                  {craftInformation.yearsOfExperience}
                                </Typography>
                              ) : (
                                <Typography>Years of Experience</Typography>
                              )}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="atbd_custom_field_title">
                            <p>Reason for Joining Socicraft:</p>
                          </div>
                          <div className="atbd_custom_field_content">
                            <div>
                              {craftInformation.reasonForJoining.length > 0 ? (
                                <>
                                  {craftInformation.reasonForJoining.map(
                                    (reason) => (
                                      <span
                                        className="badge badge-pill badge-primary mr-2 mt-1"
                                        key={reason.id}
                                      >
                                        {reason}
                                      </span>
                                    )
                                  )}
                                </>
                              ) : (
                                <p>Insert Reasons for Joining Socicraft</p>
                              )}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="atbd_custom_field_title">
                            <p style={{ marginTop: 0 }}>
                              Interested Industries:
                            </p>
                            <label htmlFor="industry" className="form-label">
                              Relevant Industries{" "}
                              <i
                                onClick={handleIndustriesDetailsOpen}
                                className="fa fa-question-circle"
                              ></i>
                            </label>
                            <IndustrySubcategories
                              open={openIndustriesDetails}
                              handleOpen={handleIndustriesDetailsOpen}
                              handleClose={handleIndustriesDetailsOpen}
                            />
                          </div>
                          <div className="atbd_custom_field_content">
                            {craftInformation.interestedIndustries.length >
                            0 ? (
                              <>
                                {craftInformation.interestedIndustries.map(
                                  (industries) => (
                                    <span
                                      className="badge badge-pill badge-primary mr-2 mt-1"
                                      key={industries.id}
                                    >
                                      {industries}
                                    </span>
                                  )
                                )}
                              </>
                            ) : (
                              <p>
                                Insert, In which Industries are you Interested
                              </p>
                            )}
                          </div>
                        </li>
                      </ul>
                    </>
                  )
                )}
              </div>

              <br />
              {/* <div styles={"float:right;"}>
      <button className="btn btn-xs btn-gradient btn-gradient-two">
        Save Craft Information
      </button>
    </div> */}
            </div>
          </div>
          <br />
        </div>
      </Fragment>
    </div>
  );
};

ListingCraftInfo.propTypes = {
  updateCraftInformation: PropTypes.func.isRequired,
  getAbout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  craftInformation: state.craftProfile.about,
});

export default connect(mapStateToProps, { updateCraftInformation, getAbout })(
  ListingCraftInfo
);
