import React, { Fragment, useState, lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect } from "react";
import UploadImage from "../content/modal/gallery/uploadImage";
import CreateAlbum from "../content/modal/gallery/createAlbum";

import { useSelector, useDispatch } from "react-redux";
import {
  getVideoAlbum,
  clearAlbumVideos,
} from "../../redux/action/craftProfile";

import { VideoBar } from "../video/videoBar";
import Spinner from "../content/modal/spinnermodal";
const FolderBar = lazy(() => import("../folder/folderBar"));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
}));

export const Video = ({ viewType, userId, isAuthenticated }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVideoAlbum(userId));
  }, [dispatch, userId]);

  const styles = {
    view: {
      visibility: viewType === "view-profile" ? "hidden" : "visible",
    },
  };

  const videoAlbum = useSelector((state) => state.craftProfile.videoAlbums);

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [view, setView] = useState("video");
  const [showCreateAlbum, setShowCreateAlbum] = useState(false);
  const [showUploadVideo, setShowUploadVideo] = useState(false);
  const [showVideoAlbums, setShowVideoAlbums] = useState(true);
  const [selectedAlbumId, setSelectedAlbumId] = useState("");

  const toggleCreateAlbum = () => {
    setShowCreateAlbum(!showCreateAlbum);
  };
  const toggleUploadVideo = () => {
    setShowUploadVideo(!showUploadVideo);
  };

  const toggleVideoView = (type, id) => {
    setView(type);
    let videoAlbumShowState = showVideoAlbums;
    setShowVideoAlbums(!videoAlbumShowState);

    setSelectedAlbumId(id);
    dispatch(clearAlbumVideos());
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>Video</h4>
                </div>
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div>
              {videoAlbum != null && showVideoAlbums === true && (
                <div>
                  <Suspense fallback={<Spinner />}>
                    <FolderBar
                      viewType={viewType}
                      type="videoalbum"
                      albumInfo={videoAlbum}
                      togglePhotoView={toggleVideoView}
                    />
                  </Suspense>
                </div>
              )}
            </div>
            {!showVideoAlbums ? (
              <VideoBar
                viewType={viewType}
                userId={userId}
                selectedAlbumId={selectedAlbumId}
              />
            ) : (
              <p></p>
            )}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {view !== "video" && (
                  <div className="custom-control custom-switch">
                    <button
                      className="btn  btn-sm btn-info"
                      onClick={() => toggleVideoView("video")}
                    >
                      <i className="fa fa-backward" aria-hidden="true"></i>
                      {""}
                      {""} Back
                    </button>
                  </div>
                )}
              </Grid>

              <Grid style={styles.view} item>
                {view === "video" ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleCreateAlbum}
                  >
                    <i className="fas fa-plus "> </i>{" "}
                    {view === "video" ? "Create Video Album" : "Upload Video"}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={toggleUploadVideo}
                  >
                    <i className="fas fa-plus "> </i>{" "}
                    {view === "video" ? "Create Video Album" : "Upload Video"}
                  </button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Put the audio player here */}
      </div>
      <CreateAlbum
        type={"video"}
        showCreateAlbum={showCreateAlbum}
        toggleCreateAlbum={toggleCreateAlbum}
      />{" "}
      {
        <UploadImage // This must be turn in to UploadVideo
          showUploadPhoto={showUploadVideo}
          toggleUploadPhoto={toggleUploadVideo}
          selectedAlbumId={selectedAlbumId}
          type={"video"}
        />
      }
    </Fragment>
  );
};
