import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// import Spinner from "../../content/modal/spinnermodal";
import Spinner from "../content/modal/spinnermodal";
import "./postFeedstyles.css";

export default function SkeletonLoader({
  height,
  width,
  showAvatar,
  showText,
}) {
  return (
    <div style={{ marginBottom: "25px" }}>
      {showAvatar && <Skeleton variant="circular" width={40} height={40} />}
      {showText && <Skeleton variant="text" />}
      <Skeleton variant="rectangular" height={height} width={width} />
    </div>
  );
}
