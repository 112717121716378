import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
// import { AdvSearch } from "../advance-search.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
// import PaginationComponent from "./pagination-component";
import Pagination from "./pagination";
import { useSelector, useDispatch } from "react-redux";
import Container from "./swipeButton/container";
import ReopenModal from "./modal/reopenModal";
import RatingModal from "./modal/ratingModal";
import { updateProjectApprove } from "../../../redux/action/userpost";
import Spinner from "../../content/modal/spinnermodal";

const ViewApplicantsCard = ({ list }) => {
  const applicants = useSelector((state) => state.userListing.applicants);
  const isLoading = useSelector((state) => state.process.isloading);
  const dispatch = useDispatch();
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [openReopenModal, setOpenReopenModal] = useState(false);
  const [openReviewContainer, setOpenReviewContainer] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState();
  const handleReopenModal = () => {
    setOpenReopenModal(!openReopenModal);
  };
  const handleOpenRatingModal = () => {
    setOpenRatingModal(!openRatingModal);
  };
  const handleOpenReviewContainer = () => {
    setOpenReviewContainer(!openReviewContainer);
  };
  const currentApplicantHandler = (applicant) => {
    setCurrentApplicant(applicant);
  };
  const handleApproveProject = (currentApplicant) => {
    dispatch(updateProjectApprove(currentApplicant));
    setOpenReviewContainer(false);
  };
  return (
    <Fragment>
      {isLoading && <Spinner />}
      {isLoading === false && applicants && applicants.length === 0 && (
        <div>
          <h2 className="alert alert-warning " role="alert">
            No Projects Found
          </h2>
        </div>
      )}
      {applicants.map((value) => {
        const { title, id, applicants } = value;
        return (
          <div className="col-lg-9 mb-3 p-0 " key={id}>
            <div className="atbd_single_listing atbd_listing_list ">
              <div className="atbd_single_listing_wrapper atbd_listing_info applicant__listing-container">
                <figure className="atbd_applicant_list_listing_image_wrapper">
                  <div className="listing_image ">
                    <img
                      src={
                        value.listingId.listingImageUrl.length
                          ? value.listingId.listingImageUrl[0].url
                          : "/assets/img/no-image-available.jpeg"
                      }
                      alt="listingimage"
                      className="view-applicant-profile-img"
                    />
                  </div>
                </figure>

                <div className="atbd_listing_content_wrapper_container">
                  <div className="atbd_content_upper">
                    <h4 className="atbd_listing_title">
                      Title: {value.listingId.title}
                      <NavLink to={"/listing-detailsid"}>{title}</NavLink>
                    </h4>

                    <div className="atbd_listing_data_list">
                      <ul>
                        <li>
                          <p>
                            <span className="la la-map-marker"></span>
                            {value.listingId.projectLocation
                              .charAt(0)
                              .toUpperCase() +
                              value.listingId.projectLocation.slice(1)}
                          </p>
                        </li>

                        <p>
                          <span className="la la-calendar-check-o"></span>
                          Posted: {moment(
                            value.listingId.createdAt
                          ).fromNow()}{" "}
                        </p>
                        <p>
                          <span className="la la-calendar-check-o"></span>
                          Expiration:{" "}
                          {value.listingId.projectEndDate
                            ? moment(value.listingId.projectEndDate).fromNow()
                            : "continuous"}
                        </p>
                        <li>
                          <p>
                            <span className="la la-calendar-check-o"></span>
                            Total Applicants: {applicants.length}
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="la la-calendar-check-o"></span>
                            Total Cost: <i className="fa fa-dollar"></i>{" "}
                            {value.listingId.cost}
                          </p>
                        </li>

                        <Accordion className="applicant__list-select_container">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                            className="applicant__list-select"
                          >
                            <h3>Applicant List</h3>
                          </AccordionSummary>

                          <Pagination
                            currentApplicantHandler={currentApplicantHandler}
                            itemsPerPage={5}
                            applicantList={value.applicants}
                            listing={value}
                            handleOpenReviewContainer={
                              handleOpenReviewContainer
                            }
                            handleReopenModal={handleReopenModal}
                            handleOpenRatingModal={handleOpenRatingModal}
                          />
                        </Accordion>
                        {openReviewContainer && (
                          <Container
                            handleApproveProject={handleApproveProject}
                            currentApplicant={currentApplicant}
                            handleReopenModal={handleReopenModal}
                            handleOpenRatingModal={handleOpenRatingModal}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <ReopenModal
        handleOpenReviewContainer={handleOpenReviewContainer}
        currentApplicant={currentApplicant}
        openReopenModal={openReopenModal}
        handleReopenModal={handleReopenModal}
      />
      <RatingModal
        handleOpenReviewContainer={handleOpenReviewContainer}
        openRatingModal={openRatingModal}
        currentApplicant={currentApplicant}
        handleOpenRatingModal={handleOpenRatingModal}
      />
    </Fragment>
  );
};
export default ViewApplicantsCard;
