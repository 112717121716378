import React, { useState, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import * as consts from "../../../services/consts.js";

import { getAllListings } from "../../../redux/action/userpost";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

const AdvSearch = ({ getAllListings, listings }) => {
  useEffect(() => {
    const words = [
      "collaboration",
      "events",
      "connection",
      "experience",
      "chance",
      "milestone",
      "exposure",
      "partnership",
    ];
    const changeWord = () => {
      const word = words[Math.floor(Math.random() * words.length)];
      setWord(word);
    };
    const interval = setInterval(() => {
      changeWord();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [word, setWord] = useState("Craft");

  const [searchTerm, setSearchTerm] = useState({
    keyword: "",
    address: "",
    category: "",
  });

  const onChangeHandler = ({ currentTarget }) => {
    setSearchTerm({ ...searchTerm, [currentTarget.name]: currentTarget.value });
  };
  const getListings = () => {
    getAllListings(searchTerm);
  };

  return (
    <Fragment>
      <div className="directory_content_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="search_title_area">
                <h1 className="title">
                  Find your next...<span>{word}</span>
                </h1>

                <p className="sub_title">
                  Discover a world to explore, or create your own. The
                  possibilities are endless.
                </p>
              </div>
              {/* ends: .search_title_area */}
              <form action="/" className="search_form">
                <div className="atbd_seach_fields_wrapper">
                  <div className="single_search_field search_query">
                    <input
                      className="form-control search_fields"
                      onChange={onChangeHandler}
                      value={searchTerm.keyword}
                      name="keyword"
                      type="text"
                      placeholder="What are you looking for?"
                    />
                  </div>{" "}
                  <div className="single_search_field search_category">
                    <select
                      className="form-control home-category"
                      onChange={(e) => onChangeHandler(e)}
                      name="state"
                    >
                      <option value>Select State</option>
                      {consts.stateList.map((category, idx) => {
                        return (
                          <option
                            key={idx}
                            // onChange={onChangeHandler}
                            name="address"
                            value={category}
                          >
                            {category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="single_search_field search_category">
                    <select
                      className="form-control home-category"
                      onChange={(e) => onChangeHandler(e)}
                      name="category"
                    >
                      <option value>Select a category</option>
                      {consts.searchCategories.map((category, idx) => {
                        return (
                          <option key={idx} value={category}>
                            {category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div className="single_search_field search_location">
                                            <select className="search_fields" id="at_biz_dir-location">
                                            <option value>Select a location</option>
                                            <option value="ab">AB Simple</option>
                                            <option value="australia">Australia</option>
                                            <option value="australia-australia">Australia</option>
                                            </select>
                                        </div> */}
                  <div className="atbd_submit_btn">
                    <NavLink
                      to={{
                        pathname: "/all-listings-list",
                      }}
                    >
                      <button
                        onClick={() => getListings()}
                        className="btn btn-block btn-gradient btn-gradient-one btn-md btn_search"
                      >
                        Search
                      </button>
                    </NavLink>
                  </div>
                </div>
              </form>
              {/* ends: .search_form */}
              <div className="directory_home_category_area">
                {/* <ul className="categories">
                                        <li>
                                            <NavLink onClick={noAction} to="/at_demo">
                                                <span className="color-primary"><i className="la la-cutlery" /></span>
                                                Restaurants
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={noAction} to="/at_demo">
                                                <span className="color-success"><i className="la la-map-marker" /></span>
                                                Places
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={noAction} to="/at_demo">
                                                <span className="color-warning"><i className="la la-shopping-cart" /></span>
                                                Shopping
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink onClick={noAction} to="/at_demo">
                                                <span className="color-danger"><i className="la la-bed" /></span>
                                                Hotels
                                            </NavLink>
                                        </li>
                                    </ul> */}
              </div>
              {/* ends: .directory_home_category_area */}
            </div>
            {/* ends: .col-lg-10 */}
          </div>
        </div>
      </div>
      {/* ends: .directory_search_area */}
    </Fragment>
  );
};

AdvSearch.propTypes = {
  getAllListings: PropTypes.func.isRequired,
  listings: PropTypes.object,
};
const mapStateToProps = (state) => ({
  listings: state.listings,
});

export default connect(mapStateToProps, { getAllListings })(AdvSearch);
