import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import ReactPaginate from "react-paginate";
import { postResponseForApplicant } from "../../../redux/action/userpost";
import Spinner from "../../content/modal/spinnermodal";
import { Chip } from "@material-ui/core";
import Container from "./swipeButton/container";
function Items({
  currentItems,
  postResponseForApplicant,
  applicantList,
  loading,
  listing,
  handleOpenReviewContainer,
  currentApplicantHandler,
}) {
  const [modified, setModified] = useState([]);
  // const [updated, setUpdated] = useState([]);
  const onSelectHandler = (e, item) => {
    let temp = modified.filter(
      (mod) => mod._id !== item._id && item.status !== e.target.value
    );

    setModified([
      ...temp,
      {
        status: e.target.value,
        id: item._id.toString(),
        postId: listing.listingId.id,
        userId: item.userId.id,
      },
    ]);
  };

  const onSaveHandler = (e, item) => {
    e.preventDefault();
    //Make Request
    let postModified = modified.find(
      (data) => data.id.toString() === item._id.toString()
    );

    postResponseForApplicant(postModified);
    //Filter Modified Applicant
    let filterModified = modified.filter(
      (data) => data.id !== item._id.toString()
    );
    setModified(filterModified);
  };
  const webAddress =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
      ? "https://workshop.socicraft.us"
      : "https://workshop.socicraft.us";
  const openReviewContainer = (applicant) => {
    handleOpenReviewContainer();
    currentApplicantHandler({ applicant, listing });
  };
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div key={item.id} style={{ margin: "20px" }}>
            <label htmlFor="jobStatus">
              Select Applicant Status for:{"  "}
            </label>
            <span style={{ fontSize: "20px", paddingLeft: "10px" }}>
              <strong>
                <a href={`${webAddress}/${item.userId.userName}`}>
                  {item.userId.firstName} {item.userId.lastName}{" "}
                </a>
              </strong>
            </span>
            {[
              "completed",
              "payment_received",
              "document_waiting",
              "hired",
              "in_progress",
              "pending_review",
              "approved",
              "reopened",
            ].includes(item.status.toLowerCase()) ? (
              <>
                {" "}
                <div>
                  {" "}
                  <i> At this stage you are not able to modify status, </i>
                  <h5 style={{ color: "green" }}>
                    {" "}
                    If you have any Issue Please contact support.{" "}
                  </h5>
                </div>{" "}
                <br />
                <div>
                  {" "}
                  {item.status.toLowerCase() === "pending_review" ? (
                    <>
                      <div className="d-flex flex-column justify-content-center m-width ">
                        <Chip
                          label={item.status}
                          variant="outlined"
                          clickable={false}
                          style={{
                            height: "30px",
                            fontSize: "15px",
                            color: "#97e5e5",
                            fontWeight: "bold",
                            marginBottom: "5px",
                          }}
                        />
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => openReviewContainer(item, listing)}
                        >
                          Review and Approve
                        </button>
                      </div>
                    </>
                  ) : (
                    <Chip
                      label={item.status}
                      variant="outlined"
                      clickable={false}
                      style={{
                        maxWidth: "200px",
                        height: "30px",
                        fontSize: "15px",
                        color: "#97e5e5",
                        fontWeight: "bold",
                      }}
                      // style={{ backgroundColor: "green", color: "white" hi }}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                <select
                  className="form-select form-select-xs h-100 applicant_list_select_job_status"
                  name="jobStatus"
                  onChange={(e) => {
                    onSelectHandler(e, item);
                  }}
                >
                  <option
                    disabled
                    value="pending"
                    selected={item.status.toLowerCase() === "pending"}
                  >
                    Pending
                  </option>{" "}
                  <option
                    disabled={item.status.toLowerCase() === "paid"}
                    value="screening"
                    selected={item.status.toLowerCase() === "screening"}
                  >
                    Screening
                  </option>
                  <option
                    disabled={item.status.toLowerCase() === "paid"}
                    value="accepted"
                    selected={item.status.toLowerCase() === "accepted"}
                  >
                    Accepted
                  </option>{" "}
                  <option
                    disabled
                    value="paid"
                    selected={item.status.toLowerCase() === "paid"}
                  >
                    Paid
                  </option>
                  <option
                    disabled={item.status.toLowerCase() === "paid"}
                    value="declined"
                    selected={item.status.toLowerCase() === "declined"}
                  >
                    Declined
                  </option>
                </select>{" "}
              </>
            )}

            {modified &&
              modified.find(
                (data) =>
                  data.id === item._id.toString() &&
                  data.status.toLowerCase() !== item.status.toLowerCase()
              ) && (
                <button
                  onClick={(e) => onSaveHandler(e, item)}
                  className="btn btn-primary btn-sm"
                >
                  {!loading ? "Save" : <Spinner />}
                </button>
              )}
            <br />
          </div>
        ))}
    </>
  );
}
const Pagination = ({
  itemsPerPage,
  applicantList,
  postResponseForApplicant,
  appList,
  loading,
  listing,
  handleOpenReviewContainer,
  currentApplicantHandler,
}) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.

    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(applicantList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(applicantList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, applicantList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % applicantList.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <Items
        currentApplicantHandler={currentApplicantHandler}
        handleOpenReviewContainer={handleOpenReviewContainer}
        loading={loading}
        currentItems={currentItems}
        applicantList={appList}
        postResponseForApplicant={postResponseForApplicant}
        listing={listing}
      />
      <ReactPaginate
        breakLabel="..."
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={
          currentItems && currentItems.length > 5
            ? "paginationBttns"
            : "paginateBttnsOneListing"
        }
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        renderOnZeroPageCount={null}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  appList: state.userListing.applicants,
  loading: state.userListing.loading,
});
Pagination.propTypes = {
  postResponseForApplicant: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { postResponseForApplicant })(
  Pagination
);
