import API from "../../api/api";
import { LOAD_ADVERTS } from "./types";

// const adminAPI = "http://localhost:8000/api/admin";

export const loadAdverts = () => async (dispatch) => {
  try {
    const response = await API.get(`/advert/getadvert`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: LOAD_ADVERTS, payload: response.data });
  } catch (error) {
    console.log("Error " + error);
  }
};
