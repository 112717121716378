import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import RegistrationForm from "../content/element/registration-form";

const Register = (props) => {
  return (
    <Fragment>
      {/* Header section start */}

      <section
        className=" bgimage overlay overlay--dark"
        style={{ overflow: "auto" }}
      >
        <div className="bg_image_holder">
          <img
            src="/assets/img/intro1.jpg"
            style={{ objectFit: "cover" }}
            alt=""
          />
        </div>

        <div className="directory_content_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                {props.match.params.withprovider !== "true" && (
                  <div className="search_title_area">
                    <h2 className="title">Register</h2>
                  </div>
                )}
                {props.match.params.withprovider === "true" && (
                  <div className="search_title_area ">
                    <h2 className="title">Welcome To SOCICRAFT</h2>
                    <h2 className="title">Please Complete Your Profile</h2>
                  </div>
                )}
                <section className="search_form">
                  <div className="atbd_seach_fields_wrapper col-lg-8 offset-lg-2 col-md-6 offset-md-3">
                    <RegistrationForm
                      withprovider={props.match.params.withprovider}
                    />
                  </div>
                </section>
                <div className="directory_home_category_area"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(null, null)(withRouter(Register));
