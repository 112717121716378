import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, Grid } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { getGalleries } from "../../redux/action/public";
import FolderBar from "./folder/folderBar";

import { VideoBar } from "./video/videoBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
  },
}));

export const Video = ({ viewType }) => {
  const dispatch = useDispatch();
  const { videoAlbums } = useSelector(
    (state) => state.publicReducer.publicCraftProfile
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [view, setView] = useState("video");

  const [showVideoAlbums, setShowVideoAlbums] = useState(true);

  const toggleVideoView = (type, id) => {
    setView(type);

    setShowVideoAlbums(!showVideoAlbums);
    // setSelectedAlbumId(id);
    dispatch(getGalleries("Video", id));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_gallery">
        <Accordion expanded={expanded} onChange={handleChange("panel1")}>
          <Grid container className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="atbd_content_module__tittle_area">
                <div className="atbd_area_title">
                  <h4>Video</h4>
                </div>
              </div>
            </AccordionSummary>
          </Grid>
          <AccordionDetails className={classes.root}>
            <div>
              {videoAlbums != null && showVideoAlbums === true && (
                <div>
                  {" "}
                  <FolderBar
                    viewType={viewType}
                    type="videoalbum"
                    albumInfo={videoAlbums}
                    togglePhotoView={toggleVideoView}
                  />
                </div>
              )}
            </div>
            {!showVideoAlbums && <VideoBar viewType={viewType} />}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {view !== "video" && (
                  <div className="custom-control custom-switch">
                    <button
                      className="btn  btn-sm btn-info"
                      onClick={() => toggleVideoView("video")}
                    >
                      <i className="fa fa-backward" aria-hidden="true"></i>
                      {""}
                      {""} Back
                    </button>
                  </div>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  );
};
