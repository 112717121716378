import {
  INCOMING_CALL,
  IN_MEETING,
  CALLING,
  CALL_ENDED,
  CALL_DECLINED,
  ANSWER_CALL,
  CALL_ANSWERED,
  REMOTE_VIDEO_TOGGLER,
  REMOTE_AUDIO_TOGGLER,
} from "../action/types";

const initialState = {
  incomingCall: false,
  inMeeting: false,
  calling: false,
  room: {
    token: null,
    name: null,
    audio: false,
    video: false,
    streaming: false,
    loading: true,
    remoteAudio: true,
    remoteVideo: true,
  },
};

const VideoCalling = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INCOMING_CALL:
      return {
        ...state,
        incomingCall: true,
        room: {
          token: null,
          room: payload.data.room,
          userName: payload.data.name,
          streaming: false,
          audio: true,
          video: true,
          loading: false,
          remoteAudio: true,
          remoteVideo: true,
        },
      };

    case IN_MEETING:
      return {
        ...state,
        inMeeting: true,
      };
    case CALLING:
      return {
        ...state,
        calling: true,
        room: {
          token: payload.token,
          room: payload.room,
          userName: payload.userName,
          streaming: true,
          audio: true,
          video: true,
          loading: false,
          stream: payload.stream,
          remoteAudio: true,
          remoteVideo: true,
        },
      };
    case ANSWER_CALL:
      return {
        ...state,
        calling: false,
        inMeeting: true,
        room: {
          token: payload.token,
          room: payload.room,
          userName: payload.userName,
          streaming: true,
          audio: true,
          video: true,
          loading: false,
          track: null,
          stream: payload.stream,
          remoteAudio: true,
          remoteVideo: true,
        },
      };
    case CALL_ANSWERED:
      return {
        ...state,
        calling: false,
        inMeeting: true,
      };
    case CALL_ENDED:
    case CALL_DECLINED:
      return {
        ...state,
        incomingCall: false,
        inMeeting: false,
        calling: false,
        room: {
          token: null,
          name: null,
          audio: false,
          video: false,
          streaming: false,
          loading: true,
          remoteAudio: true,
          remoteVideo: true,
        },
      };
    case REMOTE_AUDIO_TOGGLER:
      return {
        ...state,
        room: {
          ...state.room,
          remoteAudio: payload,
        },
      };
    case REMOTE_VIDEO_TOGGLER:
      return {
        ...state,
        room: {
          ...state.room,
          remoteVideo: payload,
        },
      };

    default:
      return state;
  }
};

export default VideoCalling;
