import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import FolderImg from "./styles/folder.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteAlbum } from "../../redux/action/craftProfile";
import { useSelector } from "react-redux";
import Details from "./details";

import MoreOptions from "./moreOptions";
import "./styles/folder.css";

export default function FolderCard({ viewType, type, image, togglePhotoView }) {
  const settings = useSelector((state) => state.auth.user.settings);
  const [imageLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [showUploadPhoto, setShowUploadPhoto] = useState(false);

  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
  };

  const deleteAlbumFunc = (albumId) => {
    dispatch(deleteAlbum(albumId, type));
    handleClose();
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card sx={{ display: "flex", maxWidth: 450, maxHeight: "70%" }}>
      <CardMedia
        className="card-image"
        component="img"
        onLoad={(e) => {
          setLoading(false);
        }}
        image={
          imageLoading
            ? "/assets/img/loading/purple-loading-spinner_1.gif"
            : image.coverPhoto
            ? image.coverPhoto
            : FolderImg
        }
        onClick={() => togglePhotoView("photos", image._id)}
        alt="Live from space album cover"
      />

      <div className="card-style">
        <div className="card_title_more_options">
          <h5>
            {image.albumTitle.length > 25
              ? image.albumTitle.slice(0, 25) + " ..."
              : image.albumTitle}
          </h5>
          {!(settings.defaultVideoAlbums === image._id) &&
            !(settings.defaultAudioAlbums === image._id) &&
            !(settings.defaultImageAlbums === image._id) && (
              <MoreOptions
                style={{ cursor: "pointer" }}
                image={image}
                type={type}
              />
            )}
        </div>

        <p className="description">
          {image.description.length > 25
            ? image.description.slice(0, 25) + " ..."
            : image.description}{" "}
        </p>

        <div className="details">
          <Details title={image.albumTitle} description={image.description} />
          {image.public === true && (
            <i className="fa fa-globe public" aria-hidden="true"></i>
          )}
        </div>
      </div>
    </Card>
  );
}
