import React from "react";
import HeaderV2 from "../layout/headerv2/headerv2";
import PostFeed from "../postFeed/postFeed";
import { useParams } from "react-router";
export default function Discovery(props) {
  const { id } = useParams();
  const showOnboardingStatus =
    props.location.state && props.location.state.showOnboarding;
  return (
    <div style={{ backgroundColor: "#c9c9c9" }}>
      <div style={{ minHeight: "1500px" }}>
        <HeaderV2 />
        <PostFeed
          showOnboarding={showOnboardingStatus ? true : false}
          postId={id}
        />
      </div>
    </div>
  );
}
