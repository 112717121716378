import React, { Component } from "react";

import "./App.css";
import Track from "./track";
import { connect } from "react-redux";
class Participant extends Component {
  constructor(props) {
    super(props);

    const existingPublications = Array.from(
      this.props.participant.tracks.values()
    );
    const existingTracks = existingPublications.map(
      (publication) => publication.track
    );
    const nonNullTracks = existingTracks.filter((track) => track !== null);

    this.state = {
      tracks: nonNullTracks,
      audio: true,
      video: true,
    };
  }
  componentDidMount() {
    if (!this.props.localParticipant) {
      this.props.participant.on("trackSubscribed", (track) => {
        this.addTrack(track);
      });
    }
    if (this.props.stream) {
      this.props.stream.localParticipant.videoTracks.forEach((track) => {
        this.setState({ video: track.isTrackEnabled });
      });
      this.props.stream.localParticipant.audioTracks.forEach((track) => {
        this.setState({ audio: track.isTrackEnabled });
      });

      this.props.stream.participants.forEach((track) => {});
    }
  }

  addTrack(track) {
    this.setState({
      tracks: [...this.state.tracks, track],
    });
  }

  audioToggler() {
    this.props.stream.localParticipant.audioTracks.forEach((track) => {
      if (this.state.audio) {
        track.track.disable();
        this.setState({ audio: false });
      } else {
        this.setState({ audio: true });
        track.track.enable();
      }
    });
  }
  videoToggler() {
    this.props.stream.localParticipant.videoTracks.forEach((track) => {
      if (this.state.video) {
        this.setState({ video: false });
        track.track.disable();
      } else {
        this.setState({ video: true });
        track.track.enable();
      }
    });
  }
  render() {
    return (
      <div
        className="video-call-participant"
        id={this.props.participant.identity}
      >
        <div className="video-call-identity">
          <h3>{this.props.participant.identity.toUpperCase()}</h3>
        </div>
        {this.state.tracks.map((track) => (
          <>
            {" "}
            <Track
              identity={this.props.participant.identity}
              key={track}
              filter={this.state.filter}
              track={track}
            />
          </>
        ))}
        {this.props.localParticipant && (
          <div id="track-setting">
            {this.state.audio ? (
              <span onClick={() => this.audioToggler(this.props.participant)}>
                <i className="fa fa-solid fa-microphone"></i>{" "}
              </span>
            ) : (
              <span onClick={() => this.audioToggler(this.props.participant)}>
                <i className="fa fa-solid fa-microphone-slash"></i>
              </span>
            )}
            {/* <
          <i className="fa fa-solid fa-video-slash"></i> */}
            {this.state.video ? (
              <span onClick={() => this.videoToggler(this.props.participant)}>
                {" "}
                <i className="fa fa-solid fa-video"></i>
              </span>
            ) : (
              <span onClick={() => this.videoToggler(this.props.participant)}>
                <i className="fa fa-solid fa-video-slash"></i>
              </span>
            )}
          </div>
        )}
        {!this.props.localParticipant && (
          <div id="track-setting-remote">
            {this.props.videoCalling.room.remoteAudio ? (
              <i className="fa fa-solid fa-microphone"></i>
            ) : (
              <i className="fa fa-solid fa-microphone-slash"></i>
            )}
            {this.props.videoCalling.room.remoteVideo ? (
              <i className="fa fa-solid fa-video"></i>
            ) : (
              <i className="fa fa-solid fa-video-slash"></i>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stream: state.videoCalling.room.stream,
  videoCalling: state.videoCalling,
});
export default connect(mapStateToProps, null)(Participant);
