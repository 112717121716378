import API from "../../api/api";
import { paymentHistory } from "./payment";
import { GET_ONBOARDING_LINK, GET_ACCOUNT_BALANCE } from "./types";
let url = "http://localhost:8000";
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST") {
  url = process.env.REACT_APP_SERVER_URL;
}

export const completeOnboarding = () => async (dispatch) => {
  try {
    const { data } = await API.post("/stripe/onboarding", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({
      type: GET_ONBOARDING_LINK,
      payload: data.onboardingReference,
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
export const getAccountDetails = (reference) => async (dispatch) => {
  try {
    // if (reference) {
    //   const { data } = await API.get(`/stripe/account?reference=${reference}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-auth-user": localStorage.getItem("token"),
    //     },
    //   });
    //   dispatch({ type: GET_ACCOUNT_DETAILS, payload: data });
    //   return data;
    // } else {
    //   const { data } = await API.get(`/stripe/account`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-auth-user": localStorage.getItem("token"),
    //     },
    //   });
    //   dispatch({ type: GET_ACCOUNT_DETAILS, payload: data });
    // }
  } catch (error) {
    console.log(error.message);
  }
};
export const getAccountBalance = (reference) => async (dispatch) => {
  try {
    const { data } = await API.get(`/stripe/balance`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_ACCOUNT_BALANCE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
export const createPayout = (id) => async (dispatch) => {
  try {
    const { data } = await API.post(`/stripe/payout/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch(getAccountBalance);
    dispatch(paymentHistory);
  } catch (error) {
    console.log(error.message);
  }
};
