import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import CardHeader from "@mui/material/CardHeader";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import moment from "moment";
import { getNotification } from "../../../redux/action/notification";
import { clearNotification } from "../../../redux/action/notification";
import "./headerV2.css";

export default function HeaderV2Notification() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotification());
  }, []);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const clearNotificationofUser = () => {
    dispatch(clearNotification());
  };

  const notificationArray = notifications.notifications;

  return (
    <div style={{ margin: "auto" }}>
      {" "}
      <i className="la la-2x la-bell" onClick={handleOpenUserMenu}>
        <span
          //   onClick={togglePopper}
          style={{
            position: "absolute",
            fontSize: "xx-small",
            marginLeft: "-5px",
            marginTop: "-10px",
            backgroundColor: "var(--orange)",
          }}
          className="badge badge-light badge-sm"
        >
          {notifications.notifications &&
            notifications.count > 0 &&
            notifications.count}
        </span>
      </i>
      <Box
        className="avatar-box"
        sx={{
          ml: "auto",
        }}
      >
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser) && notifications.count > 0}
          onClose={handleCloseUserMenu}
        >
          <span className="notification_clear_button">
            {" "}
            <Button className="btn-sm mr-2" onClick={clearNotificationofUser}>
              Clear
            </Button>
          </span>
          {notificationArray && (
            <div
              style={{
                width: "400px",
                maxHeight: "300px",
                overflowY: "auto",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column-reverse",
              }}
            >
              {notificationArray.map((notification) => (
                <NavLink
                  to={
                    notification.notificationType === "Message"
                      ? "/chat"
                      : "/dashboard-listings#applicantList"
                  }
                >
                  <CardHeader
                    style={{ width: "100%" }}
                    key={notification._id}
                    avatar={
                      <Avatar aria-label="recipe">
                        <img
                          className="notification_image"
                          src={
                            notification.senderId &&
                            notification.senderId.userAvatar
                              ? notification.senderId.userAvatar
                              : "../../assets/img/profile.png"
                          }
                        />
                      </Avatar>
                    }
                    title={notification.message}
                    subheader={
                      moment(notification.createdAt).fromNow("dd") + " ago"
                    }
                  />
                </NavLink>
              ))}
            </div>
          )}
        </Menu>
      </Box>
    </div>
  );
}
