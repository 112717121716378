import React, { useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAppliedListings } from "../../../redux/action/userpost";
import AppliedProjectList from "./listViewAppliedProjects";

const ViewAppliedProjects = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppliedListings());
  }, []);
  const appliedListings = useSelector(
    (state) => state.userListing.appliedListings
  );

  return (
    <Fragment>
      <section className="all-listing-wrapper section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="atbd_generic_header">
                <div className="atbd_generic_header_title">
                  <h4>All Applied Projects</h4>
                  {/* <p>Total Listing Found: {Object.values(this.state.list).length}</p> */}
                </div>
                <div
                  className="atbd_listing_action_btn btn-toolbar"
                  role="toolbar"
                >
                  {/* <div className="view-mode">
                                            <NavLink className="action-btn" to="all-listings-grid"><span className="la la-th-large"></span></NavLink><NavLink className="action-btn active" to="all-listings-list"><span className="la la-list"></span></NavLink>
                                        </div> */}
                  {/* <!-- Orderby dropdown --> */}
                  <div className="dropdown">
                    {/* <a
                      className="action-btn dropdown-toggle"
                      href=" "
                      role="button"
                      id="dropdownMenuLink2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort by <span className="caret"></span>
                    </a> */}
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink2"
                    >
                      {/* <a className="dropdown-item" onClick={sorting} href="a-z">
                        A to Z ( title )
                      </a>
                      <a className="dropdown-item" onClick={sorting} href="z-a">
                        Z to A ( title )
                      </a>
                      <a className="dropdown-item" onClick={sorting} href="new">
                        Latest listings
                      </a>
                      <a className="dropdown-item" onClick={sorting} href="old">
                        Oldest listings
                      </a>
                      <a className="dropdown-item" onClick={sorting} href="low">
                        Price ( low to high )
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={sorting}
                        href="heigh"
                      >
                        Price ( high to low )
                      </a> */}
                    </div>
                  </div>
                </div>
                {/*<!-- ends: .atbd_listing_action_btn -->*/}
              </div>
              {/*<!-- ends: .atbd_generic_header -->*/}
            </div>{" "}
            {/*<!-- ends: .col-lg-12 -->*/}
            <div className="col-lg-12 listing-items listing-items-applied-projects-container">
              <div className="row">
                <div className="col-lg-12 order-lg-1 order-0">
                  <div className="row">
                    {appliedListings ? (
                      <AppliedProjectList appliedListings={appliedListings} />
                    ) : (
                      //   <ListingCardGrid12ViewAppliedProjects
                      //     appliedListings={this.props.appliedListings}
                      //     user={this.props.user}
                      //   />
                      <div className="col-lg-12">
                        <div className="alert alert-warning" role="alert">
                          Data Not found!
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ViewAppliedProjects;
