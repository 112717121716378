import {
  LOAD_ABOUT_AND_LISTINGS,
  LOAD_DISCOVERY_CONTENTS,
  LOAD_UPVOTED_CONTENTS_IDS,
  LOAD_COMMENTS,
  LOAD_REPLIES,
  LOAD_SAVE_FOR_LATER,
  LOAD_ADS,
  FAILED_REQUEST,
  CLEAN_ABOUT_AND_LISTINGS,
  CLEAN_CONTENTS,
  CLEAN_COMMENTS,
  CLEAR_REPLIES,
  LOAD_DISCOVERY_ABOUT,
  LOAD_LATEST_COMMENT,
  LOAD_LATEST_REPLY,
  LOAD_TRENDING_LISTINGS,
  LOAD_NEW_LISTINGS,
  SET_NAVIGATION,
  UPLOAD_SUCCESS,
} from "../action/types";

const initialState = {
  uploadStatus: {},
  navigationState: "home",
  reloadNavigationState: true,
  contents: [],
  comments: [],
  postsCount: 0,
  savedPostsId: [],
  replies: [],
  saveForLater: [],
  about: {},
  numberOfContents: {},
  aboutAndListings: [],
  trendingLists: [],
  newListings: [],
  errorMessage: "",
  upVotedContentsIds: [],
};

const discovery = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DISCOVERY_CONTENTS:
      return {
        ...state,
        contents: [...state.contents, ...action.payload.posts],
        postsCount: action.payload.postsCount,
        savedPostsId: action.payload.savedPostsIds,
      };

    case CLEAN_CONTENTS:
      return {
        ...state,
        contents: [],
        postsCount: 0,
      };

    case CLEAN_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case CLEAR_REPLIES:
      return {
        ...state,
        replies: [],
      };
    case LOAD_LATEST_COMMENT:
      return {
        ...state,
        comments: [...action.payload.comments, ...state.comments],
      };
    case LOAD_COMMENTS:
      return {
        ...state,
        comments: [...state.comments, ...action.payload.comments],
      };
    case LOAD_REPLIES:
      return {
        ...state,
        replies: [...state.replies, ...action.payload.replies],
      };
    case LOAD_LATEST_REPLY:
      return {
        ...state,
        replies: [...action.payload.replies, ...state.replies],
      };
    case LOAD_DISCOVERY_ABOUT:
      return {
        ...state,
        about: action.payload.about,
        numberOfContents: action.payload.numberOfContents,
      };

    case LOAD_ADS:
      return {
        ...state,
        comments: action.payload,
      };

    case LOAD_SAVE_FOR_LATER:
      return {
        ...state,
        saveForLater: action.payload,
      };

    case FAILED_REQUEST:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case LOAD_TRENDING_LISTINGS:
      return {
        ...state,
        trendingLists: action.payload.trendingListings,
      };
    case LOAD_NEW_LISTINGS:
      return {
        ...state,
        newListings: action.payload.newListings,
      };
    case LOAD_UPVOTED_CONTENTS_IDS:
      return {
        ...state,
        upVotedContentsIds: action.payload.upVotedContentsIds,
      };
    case LOAD_ABOUT_AND_LISTINGS:
      return {
        ...state,
        aboutAndListings: action.payload.authorInfo,
      };
    case CLEAN_ABOUT_AND_LISTINGS:
      return {
        ...state,
        aboutAndListings: [],
      };
    case SET_NAVIGATION:
      return {
        ...state,
        navigationState: action.payload,
        reloadNavigationState: !state.reloadNavigationState,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadStatus: action.payload,
      };
    default:
      return state;
  }
};

export default discovery;
