import { toast } from "react-toastify";

export const setAlert = (type, msg) => async (dispatch) => {
  switch (type) {
    case "error":
      return toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        toastId: msg,
      });

    case "success":
      return toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        toastId: msg,
      });
    case "info":
      return toast.info(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        toastId: msg,
      });
    case "warning":
      return toast.warn(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        toastId: msg,
      });
    case "dark":
      return toast.dark(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        toastId: msg,
      });
    default:
      return null;
  }
};
