import API from "../../api/api";
import { SHOW_CONNECTION } from "./types";

export const connection =
  (userName, address, industries, userId) => async (dispatch) => {
    try {
      const connections = await API.get(`/connection/${userId}`, {
        params: {
          userName: userName,
          address: address,
          industries: industries,
        },
      });
      dispatch({ type: SHOW_CONNECTION, payload: connections.data });
    } catch (e) {
      console.log(e);
    }
  };

export const deleteConnection = (connectedUserId) => async (dispatch) => {
  try {
    await API.delete("/connection/" + connectedUserId);

    dispatch(connection());
  } catch (e) {
    console.log(e);
  }
};
