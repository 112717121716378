import * as React from "react";
import { Redirect } from "react-router";
import ListingCard from "./listingcard/listingCard";
import { listingData } from "./testdata";
import Jumbotron from "./jumbtron/jumbotron";
import { Footer } from "../layout/footer";
import { ContentBlockHome } from "../content/element/content-block";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPopularListings,
  getPopularClients,
  getPopularContractors,
} from "../../redux/action/home";
import SkeletonLoader from "../postFeed/skeletonLoader";
import "./style.css";
export default function LandingPage() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getPopularListings());
      dispatch(getPopularClients());
      dispatch(getPopularContractors());
    }
  }, [isAuthenticated]);

  const popularListings = useSelector(
    (state) => state.homeReducer.popularListings.popularListings
  );
  const popularClients = useSelector(
    (state) => state.homeReducer.popularClient.popularClients
  );
  const popularContractors = useSelector(
    (state) => state.homeReducer.popularContractors.popularContractors
  );
  if (!isAuthenticated) {
    return <Redirect to="/landingpagepublic" />;
  }

  const skeletonLoaderCounter = [1, 2, 3, 4];

  const skeletonLoaders = () => {
    return skeletonLoaderCounter.map(() => (
      <SkeletonLoader
        height={300}
        width={300}
        showAvatar={false}
        showText={false}
      />
    ));
  };

  return (
    <div className="landing_page__main_container">
      <Jumbotron className="landing_page__jumbotron" />

      {/* For large devices */}
      <div className="landing_page_large_devices_container">
        {/* Popular  Listings  Start */}
        <h3 className="landing_page_listing_title">Popular Listings</h3>
        {popularListings && popularListings.length > 0 && (
          <div className="landing_page_listings">
            {popularListings.map((listing) => (
              <ListingCard
                cardType="popularListing"
                listingInfo={listing}
                showFavorite={true}
                showAvatar={true}
                showReview={false}
                showLocation={false}
              />
            ))}
          </div>
        )}
        {popularListings === undefined && (
          <div className="landing_page_listings">{skeletonLoaders()}</div>
        )}
        {/************  Popular  Listings  End *************/}

        {/* Paying Clients Start */}
        <h3 className="landing_page_listing_title">Paying Clients</h3>
        {popularClients && popularClients.length > 0 && (
          <div className="landing_page_listings">
            {popularClients.map((listing) => (
              <ListingCard
                cardType="popularClients"
                listingInfo={listing}
                showFavorite={false}
                showAvatar={false}
                showReview={false}
                showLocation={true}
              />
            ))}
          </div>
        )}
        {popularClients === undefined && (
          <div className="landing_page_listings">{skeletonLoaders()}</div>
        )}

        {/************  Popular  Listings  End *************/}

        {/* Contractors */}
        <h3 className="landing_page_listing_title">Contractors</h3>
        {popularContractors && popularContractors.length > 0 && (
          <div className="landing_page_listings">
            {popularContractors.map((listing) => (
              <ListingCard
                cardType="popularContractors"
                listingInfo={listing}
                showFavorite={false}
                showAvatar={false}
                showReview={true}
                showLocation={true}
              />
            ))}
          </div>
        )}
        {popularContractors === undefined && (
          <div className="landing_page_listings">{skeletonLoaders()}</div>
        )}
      </div>

      {/* /************************************** for mobile devices  ********************************************/}

      <div className="landing_page_for_mobile_devices">
        {" "}
        <h3 className="landing_page_listing_title">Popular Listings</h3>
        {popularListings && popularListings.length > 0 && (
          <div className="landing_page_card_scroller">
            {popularListings.map((listing) => (
              <ListingCard
                cardType="popularListing"
                listingInfo={listing}
                showFavorite={true}
                showAvatar={true}
                showReview={false}
                showLocation={false}
              />
            ))}
          </div>
        )}
        {popularListings === undefined && (
          <div className="landing_page_card_scroller">{skeletonLoaders()}</div>
        )}
        <h3 className="landing_page_listing_title">Paying Clients</h3>
        {popularClients && popularClients.length > 0 && (
          <div className="landing_page_card_scroller">
            {popularClients.map((listing) => (
              <ListingCard
                cardType="popularClients"
                listingInfo={listing}
                showFavorite={false}
                showAvatar={false}
                showReview={false}
                showLocation={true}
              />
            ))}
          </div>
        )}
        {popularClients === undefined && (
          <div className="landing_page_card_scroller">{skeletonLoaders()}</div>
        )}
        <h3 className="landing_page_listing_title">Contractors</h3>
        {popularContractors && popularContractors.length > 0 && (
          <div className="landing_page_card_scroller">
            {popularContractors.map((listing) => (
              <ListingCard
                cardType="popularContractors"
                listingInfo={listing}
                showFavorite={false}
                showAvatar={false}
                showReview={true}
                showLocation={true}
              />
            ))}
          </div>
        )}
        {popularContractors === undefined && (
          <div className="landing_page_card_scroller">{skeletonLoaders()}</div>
        )}
      </div>
      <ContentBlockHome />
      <Footer />
    </div>
  );
}
