import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { Chip, Paper } from "@mui/material";
const StepperModal = ({
  handleOpen,
  open,
  projectStatus,
  status,
  filterStepper,
}) => {
  return (
    <Dialog onClose={handleOpen} open={open}>
      <DialogTitle>Application Status</DialogTitle>

      <Paper>
        {" "}
        <Box sx={{ width: "100%", padding: "20px" }}>
          <Stepper
            activeStep={projectStatus[status]["step"]}
            orientation="vertical"
          >
            {filterStepper(status).map((label) => (
              <Step key={label}>
                <div>
                  <StepLabel>{label}</StepLabel>
                </div>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default StepperModal;
