import { SET_SOCKET } from "../action/types";

const initialState = {
  socket: null,
  loading: false,
  connected: false,
};

const socket = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_SOCKET:
      return {
        ...state,
        socket: payload,
        loading: true,
        connected: true,
      };

    default:
      return state;
  }
};

export default socket;
