import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";

import { Typography } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { getPayedProjects } from "../../../redux/action/userpost";
import DocusignModal from "../docusign/docuModal";

import {
  sendEnvelope,
  createViewEnvelope,
} from "../../../redux/action/docusign";
import Spinner from "../../content/modal/spinnermodal.jsx";
import { Pagination, Stack } from "@mui/material";
import ProgressStepper from "../stepper/stepper";
import "./style.css";
const cardStyle = {
  marginLeft: "20px",
  marginTop: "20px",
  maxWidth: "300px",
  minWidth: "300px",
  border: "2px solid yellow",
  borderRadius: "5px",
  padding: "10px",
};
export default function PayedProject() {
  const { docusign_loading } = useSelector((state) => state.process);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPayedProjects(1));
  }, [dispatch]);
  const [showEnvelop, setShowEnvelop] = useState(false);
  const [envelopUrl, setEnvelopeUrl] = useState("");
  const payedProjects = useSelector((state) => state.userListing.payedProjects);
  const prepareDocument = (listingId, documentId) => {
    dispatch(sendEnvelope(documentId, listingId)).then((data) => {
      window.open(`/docusign-processing/${documentId}`, "_self");
    });
  };

  const toggleEnvelopView = () => {
    setShowEnvelop(!showEnvelop);
  };
  return (
    <div>
      <>
        {" "}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {docusign_loading ? (
            <Spinner />
          ) : (
            payedProjects &&
            payedProjects.data &&
            payedProjects.data.map((document) => (
              <Card
                style={cardStyle}
                className="payed_projects_prepare_documents"
              >
                <ProgressStepper step={0} />
                <CardContent
                  style={{ maxHeight: "100px", marginBottom: "10px" }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {document.listing.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {document.listing.projectLabel}
                  </Typography>
                </CardContent>
                {/* <ApplicatntCard
                  userFirstName={document.applicant.firstName}
                  userLastName={document.applicant.lastName}
                  userAvatar={document.applicant.userAvatar}
                  date={document.date}
                  dateLabel="Document Created"
                /> */}
                <CardActions
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    onClick={() =>
                      prepareDocument(document.listing.id, document.id)
                    }
                    className="btn btn-primary text-dark"
                  >
                    Start Signing Document
                  </button>
                </CardActions>
              </Card>
            ))
          )}
        </div>
        <div className="d-flex justify-content-center pt-5 ">
          {payedProjects.info.totalPage > 1 && (
            <Pagination
              count={payedProjects.info.totalPage}
              color="primary"
              onChange={(e, page) => {
                dispatch(getPayedProjects(page));
              }}
            />
          )}
        </div>
        {payedProjects && !payedProjects.data.length && (
          <div>
            {" "}
            <h3
              className="alert alert-success  project_activity_payed_projects_alert"
              role="alert"
            >
              {" "}
              You don't have documents to prepare!{" "}
            </h3>{" "}
          </div>
        )}
      </>
      <DocusignModal
        showEnvelop={showEnvelop}
        toggleEnvelopView={toggleEnvelopView}
        envelopUrl={envelopUrl}
      />
    </div>
  );
}

// <iframe
//   width="900px"
//   height="900px"
//   title="sign"
//   src="https://demo.docusign.net/Signing/MTRedeem/v1/ec134107-67a4-454b-b729-ae73aa615b82?slt=eyJ0eXAiOiJNVCIsImFsZyI6IlJTMjU2Iiwia2lkIjoiNjgxODVmZjEtNGU1MS00Y2U5LWFmMWMtNjg5ODEyMjAzMzE3In0.AQYAAAABAAMABwAANCTUEn7aSAgAANQ1WzR-2kgYAAEAAAAAAAAAIQC9AgAAeyJUb2tlbklkIjoiYzMyZjkwZDAtOGYyMy00MzZhLWFlNTctN2Q1MDgxZGRkZjAxIiwiRXhwaXJhdGlvbiI6IjIwMjItMDgtMTRUMTY6Mzk6MTYrMDA6MDAiLCJJc3N1ZWRBdCI6IjIwMjItMDgtMTRUMTY6MzQ6MTYuNzEzNTkxMyswMDowMCIsIlJlc291cmNlSWQiOiIzNmViZTZhYy1kNmZiLTQ2MzktYjYzMS02YWI3MGM2Y2U0MDUiLCJSZXNvdXJjZXMiOiJ7XCJFbnZlbG9wZUlkXCI6XCIzNmViZTZhYy1kNmZiLTQ2MzktYjYzMS02YWI3MGM2Y2U0MDVcIixcIkFjdG9yVXNlcklkXCI6XCJlZDI5Mjg3Mi1hMWZjLTQyZDEtYmIzZS1kNjgwMjQxNTc4NThcIixcIlJlY2lwaWVudElkXCI6XCI5ZTkzZDBiZi1hODFmLTRjMTAtODk2OC0xODI2NzA5ZmIxOThcIixcIkZha2VRdWVyeVN0cmluZ1wiOlwidD1kMDkxYTM0NC03YTg3LTQyMzAtOThhMy1mNjMzZTk2OGVhNmRcIixcIkludGVncmF0b3JLZXlcIjpcIjBmZDIzODJjLTNlZjAtNDg4Ni1iODM3LTE1YjliMjU4MTkwYVwifSIsIlRva2VuVHlwZSI6MSwiQXVkaWVuY2UiOiIyNWUwOTM5OC0wMzQ0LTQ5MGMtOGU1My0zYWIyY2E1NjI3YmYiLCJSZWRpcmVjdFVyaSI6Imh0dHBzOi8vZGVtby5kb2N1c2lnbi5uZXQvU2lnbmluZy9TdGFydEluU2Vzc2lvbi5hc3B4IiwiSGFzaEFsZ29yaXRobSI6MCwiSGFzaFJvdW5kcyI6MCwiVG9rZW5TdGF0dXMiOjAsIklzU2luZ2xlVXNlIjpmYWxzZX0_AID7W4YTftpI.XbhCQk4-YJxSV8PZOZ-QAkGDsny_w-zsaCL7opENrNyp9tpMo9WJRFQvp4LzJdmrUuZw_6QkMqMjL3BE36cQepylIpKG3t2fiIP2_Oxr8dEd2Lzh3cG9WhzX2g_iBo6ro8xo88wnXoRcvV6xYjB5P4UU7nUObfHMxCwtkfhcRfsWV_0iZ8NcRuJA8QtrcZ1DLPE__DSIKu8w5m_e1Sc40rxuxZ87zm6lnpdaRYfOzBADJQemlNrGOpE09P0SVRqowYJQYzNmSBjhf2DJoi-o3VwQjVb71twoqizM7VOIkd5Y6XMv5fREXDmWVqAUyjWo6m0CAHLFGEMjJPzFF3HQ3w"
// >
//   {" "}
// </iframe>;
