import API from "../../api/api";

export const getCurrentLocation = (body) => async (dispatch) => {
  // let coordinates = (lat, lo)

  try {
    const response = await API.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${body.long},${body.lat}.json?access_token=pk.eyJ1Ijoic29jaWNyYWZ0IiwiYSI6ImNrc2k3dWp6YjBkNDgycXBlNHR4djN6a2gifQ.a2tUsI4-25vKavxaJHKrDQ`
    );

    if (response.status === 200) {
   
      const payload = {
        zipcode: response.data.features[0].context.find((data) =>
          data.id.includes("postcode")
        ).text,
        state: response.data.features[0].context.find((data) =>
          data.id.includes("region")
        ).text,
        country: response.data.features[0].context.find((data) =>
          data.id.includes("country")
        ).text,
        street: response.data.features[0].place_name.split(",")[0],
        city: response.data.features[0].place_name.split(",")[1],
      };
      return payload;
    }
  } catch (error) {
    console.log(error);
    // return error.response.data.msg;
  }
};
