import React, { Fragment } from "react";
import Header from "../layout/header";
import { Footer } from "../layout/footer";
import { BreadcrumbAbout } from "../content/element/breadcrumb";

import { connect } from "react-redux";
import { ContentBlockHome } from "../content/element/content-block";
import TeamInfo from "../TeamInfoPage/team-info";

const About = (props) => {
  const light = props.logo[0].light;
  return (
    <Fragment>
      <section className="about-wrapper bg-gradient-ps">
        <div className="mainmenu-wrapper">
          <Header logo={light} class="menu--light" />
        </div>

        <BreadcrumbAbout />
        <br />
        <ContentBlockHome />
      </section>

      <TeamInfo />

      {/* <section className="testimonial-wrapper section-padding-strict">
        <div className="container">
          <SectionTitle
            title="Trusted By Over 4000+ Users"
            content="Here is what people say about Direo"
          />
          <div className="row">
            <Testimonial />
          </div>
        </div>
      </section>
    */}

      {/* <section className="clients-logo-wrapper border-top p-top-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ClientLogo />
            </div>
          </div>
        </div>
      </section>
     */}

      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    logo: state.logo,
  };
};

export default connect(mapStateToProps)(About);
