import React, { Component, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateAlbum from "../../content/modal/gallery/createAlbum";
import { getHeaderImage } from "../../../redux/action/craftProfile";

const noAction = (e) => e.preventDefault();

export default function BreadcrumbWraper({ title, userId }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  useEffect(() => {
    if (isAuthenticated) dispatch(getHeaderImage(userId));
  }, [dispatch, userId, isAuthenticated]);
  const headerImage = useSelector((state) => state.craftProfile.headerImage);
  return (
    <Fragment>
      <div
        className="breadcrumb-wrapper content_above"
        style={{
          backgroundImage: `url('${headerImage ? headerImage : ""}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2 style={{ width: "50%", margin: "auto", textAlign: "center" }}>
          {title}
        </h2>
      </div>
    </Fragment>
  );
}

export const BreadcrumbDashboard = ({ profileView, userId }) => {
  const [showCreateAlbum, setShowCreateAlbum] = useState(false);
  const [view] = useState(profileView);
  const styles = {
    view: {
      visibility: view === "profile-view" ? "hidden" : "visible",
    },
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHeaderImage(userId));
  }, [dispatch, userId]);

  const headerImage = useSelector((state) => state.craftProfile.headerImage);

  const toggleCreateAlbum = () => {
    setShowCreateAlbum(!showCreateAlbum);
  };

  return (
    <Fragment>
      <div
        className="breadcrumb-wrapper content_above"
        style={{
          backgroundImage: `url('${headerImage ? headerImage : ""}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {/* <li className="breadcrumb-item">
                      <NavLink to="/">Home</NavLink>
                    </li> */}
                  {/* <li className="breadcrumb-item active" aria-current="page">
                      {this.props.title}
                    </li> */}
                </ol>
              </nav>
              <div style={styles.view} className="custom-file">
                <label htmlFor="files" className="dashboard-button">
                  <button
                    onClick={() => toggleCreateAlbum()}
                    type="button"
                    className="btn btn-outline-dark"
                    style={{ color: "#97e5e5", marginTop: "90px" }}
                  >
                    Upload Header Image
                  </button>
                </label>
              </div>
              <CreateAlbum
                showCreateAlbum={showCreateAlbum}
                type={"headerImage"}
                toggleCreateAlbum={toggleCreateAlbum}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export class BreadcrumbSingle extends Component {
  render() {
    const postingData = this.props.postingData;
    return (
      <Fragment>
        <div className="col-lg-8 col-md-7">
          {/* <ul className="list-unstyled listing-info--badges">
                        <li><span className={"atbd_badge atbd_badge_"+badge}>{badge}</span></li>
                        <li><span className="atbd_badge atbd_badge_popular">Popular</span></li>
                    </ul>
                    <ul className="list-unstyled listing-info--meta">
                        <li>
                            <div className="atbd_listing_category">
                                <a href=" "><span className="la la-glass"></span>{category}</a>
                            </div>
                        </li>
                    </ul> */}
          {/*<!-- ends: .listing-info-meta -->*/}
          <h1>{postingData.title}</h1>
          <p className="subtitle">{postingData.company.name}</p>
        </div>
        <div className="col-lg-4 col-md-5 d-flex align-items-end justify-content-start justify-content-md-end">
          <div className="atbd_listing_action_area">
            <div className="atbd_action atbd_save">
              <div className="action_button">
                <a href=" " onClick={noAction} className="atbdp-favourites">
                  <span className="la la-heart-o"></span> Save
                </a>
              </div>
            </div>
            <div className="atbd_action atbd_share dropdown">
              <span
                className="dropdown-toggle"
                // id="social-links"
                // data-toggle="dropdown"
                // aria-haspopup="true"
                // aria-expanded="false"
                // role="menu"
              >
                <span className="la la-share"></span>Share
              </span>
              <div
                className="atbd_director_social_wrap dropdown-menu"
                aria-labelledby="social-links"
              >
                <ul className="list-unstyled">
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-facebook-f color-facebook"></span>
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-twitter color-twitter"></span>
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-pinterest-p color-pinterest"></span>
                      Pinterest
                    </a>
                  </li>
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-google-plus-g color-gplus"></span>
                      Google Plus
                    </a>
                  </li>
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-linkedin-in color-linkedin"></span>
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-tumblr color-tumblr"></span>Tumblr
                    </a>
                  </li>
                  <li>
                    <a onClick={noAction} href=" " target="_blank">
                      <span className="fab fa-vk color-vk"></span>VKontakte
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!--Ends social share--> */}
            </div>
            {/* <!-- Report Abuse--> */}
            <div className="atbd_action atbd_report">
              <div className="action_button">
                <a
                  href=" "
                  data-toggle="modal"
                  data-target="#atbdp-report-abuse-modal"
                >
                  <span className="la la-flag-o"></span> Report
                </a>
              </div>
              {/* <!-- Modal (report abuse form) --> */}
            </div>
          </div>
          {/* <!-- ends: .atbd_listing_action_area --> */}
        </div>
      </Fragment>
    );
  }
}

export class BreadcrumbAbout extends Component {
  render() {
    return (
      <Fragment>
        <div className="about-intro content_above">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <h1>Welcome to Socicraft</h1>
                <a
                  href="https://www.youtube.com/watch?v=0C4fX_x_Vsg"
                  className="video-iframe play-btn-two"
                >
                  <span className="icon">
                    <i className="la la-youtube-play"></i>
                  </span>
                  <span>
                    We are a team of creatives working to unite all creative
                    industries under one roof. Socicraft is an online platform
                    connecting people through a supportive community and
                    tangible work experience.
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
