import { GET_AUDIO_PLAYLIST } from "../action/types";

const initState = {
  playListAlbum: [],
  loading: false
};

const audioPlaylistReducer = (state = initState, action) => {
  
  const { payload, type} = action;
 
  switch (type) {
    case GET_AUDIO_PLAYLIST:
      return {
        ...state,
        playListAlbum: payload,
        loading: true,
      };
    default:
      return state;
  }
};
export default audioPlaylistReducer;