import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import Spinner from "../content/modal/spinnermodal";

//Efficent private route control warehouse
const PrivateRoute = ({
  isAuthenticated,
  loading,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !loading ? (
          <section className="section-title p-top-150 fa-10x">
            {" "}
            <Spinner />
          </section>
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/#" />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {})(PrivateRoute);
