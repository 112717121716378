import React from "react";
import { useState, useEffect } from "react";
import UpdateContent from "../content/modal/gallery/updateContent";
import { deleteMediaFile } from "../../redux/action/craftProfile";
import { FileType } from "../../services/consts";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function MediaElement({
  videoId,
  viewType,
  selectedAlbumId,
  videoUrl,
  videoTag,
  videoDescription,
  videoTitle,
  videofunc,
}) {
  const videoSizer = {
    marginTop: "5px",
    inlineSize: "100%",
    aspectRatio: "16/9",
    borderRadius: "3%",
    objectFit: "cover",
    marginBottom: "10px",
    height: "auto",
  };
  const isLoading = useSelector((state) => state.process.isLoading);

  useEffect(() => {
    //switch modal
  }, [isLoading]);

  const styles = {
    view: {
      visibility: viewType === "view-profile" ? "hidden" : "visible",
      marginLeft: "4px",
    },
  };
  const dispatch = useDispatch();
  const [showUploadPhoto, setShowUploadPhoto] = useState(false);

  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
  };

  const deleteVideo = (videoId, selectedAlbumId) => {
    dispatch(
      deleteMediaFile(
        videoId,
        selectedAlbumId,
        FileType["video"],
        "videoAlbums"
      )
    ); //use method from action here!
  };

  return (
    <>
      <div className="media-element">
        <video
          key={videoUrl}
          style={videoSizer}
          id="small-img"
          onClick={() =>
            videofunc(videoUrl, videoTag, videoDescription, videoTitle)
          }
          alt="socicraft users video"
        >
          {" "}
          <source src={videoUrl} type="video/mp4" />{" "}
        </video>

        <div style={styles.view}>
          <i onClick={() => toggleUploadPhoto()} className="fa fa-pencil">
            {" "}
          </i>

          <i
            className="delete-button fa fa-trash-alt"
            onClick={() => deleteVideo(videoId, selectedAlbumId)}
          >
            {" "}
          </i>
        </div>
      </div>
      <UpdateContent
        showUploadPhoto={showUploadPhoto}
        toggleUploadPhoto={toggleUploadPhoto}
        contentInfo={{
          url: videoUrl,
          tags: videoTag,
          description: videoDescription,
          title: videoTitle,
          id: videoId,
          selectedAlbumId: selectedAlbumId,
          type: "video",
        }}
        updatingAlbum={false}
      />
    </>
  );
}
