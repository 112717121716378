import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GoogleButton from "react-google-button";
import { PropTypes } from "prop-types";
// import login user

import { loginuser } from "../../../redux/action/auth";
import AlertMessage from "../controls/AlertMessage";
import { validateUserLogin } from "../../../services/validator";
import Spinner from "./spinnermodal";
import Recoverpsw from "./recoverpsw";
// import * as usersCrudController from '../../../../routes/userRouter';

class Login extends Component {
  state = {
    userName: "",
    password: "",
    errors: null,
    disabled: true,
    openRecoverypsw: false,
    closeModal: false,
  };

  render() {
    const onChangeHandler = ({ currentTarget: input }) => {
      const { name, value } = input;
      let userName = this.state.userName;
      let password = this.state.password;

      //check if the input is email
      let email = /@?.com/.test(value);

      this.setState({ [name]: value });

      this.setState({
        disabled: name === "userName" && value.length < 3,
      });

      if (name === "password") {
        let validate = validateUserLogin({ userName, password }, email);
        if (validate)
          return this.setState({
            errors: { [name]: validate[name] },
          });
        if (!validate)
          return this.setState({
            errors: null,
          });
      }
    };

    const Login = async (e) => {
      e.preventDefault();

      let body = {
        userName: this.state.userName,
        password: this.state.password,
      };
      const validate = validateUserLogin(body);
      if (validate)
        return this.setState({
          errors: { msg: "User Name and Password not correct" },
        });
      await this.props.loginuser(body);
      if (this.props.isAuthenticated) window.open("/social/discovery", "_self");
    };

    const openRecoverypsw = () => {
      this.setState({ openRecoverypsw: !this.state.openRecoverypsw });
    };
    const googleAuth = () => {
      window.open(
        process.env.REACT_APP_ENV === "STAGING"
          ? "http://3.128.168.201:8000/api/auth/google"
          : "http://localhost:8000/api/auth/google",
        "_self"
      );
    };

    return (
      <Fragment>
        <>
          {" "}
          <div
            className={this.props.manualTrigger ? "" : "modal fade"}
            id="login_modal"
            role="dialog"
            aria-labelledby="login_modal_label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="login_modal_label">
                    <i className="la la-lock" />{" "}
                    {!this.state.openRecoverypsw
                      ? "Sign In"
                      : "Reset Your Password"}
                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.props.manualTrigger && this.props.handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {!this.state.openRecoverypsw ? (
                    <>
                      {this.state.errors && (
                        <AlertMessage
                          type="danger"
                          error={this.state.errors.msg}
                        />
                      )}
                      <form action="/" id="login-form">
                        <input
                          onChange={onChangeHandler}
                          type="text"
                          className="form-control"
                          placeholder="Username or Email"
                          name="userName"
                          required
                        />
                        {this.state.errors && (
                          <AlertMessage
                            type="danger"
                            error={this.state.errors.userName}
                          />
                        )}
                        <input
                          onChange={onChangeHandler}
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          required
                          name="password"
                          disabled={this.state.disabled}
                        />
                        <div className="keep_signed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="keep_signed_in"
                            defaultValue={1}
                            id="keep_signed_in"
                          />
                          <label
                            htmlFor="keep_signed_in"
                            className="not_empty custom-control-label"
                          >
                            Keep me signed in
                          </label>
                        </div>
                        {this.props.loading ? (
                          <div className="btn btn-block btn-lg btn-gradient btn-gradient-two">
                            <Spinner />
                          </div>
                        ) : (
                          <button
                            onClick={Login}
                            type="submit"
                            className="btn btn-block btn-lg btn-gradient btn-gradient-two"
                          >
                            Sign In
                          </button>
                        )}
                        <GoogleButton onClick={googleAuth} />

                        <br />
                      </form>
                      <div className="form-excerpts">
                        <ul className="list-unstyled">
                          <li>
                            Not a member? <a href="/register">Sign up</a>
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => openRecoverypsw()}
                            >
                              Recover Password
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <Recoverpsw openRecoverypsw={openRecoverypsw} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </Fragment>
    );
  }
}
Login.propTypes = {
  loginuser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => {
  return {
    login: state.login,
    users: state.users,
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.process.isloading,
  };
};
// const mapDispatchToProp = (dispatch) => {
// 	return {
// 		logindata: (login) => dispatch(LogInAc(login)),
// 	};
// };
export default connect(mapStateToProps, { loginuser })(withRouter(Login));
