import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { PropTypes } from "prop-types";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import ExternalListingDetailsCard from "./externalListingDetailsCard";
const style = {
  color: "#33cccc",
  "text-decoration": "none",
  "background-color": "transparent",
  width: "100%",
};

class ExternalListings extends Component {
  render() {
    const { data } = this.props;

    return (
      <Fragment>
        <div className="col-lg-12">
          <div className="atbd_single_listing atbd_listing_list ">
            <article className="atbd_single_listing_wrapper">
              {/* <figure className="atbd_listing_thumbnail_area">
                                <div className="atbd_listing_image">
                                    <a href=" ">
                                        <img src={img} alt="listingimage" />
                                    </a>
                                </div>
                                                                            
                                <div className="atbd_thumbnail_overlay_content">
                                    <ul className="atbd_upper_badge">
                                        <li><span className={"text-capitalize atbd_badge atbd_badge_"+badge}>{badge}</span></li>
                                    </ul>
                                </div>
                            </figure> */}

              <div className="atbd_listing_info">
                <div className="atbd_content_upper">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <h4 className="atbd_listing_title">
                      {data.MatchedObjectDescriptor.PositionTitle.split(" ")
                        .length > 5
                        ? data.MatchedObjectDescriptor.PositionTitle.split(" ")
                            .splice(0, 5)
                            .join(" ")
                        : data.MatchedObjectDescriptor.PositionTitle}
                    </h4>
                    <div
                      data-toggle={this.props.isAuthenticated ? null : "modal"}
                      data-target={
                        this.props.isAuthenticated ? null : "#login_modal"
                      }
                    >
                      {/* <a
                        href="#"
                        // onClick={() => favoriteListing}
                        className="atbdp-favourites"
                      > */}{" "}
                      {/* <div
                        style={{ cursor: "pointer" }}
                        className="btn btn-xs btn-gradient btn-gradient-two"
                      >
                        Details
                      </div>{" "} */}
                      <ExternalListingDetailsCard data={data} />
                      {/* //</a> */}
                    </div>{" "}
                  </Grid>

                  <div className="atbd_listing_data_list">
                    <ul>
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <span className="la la-user"></span> Company:{" "}
                          <strong>
                            {" "}
                            {data.MatchedObjectDescriptor.OrganizationName}
                          </strong>{" "}
                          <br />
                        </p>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <i
                            style={{ color: "#33cccc" }}
                            className="far fa-building"
                          ></i>{" "}
                          Department:{" "}
                          <strong>
                            {" "}
                            {data.MatchedObjectDescriptor.DepartmentName}{" "}
                          </strong>{" "}
                          <br />
                        </p>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <p>
                          <i
                            style={{ color: "#33cccc" }}
                            className="fas fa-chair"
                          ></i>{" "}
                          Position:{" "}
                          <strong>
                            {
                              data.MatchedObjectDescriptor.PositionSchedule[0]
                                .Name
                            }{" "}
                            {
                              data.MatchedObjectDescriptor
                                .PositionOfferingType[0].Name
                            }
                          </strong>
                          {!data.MatchedObjectDescriptor.PositionSchedule[0]
                            .Name &&
                            !data.MatchedObjectDescriptor
                              .PositionOfferingType[0].Name && (
                              <strong> unavailable</strong>
                            )}
                        </p>{" "}
                      </li>{" "}
                      <li>
                        {" "}
                        <p>
                          <span className="la la-map-marker"></span>
                          Location:{" "}
                          <strong>
                            {data.MatchedObjectDescriptor.PositionLocation[0] &&
                            data.MatchedObjectDescriptor.PositionLocation[0]
                              .LocationName
                              ? data.MatchedObjectDescriptor.PositionLocation[0]
                                  .LocationName
                              : ""}
                          </strong>
                        </p>{" "}
                      </li>
                      <li>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex"
                        >
                          {" "}
                          <p>
                            <span className="la la-calendar-check-o"></span>
                            Application close Date:{" "}
                            <strong>
                              {" "}
                              {moment(
                                data.MatchedObjectDescriptor
                                  .ApplicationCloseDate
                              ).format("MM-DD-YYYY  LT")}{" "}
                            </strong>
                          </p>
                        </Grid>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="atbd_listing_bottom_content">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex"
                  >
                    {data.MatchedObjectDescriptor.JobCategory.map((data) => (
                      <div className="border-secondary ">
                        <span className="p-xl-8">{data.Name}</span>
                      </div>
                    ))}
                  </Grid>
                </div>
              </div>
            </article>
          </div>
        </div>
      </Fragment>
    );
  }
}
ExternalListings.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(ExternalListings);
export class ExternalListingsViewApplicants extends Component {
  render() {
    const { list } = this.props;
    return (
      <Fragment>
        {Object.values(list).map((value, key) => {
          const { img, title, location, id } = value;
          return (
            <div className="col-lg-12" key={key}>
              <div className="atbd_single_listing atbd_listing_list">
                <article className="atbd_single_listing_wrapper">
                  <figure className="atbd_listing_thumbnail_area">
                    <div className="listing_image">
                      <img
                        src={img}
                        alt="listingimage"
                        className="view-applicant-profile-img"
                      />
                    </div>
                  </figure>

                  <div className="atbd_listing_info">
                    <div className="atbd_content_upper">
                      <h4 className="atbd_listing_title">
                        <NavLink to={"/listing-details" + id}>{title}</NavLink>
                      </h4>
                      {/* <div className="atbd_listing_meta">
                        <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> 
                        <span className="atbd_meta atbd_listing_price">{"$ "+price}</span>
                         <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span> 
                      </div> */}

                      <div className="atbd_listing_data_list">
                        <ul>
                          <li>
                            <p>
                              <span className="la la-map-marker"></span>
                              {location}
                            </p>
                          </li>
                          <li>
                            {/* <p><span className="la la-phone"></span>{phone}</p> */}
                          </li>
                          <li>
                            <p>
                              <span className="la la-calendar-check-o"></span>
                              Applied Date: 2 months ago
                            </p>
                          </li>
                          <li>
                            <label htmlFor="jobStatus">Applicant Status:</label>
                            <select name="jobStatus">
                              <option value="pending">Pending</option>
                              <option value="accepted">Accepted</option>
                              <option value="declined">Declined</option>
                            </select>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="atbd_listing_bottom_content">
                      <div className="atbd_content_left">
                        {/* <div className="atbd_listing_category">
                          <a href=" ">
                            <span className={"la " + icon}></span>
                            {category}
                          </a>
                        </div> */}
                      </div>
                      {/* <ul className="atbd_content_right">
                        <li className="atbd_count">
                          <span className="la la-eye"></span>900+
                        </li>
                        <li className="atbd_save">
                          <span className="la la-heart-o"></span>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export class ExternalListingsViewAppliedProjects extends Component {
  render() {
    const { list } = this.props;
    return (
      <Fragment>
        {Object.values(list).map((value, key) => {
          const { img, badge, title, location, id } = value;
          return (
            <div className="col-lg-12" key={key}>
              <div className="atbd_single_listing atbd_listing_list">
                <article className="atbd_single_listing_wrapper">
                  <figure className="atbd_listing_thumbnail_area">
                    <div className="atbd_listing_image">
                      <a href=" ">
                        <img src={img} alt="listingimage" />
                      </a>
                    </div>

                    <div className="atbd_thumbnail_overlay_content">
                      <ul className="atbd_upper_badge">
                        <li>
                          <span
                            className={
                              "text-capitalize atbd_badge atbd_badge_" + badge
                            }
                          >
                            {badge}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </figure>

                  <div className="atbd_listing_info">
                    <div className="atbd_content_upper">
                      <h4 className="atbd_listing_title">
                        <NavLink to={"/listing-details" + id}>{title}</NavLink>
                      </h4>
                      <div className="atbd_listing_meta">
                        {/* <span className="atbd_meta atbd_listing_rating">{rating}<i className="la la-star"></i></span> */}
                        {/* <span className="atbd_meta atbd_listing_price">{"$ "+price}</span> */}
                        {/* <span className={"atbd_meta atbd_badge_"+opCl}>{opCl} Now</span> */}
                      </div>
                      <div className="applicant_status_container">
                        <div className="atbd_listing_data_list">
                          <ul>
                            <li>
                              <p>
                                <span className="la la-map-marker"></span>
                                {location}
                              </p>
                            </li>
                            <li>
                              {/* <p><span className="la la-phone"></span>{phone}</p> */}
                            </li>
                            <li>
                              <p>
                                <span className="la la-envelope"></span>
                                Posted 2 months ago
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="applicant_status_item">
                          <h4 style={{ style }}>Application Status:</h4>
                          <p style={{ "margin-left": "1.5rem" }}>
                            Pending Review
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="atbd_listing_bottom_content">
                                            <div className="atbd_content_left">
                                                <div className="atbd_listing_category">
                                                    <a href=" "><span className={"la "+icon}></span>{category}</a>
                                                </div>
                                            </div>
                                            <ul className="atbd_content_right">
                                                <li className="atbd_count"><span className="la la-eye"></span>900+</li>
                                                <li className="atbd_save">
                                                    <span className="la la-heart-o"></span>
                                                </li>
                                            </ul>
                                        </div> */}
                  </div>
                </article>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

// Mock data for Support List
