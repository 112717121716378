import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { uploadProfilePicture } from "../../redux/action/auth";
import { postAccountInformation } from "../../redux/action/accountProfile";
import { getCurrentLocation } from "../../redux/action/mapBox";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "react-circular-progressbar/dist/styles.css";
import AlertMessage from "../content/controls/AlertMessage";

const ProfileDashboard = ({
  uploadProfilePicture,
  user,
  getCurrentLocation,
  postAccountInformation,
  silverCompleted,
}) => {
  // should be refactored using useReducer
  const [profile, setProfile] = useState({
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    email: user && user.email,
  });
  const [socialNetwork, setSocialNetwork] = useState({
    facebook: user.socialNetwork ? user.socialNetwork.facebook : "",
    twitter: user.socialNetwork && user.socialNetwork.twitter,
    youtube: user.socialNetwork && user.socialNetwork.youtube,
    instagram: user.socialNetwork && user.socialNetwork.instagram,
    portfolio: user.socialNetwork && user.socialNetwork.portfolio,
    tumblr: user.socialNetwork && user.socialNetwork.tumblr,
  });
  const [errors, setError] = useState({
    portfolio: null,
  });
  const [address, setAddress] = useState({
    street: user.address && user.address.street,
    zipcode: user.address && user.address.zipcode,
    country: user.address && user.address.country,
    phoneNumber: user.address ? user.address.phoneNumber : "",
    state: user.address && user.address.state,
    city: user.address && user.address.city,
  });
  const socialNetworkOnChangeHandler = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    if (name === "portfolio") {
      var r =
        /^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/;
      if (value.length > 0 && !r.test(value)) {
        setError({
          ...errors,
          [name]: "URL must be in https://www.yourdomian.com",
        });
      }
      if (r.test(value) || value.length === 0) {
        setError({
          ...errors,
          [name]: null,
        });
      }
    }

    setSocialNetwork({
      ...socialNetwork,
      [name]: value,
    });
  };
  const [phoneValid, setPhoneValid] = useState(true);
  const addressOnChangeHandler = (e, v) => {
    const newInput = { [e.target.name]: e.target.value };
    setAddress((address) => ({
      ...address,
      ...newInput,
    }));
  };
  const phoneOnchangeHandler = (validation, value) => {
    if (!isNaN(value)) {
      setAddress({ phoneNumber: value });
      // setPhoneValid(validation);
    }
  };
  const profileOnChangeHandler = ({ currentTarget }) => {
    const { value, name } = currentTarget;
    setProfile({ ...profile, [name]: value });
  };
  const [setting, setSetting] = useState({
    profile: false,
    socialNetwork: false,
    address: false,
  });

  //***************Profile Image Upload  */
  const [photoSelected, setPhotoSelected] = useState(false);
  const [image, setImage] = useState("./assets/img/author-profile.jpg");

  const [file, setFile] = useState("");
  const imageHandler = (e) => {
    setFile(e.target.files[0]);
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setPhotoSelected(true);
  };
  const deleteImage = () => {
    setImage("./assets/img/author-profile.jpg");
    setPhotoSelected(false);
  };

  const uploadImage = (e) => {
    let data = new FormData();

    data.append("profilePhoto", file);
    uploadProfilePicture(data);
    setPhotoSelected(false);
  };

  useEffect(() => {
    if (user && user.userAvatar) {
      setImage(user.userAvatar);
    }
  }, [user]);
  const getLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        async function (position) {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          let location = await getCurrentLocation({ lat: lat, long: long });

          setAddress({
            ...location,
            phoneNumber: address.phoneNumber,
          });
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  const postPersonal = () => {
    setSetting({ ...setting, profile: false });
    postAccountInformation(profile, "profile");
  };
  const postSocialNetwork = () => {
    var r =
      /^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/;
    if (
      socialNetwork.portfolio.length > 0 &&
      !r.test(socialNetwork.portfolio)
    ) {
      setError({
        ...errors,
        portfolio: "URL must be in https://www.yourdomian.com",
      });
    }

    if (
      r.test(socialNetwork.portfolio) ||
      socialNetwork.portfolio.length === 0
    ) {
      setError({
        ...errors,
        portfolio: null,
      });
      setSetting({ ...setting, socialNetwork: false });
      postAccountInformation(socialNetwork, "socialNetwork");
    }
  };
  const postAddress = () => {
    setSetting({ ...setting, address: false });
    postAccountInformation(address, "address");
  };

  return (
    <Fragment>
      <div className="account__profile__form-container">
        <div className="row">
          <div className="col-lg-3 col-md-12  mb-5 mb-lg-0 p-1 ">
            <div className="user_pro_img_area account__profile-image">
              <img
                width="120px"
                height="120px"
                src={image}
                alt="profile"
                id="image"
                className="profile-image mb-3"
              />

              {/* <div className="image-info">
                <h6>Profile Image</h6>
              </div> */}

              {!photoSelected ? (
                <div className="custom-file-upload">
                  <input
                    type="file"
                    id="input"
                    accept="image/*"
                    onChange={imageHandler}
                    capture="camera"
                  />
                  <label htmlFor="input" className="btn btn-sm btn-secondary">
                    Change
                  </label>
                </div>
              ) : (
                <>
                  {" "}
                  <div className="col">
                    <div className="form-group">
                      <div className="custom-file-upload">
                        <input
                          type="button"
                          id="upload"
                          onClick={(e) => uploadImage(e)}
                        />
                        <label htmlFor="upload" className="btn btn-sm btn-dark">
                          Save
                        </label>
                      </div>
                    </div>
                    {/* <div className="custom-file-upload">
                     
                    </div>
                    <div className="custom-file-upload">
                      <input
                        type="file"
                        id="retake"
                        accept="image/*"
                        onChange={imageHandler}
                        capture="camera"
                      />
                      <label htmlFor="retake" className="btn btn-sm btn-dark">
                        Retake
                      </label>
                    </div> */}
                  </div>{" "}
                </>
              )}
              <button className="btn btn-sm btn-danger" onClick={deleteImage}>
                Delete
              </button>
            </div>
          </div>
          {user && (
            <>
              <div className="col-lg-9 col-md-12 p-1 ">
                <div className="atbd_content_module">
                  {/* <div className="atbd_content_module__tittle_area">
                      <div className="atbd_area_title">
                        {" "}
                        <div className="profile-progress">
                          <div className="user_pro_img_area m-right-100">
                            <div
                              className="p-left-30"
                              style={{ width: 175, height: 175 }}
                            >
                              <CircularProgressbar
                                value={
                                  user.Reward
                                    ? user.Reward.accountProfileCompletion
                                    : 25
                                }
                                text={`${
                                  user.Reward
                                    ? user.Reward.accountProfileCompletion
                                    : 25
                                }%`}
                              />
                            </div>
                            <div>
                              <br />
                              <h3>Account Completion</h3>{" "}
                              <h3>Complete and Earn Credits </h3>
                            </div>{" "}
                            <div
                              style={{ display: "block", textAlign: "center" }}
                            ></div>{" "}
                          </div>

                          {silverCompleted && (
                            <>
                              {" "}
                              <div className="user_pro_img_area">
                                {" "}
                                <div className="silver-awarded">
                                  <h3>Thank You </h3>
                                  <i>
                                    For Completing Your Account Profile.
                                  </i>{" "}
                                  <h3>CLICK to Redeem Your Credit</h3>
                                </div>
                                <div className="level-badges">
                                  <div className="shine-coin">
                                    <img
                                      width="150x"
                                      height="150px"
                                      className="silver-awarded"
                                      src="/assets/img/awards/silverAward.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div> */}
                  <div className="atbd_content_module__tittle_area pt-3">
                    <div className="profile-container">
                      <div className="atbd_area_title">
                        {" "}
                        <h4>
                          <span className="la la-user"></span>My Account{" "}
                        </h4>
                      </div>{" "}
                      <div className="atbd_area_title">
                        {" "}
                        {setting.profile ? (
                          <button
                            onClick={() => postPersonal()}
                            style={{
                              background: "none",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <h4>
                              <i className="la la-check p-right-10"></i> Save
                            </h4>
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              setSetting({ ...setting, profile: true })
                            }
                            style={{
                              background: "none",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <h4>
                              <i className="la la-pencil p-right-10"></i> Edit
                            </h4>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* My Profile */}
                  <div className="atbdb_content_module_contents">
                    <div className="user_info_wrap">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="first_name"
                              className="first_name account__profile__form-label"
                            >
                              First Name
                            </label>
                            <input
                              disabled={!setting.profile}
                              className="form-control"
                              id="first_name"
                              type="text"
                              name="firstName"
                              value={profile.firstName}
                              onChange={profileOnChangeHandler}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="last_name"
                              className="last_name account__profile__form-label"
                            >
                              Last Name
                            </label>
                            <input
                              disabled={!setting.profile}
                              className="form-control"
                              id="last_name"
                              name="lastName"
                              value={profile.lastName}
                              onChange={profileOnChangeHandler}
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="user_name"
                              className="user_name account__profile__form-label"
                            >
                              Username
                            </label>
                            <input
                              className="form-control"
                              id="user_name"
                              value={user.userName}
                              type="text"
                              disabled="disabled"
                            />
                            <p>(Username can not be changed)</p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="req_email"
                              className="req_email account__profile__form-label"
                            >
                              Email{" "}
                            </label>
                            {/* set state of email */}
                            <input
                              disabled={!setting.profile}
                              className="form-control"
                              id="req_email"
                              name="email"
                              value={profile.email}
                              onChange={profileOnChangeHandler}
                              type="text"
                              placeholder="mail@example.com"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Social */}
                  <div>
                    <div className="atbd_content_module__tittle_area pt-0">
                      <div className="profile-container">
                        <div className="atbd_area_title">
                          <h4>
                            <span className="la la-safari"></span>
                            Social Network
                          </h4>
                        </div>
                        {setting.socialNetwork ? (
                          <button
                            onClick={() => postSocialNetwork()}
                            style={{
                              background: "none",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <h4>
                              <i className="la la-check p-right-10"></i> Save
                            </h4>
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              setSetting({ ...setting, socialNetwork: true })
                            }
                            style={{
                              background: "none",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <h4>
                              <i className="la la-pencil p-right-10"></i> Edit
                            </h4>
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="atbdb_content_module_contents">
                      <div className="user_info_wrap">
                        <p>
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Note:
                          </span>{" "}
                          You only need to add your{" "}
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            username.
                          </span>{" "}
                        </p>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="facebook"
                                className="facebook account__profile__form-label"
                              >
                                Facebook <i className="fab fa-facebook"></i>
                              </label>
                              <input
                                disabled={!setting.socialNetwork}
                                className="form-control"
                                id="facebook"
                                type="url"
                                name="facebook"
                                placeholder="e.g /johnsmith"
                                value={socialNetwork.facebook}
                                onChange={socialNetworkOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="twitter"
                                className="twitter account__profile__form-label"
                              >
                                Twitter <i className="fab fa-twitter"></i>
                              </label>
                              <input
                                disabled={!setting.socialNetwork}
                                className="form-control"
                                id="twitter"
                                type="url"
                                name="twitter"
                                placeholder="e.g /johnsmith"
                                value={socialNetwork.twitter}
                                onChange={socialNetworkOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="youtube"
                                className="youtube account__profile__form-label"
                              >
                                Youtube <i className="fab fa-youtube"></i>
                              </label>
                              <input
                                disabled={!setting.socialNetwork}
                                className="form-control"
                                id="first_name"
                                type="url"
                                name="youtube"
                                placeholder="e.g /johnsmith"
                                value={socialNetwork.youtube}
                                onChange={socialNetworkOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="instagram"
                                className="instagram account__profile__form-label"
                              >
                                Instagram <i className="fab fa-instagram"></i>
                              </label>
                              <input
                                disabled={!setting.socialNetwork}
                                className="form-control"
                                id="first_name"
                                type="text"
                                name="instagram"
                                placeholder="e.g /johnsmith"
                                value={socialNetwork.instagram}
                                onChange={socialNetworkOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="tumblr"
                                className="tumblr account__profile__form-label"
                              >
                                Tumblr <i className="fab fa-tumblr"></i>
                              </label>
                              <input
                                disabled={!setting.socialNetwork}
                                className="form-control"
                                id="tumblr"
                                type="text"
                                name="tumblr"
                                placeholder="e.g /johnsmith"
                                value={socialNetwork.tumblr}
                                onChange={socialNetworkOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="portfolio"
                                className="portfolio account__profile__form-label"
                              >
                                Portfolio <i className="fa fa-globe"></i>
                              </label>
                              <input
                                disabled={!setting.socialNetwork}
                                className="form-control"
                                id="portfolio"
                                type="text"
                                name="portfolio"
                                value={socialNetwork.portfolio}
                                onChange={socialNetworkOnChangeHandler}
                              />
                            </div>{" "}
                            {errors.portfolio && (
                              <AlertMessage
                                error={errors.portfolio}
                                type="danger"
                              />
                            )}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  {/* Address */}
                  <div>
                    <div className="atbd_content_module__tittle_area pt-0">
                      <div className="profile-container">
                        <div className="atbd_area_title">
                          <h4>
                            <span className="la  la-map"></span>
                            Address
                          </h4>
                        </div>
                        {setting.address ? (
                          <button
                            onClick={() => postAddress()}
                            style={{
                              background: "none",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <h4>
                              <i className="la la-check p-right-10"></i> Save
                            </h4>
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              setSetting({ ...setting, address: true })
                            }
                            style={{
                              background: "none",
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <h4>
                              <i className="la la-pencil p-right-10"></i> Edit
                            </h4>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="atbdb_content_module_contents">
                      <div className="user_info_wrap">
                        <div className="row">
                          {" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="street"
                                className="street account__profile__form-label"
                              >
                                Street
                              </label>
                              <input
                                disabled={!setting.address}
                                className="form-control"
                                id="street"
                                type="text"
                                name="street"
                                value={address.street}
                                onChange={addressOnChangeHandler}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="city"
                                className="city account__profile__form-label"
                              >
                                City
                              </label>
                              <input
                                disabled={!setting.address}
                                className="form-control"
                                id="city"
                                type="text"
                                name="city"
                                value={address.city}
                                onChange={addressOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="state"
                                className="state account__profile__form-label"
                              >
                                State
                              </label>
                              <input
                                disabled={!setting.address}
                                className="form-control"
                                id="state"
                                type="text"
                                name="state"
                                value={address.state}
                                onChange={addressOnChangeHandler}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="country"
                                className="country account__profile__form-label"
                              >
                                Country
                              </label>
                              <input
                                disabled={!setting.address}
                                className="form-control"
                                id="country"
                                type="text"
                                name="country"
                                value={address.country}
                                onChange={addressOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="zipCode"
                                className="zipCode account__profile__form-label"
                              >
                                Zip Code
                              </label>
                              <input
                                disabled={!setting.address}
                                className="form-control"
                                id="zipCode"
                                type="number"
                                name="zipcode"
                                value={address.zipcode}
                                onChange={addressOnChangeHandler}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-6">
                            {/* <div className="form-group">
                                <label
                                  htmlFor="phoneNumber"
                                  className="phoneNumber account__profile__form-label"
                                >
                                  Phone Number
                                </label>
                                <br />
                                <IntlTelInput
                                  disabled={!setting.address}
                                  preferredCountries={["us"]}
                                  onPhoneNumberChange={phoneOnchangeHandler}
                                  value={address.phoneNumber}
                                  onPhoneNumberBlur={(check) => {
                                    setPhoneValid(check);
                                  }}
                                  inputClassName="form-control"
                                  style={{
                                    width: "300px",
                                    border: phoneValid
                                      ? "2px solid green"
                                      : "2px solid red",
                                  }}
                                />
                                ,
                              </div> */}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                      {setting.address && (
                        <button
                          type="button"
                          onClick={() => getLocation()}
                          // onClick={this.updateUser}
                          className="btn  btn-lg btn-dark "
                        >
                          <i className="fas fa-compass"></i> Current Location
                        </button>
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/*<!-- ends: .atbd_author_module -->*/}
      </div>
    </Fragment>
  );
};

ProfileDashboard.propTypes = {
  uploadProfilePicture: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getCurrentLocation: PropTypes.func.isRequired,
  addAccountInformation: PropTypes.func,
  silverCompleted: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  silverCompleted: state.awards.silverCompleted,
});
export default connect(mapStateToProps, {
  uploadProfilePicture,
  getCurrentLocation,
  postAccountInformation,
})(ProfileDashboard);
