import { useState } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearNotification } from "../../redux/action/notification";
import moment from "moment";
import "./notification.css";

const NotificationBadge = () => {
  const [anchorEl, setAnchorE1] = useState(null);
  const togglePopper = (event) => {
    setAnchorE1(anchorEl ? null : event.currentTarget);
  };

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const notificationArray = notifications.notifications;

  const dispatch = useDispatch();

  const clearNotificationofUser = () => {
    dispatch(clearNotification());
  };
  return (
    <div style={{ marginTop: "3px" }}>
      {notifications.notifications && notifications.notifications.length ? (
        <div
          className="author-info"
          id="nav-notification-container"
          style={{ maxHeight: "100px" }}
        >
          <NavLink to="#" className="author-avatar">
            <i onClick={togglePopper} className="la la-2x la-bell">
              {" "}
              <span
                onClick={togglePopper}
                style={{
                  position: "absolute",
                  fontSize: "xx-small",
                  marginLeft: "-5px",
                  marginTop: "-10px",
                  backgroundColor: "var(--orange)",
                }}
                className="badge badge-light badge-sm"
              >
                {notifications.notifications && notifications.count > 0 ? (
                  notifications.count
                ) : (
                  <div></div>
                )}
              </span>
            </i>
          </NavLink>
          <ul
            style={{ width: "400px", height: "300px", overflowY: "scroll" }}
            className="list-unstyled"
          >
            <span>
              {" "}
              <Button className="btn-sm" onClick={clearNotificationofUser}>
                Clear
              </Button>
            </span>

            {notifications.notifications && notifications.count ? (
              notificationArray.reverse().map((notification, index) => (
                <li key={index}>
                  {notifications.count > 0 ? (
                    <NavLink
                      to={
                        notification.notificationType === "Message"
                          ? "/chat"
                          : "/dashboard-listings#applicantList"
                      }
                    >
                      <div className="img-notification">
                        <img
                          width="50px"
                          height="50px"
                          src={
                            notification.senderId &&
                            notification.senderId.userAvatar
                              ? notification.senderId.userAvatar
                              : "./assets/img/profile.png"
                          }
                          alt=""
                          className="rounded-circle"
                        />{" "}
                        <p style={{ marginLeft: "10px" }}>
                          {" "}
                          {notification.message}
                        </p>
                        <br />
                      </div>
                      <b style={{ marginLeft: "180px", marginTop: "0px" }}>
                        {moment(notification.createdAt).fromNow("dd") + " ago"}
                      </b>
                    </NavLink>
                  ) : (
                    <div></div>
                  )}
                </li>
              ))
            ) : (
              <div></div>
            )}
          </ul>
        </div>
      ) : (
        <div>
          {" "}
          <i className="la la-2x la-bell"> </i>
        </div>
      )}
    </div>
  );
};

export default NotificationBadge;
