import API from "./api";
const setAuthToken = (token, user) => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    delete API.defaults.headers.common["x-auth-user"];
    localStorage.removeItem("token");
  }
};
export default setAuthToken;
