import React, { Fragment } from "react";
import moment from "moment";
import { compensationType, estimatedHours } from "../../../services/consts";
import "./styles/listing-details-story.css";

export function ContentStory({
  location,
  listing,
  firstName,
  lastName,
  projectTitle,
}) {
  return (
    <Fragment>
      <div className="atbd_content_module atbd_listing_details">
        <div className="atbd_content_module__tittle_area">
          <div className="atbd_content_module__project_owner">
            <p>Project By :</p> <h4>{firstName}</h4> <h4>{lastName}</h4>{" "}
          </div>
          <br />
          <div className="atbd_area_title">
            <ul style={{ display: "flex" }} className="list-unstyled ">
              <li className="pr-lg-4">
                <b className="atbd_area_project-infos">Project Title : </b>
                <span className="atbd_area_project-infos_value">{`${projectTitle[0].toUpperCase()}${projectTitle.slice(
                  1
                )}`}</span>
              </li>
              <li className="pr-lg-4">
                <h5> </h5>{" "}
                <b className="atbd_area_project-infos">Location : </b>
                <span className="atbd_area_project-infos_value">
                  {" "}
                  {`${location[0].toUpperCase()}${location.slice(1)}`}
                </span>
              </li>
              <li className="pr-lg-4">
                {" "}
                <h5> </h5>
                <b className="atbd_area_project-infos"> Posted :</b>{" "}
                <span className="atbd_area_project-infos_value">
                  {" "}
                  {moment(listing.createdAt).fromNow()}{" "}
                </span>
              </li>{" "}
              {/* <li>
                {" "}
                <b style={{ color: "#4dd3d3" }}> Position :</b>{" "}
                {`${listing.typeOfWork[0].toUpperCase()}${listing.typeOfWork.slice(
                  1
                )}`}
              </li> */}
            </ul>
          </div>
        </div>

        <div className="atbdb_content_module_contents">
          <div className="mb-3">
            <h4 style={{ color: "#4dd3d3" }}>Project Description</h4> <br />
            <h6 className="job_description">{listing.longDescription}</h6>
          </div>

          <div className="project_info">
            <h4>Estimated Project Start Date : </h4>
            <h5>{moment(listing.projectStartDate).format("MMM Do YY")}</h5>
          </div>

          <div className="project_info">
            <h4>Estimated Project End Date : </h4>
            <h5>
              {listing.projectEndDate
                ? moment(listing.projectEndDate).format("MMM Do YY")
                : "Continuous"}
            </h5>
          </div>

          <div className="project_info">
            <h4>Posted :</h4> <h5> {moment(listing.createdAt).fromNow()} </h5>
          </div>

          <div className="project_info">
            <h4> Compensation Type : </h4>
            <h5> {compensationType[listing.compensationType]} </h5>
          </div>
          {listing.compensationType !== "nonPaid" && (
            <div className="project_info">
              <h4>Compensation : </h4>{" "}
              <h5>
                <i className="fa fa-dollar"></i> {listing.compensation}
              </h5>
            </div>
          )}
          {listing.compensationType === "ratePerHour" && (
            <>
              {" "}
              <div className="project_info">
                <h4>Total Hours : </h4> <h5>{listing.totalHours}</h5>
              </div>
              <div className="project_info">
                <h4>Total Cost : </h4>{" "}
                <h5>
                  <i className="fa fa-dollar"></i>
                  {listing.cost}
                </h5>
              </div>
            </>
          )}
          <div className="project_info">
            <h4> Location :</h4>{" "}
            <h5>
              {listing.projectLocation[0].toUpperCase()}
              {listing.projectLocation.slice(1)}
            </h5>
          </div>

          {/* <div className="project_info">
            <h4>Estimated Hours/day : </h4>{" "}
            <h5>{estimatedHours[listing.estimatedHoursPerDay]}</h5>
          </div> */}

          {/* <div className="project_info">
            <h4> Type of work : </h4>
            <h5>
              {listing.typeOfWork[0].toUpperCase()}
              {listing.typeOfWork.slice(1)}
            </h5>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
}
