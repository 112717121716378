import React, { Fragment } from "react";
import ViewApplicantsList from "./applicantList";
import { connect } from "react-redux";
import RatingModal from "./modal/ratingModal";

const ViewApplicantListProp = (props) => {
  const getSearchTerms = () => {
    return (
      props.location && props.location.state && props.location.state.searchTerms
    );
  };

  return (
    <Fragment>
      <ViewApplicantsList searchTerms={getSearchTerms()} />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    logo: state.logo,
  };
};

export default connect(mapStateToProps)(ViewApplicantListProp);
