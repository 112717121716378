import {
  SAVE_CRAFT_PROFILE,
  GET_PHOTO_GALLERY,
  GET_AUDIO_PLAYLIST,
  GET_CERTFICATION,
  GET_ABOUT,
  GET_ALBUM_PHOTOS,
  CLEAR_ALBUM_PHOTOS,
  GET_VIDEO_ALBUM,
  GET_ALBUM_VIDEOS,
  CLEAR_ALBUM_VIDEOS,
  GET_ALBUM_AUDIOS,
  GET_AUDIO_ALBUM,
  CLEAR_ALBUM_AUDIOS,
  DELETE_CERTIFICATION,
  GET_UPDATED_ALBUM_PHOTOS,
  GET_UPDATED_ALBUM_VIDEOS,
  GET_HEADER_IMAGE,
  GET_UPDATED_PHOTO_ALBUM,
  GET_UPDATED_AUDIO_ALBUM,
  LOADINGCOMPLETED,
  LOADINGSTARTED,
} from "./types";
import {} from "./types";
import { loaduser } from "../action/auth";
import API from "../../api/api";
import { setAlert } from "./alert";
import { contentType } from "../../services/consts";
import { FileType } from "../../services/consts";

/* =================================   PHOTO ROUTES   =================*/

export const createHeaderImage = (body) => async (dispatch) => {
  try {
    const response = await API.post("/content/header-image", body, {
      headers: {
        "Content-Type": `multipart/form-data;`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_HEADER_IMAGE, payload: response.data.coverPhoto });
    dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const getHeaderImage = () => async (dispatch) => {
  try {
    const response = await API.get("/content/header-image", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_HEADER_IMAGE, payload: response.data.coverPhoto });

    dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const uploadPhoto = (photos, albumId) => async (dispatch) => {
  try {
    dispatch({ type: LOADINGSTARTED });

    await API.post("/content/gallery-photos/" + albumId, photos, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${photos._boundary}`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch(getAlbumPhotos(albumId));
    dispatch({ type: LOADINGCOMPLETED });
  } catch (error) {
    console.log("error", error);
  }
};

export const updateImageInAlbum =
  (contentInfo, imageId, selectedAlbumId) => async (dispatch) => {
    try {
      await API.put(
        "/content/gallery-photos/" + selectedAlbumId + "/" + imageId,
        contentInfo,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      dispatch(getAlbumPhotos(selectedAlbumId));
    } catch (error) {
      console.log("error" + error);
    }
  };

export const updateAudioInAlbum =
  (contentInfo, audioId, selectedAlbumId) => async (dispatch) => {
    console.log("conentInfo", contentInfo);
    console.log("audioId " + audioId);
    try {
      await API.put(
        "/content/gallery-audios/" + selectedAlbumId + "/" + audioId,
        contentInfo,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );
      dispatch(getAlbumAudios(selectedAlbumId));
    } catch (error) {
      console.log("error" + error);
    }
  };

export const updatePhotoAlbum =
  (contentInfo, selectedAlbumId) => async (dispatch) => {
    try {
      const response = await API.put(
        "/content/photo-album/" + selectedAlbumId,
        contentInfo,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: GET_UPDATED_PHOTO_ALBUM, payload: response.data });
    } catch (error) {
      console.log("error" + error);
    }
  };

export const deleteMediaFile =
  (imageId, selectedAlbumId, type, contentType) => async (dispatch) => {
    try {
      await API.delete(
        `/content/gallery-photos/${selectedAlbumId}/${imageId}?fileType=${FileType[type]}&albumType=${contentType}`
      );

      if (FileType[type] === "video") {
        dispatch(getAlbumVideos(selectedAlbumId));
      }
      if (FileType[type] === "audio") {
        dispatch(getAlbumAudios(selectedAlbumId));
      }
      if (FileType[type] === "photo") {
        dispatch(getAlbumPhotos(selectedAlbumId));
      }
    } catch (error) {
      console.log("Error Message" + error);
    }
  };

// export const deleteVideoInAlbum =
//   (imageId, selectedAlbumId) => async (dispatch) => {
//     try {
//       const response = await API.delete(
//         `/content/gallery-photos/${selectedAlbumId}/${imageId}`
//       );

//       dispatch(getAlbumVideos());
//     } catch (error) {
//       console.log("Error Message" + error);
//     }
//   };

export const deleteAlbum = (albumId, type) => async (dispatch) => {
  try {
    console.log(type);
    await API.delete(
      `/content/album/${albumId}?contentType=${contentType[type]}`
    );
    if (contentType[type] === "videoAlbums") {
      dispatch(getVideoAlbum());
    }
    if (contentType[type] === "audioAlbums") {
      dispatch(getAudioAlbum());
    }
    if (contentType[type] === "imageAlbums") {
      dispatch(getGallery());
    }
    // dispatch({ type: DELETE_ALBUM, payload: response.data });
  } catch (error) {
    console.log("Error Message " + error);
  }
};

export const updateVideoAlbum =
  (contentInfo, selectedAlbumId) => async (dispatch) => {
    try {
      await API.put("/content/video-album/" + selectedAlbumId, contentInfo, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      });

      // dispatch(getAlbumVideos(selectedAlbumId));
      dispatch(getVideoAlbum());
    } catch (error) {
      console.log("error" + error);
    }
  };

export const updateAudioAlbum =
  (contentInfo, selectedAlbumId) => async (dispatch) => {
    try {
      const response = await API.put(
        "/content/audio-album/" + selectedAlbumId,
        contentInfo,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: GET_UPDATED_AUDIO_ALBUM, payload: response.data });
    } catch (error) {
      console.log("error" + error);
    }
  };

export const updateVideoInAlbum =
  (contentInfo, videoId, selectedAlbumId) => async (dispatch) => {
    try {
      await API.put(
        "/content/gallery-videos/" + selectedAlbumId + "/" + videoId,
        contentInfo,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-user": localStorage.getItem("token"),
          },
        }
      );

      dispatch(getAlbumVideos(selectedAlbumId));
    } catch (error) {
      console.log("error", error);
    }
  };

/** Video Routes */
export const uploadVideos = (videos, albumId) => async (dispatch) => {
  try {
    dispatch({ type: LOADINGSTARTED });
    await API.post("/content/album-videos/" + albumId, videos, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${videos._boundary}`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch(getAlbumVideos(albumId));
    dispatch({ type: LOADINGCOMPLETED });
  } catch (error) {
    console.log("error", error);
  }
};

export const getAlbumVideos = (albumId) => async (dispatch) => {
  try {
    const response = await API.get(`/content/gallery-videos/${albumId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_ALBUM_VIDEOS, payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const clearAlbumVideos = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ALBUM_VIDEOS, payload: [] });
  } catch (error) {
    console.log("error", error);
  }
};

export const createVideoAlbum = (body) => async (dispatch) => {
  try {
    const response = await API.post("/content/video-album", body, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_VIDEO_ALBUM, payload: response.data });
    dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const getVideoAlbum = () => async (dispatch) => {
  try {
    const response = await API.get("/content/video-album", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_VIDEO_ALBUM, payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

/** End Video Routes */

/** Audio Routes */

export const uploadAudios = (audios, albumId) => async (dispatch) => {
  try {
    dispatch({ type: LOADINGSTARTED });
    await API.post("/content/album-audios/" + albumId, audios, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${audios._boundary}`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch(getAlbumAudios(albumId));
    dispatch({ type: LOADINGCOMPLETED });
  } catch (error) {
    console.log("error", error);
  }
};

export const getAlbumAudios = (albumId) => async (dispatch) => {
  try {
    const response = await API.get(`/content/gallery-audios/${albumId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_ALBUM_AUDIOS, payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const clearAlbumAudios = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ALBUM_AUDIOS, payload: [] });
  } catch (error) {
    console.log("error", error);
  }
};

export const createAudioAlbum = (body) => async (dispatch) => {
  try {
    const response = await API.post("/content/audio-album", body, {
      headers: {
        "Content-Type": `multipart/form-data;`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_AUDIO_ALBUM, payload: response.data });
    dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const getAudioAlbum = () => async (dispatch) => {
  try {
    const response = await API.get("/content/audio-album", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_AUDIO_ALBUM, payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

/** End Audio Routes */

export const clearAlbumPhotos = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ALBUM_PHOTOS, payload: [] });
  } catch (error) {
    console.log("error", error);
  }
};

export const getAlbumPhotos = (albumId, userId) => async (dispatch) => {
  try {
    const response = await API.get(`/content/gallery-photos/${albumId}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_ALBUM_PHOTOS, payload: response.data });
  } catch (e) {
    console.log(e);
  }
};

export const createGalleryAlbum = (body) => async (dispatch) => {
  try {
    const response = await API.post("/content/gallery-album", body, {
      headers: {
        "Content-Type": `multipart/form-data;`,
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_PHOTO_GALLERY, payload: response.data });
    // dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const postAudioFiles = (body) => async (dispatch) => {
  try {
    await API.post("/craftProfile/audio-files", body, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    // dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const getGallery = () => async (dispatch) => {
  try {
    const response = await API.get(`/content/gallery-album`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_PHOTO_GALLERY, payload: response.data });
    dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteGalleryAlbum = (albumId) => async (dispatch) => {
  try {
    await API.delete("/craftProfile/album/" + albumId, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

/* =================================   END   =================*/

/* =================================CERTIFICATION ROUTES=================*/

export const postCertification = (body) => async (dispatch) => {
  try {
    const response = await API.post("/content/certification", body, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({
      type: GET_CERTFICATION,
      payload: response.data[0].certifications,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const getCertifications = () => async (dispatch) => {
  try {
    const response = await API.get("/content/certifications", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({
      type: GET_CERTFICATION,
      payload: response.data[0].certifications,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteCertification = (certID) => async (dispatch) => {
  try {
    const deletedCertId = await API.delete(
      "/content/certifications/" + certID,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );

    dispatch({ type: DELETE_CERTIFICATION, payload: deletedCertId.data });
  } catch (error) {
    console.log("error", error);
  }
};

/* =================================     ENd      =================*/

/* =================================   AUDIO Albums  =================*/

export const createAlbumPlaylist = (body) => async (dispatch) => {
  try {
    const response = await API.post("/craftProfile/playlist", body, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_AUDIO_PLAYLIST, payload: response.data });

    dispatch(loaduser());
  } catch (error) {
    console.log("error", error);
  }
};

export const getAlbumPlayList = () => async (dispatch) => {
  try {
    await API.get("/craftProfile/playlist", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteAudioAlbum = (playlistID) => async (dispatch) => {
  try {
    const response = await API.delete(`/craftProfile/playlist/${playlistID}`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: GET_AUDIO_PLAYLIST, payload: response.data });
  } catch (error) {
    console.log("error", error);
  }
};

/* =================================     ENd      =================*/

/* =================================     About Craft Profile       =================*/

//PUT  ABout  Craft Profile
export const updateCraftInformation = (body) => async (dispatch) => {
  try {
    const response = await API.put("/craft-Profile", body, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });
    dispatch({ type: SAVE_CRAFT_PROFILE, payload: response.data });
  } catch (error) {
    console.log("error", error);
  }
};

//Get ABout

export const getAbout = (userId) => async (dispatch) => {
  try {
    const data = await API.get("/craft-profile", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-user": localStorage.getItem("token"),
      },
    });

    dispatch({ type: GET_ABOUT, payload: data.data });
  } catch (error) {
    // dispatch({ type: GET_ABOUT_ERROR, payload: error });
    dispatch(setAlert("Error", "Error While Saving About Craft Information "));
    // return error.response.data.msg;
  }

  //
};

/* =================================     ENd      =================*/
