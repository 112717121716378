import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function WelcomeModal() {
  const [open, setOpen] = React.useState(true);
  //   const handleOpen = () => setOpen(openModal);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="modal__welcome_container">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal__welcome_modal_container"
      >
        <Box sx={style} className="modal__welcome_container_box">
          <h2 className="modal__welcome_title">
            "Welcome to the Socicraft Community"
          </h2>
          <ul
            className="modal__welcome_lists"
            style={{ listStyleType: "disc" }}
          >
            <li>
              Hire creatives by adding a listing or complete onboarding to apply
              for listings
            </li>
            <li>
              Browse, share your expertise, & showcase your work in the
              community reflections feed
            </li>
            <li>
              Explore other parts of the site by clicking on your profile image
              in the right corner
            </li>
          </ul>
          <h3 className="modal__welcome_bottom_title">
            We're always adding new features, so stay tuned!
          </h3>
        </Box>
      </Modal>
    </div>
  );
}
