import * as React from "react";
import Modal from "@mui/material/Modal";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { completeOnboarding } from "../../../../redux/action/stripe";
export default function CompleteOnboarding({ open, toggleSignIn }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #97e5e5",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const [show, setShow] = React.useState(open);
  const handleClose = () => {
    window.history.replaceState({}, document.title);
    setShow(false);
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ONBOARDING NOT COMPLETE: <br /> You will need to complete the
          onboarding process before applying to listings. Please complete it by
          visiting the Earnings tab on your Dashboard.
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="d-flex justify-content-center ">
            <a href="/dashboard-listings/?t=earnings&st=earnings">
              {" "}
              <button className="btn btn-primary">Manage Earnings</button>
            </a>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
}
