import React, { useEffect } from "react";
import MediaElement from "./mediaElement";
import "./style/mediaScroller.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlbumVideos } from "../../redux/action/craftProfile";
import Spinner from "../content/modal/spinnermodal";
import BackDropSpinner from "../backDropSpinner/backDropSpinner";

export const VideoBar = ({ viewType, selectedAlbumId, type, userId }) => {
  const imageSizer = {
    marginTop: "5px",
    inlineSize: "100%",
    aspectRatio: "16/9",
    borderRadius: "3%",
    objectFit: "cover",
    marginBottom: "5px",
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAlbumVideos(selectedAlbumId, userId));
  }, [dispatch, selectedAlbumId, userId]);
  const videos = useSelector((state) => state.craftProfile.albumVideos);

  const isLoading = useSelector(
    (state) => state.craftProfile.videoAlbumLoading
  );

  const isUploading = useSelector((state) => state.process.isloading);

  const handleVideoChange = (video, tag, title, description) => {
    setShowFirstVideo(false);
    setCurrentVideo(video);
    setCurrentTag(tag);
    setCurrentTitle(title);
    setCurrentDescription(description);
    setIsTruncated(true);
  };

  const [currentVideo, setCurrentVideo] = useState("");
  const [currentTag, setCurrentTag] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [isTruncated, setIsTruncated] = useState(true);
  const [showFirstVideo, setShowFirstVideo] = useState(true);

  const resultDescription =
    isTruncated && currentTitle ? currentTitle.slice(0, 150) : currentTitle;

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div>
      <BackDropSpinner isUploading={isUploading} />
      <div>
        {videos.length ? (
          <div>
            <div className="big-image">
              <video
                key={currentVideo}
                controls
                style={imageSizer}
                alt="soci-craft user videos"
              >
                {" "}
                <source
                  src={showFirstVideo ? videos[0].url : currentVideo}
                />{" "}
              </video>
            </div>

            <div style={{ minHeight: "30px" }} className="info">
              <b>
                <h2
                  style={{
                    fontWeight: "bolder",
                    marginBottom: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  {showFirstVideo ? videos[0].title : currentDescription}
                </h2>
              </b>

              <p>
                {" "}
                {showFirstVideo ? videos[0].description : resultDescription}
                <span>
                  {resultDescription &&
                  resultDescription.length > 100 &&
                  isTruncated === true ? (
                    <span>...</span>
                  ) : (
                    <></>
                  )}{" "}
                </span>
              </p>

              {resultDescription &&
              resultDescription.length > 100 &&
              isTruncated ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => toggleIsTruncated()}
                >
                  {isTruncated ? <b>...see more</b> : "see less"}
                </button>
              ) : (
                <span></span>
              )}

              <p>
                {" "}
                {showFirstVideo != null && showFirstVideo
                  ? videos[0].tags.map((tag) => (
                      <span style={{ color: "#005fb9" }}>#{tag} </span>
                    ))
                  : currentTag.map((tag) => (
                      <span style={{ color: "#005fb9" }}>#{tag} </span>
                    ))}
              </p>
            </div>

            <div className="media-scroller">
              {videos.map((video, index) => (
                <MediaElement
                  viewType={viewType}
                  key={index}
                  videofunc={handleVideoChange}
                  videoId={video._id}
                  selectedAlbumId={selectedAlbumId}
                  videoUrl={video.url}
                  videoTag={video.tags}
                  videoDescription={video.description}
                  videoTitle={video.title}
                />
              ))}
            </div>
          </div>
        ) : isLoading ? (
          <div style={{ display: "flex" }}>
            <Spinner />
          </div>
        ) : (
          <div className="alert alert-info" role="alert">
            {" "}
            Empty Album, Start by adding some videos{" "}
          </div>
        )}
      </div>
    </div>
  );
};
