import React from "react";
import { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import "../postFeedstyles.css";

export default function PostLinkCopy({ id }) {
  const [copyLink, setCopyLink] = useState(false);

  const copyLinkHandler = (link) => {
    const webAddress =
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST"
        ? "https://workshop.socicraft.us"
        : "https://workshop.socicraft.us";
    setCopyLink(true);

    setTimeout(() => {
      setCopyLink(false);
    }, 1500);
    navigator.clipboard.writeText(`${webAddress}${link}`);
  };
  return (
    <div
      disabled={copyLink}
      onClick={() => copyLinkHandler(`/social/discovery/${id}`)}
    >
      {copyLink ? (
        <IconButton aria-label="share" className="icon_btn">
          <DoneAllIcon className="post_icon" />
          <span className="discover_post_feed__post_icons">0</span>
        </IconButton>
      ) : (
        <>
          {" "}
          <IconButton aria-label="share" className="icon_btn">
            <ShareIcon className="post_icon" />
            <span className="discover_post_feed__post_icons">0</span>
          </IconButton>
        </>
      )}
    </div>
  );
}
