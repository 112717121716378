import React, { Fragment } from "react";

export class LinkModal extends React.Component {
  render() {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#modal"
        >
          Launch demo modal
        </button>

        <div
          className="modal fade"
          id="modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">
                  You are leaving Socicraft for an external website.
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body external-link-modal-body">
                <p>Express Yourself. Socialize your Craft.</p>
                <p>Find your next gig!</p>
                <p>Join Today.</p>
                <br />
                <form className="modal-form">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      placeholder="Email address"
                      aria-describedby="emailHelp"
                    ></input>
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      id="InputPassword"
                      placeholder="Password"
                    ></input>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      id="ConfirmPassword"
                      placeholder="Confirm Password"
                    ></input>
                  </div>
                  <div className="form-group">
                    {/* this is where the captcha will go */}
                  </div>
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="RememberCheck"
                    ></input>
                    <label className="form-check-label" htmlFor="RememberCheck">
                      Remember me!
                    </label>
                  </div>
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="RememberCheck"
                    ></input>
                    <label className="form-check-label" htmlFor="RememberCheck">
                      By Submitting this form...
                    </label>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Continue
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export class SupportModal extends React.Component {
  render() {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#supportModal"
        >
          Launch support modal
        </button>

        <div
          className="modal fade"
          id="supportModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">
                  Send Support Request
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body external-link-modal-body">
                <p>Hello, __username__,</p>
                <p>I want to support your craft by: </p>
                <br />
                <form className="modal-form">
                  <input
                    type="checkbox"
                    id="inspirationCheckbox"
                    name="inspirationCheckbox"
                    value="Inspiration"
                    style={{ marginRight: "3px" }}
                  ></input>
                  <label htmlFor="inspirationCheckbox"> Inspiration</label>
                  <br />
                  <input
                    type="checkbox"
                    id="tipsTricksCheckbox"
                    name="tipsTricksCheckbox"
                    value="Tips Tricks"
                    style={{ marginRight: "3px" }}
                  ></input>
                  <label htmlFor="tipsTricksCheckbox"> Tips & Tricks</label>
                  <br />
                  <input
                    type="checkbox"
                    id="networkCheckbox"
                    name="networkCheckbox"
                    value="Networking"
                    style={{ marginRight: "3px" }}
                  ></input>
                  <label htmlFor="networkCheckbox">
                    {" "}
                    Networking Opportunities
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    id="entertainmentCheckbox"
                    name="entertainmentCheckbox"
                    value="Entertainment"
                    style={{ marginRight: "3px" }}
                  ></input>
                  <label htmlFor="networkCheckbox">
                    {" "}
                    Entertainment Purposes Only
                  </label>
                  <br />
                  <br />
                  <label htmlFor="supportModalTextArea">
                    Send Personalized Message (Optional)
                  </label>
                  <textarea
                    className="form-control"
                    id="supportModalTextArea"
                    rows="4"
                  ></textarea>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
