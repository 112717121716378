import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
const noAction = (e) => e.preventDefault();
export class ContentBlockHome extends Component {
  render() {
    return (
      <Fragment>
        <section className="cta section-padding border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2 className="socicraft__section_title_main_title">
                    Socialize your craft
                  </h2>
                  <p className="socicraft__section_title">
                    Socicraft is the first social marketplace for art, media,
                    entertainment and talent, designed for all specialties &
                    crafts.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
                    <ul className="feature-list-wrapper list-unstyled">
                      <li>
                        <div className="icon"></div>
                        <div className="list-content">
                          <h4>Quick onboarding</h4>
                          <p className="feature-list__description">
                            We make it easy to find, hire and work with
                            crafters. No hassle - once you have your account set
                            up, you can start browsing profiles and posting
                            listings in minutes.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div className="list-content">
                          <h4>Quick payout, 3x faster than other services</h4>
                          <p className="feature-list__description">
                            After initial payout is complete, you are eligible
                            for faster payments once the project is approved &
                            cleared. - no waiting for weeks (unless it's your
                            first using our platform it takes 7 -14 days)
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div className="list-content">
                          <h4>Community reflections feed</h4>
                          <p className="feature-list__description">
                            Browse & observe the community reflections. We
                            encourage our community members to upload and share
                            their work and abilities with others, receive direct
                            feedback from other members, and post videos so that
                            the can expand their network.
                          </p>
                        </div>
                      </li>
                    </ul>
                    {/*<!-- ends: .feature-list-wrapper -->*/}
                    <ul className="action-btns list-unstyled">
                      {/* <li><NavLink onClick={noAction} to="/at_demo" className="btn btn-success">See our Pricing</NavLink></li> */}
                      {/* <li>
                        <NavLink
                          onClick={noAction}
                          to="/at_demo"
                          className="btn btn-primary"
                        >
                          Review all listings
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <img
                      src="./assets/img/social-media-people-networking-2022-10-27-04-25-55-utc (1).jpg"
                      alt=""
                      className="svg"
                      width="100%"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
                    <ul className="feature-list-wrapper list-unstyled">
                      <li>
                        <div className="icon"></div>
                        <div className="list-content">
                          <h4>Simple Hiring</h4>
                          <p className="feature-list__description">
                            You pay nothing until you've found your crafter
                            who's going to deliver based on their experience &
                            your own screening process. You can take advantage
                            of our tools to post listings within minutes easily!
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div className="list-content">
                          <h4>A premium Solution</h4>
                          <p className="feature-list__description">
                            Negotiate terms & conditions for your project within
                            our secure site. We have video calling, so you meet
                            your criteria sooner without giving your
                            information. Casting a wide net is crucial when
                            looking to find the right freelance worker.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"></div>
                        <div className="list-content">
                          <h4>Get your business featured</h4>
                          <p className="feature-list__description">
                            Add your company for spotlight opportunity Socicraft
                            wants you to become part of the community of
                            artistic passion. Create your account now to give
                            your company the chance to give back to this growing
                            online platform for talent.
                          </p>
                        </div>
                      </li>
                    </ul>
                    {/*<!-- ends: .feature-list-wrapper -->*/}
                    <ul className="action-btns list-unstyled">
                      {/* <li><NavLink onClick={noAction} to="/at_demo" className="btn btn-success">See our Pricing</NavLink></li> */}
                      {/* <li>
                        <NavLink
                          onClick={noAction}
                          to="/at_demo"
                          className="btn btn-primary"
                        >
                          Review all listings
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export class ContentBlockAbout extends Component {
  render() {
    return (
      <Fragment>
        <section className="about-contents section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 contents-wrapper">
                <div className="contents">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-sm-6">
                      <img src="./assets/img/about-img1.png" alt="" />
                    </div>
                    <div className="col-lg-6 offset-lg-1 col-sm-6 mt-5 mt-md-0">
                      <h1>About Our Community and Our Expertise</h1>
                      <p>
                        Excepteur sint occaecat cupidatat non proident sunt in
                        culpa officia runmollit anim laborum occaecat cupidatat
                        proident. Cupidatat non proident sunt in culpa officia
                        deserunt.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
