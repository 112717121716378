import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import Header from "../layout/header/index";
import ChatSearch from "./search/Chat-Search";
import ConversationList from "./conversation/Conversation-List";
import NewConversation from "./conversation/New-Conversation";
import ChatTitle from "./chat-title/Chat-Title";
import OnlineUsers from "./online-users/onlineUsers";
import MessageList from "./message/Message-List";
import ChatForm from "./chat-form/Chat-Form";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { sendMessage } from "../../redux/action/messaging";

import "./chat.css";
import CallingNotification from "../../components/videoCall/notifications/callingNotification";
const Chat = ({ user, sendMessage, socket, messageStatus, room }) => {
  useEffect(() => {
    const switchTabToVideo = () => {
      if (room.streaming) {
        setValue("2");
      }
      if (!room.streaming) {
        setValue("1");
      }
    };
    switchTabToVideo();
  }, [room]);
  //!! ********** COMPONENT LEVEL STATE **************************//

  const [title, setTitle] = useState("");
  const [messageForm, setMessageForm] = useState("");

  //!! ********************** END  **************************//

  const loadMessage = (data) => {
    if (JSON.parse(localStorage.getItem("user")).id === data.to.id) {
      setTitle(data.User.firstName);
    } else {
      setTitle(data.to.firstName);
    }
  };
  const onChangeHandler = ({ currentTarget, keyCode }) => {
    const { value } = currentTarget;

    setMessageForm(value);
  };
  const sendMessageContent = async (e) => {
    if (e && e.keyCode === 13 && messageForm && messageForm.length) {
      let messageContent = {
        message: messageForm,
        conversationId: messageStatus.currentConversation,
        members: messageStatus.participants.find(
          (participant) => user.id !== participant.userId.toString()
        ),
      };

      setMessageForm("");
      sendMessage(messageContent);
    } else if (e && e.type === "click" && messageForm && messageForm.length) {
      let messageContent = {
        message: messageForm,
        conversationId: messageStatus.currentConversation,
        members: messageStatus.participants.find(
          (participant) => user.id !== participant.userId.toString()
        ),
      };

      setMessageForm("");
      sendMessage(messageContent);
    }
  };
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="section-bg">
      <section className="header-breadcrumb bgimage overlay overlay--dark">
        <div className="bg_image_holder">
          <img src="" alt="" />
        </div>
        <div className="mainmenu-wrapper">
          <Header class="menu--light" />
        </div>
        {/* <BreadcrumbWraper title="Add Listing" /> */}
      </section>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          padding: "30px 0px",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Chat" value="1" />
              {room.streaming && <Tab label="Video" value="2" />}
              {/* <Tab label="Item Three" value="3" /> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <div id="chat-container">
              <OnlineUsers />

              <ConversationList loadMessage={loadMessage} />
              <>
                <ConversationList loadMessage={loadMessage} />
              </>
              <NewConversation />

              <>
                <ChatSearch />
                <ChatTitle userName={title} messageStatus={messageStatus} />
                <MessageList messageStatus={messageStatus} />
                <ChatForm
                  messageForm={messageForm}
                  sendMessage={sendMessageContent}
                  onChangeHandler={onChangeHandler}
                  messageStatus={messageStatus}
                />
              </>
            </div>
          </TabPanel>
          <TabPanel value="2">
            {room.streaming && <CallingNotification />}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};
Chat.propTypes = {
  user: PropTypes.object,
  socket: PropTypes.object,
  messageStatus: PropTypes.object,
  room: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  socket: state.socket.socket,
  messageStatus: state.messaging.messageStatus,
  room: state.videoCalling.room,
});
export default connect(mapStateToProps, {
  sendMessage,
})(Chat);
