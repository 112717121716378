import React from "react";
import Typography from "@mui/material/Typography";

export default function Name({ firstName, lastName }) {
  return (
    <Typography variant="h6" gutterBottom component="div">
      {firstName} {lastName}
    </Typography>
  );
}
