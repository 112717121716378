import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Typography from "@mui/material/Typography";

const steps = [
  {
    label: "Prepare Document ",
    description: ``,
  },
  {
    label: "Client Sign Document",
    description: "",
  },
  {
    label: "Contractor Signature",
    description: ``,
  },
  {
    label: "Document Completed",
    description: `.`,
  },
];

export default function ProgressStepper({ step, document }) {
  console.log("Document ", document);
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={step} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
            // optional={
            //   index === 2 ? (
            //     <Typography variant="caption">Info ...</Typography>
            //   ) : null
            // }
            >
              {document && document.status === "Opted-Out"
                ? "Opted-Out"
                : step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}
