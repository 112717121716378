import axios from "axios";
// let url = "http://localhost:8000/api";
// let url = "http://3.131.73.246/api/";
let url = "https://server.socicraft.us/api/";
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "TEST") {
  url = `${process.env.REACT_APP_SERVER_URL}/api`;
}
export default axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    "x-auth-user": localStorage.getItem("token"),
  },
});

//  if ($request_method = 'OPTIONS') {

//       add_header    'Access-Control-Allow-Origin' $http_origin always;
//       add_header    'Access-Control-Allow-Credentials' true always;
//       add_header    Access-Control-Allow-Methods "GET, POST, OPTIONS, HEAD";
//       add_header    Access-Control-Allow-Headers 'Origin,X-Requested-With,Content-Type,Cookie-Accept,x-auth-user,Authorization';
//       add_header   'Access-Control-Max-Age' 1728000;
//       add_header   'Content-Type' 'text/plain charset=UTF-8';
//       add_header   'Content-Length' 0;
//       return              200;

//   }
