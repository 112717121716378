import React from "react";

import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";
import { postResponseForApplicant } from "../../../redux/action/userpost";
import { getMessage } from "../../../redux/action/messaging";
function ApplicantBot({
  message,
  from,
  messageStatus,
  postResponseForApplicant,
  getMessage,
  threadId,
}) {
  const onApplicationAcceptClick = () => {
    let listingId = message.botData.data.listingId;
    let applicantId = message.botData.data.applicantId;
    let body = {
      postId: listingId,
      userId: applicantId,
      status: "Accepted",
    };
    postResponseForApplicant(body);

    getMessage({ id: threadId });
  };
  const onApplicationDeclineClick = () => {
    let listingId = message.botData.data.listingId;
    let applicantId = message.botData.data.applicantId;
    let body = {
      postId: listingId,
      userId: applicantId,
      status: "Declined",
    };
    getMessage({ id: threadId });
    postResponseForApplicant(body);
  };
  const onApplicationScreeningClick = () => {
    let listingId = message.botData.data.listingId;
    let applicantId = message.botData.data.applicantId;
    let body = {
      postId: listingId,
      userId: applicantId,
      status: "Screening",
    };
    getMessage({ id: threadId });
    postResponseForApplicant(body);
  };
  const onApplicationViewProfileClick = () => {};
  return (
    <>
      {" "}
      <div id="socibot-applied-listing-container">
        <p>
          New Applicant Alert: {message.botData.data.applicantName} applied for
          your project titled {message.botData.data.listingName}
        </p>{" "}
        <p>
          Message :{" "}
          {!message.text || message.text === "" ? "None" : message.text}
        </p>
        <div id="socibot-button-container">
          {message.botData.data.handled ? (
            <>
              {" "}
              <span id="socibot-applicant-closed">
                Application Handled - Status:{" "}
                {message.botData.data.status.toUpperCase()}
              </span>
            </>
          ) : (
            <>
              {" "}
              <button
                onClick={onApplicationScreeningClick}
                id="socibot-applicant-accept"
              >
                SCREENING
              </button>
              <button
                onClick={onApplicationAcceptClick}
                id="socibot-applicant-accept"
              >
                ACCEPT
              </button>
              <button
                onClick={onApplicationDeclineClick}
                id="socibot-applicant-decline"
              >
                {" "}
                DECLINE
              </button>{" "}
              <NavLink
                id="socibot-applicant-profile"
                to={`/${message.botData.data.applicantName}`}
                target="_blank"
              >
                VIEW PROFILE
              </NavLink>
            </>
          )}
        </div>{" "}
      </div>
    </>
  );
}
ApplicantBot.propTypes = {
  message: PropTypes.object,
  postResponseForApplicant: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  postResponseForApplicant,
  getMessage,
})(ApplicantBot);
