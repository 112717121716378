import API from "../../api/api";

export const sendEnvelope = (documentId, listingId) => async (dispatch) => {
  try {
    await API.post(
      `/docusign/send-envelop/?documentId=${documentId}&listingId=${listingId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );
    return "success";
  } catch (error) {
    console.log("error message " + error);
  }
};
export const createViewEnvelope = (documentId) => async (dispatch) => {
  try {
    const { data } = await API.post(
      `/docusign/create-view/?documentId=${documentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-user": localStorage.getItem("token"),
        },
      }
    );

    return data;
  } catch (error) {
    console.log("error message " + error);
  }
};
