import React from "react";
import Header from "../../layout/header";

import notFoundImage1 from "../../../components/project-wide-images/404 images/404One.jpg";
import notFoundImage2 from "../../../components/project-wide-images/404 images/404Two.jpg";
import notFoundImage3 from "../../../components/project-wide-images/404 images/404Three.jpg";
import notFoundImage4 from "../../../components/project-wide-images/404 images/404Four.jpg";
import notFoundImage5 from "../../../components/project-wide-images/404 images/404Five.jpg";

const notFoundImages = [
  notFoundImage1,
  notFoundImage2,
  notFoundImage3,
  notFoundImage4,
  notFoundImage5,
];

const currentNotFoundImage = Math.floor(Math.random() * 5);

const NotFound = () => {
  return (
    <>
      <section className="header-breadcrumb bgimage overlay overlay--dark">
        <div className="mainmenu-wrapper">
          <Header class="menu--light" />
        </div>
        {/* <!-- ends: .mainmenu-wrapper --> */}
      </section>{" "}
      <div className="link_404_container">
        <img
          src={notFoundImages[currentNotFoundImage]}
          alt="page not found 404"
          className="link_404_image"
        />
        <a href="/" className="link_404">
          Go to Home
        </a>
      </div>
    </>
  );
};

export default NotFound;
