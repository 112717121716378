import React from "react";
// import CardListing from "./";
import CardListing from "../content/card/card-listing-grid-4";
import { Grid } from "@material-ui/core";

const ProjectListings = () => {
  return (
    <>
      <div>
        <div className="atbd_content_module__tittle_area">
          <div className="atbd_area_title">
            <h2 className="atbd_area_title_recent_listings">
              Most Recent Listings
            </h2>
            <Grid container>
              {" "}
              <CardListing />{" "}
            </Grid>
          </div>
        </div>

        {/* <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="toggleSwitchGallery"></input>
            <label class="custom-control-label" for="toggleSwitchGallery">I don't need this.</label>
        </div> */}
      </div>
    </>
  );
};

export default ProjectListings;
