import React, { useEffect } from "react";
import MediaElement from "./mediaElement";
import AudioPlayerCard from "../postFeed/audioPlayer/audioPlayerCard";
import InfoCard from "./infoCard";
import "./style/mediaScroller.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMediaFile,
  getAlbumAudios,
} from "../../redux/action/craftProfile";
import Spinner from "../content/modal/spinnermodal";
import BackDropSpinner from "../backDropSpinner/backDropSpinner";
import { FileType } from "../../services/consts";
import UpdateContent from "../content/modal/gallery/updateContent";
export const AudioBar = ({
  isAuthenticated,
  viewType,
  selectedAlbumId,
  type,
  userId,
}) => {
  const deleteAudio = (audioId, selectedAlbumId) => {
    dispatch(
      deleteMediaFile(
        audioId,
        selectedAlbumId,
        FileType["audio"],
        "audioAlbums"
      )
    ); //use method from action here!
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAlbumAudios(selectedAlbumId, userId, isAuthenticated));
  }, [dispatch, userId, isAuthenticated, selectedAlbumId]);
  const audios = useSelector((state) => state.craftProfile.albumAudios);
  const users = useSelector((state) => state.auth.user);
  const isLoading = useSelector(
    (state) => state.craftProfile.audioAlbumLoading
  );
  const isUploading = useSelector((state) => state.process.isloading);

  const [showUploadPhoto, setShowUploadPhoto] = useState(false);

  const [audioState, setAudioState] = useState({
    url: "",
    description: "",
    tags: [],
    selectedAlbumId: "",
    title: "",
    type: "",
  });

  const toggleUploadPhoto = () => {
    setShowUploadPhoto(!showUploadPhoto);
    setAudioState({
      url: "",
      description: "",
      tags: [],
      selectedAlbumId: "",
      title: "",
      type: "",
      id: "",
    });
  };

  const update = (url, title, description, tags, id, type) => {
    toggleUploadPhoto();
    setAudioState({
      url: url,
      title: title,
      description: description,
      tags: tags,
      type: type,
      id: id,
    });
  };

  return (
    <div className="audio_bar_container">
      {<BackDropSpinner isUploading={isUploading} />}
      <div>
        {audios.length ? (
          <div className="audio_bar">
            {audios.map((audio) => (
              <div key={audio._id}>
                <AudioPlayerCard
                  audios={[audio]}
                  albumImage={users.userAvatar}
                  albumImageKey={users.userAvatarKey}
                />

                <InfoCard
                  title={audio.title}
                  description={audio.description}
                  tags={audio.tags}
                  update={() =>
                    update(
                      audio.url,
                      audio.title,
                      audio.description,
                      audio.tags,
                      audio._id,
                      "audio"
                    )
                  }
                  deletePost={() => deleteAudio(audio._id, selectedAlbumId)}
                />
              </div>
            ))}

            <div>
              {audioState && audioState.url && (
                <UpdateContent
                  showUploadPhoto={showUploadPhoto}
                  toggleUploadPhoto={toggleUploadPhoto}
                  contentInfo={{
                    url: audioState.url,
                    tags: audioState.tags,
                    description: audioState.description,
                    title: audioState.title,
                    id: audioState.id,
                    selectedAlbumId: selectedAlbumId,
                    type: "audio",
                  }}
                />
              )}
            </div>
          </div>
        ) : isLoading ? (
          <div style={{ display: "flex" }}>
            <Spinner />
          </div>
        ) : (
          <div className="alert alert-info" role="alert">
            {" "}
            Empty Album, Start by adding some audios
          </div>
        )}
      </div>
    </div>
  );
};
