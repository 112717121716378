import React from "react";
import styled from "styled-components";

const ProfilePicture = styled.img`
  height: 260px;
  width: 260px;
  border-radius: 50%;
  cursor: pointer;
  transition: 400ms;
  border-style: solid;
  border-color: gray;
  align-item: center;
  background-color: ;
`;

const TeamInfo = () => {
  return <div className="profile-container"></div>;
};

export default TeamInfo;
