import React from "react";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import PauseIcon from "@mui/icons-material/Pause";
import "./audioplayer.css";

function Controls(props) {
  return (
    <div className="c-player--controls">
      <div className="control--position">
        {props.audiosNumber > 1 && (
          <SkipPreviousIcon
            className="skip-btn"
            onClick={() => props.SkipSong(false)}
          />
        )}
        <div
          className="play-btn"
          onClick={() => props.setIsPlaying(!props.isPlaying)}
        >
          {props.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </div>
        {props.audiosNumber > 1 && (
          <SkipNextIcon className="skip-btn" onClick={() => props.SkipSong()} />
        )}
      </div>
    </div>
  );
}

export default Controls;
