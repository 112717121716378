import React, { useEffect } from "react";

// import Modal from "react-bootstrap/Modal";
// import { height } from "@mui/system";
import "./docuStyle.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import HeaderV2 from "./../../layout/headerv2/headerv2";
import { useLocation } from "react-router-dom";

const DocusignModal = ({ showEnvelop, toggleEnvelopView, envelopUrl }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // padding: "20px",
  };
  const location = useLocation();

  return (
    <>
      {" "}
      <div>
        <Modal
          open={showEnvelop}
          close={true}
          onClose={toggleEnvelopView}
          // aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <div className="docusign-modal-close">
              <span onClick={toggleEnvelopView}>
                <i className="fa fa-close"></i>
              </span>
            </div> */}
            <Typography>
              <iframe
                style={{
                  paddingRight: "20px",
                  width: "100%",
                  height: "850px",
                }}
                title="docusign"
                // className="docu-iframe"
                allowFullScreen
                frameBorder="0"
                src={envelopUrl.url}
                id="docusign_iframe"
              >
                {" "}
              </iframe>
            </Typography>
          </Box>
        </Modal>
      </div>
      {/* <Modal
        show={showEnvelop}
        fullscreen={true}
        onHide={() => toggleEnvelopView()}
        dialogClassName="docu-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>Modal body content</Modal.Body>
      </Modal> */}
      {/* <Modal
        show={showEnvelop}
        animation={true}
        onHide={() => toggleEnvelopView()}
        // backdrop="static"
        onClose={() => toggleEnvelopView()}
        // keyboard={false}
        // scrollable={true}
        // size="xl"
        fullScreen={true}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton={true}>
          <h2>Document</h2>

          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="docusign"
            width="1100px"
            height="600px"
            src={envelopUrl.url}
          >
            {" "}
          </iframe>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal> */}
    </>
  );
};

export default DocusignModal;
