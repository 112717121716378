import * as React from "react";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import PhotoIcon from "@mui/icons-material/Photo";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ImageUploader from "../../content/controls/ImageUploader";

import AudioPlayer from "../audioPlayer";
import {
  FileValidationType,
  FileUploaderLabel,
} from "../../../services/consts";
import { postDiscoveryContent } from "../../../redux/action/discovery";
import { useDispatch } from "react-redux";
import "../postFeedstyles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ContentUploaderModal() {
  const [image, setImage] = useState([]);
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postTags, setPostTags] = useState([]);
  const [link, setLink] = useState("");
  const [linkList, setLinkList] = useState([]);
  const [open, setOpen] = useState({ isOpen: false, fileType: "" });

  const dispatch = useDispatch();

  const [options, setOptions] = useState({
    acceptedFileTypes: null,
    label: null,
    allowFileTypeValidation: null,
  });

  const handlePostTitle = (value) => {
    setPostTitle(value);
  };

  const handlePostDescription = (value) => {
    setPostDescription(value);
  };

  const handlePostTags = (value) => {
    const tags = value.split(" ").join();
    const formatTags = tags.trim().split(",");
    //  const removeDuplicates = [...new Set(formatTags)];
    setPostTags(formatTags);

    console.log("tags post ", postTags);
  };

  function handleOpen(type) {
    setOpen({ isOpen: true, fileType: type });
    setOptions({
      acceptedFileTypes: FileValidationType[type],
      label: FileUploaderLabel[type],
      allowFileTypeValidation: true,
    });
  }

  const handleClose = () => {
    setOpen(false);
    setImage([]);
    setLinkList([]);
    setPostTitle("");
    setPostDescription("");
    setPostTags("");
  };

  const modifyLinks = (link) => {
    let modifiedLink = "";
    if (open.fileType === "video") {
      const youtubeBaseLink = "https://www.youtube.com/embed/";
      const embedId = link.split("=");
      const embedLink = youtubeBaseLink + embedId[1];
      modifiedLink = embedLink;
      return modifiedLink;
    }
    return link;
  };

  const handleLinks = (e) => {
    const returnedLink = modifyLinks(e);

    setLink(returnedLink);
    addToLinkList();
  };

  const addToLinkList = () => {
    if (link !== "") {
      setLinkList((prevLinks) => [...prevLinks, link]);
      setLink("");
    }
  };
  const clearLink = () => {
    setLinkList([]);
    setLink("");
  };

  const uploadContent = () => {
    let contents = new FormData();
    let contentsInformation = image.map((img) => {
      contents.append("gallery", img.file, img.filename);
      return {
        filename: img.filename,
        tags: [...new Set(postTags)],
        title: postTitle,
        description: postDescription,
      };
    });

    contents.append("data", JSON.stringify(contentsInformation));

    dispatch(postDiscoveryContent(open.fileType, contents)); // method from action file

    handleClose();
  };

  const imageRemover = async (blob) => {
    let check = image.filter(
      (img) =>
        img.filename !== blob.name.split(/(?=jpg|png|jpeg|gif|mp4|mp3)/g)[0]
    );

    setImage([...check]);
  };

  const imageUploader = async (blob) => {
    let imageTemp = {
      filename: blob.name.split(/(?=jpg|png|jpeg|gif|mp4|mp3)/g)[0],
      file: blob,
      tag: "",
      title: "",
      description: "",
      tags: [],
    };
    setImage([...image, imageTemp]);
  };
  const imageUrl = (blob) => {
    let img = window.URL.createObjectURL(blob);
    return img;
  };
  return (
    <div style={{ width: "100%" }}>
      <CardActions className="content_upload">
        <IconButton
          aria-label="add to favorites"
          className="icon_btn"
          onClick={() => handleOpen("photo")}
        >
          <PhotoIcon className="post_icon" sx={{ mr: 0.5 }} />
          <Typography>Photo</Typography>
        </IconButton>
        <IconButton
          aria-label="share"
          className="icon_btn"
          onClick={() => handleOpen("video")}
        >
          <VideoLibraryIcon className="post_icon" sx={{ mr: 0.5 }} />
          <Typography>Video</Typography>
        </IconButton>
        <IconButton
          aria-label="add to favorites"
          className="icon_btn post_feed_content_uploader_audio"
          onClick={() => handleOpen("audio")}
        >
          <AudiotrackIcon className="post_icon" />
          <Typography>Audio</Typography>
        </IconButton>
      </CardActions>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open.isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open.isOpen}>
          <Box sx={style} className="upload_modal">
            <div className="title_closebtn">
              <Typography variant="h6" gutterBottom component="div">
                Create Post{" "}
              </Typography>

              <Button onClick={handleClose} className="avoid_outline">
                {" "}
                <CloseRoundedIcon sx={{ mb: 1 }} />
              </Button>
            </div>
            <ImageUploader
              imageRemover={imageRemover}
              imageUploader={imageUploader}
              options={options}
            />

            {open.fileType === "photo" && (
              <div className="grid-ish">
                {linkList.map((link) => (
                  <div>
                    <img src={link} className="img_style" />
                  </div>
                ))}
                {image.map((img) => (
                  <div>
                    <img className="img_style" src={imageUrl(img.file)} />
                  </div>
                ))}
              </div>
            )}
            {open.fileType === "audio" && (
              <>
                <div>
                  {linkList.map((link) => (
                    <AudioPlayer audioSrc={link} />
                  ))}
                </div>
                <div>
                  {image.map((audio, index) => (
                    <AudioPlayer audioSrc={imageUrl(audio.file)} />
                  ))}
                </div>
              </>
            )}
            {open.fileType === "video" && (
              <div className="grid-ish">
                {linkList.map((link) => (
                  <iframe
                    src={link}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    title="video"
                  />
                ))}

                {image.map((video) => (
                  <div>
                    <video width="400" controls controlsList="nodownload">
                      <source src={imageUrl(video.file)} type="video/mp4" />
                    </video>
                  </div>
                ))}
              </div>
            )}
            <div style={{ height: "14px" }}></div>
            <div sx={{ mt: 2 }}>
              {/* <div sx={{ display: "flex" }}>
                {" "}
                <input
                  onChange={(e) => handleLinks(e.target.value)}
                  value={link}
                  id="upload_input"
                  type="text"
                  placeholder="link "
                  sx={{ mt: 2 }}
                />
                {link && (
                  <Button
                    className="removeBorderLine"
                    size="small"
                    onClick={addToLinkList}
                  >
                    Add
                  </Button>
                )}
                {(link || linkList.length > 0) && (
                  <Button
                    className="removeBorderLine"
                    size="small"
                    color="secondary"
                    onClick={clearLink}
                  >
                    Clear
                  </Button>
                )}
              </div> */}

              <input
                id="upload_input"
                type="text"
                placeholder="post title"
                sx={{ mt: 2 }}
                onChange={(e) => handlePostTitle(e.target.value)}
                value={postTitle}
              />
              <input
                id="upload_input"
                type="text"
                placeholder="post description"
                style={{ minHeight: "70px" }}
                onChange={(e) => handlePostDescription(e.target.value)}
                value={postDescription}
              />
              <input
                id="upload_input"
                type="text"
                placeholder="tags separate by , "
                onChange={(e) => handlePostTags(e.target.value)}
                value={postTags}
              />

              <Button
                variant="outlined"
                size="small"
                className="avoid_outline"
                sx={{ mt: 1 }}
                onClick={uploadContent}
              >
                Post
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
