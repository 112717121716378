import React from "react";
import Header from "../layout/header/index";
import { Footer } from "../layout/footer/index";

const PrivatePolicy = () => {
  return (
    <>
      <Header />
      <div className="private-policy">
        <h1>Private Policy</h1>
        <main id="main-content" className="main-content">
          <div className="w-container">
            <div className="w-richtext">
              <p>
                Thank you for visiting Socicraft.com (the “<em>Website</em>
                ”). Your privacy is important to us. This Privacy Policy (the “
                <em>Policy</em>
                ”) describes the types of information Socicraft Inc. (the “
                <em>Company</em>
                ”, “<em>us</em>
                ”, <em>“we</em>
                ”, or “<em>our</em>
                ”) may collect from you or that you may provide when you visit
                the Website and the products, features, materials, and services
                we offer (collectively with the Website, the&nbsp;“
                <em>Services</em>
                ”). This Policy also describes our policies and procedures for
                collecting, using, maintaining, protecting, and disclosing that
                information.&nbsp;
              </p>
              <p>
                This Policy applies to information we collect on the Website and
                through your use of the Services generally (including when you
                register for an account), and through communications between you
                and the Website (including email, text, and other electronic
                messages).&nbsp;
              </p>
              <p>
                This Policy does <em>not </em>
                apply to information collected by third parties, including any
                websites, services, and applications that you elect to access
                through the Services. It also does not apply to any information
                collected by the Company offline or through any other means,
                including any other website operated by us or any third party
                (including our affiliates and subsidiaries).&nbsp;
              </p>
              <p>
                Please review this Policy carefully. By accessing or using the
                Services (or by clicking on “accept” or “agree” to this Policy
                when prompted), you agree to the terms of this Policy on behalf
                of yourself or the entity or organization that you represent. If
                you do not agree to any term in this Policy, you should refrain
                from further use of our Services.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>
                  1. Children Using or Accessing the Services.&nbsp;
                </strong>
              </p>
              <p>
                The Services and its content are not intended for, nor directed
                at, children under the age of eighteen (18). No one under the
                age of eighteen (18) may provide any personal information to or
                on the Services. Please note that we do not knowingly collect
                any personally identifiable information from children under the
                age&nbsp;of eighteen (18). If you are under the age of eighteen
                (18), please do not attempt to use or register for the Services
                or send any information about yourself to us, including your
                name, address, telephone number, or email address. If it is
                determined that we have inadvertently collected or received
                personally identifiable information from a child under the age
                of eighteen (18), we shall immediately take the necessary steps
                to ensure that such information is deleted from our system’s
                database. If you are a parent or legal guardian and think your
                child under the age of eighteen (18) has given us information,
                you can contact us by email at info@socicraft.com or by phone at
                (800) 287-0339&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>2. Changes to Our Privacy Policy.&nbsp;</strong>
              </p>
              <p>
                This Policy was last revised on the date noted at the top of
                this page. We may update this Policy from time to time. If we
                make material changes, we will post the updated Policy on this
                page and notify you of such changes by means of a notice on the
                Website home page. Your continued use of the Services after we
                make changes is deemed to be acceptance of those changes, so
                please check the Policy periodically for updates.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>3. Information We Collect.&nbsp;</strong>
              </p>
              <p>
                We receive several types information about you from various
                sources, including (A) information and content that you give us;
                (B) automatically collected information; [and] (C) demographic
                information or other aggregate information; and (D) information
                from other sources. Each is described in further details
                below.&nbsp;
              </p>
              <p>
                <em>A. Information and Content You Give Us.&nbsp;</em>
              </p>
              <p>
                We collect personal information that you knowingly choose to
                disclose. This may include:&nbsp;
              </p>
              <p>
                (i) <em>Personal Information. </em>
                Personal information, such as your name, address, e-mail
                address, phone number, fax number, username, password, birth
                date, social security number, and any other information you
                directly provide us on or through the Services. This includes
                information you provide when you register or create an account,
                complete a questionnaire or a contest entry form, or request for
                customer service.&nbsp;
              </p>
              <p>
                (ii) <em>Email Correspondences. </em>
                Records and copies of your email messages together with your
                email address and our responses, if you choose to correspond
                with us through email.&nbsp;
              </p>
              <p>
                (iii) <em>User Content. </em>
                Information or content you submit to be published or displayed
                on public areas of the Services or transmitted to other users of
                the Services or third parties (collectively, “
                <em>User Content</em>
                ”). Your User Content is posted on and transmitted to others at
                your own risk. The Company cannot control the actions of other
                users of the Services with whom you may choose to share your
                User Content.
              </p>
              <p>
                Therefore, we cannot and do not guarantee that your User Content
                will not be viewed by unauthorized persons.&nbsp;
              </p>
              <p>
                (iv) <em>Transaction Information. </em>
                Information about any purchase or transactions made on the
                Services. This includes payment information, such as your credit
                or debit card number and other card information; other account
                and authentication information; and billing, shipping, and
                contact details.&nbsp;
              </p>
              <p>
                (v) <em>Search Queries. </em>
                Your search queries on the Website.
                <br />
                (vi) DESCRIBE ANY OTHER TYPES OF INFORMATION THE USER MAY&nbsp;
              </p>
              <p>PROVIDE.&nbsp;</p>
              <p>
                <em>B. Information We Collect Automatically.&nbsp;</em>
              </p>
              <p>
                We may use a variety of technologies to collect certain
                information about your equipment, browsing actions, and patterns
                whenever you interact with the Services, including:&nbsp;
              </p>
              <p>
                (i) <em>Activity Information. </em>
                Details of your visits to our Services, including the types of
                content you view or engage with; the features you use; the
                actions you take; the people or accounts you interact with; the
                time, frequency, and duration of your activities; and other
                information about your use of and actions on the Services.&nbsp;
              </p>
              <p>
                (ii) <em>Equipment Information. </em>
                Information about your computer and internet connection,
                including your computer operating system, IP address, browser
                type, and browser language.&nbsp;
              </p>
              <p>
                (iii) <em>Location Information</em>. Information about the
                location of your device, including GPS location, for purposes of
                enhancing or facilitating the Services. For example, such
                information may be used to enable the functionality or features
                of the Services that provide you with information about stores
                near you, or enable you to remotely order and pay for the
                Services. We may also use information about the location of the
                device you are using to help us understand how the Services and
                functionality are being used and to deliver more relevant
                advertising.&nbsp;
              </p>
              <p>
                The gathering of this information may be used for maintaining
                the quality of the Services we provide, as well as providing
                overall general statistics related to the use of the Services.
                The technologies we use for this automatic data collection may
                include:&nbsp;
              </p>
              <p></p>
              <p>
                (i) <em>Cookies</em>. A cookie is a small data file stored on
                the hard drive of your computer either for only the duration of
                your visit on a website (“session cookies”) or for a fixed
                period (“persistent cookies”). Cookies contain information that
                can later be read by a web server. We may use cookies to provide
                you with a more personal and interactive experience on the
                Services.&nbsp;
              </p>
              <p>
                (ii) <em>Web Beacons</em>. Web beacons are small files that are
                embedded in webpages, applications, and emails (also known as
                “clear gifs”, “pixel tags”, “web bugs”, and “single-pixel
                gifs”). Web beacons allow the Company, for example, to track who
                has visited those webpages or opened an email, to test the
                effectiveness of our marketing, and for other related website
                statistics.&nbsp;
              </p>
              <p>
                (iii) <em>JavaScripts</em>. JavaScripts are code snippets
                embedded in various parts of websites and applications that
                facilitate a variety of operations including accelerating the
                refresh speed of certain functionality or monitoring usage of
                various online components.&nbsp;
              </p>
              <p>
                (iv) <em>Entity Tags. </em>
                Entity Tags are HTTP code mechanisms that allow portions of
                websites to be stored or “cached” within your browser and
                validates these caches when the website is opened, accelerating
                website performance since the web server does not need to send a
                full response if the content has not changed.&nbsp;
              </p>
              <p>
                (v) <em>HTML5 Local Storage. </em>
                HTML5 local storage allows data from websites to be stored or
                “cached” within your browser to store and retrieve data in HTML5
                pages when the website is revisited.&nbsp;
              </p>
              <p>
                (vi) <em>Resettable Device Identifiers</em>. Resettable device
                identifiers (also known as “advertising identifiers”) are
                similar to cookies and are found on many mobile devices and
                tablets (for example, the “Identifier for Advertisers” or “IDFA”
                on Apple iOS devices and the “Google Advertising ID” on Android
                devices), and certain streaming media devices. Like cookies,
                resettable device identifiers are used to make online
                advertising more relevant.&nbsp;
              </p>
              <p>
                This Policy does not cover the use of tracking technologies by
                third parties. The Services may contain links, content,
                advertising, or references to other websites by third parties,
                including advertisers, ad networks and servers, content
                providers, and application providers. These third parties may
                use cookies or other tracking technologies to collect
                information about you when you use the Services. The information
                they collect may be associated with your personal information or
                they may collect information about your online activities over
                time and across different websites. Please be aware that we do
                not control these third parties’ tracking technologies or when
                and how they may be used. Therefore, the Company does not claim
                nor accept responsibility for any privacy policies, practices,
                or procedures of any third party. We encourage you to read the
                privacy statements and terms and conditions of linked or
                referenced websites you enter. If you have any questions about
                an ad or other targeted content, you should contact the
                responsible provider directly.&nbsp;
              </p>
              <p>
                <em>C. Demographic Information.&nbsp;</em>
              </p>
              <p>
                We may collect demographic, statistical, or other aggregate
                information that is about you, but individually does not
                identify you. Some of this information may be derived from
                personal information, but it is not personal information and
                cannot be tied back to you. Examples of such aggregate
                information include gender, age, race, household income, and
                political affiliation.&nbsp;
              </p>
              <p>
                <em>D. Information from Other Sources.&nbsp;</em>
              </p>
              <p>
                We may receive information about you from other sources and add
                it to our account information. We protect this information
                according to the practices described in this Policy, plus any
                additional restrictions imposed by the source of the data. These
                sources may include online and offline data providers, from
                which we obtain demographic, interest-based, and online
                advertising related data; publicly-available sources such as
                open government databases or social networks; and service
                providers who provide us with information, or updates to that
                information, based on their relationship with you. By gathering
                additional information about you, we can correct inaccurate
                information, enhance the security of your transactions, and give
                you product or service recommendations and special offers that
                are more likely to interest you.&nbsp;
              </p>
              <p>
                <strong>4. How We Use Your Information.&nbsp;</strong>
              </p>
              <p>
                We may use the information we collect about you in a variety of
                ways, including to:&nbsp;
              </p>
              <p>
                (i) provide the Services and its content to you;
                <br />
                (ii) respond to comments and questions, and provide customer
                service;
                <br />
                (iii) fulfill any other purpose for which you provide such
                information;
                <br />
                (iv) communicate with you about your order, purchase, account,
                or subscription;&nbsp;
              </p>
              <p></p>
              <p>
                (v) inform you about important changes to, or other news about,
                the Services or any of its features or content;&nbsp;
              </p>
              <p>
                (vi) operate, maintain, improve, personalize, and analyze the
                Services;&nbsp;
              </p>
              <p>
                (vii) monitor and analyze trends, usage, and activities for
                marketing or advertising purposes;&nbsp;
              </p>
              <p>
                (viii) detect, prevent, or investigate security breaches, fraud,
                and other unauthorized or illegal activity;&nbsp;
              </p>
              <p>
                (ix) carry out our obligations and enforce our rights arising
                from any contracts entered into between you and us, including
                for billing and collection;&nbsp;
              </p>
              <p>
                (x) maintain appropriate records for internal administrative
                purposes; (xi) allow you to participate in interactive features
                on the Services;&nbsp;
              </p>
              <p>
                (xii) send promotional communications, such as information about
                features, newsletters, offers, promotions, contests, and
                events;&nbsp;
              </p>
              <p>
                (xiii) share information across the Company’s products and
                devices to provide a more tailored and consistent experience on
                all Company products you use;&nbsp;
              </p>
              <p>
                (xiv) develop, test and improve new products or services,
                including by conducting surveys and research, and testing and
                troubleshooting new products and features;&nbsp;
              </p>
              <p>
                (xv) In any other way we may describe when you provide the
                information; and (xvi) For any other purpose with your
                consent.&nbsp;
              </p>
              <p>
                We may use the information we have collected from you to enable
                us to display advertisements to our advertisers’ target
                audiences. Even though we do not disclose your personal
                information for these purposes without your consent, if you
                click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria.&nbsp;
              </p>
              <p></p>
              <p>
                <strong>5. How We Share Your Information.&nbsp;</strong>
              </p>
              <p>
                We may disclose aggregated or anonymized information about our
                users without any restrictions. We will not share your personal
                information that we collect or you provide as described in this
                Policy except in the following circumstances:&nbsp;
              </p>
              <p>
                (A){" "}
                <em>
                  With subsidiaries and affiliates for business purposes.{" "}
                </em>
                To our subsidiaries and affiliated companies for purposes of
                management and analysis, decision-making, and other business
                purposes.&nbsp;
              </p>
              <p>
                (B) <em>When we work with service providers. </em>
                To service providers, contractors, and other third parties that
                provide us with support services, such as credit card
                processing, website hosting, email and postal delivery, location
                mapping, product and service delivery, or analytics services,
                and who are bound by contractual obligations to keep personal
                information confidential and use it only for the purposes for
                which we disclose it to them.&nbsp;
              </p>
              <p>
                (C){" "}
                <em>
                  When we become involved in a sale or transfer of the Company.{" "}
                </em>
                If we become involved with a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of the Company’s assets (whether as a going concern or as
                part of bankruptcy, liquidation, or similar proceeding), to
                business entities or people involved in the negotiation or
                transfer.&nbsp;
              </p>
              <p>
                (D) <em>When we are required by law. </em>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.&nbsp;
              </p>
              <p>
                (E) <em>When we enforce our rights. </em>
                To enforce or apply this Policy, our Terms of Use
                (socicraftTermsOfUse.pdf), and other agreements, including for
                billing and collection purposes.&nbsp;
              </p>
              <p>
                (F) <em>To help protect lawful interests. </em>
                If we believe disclosure will help us protect the rights,
                property, or safety of the Company, our users, partners, agents,
                and others. This includes exchanging information with other
                companies and organizations for fraud protection, and spam and
                malware prevention.&nbsp;
              </p>
              <p>
                (G){" "}
                <em>
                  To fulfill the purpose for that information or with your
                  consent.{" "}
                </em>
                To fulfill the purpose for which you provide the information,
                for any other purpose disclosed by us when you provide the
                information, or with your consent.&nbsp;
              </p>
              <p>
                (H) <em>When we work with marketing service providers. </em>
                To marketing service providers to assess, develop, and provide
                you with promotions and special offers
              </p>
              <p>
                that may interest you, administer contests, sweepstakes, and
                events, or for other promotional purposes.&nbsp;
              </p>
              <p>
                (I) <em>When we work with business partners. </em>
                To third parties whom we have strategic marketing alliances or
                partnerships with for the purpose of providing you information
                regarding products and services that we think will be of
                interest to you.&nbsp;
              </p>
              <p>(J) ANY OTHER TYPES OF THIRD-PARTY DISCLOSURES.&nbsp;</p>
              <p>
                Information that you post on or through the public areas of the
                Services (e.g., chat rooms, bulletin boards, and discussion
                groups) are generally accessible to, and may be collected and
                used by, others and may result in unsolicited messages or other
                contact from others. Users of the Services are encouraged to
                exercise caution when providing personal information about
                themselves in public or interactive areas.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>6. Your Choices.&nbsp;</strong>
              </p>
              <p>
                <em>6.1 Mechanisms to Control Your Information.&nbsp;</em>
              </p>
              <p>
                We strive to provide you with choices regarding the personal
                information you provide to us. We have created mechanisms to
                provide you with the following control over your
                information:&nbsp;
              </p>
              <p>
                (i) <em>Cookies &amp;Other Tracking Technologies</em>. You may
                be able to set your browser to reject cookies and certain other
                technologies by adjusting the appropriate settings in your
                browser. Each browser is different, but many common browsers
                have preferences that may be adjusted to allow you to either
                accept or reject cookies and certain other technologies before
                they are set or installed, or allow you to remove or reject the
                use or installation of certain technologies altogether. We
                recommend that you refer to the Help menu in your browser to
                learn how to modify your browser settings. Please note that you
                cannot remove Flash cookies simply by changing your browser
                settings. To learn how you can manage your Flash cookie
                settings, visit the Flash player settings page on Adobe’s
                website. If you disable or refuse cookies, please note that some
                parts of the Services may become inaccessible or may not
                function properly.&nbsp;
              </p>
              <p>
                (ii) <em>Promotional Communications from the Company</em>. If
                you do not wish to have your contact information used by the
                Company to promote our own or third-party products or services,
                you can opt-out by (i) informing us of your preference at the
                time you register for an account, complete an order form, sign
                up for our&nbsp;
              </p>
              <p>
                newsletter, or complete any other form on or through the
                Services which we collect your data; (ii) modifying your user
                preferences in your account profile by checking or unchecking
                the relevant boxes; (iii) following the opt-out instructions in
                the promotional emails we send you; or (iv) sending us an email
                stating your request. Please note that if you opt out of
                receiving promotional communications from us, we may still send
                you transactional communications, including emails about your
                account or purchases.&nbsp;
              </p>
              <p>
                (iii){" "}
                <em>
                  Disclosure of Your Information for Third-Party Advertising
                </em>
                . If you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt-out by (i) informing us of your preference
                at the time you register for an account, complete an order form,
                sign up for our newsletter, or complete any other form on or
                through the Services which we collect your data; (ii) modifying
                your user preferences in your account profile by checking or
                unchecking the relevant boxes; (iii) following the opt-out
                instructions in the promotional emails we send you; or (iv)
                sending us an email stating your request.&nbsp;
              </p>
              <p>
                (iv) <em>Targeted Advertising</em>. If you do not want us to use
                information that we collect or that you provide to us to deliver
                advertisements according to our advertisers’ target-audience
                preferences, you can opt-out by modifying your user preferences
                in your account profile by checking or unchecking the relevant
                boxes or sending us an email stating your request. We do not
                control third parties’ collection or use of your information to
                serve interest-based advertising. However, these third parties
                may provide you with ways to choose not to have your information
                collected or used in this way. Many advertising companies that
                collect information for interest- based advertising are members
                of the Digital Advertising Alliance or the Network Advertising
                Initiative, both of which maintain websites where people can opt
                out of interest-based advertising from their members. To opt-out
                on these pages, visit www.AboutAds.info and
                www.networkadvertising.org.&nbsp;
              </p>
              <p>
                (v) <em>Location Information</em>. You may be able to adjust the
                settings of your device so that information about your physical
                location is not sent to us or third parties by (a) disabling
                location services within the device settings; or (b) denying
                certain websites or mobile applications permission to access
                location information by changing the relevant preferences and
                permissions in your mobile device or browser settings. Please
                note that your location may be derived from your WiFi,
                Bluetooth, and other device settings. If you have questions
                about how to disable your device’s location services, we
                recommend you contact your mobile service carrier or your device
                manufacturer.&nbsp;
              </p>
              <p></p>
              <p>
                (vi) <em>Voice Transmissions</em>. You may be able to adjust the
                settings of your device so that your voice transmissions cannot
                be sent to us or third parties by (a) disabling microphone and
                speech recognition services within the device settings; or (b)
                denying certain websites or mobile applications permission to
                access microphone and speech recognition services by changing
                the relevant preferences and permissions in your mobile device
                or browser settings.&nbsp;
              </p>
              <p>
                <em>6.2 How We Respond to Do Not Track Signals&nbsp;</em>
              </p>
              <p>
                “Do Not Track” is a privacy preference you can set in most web
                browsers. When you turn on this preference, it sends a signal or
                message to the websites you visit indicating that you do not
                wish to be tracked. Please note that we currently do not respond
                to Do Not Track browser settings.&nbsp;
              </p>
              <p>
                <strong>
                  7. Accessing and Correcting Your Information.&nbsp;
                </strong>
              </p>
              <p>
                You may send us an email to request access to, correct, or
                remove any personal information that you have provided to us.
                You may also access, correct, or remove your personal
                information by logging into the Website and visiting your
                account profile page. We cannot delete your personal information
                except by also deleting your account.&nbsp;
              </p>
              <p>
                We may not accommodate a request to change information if we
                believe the change would violate any law or legal requirement or
                cause the information to be incorrect.&nbsp;
              </p>
              <p>
                If you delete your User Content from the Services or your
                account, copies of your User Content may remain viewable in
                cached and archived pages, or might have been copied or stored
                by other users of the Services.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>8. California Privacy Rights.&nbsp;</strong>
              </p>
              <p>
                Under California Civil Code sections 1798.83-1798.84, California
                residents are entitled to ask us for a notice identifying the
                categories of personal customer information which we share with
                our affiliates and third parties for marketing purposes, and
                providing contact information for such affiliates and third
                parties. If you are a California resident and would like a copy
                of this notice, please submit a written request to the following
                address:&nbsp;
              </p>
              <p>
                1261 Locust Street, #221, Walnut Creek, California, 94596&nbsp;
              </p>
              <p>
                We will make every effort to promptly respond to your
                request.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>9. How We Protect Your Information.&nbsp;</strong>
              </p>
              <p>
                We take reasonable precautions to secure your personal
                information. We have implemented technical, physical, and
                administrative security measures designed to reduce the risk of
                loss, misuse, unauthorized access, disclosure, or modification
                of your information. We have put in place appropriate physical,
                electronic, and managerial procedures to safeguard the
                information we collect. All information you provide to us is
                stored on our secure servers behind firewalls. When you transmit
                highly sensitive information (such as a credit card number)
                through the Services, we encrypt the transmission of that
                information using Secure Sockets Layer (SSL) technology.&nbsp;
              </p>
              <p>
                The safety and security of your information also depend on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of the Services, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone.&nbsp;
              </p>
              <p>
                While we have employed security technologies and procedures to
                assist safeguarding your personal information, no system or
                network can be guaranteed to be 100% secure. Unauthorized entry
                or use, hardware or software failure, and other factors may
                compromise the security of user information at any time. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Website or any other
                Services.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>10. Terms of Use.&nbsp;</strong>
              </p>
              <p>
                If you choose to visit the Services, your visit and any dispute
                over privacy is subject to this Policy and our Terms of Use
                including limitations on damages, resolution of disputes, and
                application of the laws of the State of California.
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>11. Contact Us.&nbsp;</strong>
              </p>
              <p>
                We welcome your questions, comments, and concerns about privacy.
                You can contact us at:&nbsp;
              </p>
              <p>
                ADDRESS: 1261 Locust Street, #221, Walnut Creek, California,
                94596&nbsp;
              </p>
              <p>
                E-MAIL ADDRESS info@socicraft.com
                <br />
                Toll-free number: 800-287-0339
              </p>
              <p>&zwj;</p>
              <p>&zwj;</p>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PrivatePolicy;
