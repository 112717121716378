export const trendingListsTestData = [
  {
    listingTitle: "space program of aliens",
    listingImage:
      "https://images.unsplash.com/photo-1665072523770-601bd7e96aef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
    username: "luis",
    views: 812,
    postedDate: "19/07/2022",
    listingId: "902101292103io23",
  },
  {
    listingTitle: "project wakanda",
    listingImage:
      "https://images.unsplash.com/photo-1664907560281-9c038d860fd0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
    username: "wakanda",
    views: 373,
    postedDate: "12/09/2021",
    listingId: "0293023lkedfjs023",
  },
  {
    listingTitle: "AI and UI",
    listingImage:
      "https://images.unsplash.com/photo-1665044318127-69802cb5fcb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
    username: "ultron",
    views: 343,
    postedDate: "12/07/2020",
    listingId: "kjsdlkjfaksjd2903",
  },
  {
    listingTitle: "art gallery",
    listingImage:
      "https://images.unsplash.com/photo-1665054546585-11413bcdfb52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzNXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
    username: "newage",
    views: 922,
    postedDate: "22/07/2020",
    listingId: "kjsdlkjfaksjdf",
  },
  {
    listingTitle: "super AI",
    listingImage:
      "https://images.unsplash.com/photo-1664747477807-566fbc875bdc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0NHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
    username: "newone",
    views: 456,
    postedDate: "30/09/2019",
    listingId: "09309ksdjflk02",
  },
];
