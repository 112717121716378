import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { createViewEnvelope } from "../../../redux/action/docusign";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Spinner from "../../content/modal/spinnermodal";
export default function DocusignReturnPage({ match }) {
  const [envelopeUrl, setEnvelopeUrl] = useState(null);
  const docId = match.params.id;
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(createViewEnvelope(docId)).then((data) => {
      setEnvelopeUrl(data);
    });
  }, []);
  return (
    <>
      {envelopeUrl ? (
        <iframe
          style={{
            paddingRight: "20px",
            width: "100%",
            height: "850px",
          }}
          title="docusign"
          // className="docu-iframe"
          allowFullScreen
          frameBorder="0"
          src={envelopeUrl.url}
          id="docusign_iframe"
        ></iframe>
      ) : (
        <div className="m-auto">
          <Spinner />
        </div>
      )}
    </>
  );
}

// <iframe
//   width="900px"
//   height="900px"
//   title="sign"
//   src="https://demo.docusign.net/Signing/MTRedeem/v1/ec134107-67a4-454b-b729-ae73aa615b82?slt=eyJ0eXAiOiJNVCIsImFsZyI6IlJTMjU2Iiwia2lkIjoiNjgxODVmZjEtNGU1MS00Y2U5LWFmMWMtNjg5ODEyMjAzMzE3In0.AQYAAAABAAMABwAANCTUEn7aSAgAANQ1WzR-2kgYAAEAAAAAAAAAIQC9AgAAeyJUb2tlbklkIjoiYzMyZjkwZDAtOGYyMy00MzZhLWFlNTctN2Q1MDgxZGRkZjAxIiwiRXhwaXJhdGlvbiI6IjIwMjItMDgtMTRUMTY6Mzk6MTYrMDA6MDAiLCJJc3N1ZWRBdCI6IjIwMjItMDgtMTRUMTY6MzQ6MTYuNzEzNTkxMyswMDowMCIsIlJlc291cmNlSWQiOiIzNmViZTZhYy1kNmZiLTQ2MzktYjYzMS02YWI3MGM2Y2U0MDUiLCJSZXNvdXJjZXMiOiJ7XCJFbnZlbG9wZUlkXCI6XCIzNmViZTZhYy1kNmZiLTQ2MzktYjYzMS02YWI3MGM2Y2U0MDVcIixcIkFjdG9yVXNlcklkXCI6XCJlZDI5Mjg3Mi1hMWZjLTQyZDEtYmIzZS1kNjgwMjQxNTc4NThcIixcIlJlY2lwaWVudElkXCI6XCI5ZTkzZDBiZi1hODFmLTRjMTAtODk2OC0xODI2NzA5ZmIxOThcIixcIkZha2VRdWVyeVN0cmluZ1wiOlwidD1kMDkxYTM0NC03YTg3LTQyMzAtOThhMy1mNjMzZTk2OGVhNmRcIixcIkludGVncmF0b3JLZXlcIjpcIjBmZDIzODJjLTNlZjAtNDg4Ni1iODM3LTE1YjliMjU4MTkwYVwifSIsIlRva2VuVHlwZSI6MSwiQXVkaWVuY2UiOiIyNWUwOTM5OC0wMzQ0LTQ5MGMtOGU1My0zYWIyY2E1NjI3YmYiLCJSZWRpcmVjdFVyaSI6Imh0dHBzOi8vZGVtby5kb2N1c2lnbi5uZXQvU2lnbmluZy9TdGFydEluU2Vzc2lvbi5hc3B4IiwiSGFzaEFsZ29yaXRobSI6MCwiSGFzaFJvdW5kcyI6MCwiVG9rZW5TdGF0dXMiOjAsIklzU2luZ2xlVXNlIjpmYWxzZX0_AID7W4YTftpI.XbhCQk4-YJxSV8PZOZ-QAkGDsny_w-zsaCL7opENrNyp9tpMo9WJRFQvp4LzJdmrUuZw_6QkMqMjL3BE36cQepylIpKG3t2fiIP2_Oxr8dEd2Lzh3cG9WhzX2g_iBo6ro8xo88wnXoRcvV6xYjB5P4UU7nUObfHMxCwtkfhcRfsWV_0iZ8NcRuJA8QtrcZ1DLPE__DSIKu8w5m_e1Sc40rxuxZ87zm6lnpdaRYfOzBADJQemlNrGOpE09P0SVRqowYJQYzNmSBjhf2DJoi-o3VwQjVb71twoqizM7VOIkd5Y6XMv5fREXDmWVqAUyjWo6m0CAHLFGEMjJPzFF3HQ3w"
// >
//   {" "}
// </iframe>;
